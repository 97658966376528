"use strict";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import { RefleshButton } from "../common/CustomButton"
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { formatDateHHmmdd, getSystemDatetime } from "../../common/utils/DateUtils";
import { viewId, titleName, table, masterClassification, masterStatus } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";
import { errorLog } from "../../common/logger/Logger";
import Forbidden from "../../_components/Forbidden";

const masterListTemplate = [
  {
    "MasterId": viewId.segmentInfo,
    "Url": "/master/group/segmentInfo",
    "Outline": "グループ全体の事業セグメントの有効期間等の情報を管理します",
    "AuthKey": "SegmentInfo_Set",
  },
  {
    "MasterId": viewId.associationInfo,
    "Url": "/master/org/associationInfo",
    "Outline": "組織の第1階層の関連付けを管理します",
    "AuthKey": "AnalysisHQAssociations_Set",
  },
  {
    "MasterId": viewId.hierarchyInfo,
    "Url": "/master/org/hierarchyInfo",
    "Outline": "CHORFY上で分析対象となる組織階層を管理します",
    "AuthKey": "DepartmentHierarchy_Set",
  },
  {
    "MasterId": viewId.newGraduateInfo,
    "Url": "/master/dashboard/newGraduateInfo",
    "Outline": "人員構成シミュレーションに使用する新卒採用に関する設定値を管理します",
    "AuthKey": "NewGraduate_Set",
  },
  {
    "MasterId": viewId.recruitmentPlanInfo,
    "Url": "/master/dashboard/recruitmentPlanInfo",
    "Outline": "年度ごとの採用予定人数を管理します",
    "AuthKey": "RecruitmentPlan_Set",
  },
  {
    "MasterId": viewId.absenceRateInfo,
    "Url": "/master/dashboard/absenceRateInfo",
    "Outline": "高欠勤と判断するための欠勤率の閾値を管理します",
    "AuthKey": "AbsenceRate_Set",
  },
]

const MasterListContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [groupList, setGroupList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);
  const [dashboardList, setDashboardList] = React.useState([]);
  const [lastFetchDatetime, setLastFetchDatetime] = React.useState();
  const authConf = props.authority.Master_AuthInfo.Auth_Conf;

  React.useEffect(() => {
    document.title = props.pageTitle;
    init();
    if (props.message) {
      props.showToast(props.message);
    }
  }, []);

  const _getMaster = async () => {
    try {
      let master = await getDynamoDB(
        table.Master,
        null,
        props.corporationNumber,
        null,
        null
      );
      return master;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.master, "getMaster", error);
    }
  }

  /**
   * 権限のないマスターは画面表示しないようにフィルターする
   */
  const _filterAuthMaster = (list, classification) => {
    return list.filter(item => {
      const master = masterListTemplate.find(m => m.MasterId === item.MasterId);
      return master && authConf?.[classification]?.[master.AuthKey] === true;
    });
  }

  const _setMaster = async () => {
    try {
      // マスター情報取得
      let master = await _getMaster();
      master = master.sort((a, b) => a.MasterId.localeCompare(b.MasterId));

      if (master.length) {
        // グループ会社関連マスター
        // 親会社でない、もしくは権限を持っていない場合は表示しない
        if (props.isParent) {
          const group = master.filter(m => m.Classification == masterClassification["group"].value);
          const authGroup = _filterAuthMaster(group, "Group");
          setGroupList(authGroup);
        }

        // 組織関連マスター
        const department = master.filter(m => m.Classification == masterClassification["department"].value);
        const authDepartment = _filterAuthMaster(department, "Department");
        setDepartmentList(authDepartment);

        // ダッシュボード設定
        const dashboard = master.filter(m => m.Classification == masterClassification["dashboard"].value);
        const authDashboard = _filterAuthMaster(dashboard, "Dashboard");
        setDashboardList(authDashboard);
      }
      setLastFetchDatetime(formatDateHHmmdd(getSystemDatetime()));
    } catch (error) {
      errorLog("E000001", props.userId, viewId.master, "setMaster", error);
    }
  }

  const init = async () => {
    await _setMaster();
    setIsLoaded(true);
  };

  const BaseTable = ({ list }) => {
    if (list.length) {
      return (
        <div className="setting-table is-master">
          <table className="setting-table__table table-fixed w-full">
            <thead>
              <tr>
                <th className="setting-table__col-master-name">機能名</th>
                <th className="setting-table__col-master-Outline">機能概要</th>
                <th className="setting-table__col-master-last-update-date">最終更新日時</th>
                <th className="setting-table__col-master-last-update-user">最終更新者</th>
                <th className="setting-table__col-master-status">ステータス</th>
              </tr>
            </thead>
            <tbody className="text-caption-1">
              {list.map((master, idx) => {
                const t = masterListTemplate.find(t => t.MasterId == master.MasterId);
                return (
                  <tr key={idx}>
                    <td>
                      <Link variant="button" underline="none" component={RouterLink} to={t.Url}>
                        <span style={{ fontSize: "16px", textTransform: "none" }}>{master.MasterName}</span>
                      </Link>
                    </td>
                    <td>{t.Outline}</td>
                    <td>{master.UpdateDateTime.slice(0, -3)}</td>
                    <td>{master.UpdateUserName}</td>
                    <td>{masterStatus[master.Status].name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="text-center my-10">
          <span className="text-h5 red--text">登録可能なマスター情報がありません。</span>
        </div>
      );
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{titleName.masterList}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              {/*  ===== リード ===== */}
              <p className="text-body text-center mb-10">
                CHROFYで利用する各種マスターを管理します。必要に応じて設定値を変更してください。
              </p>
              {/*  ===== /リード ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-16 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">マスター一覧</h2>
                <div className="flex mt-4">
                  <div className="base-table-header div-mid mb-1">
                    <span className="text-caption-2 pr-2">{lastFetchDatetime} 時点</span>
                    <div className="btn-group">
                      <RefleshButton className="btn header-btn small-btn white--text flex"
                        name="一覧更新" onClick={() => _setMaster()} />
                    </div>
                  </div>
                </div>
                {/*  ===== エラーメッセージ表示===== */}
                <div className="error-messages">
                  {props.errorMessages && Object.keys(props.errorMessages).flatMap((key, keyIdx) =>
                    props.errorMessages[key].map((msg, msgIdx) => (
                      <span key={`${key}-${msgIdx}`} className="red--text">{(keyIdx !== 0 || msgIdx !== 0) && <br />}{msg.errorMessage}</span>
                    ))
                  )}
                </div>
                {/*  ===== マスター一覧 ===== */}
                {/*  ===== グループ会社関連マスター ===== */}
                {props.isParent &&
                  <React.Fragment>
                    <div className="flex items-center justify-between my-6">
                      <div className="text-body-2-medium grow">
                        <div>■{masterClassification.group.name}</div>
                      </div>
                    </div>
                    <BaseTable list={groupList} />
                  </React.Fragment>
                }
                {/*  ===== /グループ会社関連マスター ===== */}
                {/*  ===== 組織関連マスター ===== */}
                <div className="flex items-center justify-between my-6">
                  <div className="text-body-2-medium grow">
                    <div>■{masterClassification.department.name}</div>
                  </div>
                </div>
                <BaseTable list={departmentList} />
                {/*  ===== /組織関連マスター ===== */}
                {/*  ===== ダッシュボード設定 ===== */}
                <div className="flex items-center justify-between my-6">
                  <div className="text-body-2-medium grow">
                    <div>■{masterClassification.dashboard.name}</div>
                  </div>
                </div>
                <BaseTable list={dashboardList} />
                {/*  ===== /ダッシュボード設定 ===== */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

const MasterList = () => {
  const state = useSelector((state) => state);
  const location = useLocation();
  const showToast = useToast();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability) {
    return <Forbidden />
  }

  return <MasterListContent
    pageTitle={titleName.masterList + titleName.common}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    isParent={state.company.isParent}
    message={location.state?.message}
    errorMessages={location.state?.errorMessages}
    showToast={showToast}
    authority={state.authority}
  />;
};

export default MasterList;
