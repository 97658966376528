"use strict";
import * as React from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";

import { formatDate } from "../../common/utils/DateUtils";
import { PathInfoList } from "../../_constants/PathInfoList";
import { getNowDispStateCtgSize, getNowDispStateBdgSize } from "../../services/calc/CalcDispSize";

import { mainPageInfoList, monitoringInfoList } from "../../_constants/PageInfoList";
import { viewId, allConditions, restApiKey, listingClassification, companySize as companySizeCode } from "../../_constants/Code";
import img_category_mydashboard from "../../assets/img/category/img_category_mydashboard.png";
import img_my_dashboard_init from "../../assets/img/my-dashboard/img_my-dashboard_init.png";
import Loading from '../../assets/img/Loading/Loading.svg';
import Forbidden from "../../_components/Forbidden";

import { errorLog } from "../../common/logger/Logger";
import { titleName, orderBy, table, analysisMode } from "../../_constants/Code";
import { getPageText, getFileNameList, readDashboardId, getBenchmarkCompany, getIndustrySector } from "../../common/utils/FileUtils";
import { getDynamoDB, registDynamoDB, updateDynamoDB, deleteDynamoDB } from "../../common/utils/DynamoDbUtils";
import { httpGet } from '../../services/api/AmplifyApi';
import { availabilityMiddleTitle, availabilitySmallTitle } from '../../common/utils/AnalysisModeUtil';

import '../../assets/css/vendor/normalize.css'
import '../../assets/css/vendor/animate.min.css'
import '../../assets/css/vendor/hamburgers.css'
import '../../assets/icon/style.css'
import '../../assets/css/style.css'
import '../../assets/js/tailwindcss.js'
import '../../assets/js/adobe_fonts.js'

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

//@GlobalCustomizeFunction
class MyDashboardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isDashboardLoaded: false,
      isBenchmarkRelDashboard: false,
      isKpiRelDashboard: false,
      //@ComponentState
      pageInfoDashboardKey: '',
      isOpenYear: false,
      year: '',
      month: '',
      isYear: true,
      isAndOr: true,
      pageText: null,
      bmSet: [],
      bmVersion: [],
      bmMemo: '',
      bmError: false,
      bmDispStatus: null,
      toastMessage: null,
      ctSet: [],
      ctCategoryName: [],
      ctCategory: [],
      ctError: false,
      scError: false,
      dispStateBm: '',
      dispStateCtg: '',
      dispStateAndOr: '',
      dispStateBdg: '',
      saveConBtnFlg: true,
      kpiTargetInfoList: [],
      kpiTargetInfo: {},
      isOpenKpiYear: false,
      relDashboardList: [],
      benchmarkTarget: {},
      benchmarkTargetList: [],
    };
    //@Component
    this.yearRef = React.createRef();
    this.kpiYearRef = React.createRef();
    this.mode = this.props.mode;
    this.dashboard;
    this.dashboardAuthConf = this.props.dashboardAuthConf;
    this.dashboardInfo;
    this.kpiTargetInfo = [];
    this.relDashboardList = [];
    this.corpNumList = [this.props.corporationNumber];
    this.pageInfoList = mainPageInfoList.concat(monitoringInfoList);
    this.industrySector = [];
    this.jokatsuIndustrySector = [];
    this.edinetIndustrySector = [];
    this.companySize = [];
  }
  dashboardList = [];
  pageInfoKey;
  defaultShowCount = 0;
  isShowAll = false;
  yearList = [];
  bmList = [];
  dashboardKeyList = [];
  dashboardKeyBmList = [];
  dashboardKeyCtList = [];
  dashboardKeySearchList = [];
  dashboardAccount = 0;

  radioBmAllFlg = true;
  radioBmHaveFlg = false;
  radioBmNohaveFlg = false;
  chBoxCtgAllFlg = true;
  chBoxCtgFlg = [];
  chBoxBdgAllFlg = true;
  chBoxBdgFlg = [];
  doTimeForReset = 0;

  //@CustomizeFunction
  keyinfo = (str) => {
    if (str == undefined) {
      return { key: "", index1: 0, index2: 0 };
    } else {
      const a = str.split(".");
      return { key: a[0], index1: Number(a[1]) - 1, index2: Number(a[2]) - 1 };
    }
  };

  getDashboardList = (key) => {
    let page;
    if (key < 6 || key.startsWith('S')) { page = mainPageInfoList.find(page => { return page.key === key; }); }
    else { page = monitoringInfoList.find(page => { return page.key === key; }); }
    return page.dashboardList;
  };

  getQuickSightDashboardEmbedURL = async (dskey, isYear) => {
    if (dskey != undefined) {
      this.setState({
        isDashboardLoaded: false,
      });
      // 設定ファイルを取得
      try {
        const keyobj = this.keyinfo(dskey);
        let isKpi;
        let isBenchmark;
        // マイダッシュボードにベンチマーク利用、KPI関連ダッシュボードが含まれているか判定
        if (this.dashboardKeyList.some(v => v.startsWith('S'))) {
          isKpi = true;
          isBenchmark = true;
        } else if (this.dashboardKeyList.some(v => v.startsWith('10'))) {
          isKpi = false;
          isBenchmark = true;
        } else {
          isKpi = false;
          isBenchmark = false;
        };
        this.setState({
          titleIdx: keyobj.index1,
          dashboardIdxSub: keyobj.index2,
          pageInfoDashboardKey: dskey,
        });
        this.pageInfoKey = keyobj.key;
        if (keyobj.key !== "") {
          this.dashboardList = this.getDashboardList(keyobj.key, keyobj.index1);
          this.dashboardInfo = this.dashboardList[keyobj.index1].dashboard[keyobj.index2];
        }
        // KPI情報取得
        if (isKpi) {
          await this.getKpiTargetInfo();
          this.setKpiTargetInfoList();
          await this.getRelDashboard();
          this.setRelDashboardList();
        };
        // ベンチマーク情報取得
        if (isBenchmark) {
          this.companySize = Object.keys(companySizeCode).reduce((l, key) => {
            l[key] = companySizeCode[key].name;
            return l;
          }, {});
          await this.getBenchmarkIndustrySector(this.dashboardKeyList, dskey);
          await this.getBenchmarkTarget();
        };
        //　ダッシュボードIDを取得
        const dashboardId = await this.getDashboardId(isYear);
        // EmbedURL取得
        Auth.currentAuthenticatedUser().then((user) => {
          const init = {
            queryStringParameters: {
              userName: user.username,
              dashboardId: dashboardId, // TODO ダッシュボードの特定
            },
            headers: {},
          };
          httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.quickSightEmbedUrl(this.props.corporationNumber), init)
            .then(async (res) => {
              const containerDiv = document.getElementById("embeddingContainer");
              if (!res.EmbedUrl.startsWith("Error generating embedding url:")) {
                if (containerDiv != null) {
                  containerDiv.innerHTML = "";
                }
                const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
                const frameOptions = {
                  url: res.EmbedUrl,
                  container: containerDiv,
                  height: "AutoFit",
                  width: "100%",
                  resizeHeightOnSizeChangedEvent: true,

                  onChange: (changeEvent, metadata) => {
                    switch (changeEvent.eventName) {
                      case 'FRAME_LOADED': {
                        if (this.tmpChangeDSKey != null) {
                          this.changeDashboard(this.tmpChangeDSKey, true);
                          this.tmpChangeDSKey = null;
                        } else {
                          if (this.firstChangeDSKey != null) {
                            this.changeDashboard(this.firstChangeDSKey, true);
                            this.firstChangeDSKey = null;
                          } else {
                            this.setState({
                              isDashboardLoaded: true,
                            });
                          }
                        }
                        this.setState({
                          isLoaded: true,
                        });
                        break;
                      }
                    }
                  },
                };
                const contentOptions = {
                  parameters: paramObj,
                  locale: "ja-JP",
                  sheetOptions: {
                    emitSizeChangedEventOnSheetChange: true,
                  },
                  toolbarOptions: {
                    export: true,
                  },
                  attributionOptions: {
                    overlayContent: true,
                  },
                  onMessage: async (messageEvent, experienceMetadata) => {
                    switch (messageEvent.eventName) {
                      case 'ERROR_OCCURRED': {
                        this.setState({
                          isDashboardLoaded: true,
                        });
                        break;
                      }
                    }
                  },
                };
                const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
                this.dashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions);
              } else {
                containerDiv.innerHTML =
                  "ダッシュボードのURL取得に失敗しました。画面を再表示してください。";
              }
            })
            .catch((err) => {
              this.setState({
                isDashboardLoaded: true,
              });
              throw new Error(err);
            });
        });
      } catch (err) {
        errorLog("E000001", this.props.userId, this.props.pageId, "getQuickSightDashboardEmbedURL", err);
      }
    }
  };

  titleIdx = 0;
  dashboardIdxSub = 0;
  dashboardKeyForChange = "";
  changeDashboard = async (dskey, reloadFlg) => {
    try {
      this.dashboardKeyForChange = dskey;
      let ymObj = await this.getYearList({
        year: this.state.year,
        month: this.state.month,
      });
      this.setState({
        isDashboardLoaded: false,
      });
      const keyobj = this.keyinfo(dskey);
      this.titleIdx = keyobj.index1,
        this.dashboardIdxSub = keyobj.index2,
        this.setState({
          pageInfoDashboardKey: dskey,
        });

      // フィルター条件 "業種" の設定変更
      if (dskey.startsWith('10')) {
        this.industrySector = this.jokatsuIndustrySector;
      } else if (dskey.startsWith('S')) {
        this.industrySector = this.edinetIndustrySector;
      } else {
        this.industrySector = [];
      };

      this.pageInfoKey = keyobj.key;
      this.dashboardList = this.getDashboardList(keyobj.key);
      this.dashboardInfo = this.dashboardList[keyobj.index1].dashboard[keyobj.index2];
      const kpiTargetInfo = this.setKpiTargetInfoList();
      this.setRelDashboardList();
      //　ダッシュボードIDを取得
      const dashboardId = await this.getDashboardId(this.state.isYear);
      const paramObj = await this.getParameters(ymObj.year, ymObj.month, kpiTargetInfo, this.state.benchmarkTarget);
      const options = {
        parameters: paramObj,
      };
      await this.dashboard.navigateToDashboard(dashboardId, options);
      this.setBmInfo();
      if (this.dashboardInfo != undefined && this.bmSet[this.dashboardInfo.key] != undefined) {
        this.bmHaveFlg = true;
      } else {
        this.bmHaveFlg = false;
      }

      await this.getCt();
      this.setCtSet(dskey);
      if (this.ctgCategory == undefined || this.ctgCategory == []) {
        this.ctgCheckCategoryNm = [];
      } else {
        this.ctgCheckCategoryNm = [];
        this.ctgCategory.map((item) => {
          this.catList.map((itemCat) => {
            if (itemCat["CategoryKey"] == item) {
              this.ctgCheckCategoryNm.push(this.ctgCategoryName[item]);
            }
          })
        })
      }
      if (reloadFlg) {
        this.dashboardKeyDispList.map((itemD, indexD) => {
          this.dashboardKeyList.map((itemL, indexL) => {
            if (this.defaultShowCount == 0 || indexD < this.defaultShowCount) {
              if (itemD == itemL) {
                document.getElementById("liNav" + indexD).classList.remove('display-none');
              }
            }
          })
        })
      }
      this.setState({
        isDashboardLoaded: true,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeDashboard", error);
      this.setState({
        isDashboardLoaded: true,
      });
    }
  };

  getDashboardNameByKey = (dskey) => {
    const keyobj = this.keyinfo(dskey);
    return this.state.pageText[keyobj.key].dashboardList[keyobj.index1].dashboard[keyobj.index2].name;
  };

  getDashboardItemByKey = (dskey) => {
    const keyobj = this.keyinfo(dskey);
    const list = this.getDashboardList(keyobj.key, keyobj.index1);
    return list[keyobj.index1].dashboard[keyobj.index2];
  };

  showAllLink = () => {
    let showAllBtn = document.getElementById("showAllBtn");
    let myDashboardToggleText = document.getElementById("myDashboardToggleText");
    let ulNav = document.getElementById("ulNav");
    if (showAllBtn.classList.contains('show-part')) {
      showAllBtn.classList.remove('show-part');
      myDashboardToggleText.textContent = ('一部を表示');
      for (let i = 0; i < ulNav.children.length; i++) {
        if (ulNav.children[i].children[0].classList.contains('display-none')) {
          ulNav.children[i].children[0].classList.remove('display-none');
        }
      }
      this.isShowAll = true;
    } else {
      showAllBtn.classList.add('show-part');
      myDashboardToggleText.textContent = ('すべて表示（全' + this.dashboardKeyDispList.length + '件）');
      for (let i = 0; i < ulNav.children.length; i++) {
        if (i >= this.defaultShowCount) {
          ulNav.children[i].children[0].classList.add('display-none');
        }
      }
      this.isShowAll = false;
    }

  };

  getYearList = async (currentYearMonth = null) => {
    let rtnObj = null;
    try {
      const baseKey = process.env.REACT_APP_FOLDER_DATA_SET_BASE;
      let fileKey = this.isMonthly() ? process.env.REACT_APP_FOLDER_STANDARD_FORMAT_MONTH : process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL;
      let fileList = await getFileNameList(this.props.bucketName, baseKey + fileKey, orderBy.desc);

      if (process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL == fileKey) {
        // 最初のアンダースコアより前が数値のものを取り出し、"年度"をつける
        fileList = fileList
          .map((item) => {
            return item.split("_")[0];
          })
          .filter((item) => {
            return !isNaN(item);
          })
          .map((item) => {
            return item + "年度";
          });
        this.yearList = fileList;
        rtnObj = {
          year: (!currentYearMonth) ? this.yearList[0] : currentYearMonth.year.substring(0, 4) + "年度",
          month: '',
        }
        this.setState(rtnObj);
      } else if (process.env.REACT_APP_FOLDER_STANDARD_FORMAT_MONTH == fileKey) {
        fileList = fileList
          .map((item) => {
            return item.split(".")[0];
          })
          .filter((item) => {
            return !isNaN(item);
          })
          .map((item) => {
            if (item.length > 4) {
              let m = item.substring(4);
              item = item.substring(0, 4) + "年" + ((m.indexOf("0") == 0) ? m.substring(1) : m) + "月";
            }
            return item;
          });
        this.yearList = fileList;
        if (!currentYearMonth) {
          rtnObj = {
            year: this.yearList[0].substring(0, 5),
            month: this.yearList[0].substring(5),
          }
          this.setState(rtnObj);
        } else {
          rtnObj = {
            year: currentYearMonth.year.substring(0, 5),
            month: (!currentYearMonth.month) ? this.yearList[0].substring(5) : currentYearMonth.month,
          }
          this.setState(rtnObj);
        }
      }
      return rtnObj;
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "getYearList",
        error
      );
    }
  };

  getDashboardId = async (isYear) => {
    const dashboardInfoList = await readDashboardId(this.props.bucketName);
    const dashboardIds = dashboardInfoList[this.dashboardInfo.key][analysisMode.standalone];
    const dashboardId = !this.dashboardInfo.quarterFlg || isYear
      ? dashboardIds.yearDashboardId
      : dashboardIds.quarterDashboardId;
    return dashboardId;
  }

  getParameters = async (year, month, kpiTargetInfo, benchmarkTarget) => {
    const lDashboardKey = this.dashboardInfo.key.split('.')[0];
    const mDashboardKey = this.dashboardInfo.key.split('.')[1] - 1;
    const categoryList = [
      lDashboardKey + ' ' + this.state.pageText[lDashboardKey].name,
      this.state.pageText[lDashboardKey].dashboardList[mDashboardKey].name,
    ];
    let parameters = [
      {
        Name: "ownCorpNum",
        Values: [this.corpNumList],
      },
      {
        Name: "year",
        Values: [year],
      },
      {
        Name: "outputCategory",
        Values: [categoryList.join(' > ').replaceAll('&', '＆')]
      },
      {
        Name: "categoryExplanation",
        Values: [this.state.pageText[lDashboardKey].dashboardList[mDashboardKey]?.categoryExplanation]
      },
      {
        Name: "cloudFrontUrl",
        Values: [process.env.REACT_APP_URL_FRONT]
      },
    ];
    if (!this.props.isParent || this.mode == analysisMode.standalone) {
      parameters.push(
        {
          Name: "corpNum",
          Values: [this.corpNumList],
        }
      )
    } else {
      // 権限に応じて表示制御する
      const refAuth = this.props.authority.referenceAuth;
      const targets = [];
      if (this.props.condition === "nothing") {
        // 分析対象が「すべて」の場合権限の会社をそのまま設定
        targets.push({
          Name: "corpNum", Values: refAuth.authCompany
        }, {
          Name: "segmentCode", Values: refAuth.authSegment
        });
      } else {
        // 分析対象が「すべて」以外は権限のない会社を除外
        const corp = this.props.targets.find(t => t.Name === "corpNum");
        const segment = this.props.targets.find(t => t.Name === "segmentCode");
        targets.push({
          Name: "corpNum", Values: corp.Values.filter(v => refAuth.authCompany.includes(v))
        }, {
          Name: "segmentCode", Values: segment.Values.filter(v => refAuth.authSegment.includes(v))
        });
      }
      targets.map((t) => {
        parameters.push(t);
      });
    };
    if (this.isMonthly()) {
      parameters.push({
        Name: "month",
        Values: [month],
      });
      
      const yearNum = parseInt(year, 10);
      const monthNum = parseInt(month, 10);
      const accPeriodNum = parseInt(this.props.accPeriod, 10);
      let fiscalYear
      if (accPeriodNum !== 12 && monthNum <= accPeriodNum) {
        fiscalYear = yearNum - 1;
      } else {
        fiscalYear = yearNum;
      }
      parameters.push({
        Name: "fiscalYear",
        Values: [fiscalYear + "年度"],
      });
    };
    if (this.dashboardInfo.key.startsWith('S')) {
      parameters.push({
        Name: "goal",
        Values: [kpiTargetInfo.value != undefined ? Number(kpiTargetInfo.value) : 0],
      });
    };
    if (this.mode == analysisMode.standalone ? this.dashboardInfo?.benchmarkTarget.standalone : this.dashboardInfo?.benchmarkTarget.consolidate) {
      let cl, lc, is, cs;
      if (benchmarkTarget.ConditionInfo.ListingClassification.length) {
        lc = benchmarkTarget.ConditionInfo.ListingClassification.map(v => {
          const c = Object.values(listingClassification).find(item => item.value === v);
          return c ? c.name : "";
        });
      } else {
        lc = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.IndustrySector.length) {
        is = benchmarkTarget.ConditionInfo.IndustrySector
          .filter(v1 => this.industrySector.some(v2 => v1 == v2.Key))
          .map((v1) => {
            const data = this.industrySector.find(v2 => v1 == v2.Key);
            return data.Key + ' ' + data.Name;
          });
      } else {
        is = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.CompanySize.length) {
        cs = benchmarkTarget.ConditionInfo.CompanySize.map(v => this.companySize[v]);
      } else {
        cs = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.CompanyList.length) {
        const s3Data = await getBenchmarkCompany(
          this.props.corporationNumber,
          PathInfoList.Folder.benchmarkCompanyFolder + PathInfoList.Objectkeyname.benchmarkCompany,
          'CorporationNumber',
          benchmarkTarget.ConditionInfo.CompanyList
        );
        cl = s3Data.map(d => d.CorporationName);
      } else {
        cl = ['ALL_VALUES'];
      }
      parameters.push(
        {
          Name: "companyList",
          Values: cl,
        },
        {
          Name: "listingClassification",
          Values: lc,
        },
        {
          Name: "industrySector",
          Values: is,
        },
        {
          Name: "companySize",
          Values: cs,
        },
      );
    };
    return parameters;
  }

  setIsOpenYear = (isOpen) => {
    if (this.state.isDashboardLoaded) {
      if (this.dashboardInfo.yearFilterFlg) {
        this.setState({
          isOpenYear: isOpen,
        });
        const yearButton = document.getElementById("yearButton");
        if (isOpen) {
          yearButton.classList.add('is-active');
        } else {
          yearButton.classList.remove('is-active');
        }
      }
    }
  };

  changeYear = (value) => {
    try {
      let yearVal = value;
      let monthVal = '';
      if (this.isMonthly()) {
        yearVal = value.substring(0, 5);
        monthVal = value.substring(5);
      }
      this.setState({
        year: yearVal,
        month: monthVal,
      });
      this.setIsOpenYear(false);
      const paramObj = this.getParameters(yearVal, monthVal, this.state.kpiTargetInfo, this.state.benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYear", error);
    }
  };

  changeYearQuarter = async (isYear) => {
    try {
      this.setState({
        isYear: isYear,
      });
      //　ダッシュボードIDを取得
      const dashboardId = await this.getDashboardId(isYear);
      const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
      const options = {
        parameters: paramObj,
      };
      await this.dashboard.navigateToDashboard(dashboardId, options);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYearQuarter", error);
    }
  };

  isIncludeQuater = (list) => {
    for (const item of list) {
      if (item.quarterFlg) {
        return true;
        break;
      }
    }
    return false;
  }

  showToast = (message) => {
    this.setState({
      toastMessage: message,
    });
    const boxToastTimeoutSec = 4300;
    const fadeOutTimeoutSec = 300;
    const boxToast = document.querySelector(".box-toast");
    setTimeout(() => {
      boxToast.classList.add("is-active");
    }, fadeOutTimeoutSec);
    setTimeout(() => {
      this.removeToast();
    }, boxToastTimeoutSec);
  };

  removeToast = () => {
    this.setState({
      saveConBtnFlg: true,
    });
    const boxToast = document.querySelector(".box-toast");
    boxToast.classList.remove("is-active");
  };

  bmSet = [];
  bmVersion = [];
  setBmInfo = () => {
    this.bmSet = [];
    this.bmVersion = [];
    this.bmList.map(
      (item) => this.bmSet[item["DashboardKey"]] = item["Memo"]
    );
    this.bmList.map(
      (item) => this.bmVersion[item["DashboardKey"]] = item["Version"]
    );
    this.setState({
      bmSet: this.bmSet,
      bmVersion: this.bmVersion,
    });
  };

  setBmInfoForDs = (dskey) => {
    this.bmSet = [];
    this.bmVersion = [];
    this.bmList.map(
      (item) => this.bmSet[item["DashboardKey"]] = item["Memo"]
    );
    this.bmList.map(
      (item) => this.bmVersion[item["DashboardKey"]] = item["Version"]
    );
    this.setState({
      bmSet: this.bmSet,
      bmVersion: this.bmVersion,
    });
  };

  getBm = async () => {
    try {
      this.bmList = await getDynamoDB(
        table.Bookmark,
        "CorporationNumber-index",
        this.props.corporationNumber,
        null,
        {
          UserId: this.props.userId,
        }
      );
      // 権限なしを除く
      this.bmList = this.bmList.filter(bm => {
        return this.dashboardAuthConf.Auth_Conf.some(d1 => {
          return d1.DashboardList.some(d2 => {
            return d2.Dashboard.some(d3 => d3 === bm.DashboardKey)
          })
        })
      });
      // 表示モード外を除く
      this.bmList = this.bmList.filter(bm => {
        return this.pageInfoList.some(lPage => {
          return lPage.dashboardList.some(mPage => {
            return mPage.dashboard.some(sPage => {
              if (sPage.key !== bm.DashboardKey) {
                return false;
              }
              if (this.mode === analysisMode.standalone) {
                return sPage.activeFlg
              } else {
                return sPage.groupFlg
              }
            })
          })
        })
      });
      this.setBmInfo();
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getBm", error);
    }
  };

  handleChangeBmMemo = (e) => {
    this.setState({
      bmMemo: e.target.value,
    });
  };

  openBmModal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    if (!this.bmHaveFlg) {
      this.setState({
        bmDispStatus: "entry",
        bmMemo: "",
      });
      const modalBmEntry = document.querySelector("#modal-window--bookmark-entry");
      modalBmEntry.classList.add("is-active");
      modalBmEntry.classList.remove("animate__fadeOut");
      modalBmEntry.classList.add("animate__fadeIn");
    } else {
      this.setState({
        bmDispStatus: "on",
        bmMemo: this.bmSet[this.dashboardInfo.key] == null ? "" : this.bmSet[this.dashboardInfo.key],
      });
      const modalBmOn = document.querySelector("#modal-window--bookmark-on");
      modalBmOn.classList.add("is-active");
      modalBmOn.classList.remove("animate__fadeOut");
      modalBmOn.classList.add("animate__fadeIn");
      const modalBmDeleteConfirm = document.querySelector("#modal-window--bookmark-delete-confirm");
      modalBmDeleteConfirm.classList.remove("animate__fadeOut");
      modalBmDeleteConfirm.classList.add("animate__fadeIn");
    }
  };

  closeBmModal = () => {
    this.setState({
      bmError: false,
    });
    if (this.state.bmDispStatus === "entry") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-entry");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (this.state.bmDispStatus === "on") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-on");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    } else if (this.state.bmDispStatus === "deleteConf") {
      const bookmarkBg = document.querySelector("#modal-window--bookmark-delete-confirm");
      bookmarkBg.classList.remove("animate__fadeIn");
      bookmarkBg.classList.add("animate__fadeOut");
    }
    this.setState({
      bmDispStatus: null,
    });
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector(".modal-window.is-active")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  tmpChangeDSKey = null;
  doTimeForBm = 0;
  initDsMae = async (dbFlg, dsKey) => {
    this.doTimeForBm = 1;
    this.tmpChangeDSKey = this.dashboardInfo.key;
    await this.getBm();
    await this.getCt();
    this.setDashboardKeyList();
    this.tmpChangeDSKey = null;
    if (this.dashboardKeyList.length != 0) {
      this.resetDashboard();
      if (this.dashboardAccount != 0) {
        if (!this.dashboardKeySearchList.includes(dsKey)
          || (dbFlg == "deleteBm" && !this.dashboardKeyCtList.includes(dsKey))
          || (dbFlg == "deleteCt" && !this.dashboardKeyCtList.includes(dsKey) && !this.dashboardKeyBmList.includes(dsKey))) {
          this.tmpChangeDSKey = this.dashboardKeySearchList[0];
        } else {
          this.tmpChangeDSKey = dsKey;
        }
      }
    }
    this.doQuickSightDashboard();
    this.setCtgCheckCategoryNm();
    if (this.bmSet[this.dashboardKeyList[0]] != undefined) {
      this.bmHaveFlg = true;
    } else {
      this.bmHaveFlg = false;
    }
    if (this.dashboardKeyList.length != 0 && this.dashboardAccount != 0) {
      await this.getYearList();
      this.setNav();
    }
  }

  resetDsMae = async (dbFlg, dsKey, doTimes) => {
    let deleteFlg = false;
    await this.getCt();
    this.setDashboardKeyList();
    if ((dbFlg == "deleteBm" && !this.dashboardKeyCtList.includes(dsKey))
      || (dbFlg == "deleteCt" && !this.dashboardKeyCtList.includes(dsKey) && !this.dashboardKeyBmList.includes(dsKey))) {
      if (this.dashboardKeyList.includes(dsKey)) {
        let getLocation = this.dashboardKeyList.indexOf(dsKey);
        this.dashboardKeyList.splice(getLocation, 1);
      }
      this.tmpChangeDSKey = this.dashboardKeyList[0];
      deleteFlg = true;
    } else if (dbFlg == "deleteCt" && (this.dashboardKeyCtList.includes(dsKey) || this.dashboardKeyBmList.includes(dsKey))) {
      this.saveCtDSKey = dsKey;
    }
    if (this.dashboardKeyList == 0) {
      this.catAccount = [];
      this.catList.map((item, index) => {
        this.catAccount[index] = 0;
      })
      this.ctAccount = 0;
    }
    if (!this.dashboardKeyDispList.includes(dsKey)) {
      if ((dbFlg == "deleteBm" || (dbFlg == "deleteCt" && doTimes == 1)) && deleteFlg) {
        if (document.getElementById("ulNav").clientHeight >= 128) {
          if (document.getElementsByName("liNav")[this.defaultShowCount] != null) {
            document.getElementsByName("liNav")[this.defaultShowCount].classList.remove('display-block');
            document.getElementsByName("liNav")[this.defaultShowCount].classList.remove('display-none');
            document.getElementsByName("liNav")[this.defaultShowCount].classList.add('display-none');
          }
        }
        if (document.getElementById("ulNav").clientHeight > 128) {
          if (showAllBtn.classList.contains('show-part')) {
            this.defaultShowCount = this.defaultShowCount - 1;
          }
        }
        if (this.dashboardKeyDispList.length <= this.defaultShowCount) {
          let showAllBtn = document.getElementById("showAllBtn");
          if (!showAllBtn.classList.contains('display-none')) {
            showAllBtn.classList.add('display-none');
          }
        }
        this.resetShowAllBtn(false);
      }
    }
  }

  resetShowAllBtn = async (deleteFlg) => {
    let showAllBtn = document.getElementById("showAllBtn");
    if (deleteFlg) {
      if (showAllBtn != null && showAllBtn.classList.contains('show-part')) {
        let myDashboardToggleText = document.getElementById("myDashboardToggleText");
        if (showAllBtn != null && !showAllBtn.classList.contains('show-part') && (this.dashboardKeyDispList.length > this.defaultShowCount)) {
          showAllBtn.classList.add('show-part');
        }
        if (myDashboardToggleText != null && (this.dashboardKeyDispList.length > this.defaultShowCount)) {
          myDashboardToggleText.textContent = ('すべて表示（全' + this.dashboardKeyDispList.length + '件）');
        }
        this.isShowAll = false;
      }
    } else {
      let myDashboardToggleText = document.getElementById("myDashboardToggleText");
      if (showAllBtn != null && !showAllBtn.classList.contains('show-part') && (this.dashboardKeyDispList.length > this.defaultShowCount)) {
        showAllBtn.classList.add('show-part');
      }
      if (myDashboardToggleText != null && (this.dashboardKeyDispList.length > this.defaultShowCount)) {
        myDashboardToggleText.textContent = ('すべて表示（全' + this.dashboardKeyDispList.length + '件）');
      }
      this.isShowAll = false;
    }
  }

  registBm = async () => {
    try {
      this.setState({
        isDashboardLoaded: false,
      });
      const nowDate = formatDate(new Date());
      const request = [
        {
          UserId: this.props.userId,
          DashboardKey: this.dashboardInfo.key,
          Memo: this.state.bmMemo,
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      const result = await registDynamoDB(table.Bookmark, "UserId", this.props.corporationNumber, request, this.props.userId);
      this.initDsMae("registBm", this.dashboardInfo.key);
      this.resetDsMae("registBm", this.dashboardInfo.key, 0);
      this.closeBmModal();
      this.showToast(
        this.state.pageText[this.pageInfoKey].dashboardList[
          this.titleIdx
        ].dashboard[this.dashboardIdxSub].name + "を ブックマークしました"
      );
    } catch (error) {
      this.setState({
        bmError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "registBm",
        error
      );
    }
  };

  updateBm = async () => {
    try {
      this.setState({
        isDashboardLoaded: false,
      });
      const nowDate = formatDate(new Date());
      const pk = "UserId";
      const key = {
        UserId: this.props.userId,
        DashboardKey: this.dashboardInfo.key,
      };
      const item = {
        Memo: this.state.bmMemo,
        UpdateDateTime: nowDate,
        Version: this.bmVersion[this.dashboardInfo.key] + 1,
      };
      const result = await updateDynamoDB(table.Bookmark, pk, this.props.corporationNumber, key, item, this.props.userId);
      this.initDsMae("updateBm", this.dashboardInfo.key);
      this.resetDsMae("updateBm", this.dashboardInfo.key, 0);
      this.showToast("ブックマークメモを更新しました");
      this.closeBmModal();
    } catch (error) {
      this.setState({
        bmError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "updateBm",
        error
      );
    }
  };

  deleteBm = async () => {
    try {
      this.setState({
        isDashboardLoaded: false,
      });
      const key = {
        UserId: this.props.userId,
        DashboardKey: this.dashboardInfo.key,
      };
      const result = await deleteDynamoDB(table.Bookmark, key, this.props.corporationNumber, this.props.userId);
      this.initDsMae("deleteBm", this.dashboardInfo.key);
      this.resetDsMae("deleteBm", this.dashboardInfo.key, 0);
      this.showToast("ブックマークを削除しました");
      this.closeBmModal();
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "deleteBm",
        error
      );
    }
  };

  deleteConfBm = async () => {
    this.setState({
      bmDispStatus: "deleteConf",
    });
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkDeleteConfirm = document.querySelector(
      "#modal-window--bookmark-delete-confirm"
    );
    modalBookmarkDeleteConfirm.classList.add("is-active");
  };

  deleteBackBm = async () => {
    this.setState({
      bmDispStatus: "on",
    });
    document
      .querySelector(".modal-window.is-active")
      .classList.remove("is-active");
    const modalBookmarkOn = document.querySelector(
      "#modal-window--bookmark-on"
    );
    modalBookmarkOn.classList.add("is-active");
  };

  ctgMemo = [];
  ctgCategory = [];
  ctgVersion = [];
  ctgCheckCategoryNm = [];
  setCtSet = (ctDashboardKey) => {
    this.ctgMemo = [];
    this.ctgCategory = [];
    this.ctgVersion = [];
    this.ctgCheckCategoryNm = [];
    if (this.ctSet[ctDashboardKey] != undefined && this.ctSet[ctDashboardKey] != null) {
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgMemo[item["CategoryKey"]] = item["Memo"]
      );
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgCategory[item["CategoryKey"]] = item["CategoryKey"]
      );
      this.ctSet[ctDashboardKey].map(
        (item) => this.ctgVersion[item["CategoryKey"]] = item["Version"]
      );
      this.ctgCheckCategoryNm = [];
      this.ctgCategory.map((item) => {
        this.catList.map((itemCat) => {
          if (itemCat["CategoryKey"] == item) {
            this.ctgCheckCategoryNm.push(this.ctgCategoryName[item]);
          }
        })
      })
    }
    this.setState({
      ctMemo: this.ctgMemo,
      ctCategory: this.ctgCategory,
      ctVersion: this.ctgVersion,
    });
  };

  openCtModal = async (dashboardKey) => {
    await this.getCt();
    this.setCtSet(dashboardKey);
    this.setState({
      ctDashboardKey: dashboardKey,
    });
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    if (this.state.ctSet[dashboardKey] == undefined || this.state.ctSet[dashboardKey] == null) {
      this.setState({
        ctMemo: [],
        ctCategory: [],
        ctVersion: [],
      });
    } else {
      this.ctgMemo = [];
      this.ctSet[dashboardKey].map(
        (item) => this.ctgMemo[item["CategoryKey"]] = item["Memo"]
      );
      this.ctgCategory = [];
      this.ctSet[dashboardKey].map(
        (item) => this.ctgCategory[item["CategoryKey"]] = item["CategoryKey"]
      );
    }
    let chBoxByNm = document.getElementsByClassName('chBox');
    let chTextareaByNm = document.getElementsByName('chTextarea');
    let chLiByNm = document.getElementsByName('chLi');
    let saveCtgBtn = document.getElementById('saveCtgBtn');
    let saveCtgLabel = document.getElementById('saveCtgLabel');
    let len = chBoxByNm.length;
    let isCatFlg = false;
    for (let i = 0; i < len; i++) {
      chLiByNm[i].classList.remove('is-active');
      saveCtgBtn.classList.remove('display-none');
      saveCtgBtn.classList.remove('display-block');
      saveCtgLabel.classList.remove('display-none');
      saveCtgLabel.classList.remove('display-block');
      isCatFlg = false;
      this.catList.map((item) => {
        if (item["CategoryKey"] == this.ctgCategory[i + 1]) {
          isCatFlg = true;
        }
      })
      let chBox = document.getElementById('checkbox'.concat(i + 1));
      let chTextarea = document.getElementById('chTextarea'.concat(i + 1));
      let chLi = document.getElementById('chLi'.concat(i + 1));
      if (this.ctgCategory[i + 1] == i + 1 && isCatFlg) {
        chBox.checked = true;
        chTextarea.disabled = false;
        chLi.classList.add('is-active');
        if (this.ctgMemo[i + 1] == undefined || this.ctgMemo[i + 1] == null || this.ctgMemo[i + 1] == '') {
          chTextarea.placeholder = "メモを入力（任意、最大120文字）";
        } else {
          chTextarea.value = this.ctgMemo[i + 1];
        }
      } else {
        if (chBox != null) {
          chBox.checked = false;
          chTextarea.value = "";
          chTextarea.disabled = true;
        }
      }
      if (this.props.categoryTagAuthConf.Availability && this.props.categoryTagAuthConf.Auth_Conf?.CategoryTag_Set) {
        chBoxByNm[i].disabled = false;
        chTextareaByNm[i].disabled = false;
        saveCtgBtn.classList.add('display-block');
        saveCtgLabel.classList.add('display-none');
      } else {
        chBoxByNm[i].disabled = true;
        chTextareaByNm[i].disabled = true;
        saveCtgBtn.classList.add('display-none');
        saveCtgLabel.classList.add('display-none');
      }
    }
    const modalCtOn = document.querySelector("#modal-window--tag-list");
    modalCtOn.classList.add("is-active");
    modalCtOn.classList.remove("animate__fadeOut");
    modalCtOn.classList.add("animate__fadeIn");
  };

  closeCtModal = () => {
    this.setState({
      ctError: false,
    });
    const categoryBg = document.querySelector("#modal-window--tag-list");
    categoryBg.classList.remove("animate__fadeIn");
    categoryBg.classList.add("animate__fadeOut");
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      document
        .querySelector("#modal-window--tag-list")
        .classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  checkOne = () => {
    let chBox = document.getElementsByClassName('chBox');
    let chTextarea = document.getElementsByName('chTextarea');
    let chLi = document.getElementsByName('chLi');
    let len = chBox.length;
    for (let i = 0; i < len; i++) {
      chLi[i].classList.remove('is-active');
      if (chBox[i].checked) {
        chTextarea[i].disabled = false;
        chLi[i].classList.add('is-active');
      } else {
        chTextarea[i].disabled = true;
      }
    }
  };

  ctgMemoSave = [];
  handleChangeCtgMemo = (categoryKey, e) => {
    this.ctgMemoSave = this.state.ctMemo;
    this.ctgMemoSave[categoryKey] = e.target.value;
    this.setState({
      ctMemo: this.ctgMemoSave,
    });
  };

  saveCtDSKey = null;
  doTimeForCt = 0;
  saveCategory = async () => {
    this.doTimeForCt = 1;
    this.tmpChangeDSKey = this.dashboardInfo.key;
    let chBox = document.getElementsByClassName('chBox');
    let len = chBox.length;
    let nowCtgCheckCategoryNm = [];
    let j = 0;
    let doTimes = 0;
    let doFlg = 0;
    for (let i = 0; i < len; i++) {
      if (chBox[i].checked) {
        nowCtgCheckCategoryNm[j] = this.ctgCategoryName[i + 1];
        j = j + 1;
      }
      if (this.ctSet[this.state.ctDashboardKey] == undefined || this.ctSet[this.state.ctDashboardKey] == null) {
        if (chBox[i].checked) {
          await this.registCt(chBox[i].value);
          doFlg = 1;
        }
      } else {
        let lenCtSet = this.ctSet[this.state.ctDashboardKey].length;
        let isHaveCategoryKey = false
        for (let j = 0; j < lenCtSet; j++) {
          if (chBox[i].value == this.ctSet[this.state.ctDashboardKey][j]["CategoryKey"]) {
            isHaveCategoryKey = true;
          }
        }
        if (chBox[i].checked) {
          if (isHaveCategoryKey) {
            await this.updateCt(chBox[i].value);
            doFlg = 2;
          } else {
            await this.registCt(chBox[i].value);
            doFlg = 1;
          }
        } else {
          if (isHaveCategoryKey) {
            await this.deleteCt(chBox[i].value, doTimes);
            doTimes = doTimes + 1;
            doFlg = 3;
          }
        }
      }
    }

    if (doFlg == 3) {
      this.initDsMae("deleteCt", this.dashboardInfo.key);
      this.resetDsMae("deleteCt", this.dashboardInfo.key, 0);
    } else if (doFlg != 0) {
      this.saveCtDSKey = this.dashboardInfo.key;
    }
    this.setCtSet(this.dashboardInfo.key);
    this.closeCtModal();

    this.ctgCheckCategoryNm = nowCtgCheckCategoryNm;
    this.showToast("用途タグを保存しました");
    await this.reloadData();
    this.setState({
      isDashboardLoaded: false,
    });
  };

  registCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const request = [
        {
          SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
          DashboardKey: this.state.ctDashboardKey,
          CategoryKey: categoryKey,
          Memo: this.state.ctMemo[categoryKey],
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      const result = await registDynamoDB(table.CategoryTag, "UserId", this.props.corporationNumber, request, this.props.userId);
      await this.getCt();
    } catch (error) {
      this.setState({
        ctError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "registCt",
        error
      );
    }
  };

  updateCt = async (categoryKey) => {
    try {
      const nowDate = formatDate(new Date());
      const pk = "CorporationNumber";
      const key = {
        CorporationNumber: this.props.corporationNumber,
        SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
      };
      const item = {
        Memo: this.state.ctMemo[categoryKey],
        UpdateDateTime: nowDate,
        Version: this.state.ctVersion[categoryKey] + 1,
      };
      const result = await updateDynamoDB(table.CategoryTag, pk, this.props.corporationNumber, key, item, this.props.userId);
      await this.getCt();
    } catch (error) {
      this.setState({
        ctError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "updateCt",
        error
      );
    }
  };

  deleteCt = async (categoryKey) => {
    try {
      const key = {
        CorporationNumber: this.props.corporationNumber,
        SearchKey: this.state.ctDashboardKey + "#" + categoryKey,
      };
      const result = await deleteDynamoDB(table.CategoryTag, key, this.props.corporationNumber, this.props.userId);
      await this.getCt();
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "deleteCt",
        error
      );
    }
  };

  ctList = [];
  getCt = async () => {
    try {
      this.ctList = [];
      let tmpList = [];
      await this.getCat();
      tmpList = await getDynamoDB(
        table.CategoryTag,
        null,
        this.props.corporationNumber
      );
      tmpList.map((itemCt) => {
        this.catList.map((item) => {
          if (itemCt["CategoryKey"] == item["CategoryKey"]) {
            this.ctList.push(itemCt);
          }
        })
      });
      // 権限なしを除く
      this.ctList = this.ctList.filter(ct => {
        return this.dashboardAuthConf.Auth_Conf.some(d1 => {
          return d1.DashboardList.some(d2 => {
            return d2.Dashboard.some(d3 => d3 === ct.DashboardKey)
          })
        })
      });
      // 表示モード外を除く
      this.ctList = this.ctList.filter(ct => {
        return this.pageInfoList.some(lPage => {
          return lPage.dashboardList.some(mPage => {
            return mPage.dashboard.some(sPage => {
              if (sPage.key !== ct.DashboardKey) {
                return false;
              }
              if (this.mode === analysisMode.standalone) {
                return sPage.activeFlg
              } else {
                return sPage.groupFlg
              }
            })
          })
        })
      });
      this.setCtInfo();
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getCt", error);
    }
  };

  ctSet = [];
  setCtInfo = () => {
    this.ctSet = this.groupByMethod(this.ctList, 'DashboardKey');
    this.setState({
      ctSet: this.ctSet,
    });
  };

  groupByMethod = (arr, fnOrProperty) => {
    return arr.map(typeof fnOrProperty === 'function' ? fnOrProperty : val => val[fnOrProperty])
      .reduce((acc, val, i) => {
        acc[val] = (acc[val] || []).concat(arr[i]);
        return acc;
      }, {});
  }

  catList = [];
  getCat = async () => {
    try {
      this.catList = [];
      this.catList = await getDynamoDB(table.Category, null, this.props.corporationNumber);
      this.catList = this.catList.filter(function (value) {
        return value["DeleteFlg"] == 0;
      });
      this.setCatInfo();
      this.catList = this.catList.sort(this.objectArraySort);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getCat", error);
    }
  };

  ctgCategoryKey = [];
  ctgCategoryName = [];
  setCatInfo = () => {
    if (this.catList == undefined || this.catList == null) {
      this.ctgCategoryKey = [];
      this.ctgCategoryName = [];
    } else {
      this.catList.map(
        (item) => this.ctgCategoryKey[item.CategoryKey] = item.CategoryKey
      );
      this.catList.map(
        (item) => this.ctgCategoryName[item.CategoryKey] = item.CategoryName
      );
    }
    this.setState({
      ctCategoryName: this.ctgCategoryName,
    });
  };

  registSc = async () => {
    try {
      await this.getCat();
      const nowDate = formatDate(new Date());
      let bmflg = 0;
      if (this.radioBmAllFlg) {
        bmflg = "1";
      } else if (this.radioBmHaveFlg) {
        bmflg = "2";
      } else if (this.radioBmNohaveFlg) {
        bmflg = "3";
      } else {
        bmflg = "1";
      }
      let ctg = [];
      if (!this.chBoxCtgAllFlg) {
        ctg = {
          All: false,
        }
      } else {
        ctg = {
          All: true,
        }
      }
      let chBoxCtgFlgSes = this.chBoxCtgFlg;
      if (chBoxCtgFlgSes == null) {
        for (let i = 0; i < (this.catList.length + 1); i++) {
          if (i == 0) {
            ctg["None"] = true;
          } else {
            ctg[this.catList[i - 1]["CategoryKey"]] = true;
          }
        }
      } else {
        for (let i = 0; i < (this.catList.length + 1); i++) {
          if (i == 0) {
            if (!chBoxCtgFlgSes[i]) {
              ctg["None"] = false;
            } else {
              ctg["None"] = true;
            }
          } else {
            if (!chBoxCtgFlgSes[i]) {
              ctg[this.catList[i - 1]["CategoryKey"]] = false;
            } else {
              ctg[this.catList[i - 1]["CategoryKey"]] = true;
            }
          }
        }
      }
      let bdgFlg = [];
      let bdgFlgAll = true;
      let bdgFlgNone = true;
      let bdgFlgCAS = true;
      let bdgFlgISO = true;
      let bdgFlgGRI = true;
      if (!this.chBoxBdgAllFlg) {
        bdgFlgAll = false;
      } else {
        bdgFlgAll = true;
      }
      let chBoxBdgFlgSes = this.chBoxBdgFlg;
      if (chBoxBdgFlgSes == null) {
        bdgFlgNone = true;
        bdgFlgCAS = true;
        bdgFlgISO = true;
        bdgFlgGRI = true;
      } else {
        if (!chBoxBdgFlgSes[0]) {
          bdgFlgNone = false;
        } else {
          bdgFlgNone = true;
        }
        if (!chBoxBdgFlgSes[1]) {
          bdgFlgCAS = false;
        } else {
          bdgFlgCAS = true;
        }
        if (!chBoxBdgFlgSes[2]) {
          bdgFlgISO = false;
        } else {
          bdgFlgISO = true;
        }
        if (!chBoxBdgFlgSes[3]) {
          bdgFlgGRI = false;
        } else {
          bdgFlgGRI = true;
        }
      }
      bdgFlg = {
        All: bdgFlgAll,
        None: bdgFlgNone,
        CAS: bdgFlgCAS,
        ISO: bdgFlgISO,
        GRI: bdgFlgGRI,
      };
      let andOr = "1";
      if (this.nowSearchAndOr == "Or") {
        andOr = "2";
      } else {
        andOr = "1";
      }
      const request = [
        {
          UserId: this.props.userId,
          SessionStorageKey: "1",
          BmFlg: bmflg,
          Ctg: ctg,
          BdgFlg: bdgFlg,
          AndOr: andOr,
          CreateDateTime: nowDate,
          UpdateDateTime: nowDate,
          Version: 1,
        },
      ];
      const result = await registDynamoDB(table.SearchCondition, "UserId", this.props.corporationNumber, request, this.props.userId);
    } catch (error) {
      this.setState({
        scError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "registSc",
        error
      );
    }
  };

  scVersion = 1;
  updateSc = async () => {
    try {
      await this.getCat();
      const nowDate = formatDate(new Date());
      let bmflg = 0;
      if (this.radioBmAllFlg) {
        bmflg = "1";
      } else if (this.radioBmHaveFlg) {
        bmflg = "2";
      } else if (this.radioBmNohaveFlg) {
        bmflg = "3";
      } else {
        bmflg = "1";
      }
      let ctg = [];
      if (!this.chBoxCtgAllFlg) {
        ctg = {
          All: false,
        }
      } else {
        ctg = {
          All: true,
        }
      }
      let chBoxCtgFlgSes = this.chBoxCtgFlg;
      if (chBoxCtgFlgSes == null) {
        for (let i = 0; i < (this.catList.length + 1); i++) {
          if (i == 0) {
            ctg["None"] = true;
          } else {
            ctg[this.catList[i - 1]["CategoryKey"]] = true;
          }
        }
      } else {
        for (let i = 0; i < (this.catList.length + 1); i++) {
          if (i == 0) {
            if (!chBoxCtgFlgSes[i]) {
              ctg["None"] = false;
            } else {
              ctg["None"] = true;
            }
          } else {
            if (!chBoxCtgFlgSes[i]) {
              ctg[this.catList[i - 1]["CategoryKey"]] = false;
            } else {
              ctg[this.catList[i - 1]["CategoryKey"]] = true;
            }
          }
        }
      }
      let bdgFlg = [];
      let bdgFlgAll = true;
      let bdgFlgNone = true;
      let bdgFlgCAS = true;
      let bdgFlgISO = true;
      let bdgFlgGRI = true;
      if (!this.chBoxBdgAllFlg) {
        bdgFlgAll = false;
      } else {
        bdgFlgAll = true;
      }
      let chBoxBdgFlgSes = this.chBoxBdgFlg;
      if (chBoxBdgFlgSes == null) {
        bdgFlgNone = true;
        bdgFlgCAS = true;
        bdgFlgISO = true;
        bdgFlgGRI = true;
      } else {
        if (!chBoxBdgFlgSes[0]) {
          bdgFlgNone = false;
        } else {
          bdgFlgNone = true;
        }
        if (!chBoxBdgFlgSes[1]) {
          bdgFlgCAS = false;
        } else {
          bdgFlgCAS = true;
        }
        if (!chBoxBdgFlgSes[2]) {
          bdgFlgISO = false;
        } else {
          bdgFlgISO = true;
        }
        if (!chBoxBdgFlgSes[3]) {
          bdgFlgGRI = false;
        } else {
          bdgFlgGRI = true;
        }
      }
      bdgFlg = {
        All: bdgFlgAll,
        None: bdgFlgNone,
        CAS: bdgFlgCAS,
        ISO: bdgFlgISO,
        GRI: bdgFlgGRI,
      };
      let andOr = "1";
      if (this.nowSearchAndOr == "Or") {
        andOr = "2";
      } else {
        andOr = "1";
      }
      const pk = "UserId";
      const key = {
        UserId: this.props.userId,
        SessionStorageKey: "1",
      };
      const item = {
        BmFlg: bmflg,
        Ctg: ctg,
        BdgFlg: bdgFlg,
        AndOr: andOr,
        UpdateDateTime: nowDate,
        Version: this.scVersion + 1,
      };
      const result = await updateDynamoDB(table.SearchCondition, pk, this.props.corporationNumber, key, item, this.props.userId);
    } catch (error) {
      this.setState({
        scError: true,
      });
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "updateSc",
        error
      );
    }
  };

  deleteSc = async () => {
    try {
      const key = {
        UserId: this.props.userId,
        SessionStorageKey: "1",
      };
      const result = await deleteDynamoDB(table.SearchCondition, key, this.props.corporationNumber, this.props.userId);
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "deleteSc",
        error
      );
    }
  };

  scList = [];
  getSc = async () => {
    try {
      this.scList = await getDynamoDB(
        table.SearchCondition,
        "CorporationNumber-index",
        this.props.corporationNumber,
        null,
        {
          UserId: this.props.userId,
        }
      );
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getSc", error);
    }
  };

  noBadgeAccount = 0;
  casAccount = 0;
  isoAccount = 0;
  griAccount = 0;
  catAccount = [];
  ctAccount = 0;
  setPageInfoCond = (pageInfoList) => {
    let tmpHtml = pageInfoList.map((monitoringInfo) => (
      monitoringInfo.dashboardList.map((dashboardList) => (
        dashboardList.dashboard.map((dashboardTable, index) => {
          let ctAccountFlg = 0;
          this.catList.map((item, index) => {
            if (this.ctSet[dashboardTable.key] != undefined) {
              this.ctSet[dashboardTable.key].map((itemSet) => {
                if (itemSet["CategoryKey"] != undefined && itemSet["CategoryKey"] == item["CategoryKey"]) {
                  this.catAccount[index] = this.catAccount[index] + 1;
                  ctAccountFlg = ctAccountFlg + 1;
                }
              })
            }
          })
          if (ctAccountFlg != 0) {
            this.ctAccount = this.ctAccount + 1;
          }
          if (this.dashboardKeyList.indexOf(dashboardTable.key) != -1) {
            if (dashboardTable.badge["CAS"]) {
              this.casAccount = this.casAccount + 1;
            }
            if (dashboardTable.badge["ISO"]) {
              this.isoAccount = this.isoAccount + 1;
            }
            if (dashboardTable.badge["GRI"]) {
              this.griAccount = this.griAccount + 1;
            }
            if (!dashboardTable.badge["CAS"] && !dashboardTable.badge["ISO"] && !dashboardTable.badge["GRI"]) {
              this.noBadgeAccount = this.noBadgeAccount + 1;
            }
          }
          let nowCtgFlg = false;
          if (this.nowCtgValue == undefined || this.nowCtgValue[0] == "all") {
            nowCtgFlg = true;
          } else {
            if (this.ctSet[dashboardTable.key] != undefined) {
              this.ctSet[dashboardTable.key].map((item) => {
                let len = this.nowCtgValue.length;
                for (let i = 1; i <= len; i++) {
                  if (this.nowCtgValue[i] == item["CategoryKey"]) {
                    nowCtgFlg = true;
                  }
                }
              })
            } else {
              if (this.nowCtgValue[0] == "null") {
                nowCtgFlg = true;
              }
            }
          }
          let nowBdgFlg = false;
          if (this.nowBdgValue == undefined || this.nowBdgValue[0] == "all") {
            nowBdgFlg = true;
          } else {
            if (dashboardTable.badge[this.nowBdgValue[1]]
              || dashboardTable.badge[this.nowBdgValue[2]]
              || dashboardTable.badge[this.nowBdgValue[3]]) {
              nowBdgFlg = true;
            }
            if (!dashboardTable.badge["CAS"]
              && !dashboardTable.badge["ISO"]
              && !dashboardTable.badge["GRI"]
              && this.nowBdgValue[0] == "null") {
              nowBdgFlg = true;
            }
          }

          let nowCtgBdgFlg = true;
          if (this.nowSearchAndOr != undefined && this.nowSearchAndOr != '') {
            if (this.nowSearchAndOr == "Or") {
              if (nowCtgFlg || nowBdgFlg) {
                nowCtgBdgFlg = true;
              } else {
                nowCtgBdgFlg = false;
              }
            } else {
              if (nowCtgFlg && nowBdgFlg) {
                nowCtgBdgFlg = true;
              } else {
                nowCtgBdgFlg = false;
              }
            }
          }
          if ((this.nowBmValue == undefined || this.nowBmValue[0] == "all"
            || (this.nowBmValue[1] == "1" && this.bmSet[dashboardTable.key] != undefined)
            || (this.nowBmValue[2] == "2" && this.bmSet[dashboardTable.key] == undefined))
            && nowCtgBdgFlg) {
            if (this.dashboardKeyList.indexOf(dashboardTable.key) != -1) {
              this.dashboardKeySearchList[this.dashboardAccount] = dashboardTable.key;
              this.dashboardAccount = this.dashboardAccount + 1;
            }
          }
        })
      ))
    ));
    return tmpHtml;
  }

  initDataNoDs = async () => {
    await this.getBm();
    await this.getCt();
    this.setDashboardKeyList();
    this.setCtgCheckCategoryNm();
    if (this.bmSet[this.dashboardKeyList[0]] != undefined) {
      this.bmHaveFlg = true;
    } else {
      this.bmHaveFlg = false;
    }
    if (this.dashboardKeyList.length != 0 && this.dashboardAccount != 0) {
      await this.getYearList();
      this.setNav();
    }
  }

  resetNav = () => {
    let lastFlg = 0;
    if (!this.isShowAll && document.getElementById("ulNav") != null) {
      for (var i = 0; i < document.getElementsByName("liNav").length; i++) {
        document.getElementsByName("liNav")[i].classList.remove('display-block');
        document.getElementsByName("liNav")[i].classList.remove('display-none');
        document.getElementsByName("liNav")[i].classList.add('display-none');
      }
      for (var j = 1; j <= document.getElementsByName("liNav").length; j++) {
        document.getElementsByName("liNav")[j - 1].classList.remove('display-none');
        document.getElementsByName("liNav")[j - 1].classList.add('display-block');
        if (document.getElementById("ulNav").clientHeight > 128) {
          document.getElementsByName("liNav")[j - 1].classList.remove('display-block');
          document.getElementsByName("liNav")[j - 1].classList.add('display-none');
          lastFlg = j - 1;
          break;
        } else {
          lastFlg = j;
        }
      }
      if (lastFlg != 0) {
        this.defaultShowCount = lastFlg;
      }
    }
  }

  initData = async () => {
    await this.getBm();
    await this.getCt();
    if (this.doTimeForReset != 1) {
      this.setDashboardKeyList();
    }
    this.doQuickSightDashboard();
    this.setCtgCheckCategoryNm();
    if (this.bmSet[this.dashboardKeyList[0]] != undefined) {
      this.bmHaveFlg = true;
    } else {
      this.bmHaveFlg = false;
    }
    if (this.dashboardKeyList.length != 0 && this.dashboardAccount != 0) {
      await this.getYearList();
      if (this.doTimeForReset != 1) {
        this.setNav();
      }
    }
    if (this.doTimeForReset == 1) {
      this.resetNav();
      this.doTimeForReset = 0;
    }
  }

  reloadData = async () => {
    this.setState({
      pageText: await getPageText(),
    });
    await this.getSc();
    if (this.scList == null || this.scList.length == 0) {
      this.resetAll();
    } else {
      await this.initData();
    }
    this.bmList.map(
      (item) => this.bmSet[item["DashboardKey"]] = item["Memo"]
    );
    if (this.dashboardInfo != undefined && this.bmSet[this.dashboardInfo.key] != undefined) {
      this.bmHaveFlg = true;
    } else {
      this.bmHaveFlg = false;
    }
    if (this.scList != null && this.scList.length != 0) {
      if (this.doTimeForCt != 1) {
        if (this.scList != null && this.scList.length != 0) {
          await this.initScData();
        }
      }
      await this.initSetModal();
      this.doSaveDashboard();
    }
    this.setState({
      isLoaded: true,
    });
    window.scrollTo(0, 0);
  }

  bmHaveFlg = false;
  componentDidMount = async () => {
    this.setState({
      pageText: await getPageText(),
      isLoaded: true,
    });

    await this.getSc();
    await this.initData();
    this.bmList.map(
      (item) => this.bmSet[item["DashboardKey"]] = item["Memo"]
    );
    if (this.dashboardInfo != undefined && this.bmSet[this.dashboardInfo.key] != undefined) {
      this.bmHaveFlg = true;
    } else {
      this.bmHaveFlg = false;
    }
    if (this.scList != null && this.scList.length != 0) {
      await this.initScData();
      await this.initSetModal();
      this.doSaveDashboard();
    } else {
      this.resetAll();
    }
    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps, prevState) {
    // 表示ダッシュボード切り替え
    if (prevState.pageInfoDashboardKey !== this.state.pageInfoDashboardKey) {
      // キーの接頭辞からベンチマーク対象、KPI関連ダッシュボードであるかを判定
      const newState = this.setDashboardType(this.state.pageInfoDashboardKey);
      this.setState(newState);
    };
  };

  setDashboardType = (pageInfoDashboardKey) => {
    // ダッシュボードキーがS系の場合は、ベンチマーク対象、KPI関連ダッシュボードと判定
    if (pageInfoDashboardKey.startsWith('S')) {
      return {
        isBenchmarkRelDashboard: true,
        isKpiRelDashboard: true,
      };
      // ダッシュボードキーが10系の場合は、ベンチマーク対象ダッシュボードと判定
    } else if (pageInfoDashboardKey.startsWith('10')) {
      return {
        isBenchmarkRelDashboard: true,
        isKpiRelDashboard: false,
      };
    } else {
      return {
        isBenchmarkRelDashboard: false,
        isKpiRelDashboard: false,
      };
    }
  };

  setDashboardKeyList = () => {
    this.dashboardKeyBmList = [];
    this.dashboardKeyCtList = [];
    this.dashboardKeyList = [];
    this.bmList.map((item, index) => this.dashboardKeyBmList[index] = item.DashboardKey);
    this.ctList.map((item, index) => {
      this.catList.map((itemCat) => {
        if (itemCat["CategoryKey"] == item.CategoryKey) {
          this.dashboardKeyCtList[index] = item.DashboardKey;
        }
      })
    })
    let tmpList = this.dashboardKeyBmList == [] ? this.dashboardKeyCtList : this.dashboardKeyBmList.concat(this.dashboardKeyCtList);
    this.dashboardKeyList = tmpList == [] ? tmpList : tmpList.filter((item, index) => {
      let availability = false;
      const lDashboardId = item.split('.')[0];
      const mDashboardId = item.split('.', 2).join('.');
      const lDashboardAuthConf = this.dashboardAuthConf.Auth_Conf.find(auth => auth.Key === lDashboardId);
      const pageInfo = this.pageInfoList.find(page => page.key === lDashboardId);
      const mDashboardList = pageInfo.dashboardList.find(d => d.key === mDashboardId);
      const middleFlg = availabilityMiddleTitle(this.mode, lDashboardAuthConf, mDashboardList.dashboard, mDashboardId);
      if (middleFlg) {
        const mDashboardAuthConf = lDashboardAuthConf.DashboardList.find(auth => auth.Key === mDashboardId)
        const smallFlg = mDashboardAuthConf?.Dashboard.some(d => d === item);
        if (smallFlg) {
          const sDashboard = mDashboardList.dashboard.find(d => d.key === item);
          if (this.mode === analysisMode.standalone) {  // 独立会社もしくは単体モードの場合
            availability = sDashboard?.activeFlg;
          } else {  // 連結モードの場合
            availability = sDashboard?.groupFlg;
          }
          // availability = true;
        }
      }
      return tmpList.indexOf(item) === index && availability;
    });
    let keyLength = this.dashboardKeyList.length;
    for (let i = 0; i < (keyLength - 1); i++) {
      for (let j = 0; j < (keyLength - 1 - i); j++) {
        if (this.dashboardKeyList[j] > this.dashboardKeyList[j + 1]) {
          let tmp = this.dashboardKeyList[j];
          this.dashboardKeyList[j] = this.dashboardKeyList[j + 1];
          this.dashboardKeyList[j + 1] = tmp;
        }
      }
    }
  }

  doQuickSightDashboard = async () => {
    let dashboardKeySearchList = this.dashboardKeySearchList;
    if (this.dashboardKeyList.length != 0) {
      if (this.doTimeForReset != 1) {
        if (this.doTimeForBm != 1 && this.doTimeForCt != 1) {
          if (this.scList != null && this.scList.length != 0) {
            await this.initScData();
          }
        }
        this.resetDashboard();
      }
      if (this.dashboardAccount != 0) {
        let resetDashboardKeySearchList = this.dashboardKeySearchList;
        if (resetDashboardKeySearchList == undefined || resetDashboardKeySearchList[0] == undefined) {
          this.setBmInfoForDs(dashboardKeySearchList[0]);
          this.setCtSet(dashboardKeySearchList[0]);
          this.getQuickSightDashboardEmbedURL(dashboardKeySearchList[0], this.state.isYear);
        } else {
          if (this.doTimeForReset == 1 || this.doTimeForBm == 1 || this.doTimeForCt == 1) {
            this.setBmInfoForDs(this.tmpChangeDSKey);
            this.setCtSet(this.tmpChangeDSKey);
            this.getQuickSightDashboardEmbedURL(this.tmpChangeDSKey, this.state.isYear);
          } else {
            this.setBmInfoForDs(resetDashboardKeySearchList[0]);
            this.setCtSet(resetDashboardKeySearchList[0]);
            this.getQuickSightDashboardEmbedURL(resetDashboardKeySearchList[0], this.state.isYear);
          }
        }
      }
    }
  }

  setCtgCheckCategoryNm = () => {
    if (this.ctgCategory == undefined || this.ctgCategory == []) {
      this.ctgCheckCategoryNm = [];
    } else {
      this.ctgCheckCategoryNm = [];
      this.ctgCategory.map((item) => {
        this.catList.map((itemCat) => {
          if (itemCat["CategoryKey"] == item) {
            this.ctgCheckCategoryNm.push(this.ctgCategoryName[item]);
          }
        })
      })
    }
  }

  setNav = () => {
    let lastFlg = 0;
    if (!this.isShowAll && document.getElementById("ulNav") != null && document.getElementById("ulNav").clientHeight > 128) {
      document.getElementsByName("liNav")[this.defaultShowCount - 1].classList.remove('display-block');
      document.getElementsByName("liNav")[this.defaultShowCount - 1].classList.remove('display-none');
      document.getElementsByName("liNav")[this.defaultShowCount - 1].classList.add('display-none');
      this.defaultShowCount = this.defaultShowCount - 1;
    }
    for (var i = 0; i < document.getElementsByName("liNav").length; i++) {
      if (document.getElementById("ulNav").clientHeight > 128) {
        break;
      } else {
        document.getElementsByName("liNav")[i].classList.remove('display-none');
        document.getElementsByName("liNav")[i].classList.remove('display-block');
        document.getElementsByName("liNav")[i].classList.add('display-block');
        lastFlg = i;
      }
    }
    if (lastFlg != 0) {
      this.defaultShowCount = lastFlg + 1;
    }
    if (!this.isShowAll && document.getElementById("ulNav") != null && document.getElementById("ulNav").clientHeight > 128) {
      document.getElementsByName("liNav")[lastFlg].classList.remove('display-block');
      document.getElementsByName("liNav")[lastFlg].classList.remove('display-none');
      document.getElementsByName("liNav")[lastFlg].classList.add('display-none');
      this.defaultShowCount = this.defaultShowCount - 1;
    }
  }

  isMonthly = () => {
    let rs = false;
    let keyFirst = "";
    if (this.dashboardKeyForChange != "") {
      keyFirst = this.dashboardKeyForChange.substring(0, 1);
    } else {
      keyFirst = this.dashboardInfo.key.substring(0, 1);
    }
    switch (keyFirst) {
      case '6':
        rs = true;
        break;
    }
    return rs;
  }

  objectArraySort = (objectN, objectM) => {
    return objectN["Sort"] - objectM["Sort"];
  }

  initScData = async () => {
    await this.getSc();
    await this.getCat();
    let radioBmAllFlgSes = true;
    let radioBmHaveFlgSes = false;
    let radioBmNohaveFlgSes = false;
    let chBoxCtgAllFlgSes = true;
    let chBoxCtgFlgSes = [];
    for (let i = 0; i < (this.catList.length + 1); i++) {
      chBoxCtgFlgSes[i] = true;
    }
    let chBoxBdgAllFlgSes = true;
    let chBoxBdgFlgSes = [true, true, true, true];
    let andOrSes = "And";
    if (this.scList[0]["BmFlg"] != "1") {
      radioBmAllFlgSes = false;
    }
    if (this.scList[0]["BmFlg"] == "2") {
      radioBmHaveFlgSes = true;
    }
    if (this.scList[0]["BmFlg"] == "3") {
      radioBmNohaveFlgSes = true;
    }
    if (!this.scList[0]["Ctg"]["All"]) {
      chBoxCtgAllFlgSes = false;
    }
    if (!this.scList[0]["Ctg"]["None"]) {
      chBoxCtgFlgSes[0] = false;
    }
    for (let i = 1; i < (this.catList.length + 1); i++) {
      if (this.scList[0]["Ctg"][this.catList[i - 1]["CategoryKey"]] == undefined) {
        if (chBoxCtgAllFlgSes) {
          chBoxCtgFlgSes[i] = true;
        } else {
          chBoxCtgFlgSes[i] = false;
        }
      } else {
        if (!this.scList[0]["Ctg"][this.catList[i - 1]["CategoryKey"]]) {
          chBoxCtgFlgSes[i] = false;
        }
      }
    }
    if (!this.scList[0]["BdgFlg"]["All"]) {
      chBoxBdgAllFlgSes = false;
    }
    if (!this.scList[0]["BdgFlg"]["None"]) {
      chBoxBdgFlgSes[0] = false;
    }
    if (!this.scList[0]["BdgFlg"]["CAS"]) {
      chBoxBdgFlgSes[1] = false;
    }
    if (!this.scList[0]["BdgFlg"]["ISO"]) {
      chBoxBdgFlgSes[2] = false;
    }
    if (!this.scList[0]["BdgFlg"]["GRI"]) {
      chBoxBdgFlgSes[3] = false;
    }
    if (this.scList[0]["AndOr"] == "2") {
      andOrSes = "Or";
    } else {
      andOrSes = "And";
    }
    this.nowSearchAndOr = andOrSes;
    this.radioBmAllFlg = radioBmAllFlgSes;
    this.radioBmHaveFlg = radioBmHaveFlgSes;
    this.radioBmNohaveFlg = radioBmNohaveFlgSes;
    this.chBoxCtgAllFlg = chBoxCtgAllFlgSes;
    this.chBoxCtgFlg = chBoxCtgFlgSes;
    this.chBoxBdgAllFlg = chBoxBdgAllFlgSes;
    this.chBoxBdgFlg = chBoxBdgFlgSes;
  }

  initSetModal = async () => {
    await this.getSc();
    await this.getCat();
    let radioBmAllFlgSes = true;
    let radioBmHaveFlgSes = false;
    let radioBmNohaveFlgSes = false;
    let chBoxCtgAllFlgSes = true;
    let chBoxCtgFlgSes = [];
    for (let i = 0; i < (this.catList.length + 1); i++) {
      chBoxCtgFlgSes[i] = true;
    }
    let chBoxBdgAllFlgSes = true;
    let chBoxBdgFlgSes = [true, true, true, true];
    let andOrSes = "And";

    radioBmAllFlgSes = this.radioBmAllFlg;
    radioBmHaveFlgSes = this.radioBmHaveFlg;
    radioBmNohaveFlgSes = this.radioBmNohaveFlg;
    chBoxCtgAllFlgSes = this.chBoxCtgAllFlg;
    chBoxCtgFlgSes = this.chBoxCtgFlg;
    chBoxBdgAllFlgSes = this.chBoxBdgAllFlg;
    chBoxBdgFlgSes = this.chBoxBdgFlg;
    if (this.nowSearchAndOr == "Or") {
      this.searchForAndOr(false);
    } else {
      this.searchForAndOr(true);
    }
    // ブックマーク
    let radioBmAll = document.querySelector("#radioBmAll");
    let radioBmHave = document.querySelector("#radioBmHave");
    let radioBmNohave = document.querySelector("#radioBmNohave");
    radioBmAll.checked = radioBmAllFlgSes;
    radioBmHave.checked = radioBmHaveFlgSes;
    radioBmNohave.checked = radioBmNohaveFlgSes;
    // 用途タグ
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let lenCtg = chBoxCtg.length;
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    chBoxCtgAll.indeterminate = !chBoxCtgAllFlgSes;
    chBoxCtgAll.checked = chBoxCtgAllFlgSes;
    if (this.nowCtgValue == "all" || this.chBoxCtgFlg == undefined || this.chBoxCtgFlg == null || this.chBoxCtgFlg == []) {
      for (let i = 0; i < lenCtg; i++) {
        chBoxCtg[i].checked = true;
      }
    } else {
      for (let i = 0; i < lenCtg; i++) {
        chBoxCtg[i].checked = chBoxCtgFlgSes[i];
      }
    }
    // バッジ
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let lenBdg = chBoxBdg.length;
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    chBoxBdgAll.indeterminate = !chBoxBdgAllFlgSes;
    chBoxBdgAll.checked = chBoxBdgAllFlgSes;
    if (this.chBoxBdgFlg == undefined || this.chBoxBdgFlg == null || this.chBoxBdgFlg == []) {
      for (let i = 0; i < lenBdg; i++) {
        chBoxBdg[i].checked = true;
      }
    } else {
      for (let i = 0; i < lenBdg; i++) {
        chBoxBdg[i].checked = chBoxBdgFlgSes[i];
      }
    }
    this.saveButtonType();
  }
  openSetModal = async () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    modalBg.classList.remove("animate__fadeOut");
    modalBg.classList.add("animate__fadeIn");
    await this.initSetModal();
    const modalCtOn = document.querySelector("#modal-window--dashboard-filter");
    modalCtOn.classList.remove("animate__fadeOut");
    modalCtOn.classList.add("animate__fadeIn");
    modalCtOn.classList.add("is-active");
  };

  closeSetModal = () => {
    let modalCtOn = document.querySelector("#modal-window--dashboard-filter");
    modalCtOn.classList.remove("animate__fadeIn");
    modalCtOn.classList.add("animate__fadeOut");
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.remove("animate__fadeIn");
    modalBg.classList.add("animate__fadeOut");
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      modalBg.classList.remove("is-active");
      modalCtOn.classList.remove("is-active");
    }, fadeOutTimeoutSec);
  };

  radioOne = (radioFlg) => {
    if (radioFlg == "bmAll") {
      let radioBmNohave = document.querySelector("#radioBmNohave");
      let radioBmHave = document.querySelector("#radioBmHave");
      radioBmNohave.checked = false;
      radioBmHave.checked = false;
    }
    if (radioFlg == "have") {
      let radioBmAll = document.querySelector("#radioBmAll");
      let radioBmNohave = document.querySelector("#radioBmNohave");
      radioBmAll.checked = false;
      radioBmNohave.checked = false;
    }
    if (radioFlg == "nohave") {
      let radioBmAll = document.querySelector("#radioBmAll");
      let radioBmHave = document.querySelector("#radioBmHave");
      radioBmAll.checked = false;
      radioBmHave.checked = false;
    }
    this.saveButtonType();
  };

  checkAllCtg = () => {
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let len = chBoxCtg.length;

    let checkFlg = false;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        checkFlg = true;
      }
    }
    if (checkFlg) {
      chBoxCtgAll.checked = false;
      for (let i = 0; i < len; i++) {
        chBoxCtg[i].checked = false;
      }
    } else {
      for (let i = 0; i < len; i++) {
        if (chBoxCtgAll.checked) {
          chBoxCtg[i].checked = true;
        } else {
          chBoxCtg[i].checked = false;
        }
      }
    }
    this.saveButtonType();
  };

  checkOneCtg = () => {
    let count = 0;
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let len = chBoxCtg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        count++;
      }
    }
    if (count == len) {
      document.getElementById('chBoxCtgAll').indeterminate = false;
      document.getElementById('chBoxCtgAll').checked = true;
      this.nowCtgValue[0] = "all";
    } else if (count == 0) {
      document.getElementById('chBoxCtgAll').indeterminate = false;
      document.getElementById('chBoxCtgAll').checked = false;
      this.nowCtgValue[0] = "";
    } else {
      document.getElementById('chBoxCtgAll').checked = false;
      document.getElementById('chBoxCtgAll').indeterminate = true;
      this.nowCtgValue[0] = "";
    }
    this.saveButtonType();
  }

  isAndOr;
  searchForAndOr = (andOr) => {
    this.setState({
      isAndOr: andOr,
    });
    this.isAndOr = andOr;
    if (andOr) {
      this.nowSearchAndOr = "And";
    } else {
      this.nowSearchAndOr = "Or";
    }
    if (andOr) {
      document.getElementById("searchAnd").defaultChecked = true;
      document.getElementById("searchAnd").checked = "checked";
      document.getElementById("searchOr").defaultChecked = false;
      document.getElementById("searchOr").checked = "";
    } else {
      document.getElementById("searchAnd").defaultChecked = false;
      document.getElementById("searchAnd").checked = "";
      document.getElementById("searchOr").defaultChecked = true;
      document.getElementById("searchOr").checked = "checked";
    }
  };

  checkAllBdg = () => {
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let len = chBoxBdg.length;
    let checkFlg = false;
    for (let i = 0; i < len; i++) {
      if (chBoxBdg[i].checked) {
        checkFlg = true;
      }
    }
    if (checkFlg) {
      chBoxBdgAll.checked = false;
      for (let i = 0; i < len; i++) {
        chBoxBdg[i].checked = false;
      }
    } else {
      for (let i = 0; i < len; i++) {
        if (chBoxBdgAll.checked) {
          chBoxBdg[i].checked = true;
        } else {
          chBoxBdg[i].checked = false;
        }
      }
    }
    this.saveButtonType();
  };

  checkOneBdg = () => {
    let count = 0;
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let len = chBoxBdg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxBdg[i].checked) {
        count++;
      }
    }
    if (count == len) {
      document.getElementById('chBoxBdgAll').indeterminate = false;
      document.getElementById('chBoxBdgAll').checked = true;
      this.nowBdgValue[0] = "all";
    } else if (count == 0) {
      document.getElementById('chBoxBdgAll').indeterminate = false;
      document.getElementById('chBoxBdgAll').checked = false;
      this.nowBdgValue[0] = "";
    } else {
      document.getElementById('chBoxBdgAll').checked = false;
      document.getElementById('chBoxBdgAll').indeterminate = true;
      this.nowBdgValue[0] = "";
    }
    this.saveButtonType();
  }

  saveButtonType = () => {
    let tmpRadioBmAllFlg = true;
    let tmpRadioBmHaveFlg = false;
    let tmpRadioBmNohaveFlg = false;
    let tmpChBoxCtgAllFlg = true;
    let tmpChBoxCtgFlg = [];
    let tmpChBoxBdgAllFlg = true;
    let tmpChBoxBdgFlg = [];
    let radioBmAll = document.querySelector("#radioBmAll");
    let radioBmHave = document.querySelector("#radioBmHave");
    let radioBmNohave = document.querySelector("#radioBmNohave");
    if (radioBmAll.checked) {
      tmpRadioBmAllFlg = true;
    } else {
      tmpRadioBmAllFlg = false;
    }
    if (radioBmHave.checked) {
      tmpRadioBmHaveFlg = true;
    } else {
      tmpRadioBmHaveFlg = false;
    }
    if (radioBmNohave.checked) {
      tmpRadioBmNohaveFlg = true;
    } else {
      tmpRadioBmNohaveFlg = false;
    }

    let chBoxCtgBtnFlg = false;
    let chBoxCtgOnlyNullFlg = false;
    let chBoxCheckedCount = 0;
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    let chBoxCtgNull = document.getElementById('chBoxCtgNull')
    if (chBoxCtgAll.checked) {
      chBoxCtgBtnFlg = true;
      tmpChBoxCtgAllFlg = true;
    } else {
      tmpChBoxCtgAllFlg = false;
    }
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    let lenCtg = chBoxCtg.length;
    for (let i = 0; i < lenCtg; i++) {
      if (chBoxCtg[i].checked) {
        chBoxCheckedCount = chBoxCheckedCount + 1;
        chBoxCtgBtnFlg = true;
        tmpChBoxCtgFlg[i] = true;
      } else {
        tmpChBoxCtgFlg[i] = false;
      }
    }
    if (chBoxCheckedCount == 1 && chBoxCtgNull.checked) {
      chBoxCtgOnlyNullFlg = true;
    } else {
      chBoxCtgOnlyNullFlg = false;
    }

    let chBoxBdgBtnFlg = false;
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    if (chBoxBdgAll.checked) {
      chBoxBdgBtnFlg = true;
      tmpChBoxBdgAllFlg = true;
    } else {
      tmpChBoxBdgAllFlg = false;
    }
    let chBoxBdg = document.getElementsByClassName('chBoxBdg');
    let lenBdg = chBoxBdg.length;
    for (let i = 0; i < lenBdg; i++) {
      if (chBoxBdg[i].checked) {
        chBoxBdgBtnFlg = true;
        tmpChBoxBdgFlg[i] = true;
      } else {
        tmpChBoxBdgFlg[i] = false;
      }
    }
    this.radioBmAllFlg = tmpRadioBmAllFlg;
    this.radioBmHaveFlg = tmpRadioBmHaveFlg;
    this.radioBmNohaveFlg = tmpRadioBmNohaveFlg;
    this.chBoxCtgAllFlg = tmpChBoxCtgAllFlg;
    this.chBoxCtgFlg = tmpChBoxCtgFlg;
    this.chBoxBdgAllFlg = tmpChBoxBdgAllFlg;
    this.chBoxBdgFlg = tmpChBoxBdgFlg;
    let saveDashboardBtn = document.getElementById('saveDashboardBtn');
    let saveDashboardLabel = document.getElementById('saveDashboardLabel');
    saveDashboardBtn.classList.remove('display-none');
    saveDashboardBtn.classList.remove('display-block');
    saveDashboardLabel.classList.remove('display-none');
    saveDashboardLabel.classList.remove('display-block');
    if ((chBoxCtgBtnFlg && chBoxBdgBtnFlg) && !(tmpRadioBmNohaveFlg && chBoxCtgOnlyNullFlg)) {
      saveDashboardBtn.classList.add('display-block');
      saveDashboardLabel.classList.add('display-none');
    } else {
      saveDashboardBtn.classList.add('display-none');
      saveDashboardLabel.classList.add('display-block');
    }
  }

  nowBmValue = [];
  nowCtgValue = [];
  nowBdgValue = [];
  nowSearchAndOr = "And";
  firstChangeDSKey = null;
  doSaveDashboard = async () => {
    // ブックマーク
    this.nowBmValue = [];
    const radioCtBmAll = document.querySelector("#radioBmAll");
    const radioBmHave = document.querySelector("#radioBmHave");
    const radioBmNohave = document.querySelector("#radioBmNohave");
    if (radioCtBmAll.checked) {
      this.nowBmValue[0] = "all";
    }
    if (radioBmHave.checked) {
      this.nowBmValue[1] = radioBmHave.value;
    }
    if (radioBmNohave.checked) {
      this.nowBmValue[2] = radioBmNohave.value;
    }
    // 用途タグ
    let chBoxCtg = document.getElementsByName('chBoxCtg');
    this.nowCtgValue = [];
    let len = chBoxCtg.length;
    for (let i = 0; i < len; i++) {
      if (chBoxCtg[i].checked) {
        this.nowCtgValue[i + 1] = this.catList[i]["CategoryKey"];
      }
    }
    let chBoxCtgAll = document.getElementById('chBoxCtgAll');
    let chBoxCtgNull = document.getElementById('chBoxCtgNull');
    if (chBoxCtgAll.checked) {
      this.nowCtgValue[0] = "all";
    } else {
      if (chBoxCtgNull.checked) {
        this.nowCtgValue[0] = "null";
      }
    }
    // バッジ
    let chBoxBdgAll = document.getElementById('chBoxBdgAll');
    let chBoxBdgNull = document.getElementById('chBoxBdgNull');
    this.nowBdgValue = [];
    if (chBoxBdgAll.checked) {
      this.nowBdgValue[0] = "all";
    } else {
      if (chBoxBdgNull.checked) {
        this.nowBdgValue[0] = "null";
      }
      let chBoxBdgCAS = document.getElementById('chBoxBdgCAS');
      if (chBoxBdgCAS.checked) {
        this.nowBdgValue[1] = chBoxBdgCAS.value;
      }
      let chBoxBdgISO = document.getElementById('chBoxBdgISO');
      if (chBoxBdgISO.checked) {
        this.nowBdgValue[2] = chBoxBdgISO.value;
      }
      let chBoxBdgGRI = document.getElementById('chBoxBdgGRI');
      if (chBoxBdgGRI.checked) {
        this.nowBdgValue[3] = chBoxBdgGRI.value;
      }
    }
    // ブックマーク
    let nowDispStateBm = "";
    if (this.nowBmValue[0] != "all") {
      if (this.nowBmValue[1] == "1") {
        nowDispStateBm = "ブックマークあり";
      } else if (this.nowBmValue[2] == "2") {
        nowDispStateBm = "ブックマークなし";
      }
    } else {
      nowDispStateBm = allConditions.allBookmark;
    }
    // 用途タグ
    let nowDispStateCtg = "";
    let nowDispStateAndOr = "";
    if (!chBoxCtgAll.checked) {
      if (chBoxCtgNull.checked) {
        nowDispStateCtg = nowDispStateCtg + "用途タグなし" + "、";
      }
      if (this.catList.length === chBoxCtg.length) {
        this.catList.map((item, index) => {
          if (chBoxCtg[index].checked) {
            nowDispStateCtg = nowDispStateCtg + item["CategoryName"] + "、";
          }
        })
      }
      if (nowDispStateCtg.length > 0 && nowDispStateCtg.substring(nowDispStateCtg.length - 1) == "、") {
        nowDispStateCtg = nowDispStateCtg.substring(0, nowDispStateCtg.length - 1);
      }
    } else {
      nowDispStateCtg = allConditions.allCategory;
    }
    if (this.nowSearchAndOr == "Or") {
      nowDispStateAndOr = "または";
    } else {
      nowDispStateAndOr = "かつ";
    }
    // バッジ
    let nowDispStateBdg = "";
    if (!chBoxBdgAll.checked) {
      if (chBoxBdgNull.checked) {
        nowDispStateBdg = nowDispStateBdg + "バッジなし" + "、";
      }
      if (this.nowBdgValue[1] == "CAS") {
        nowDispStateBdg = nowDispStateBdg + "CAS、";
      }
      if (this.nowBdgValue[2] == "ISO") {
        nowDispStateBdg = nowDispStateBdg + "ISO30414、";
      }
      if (this.nowBdgValue[3] == "GRI") {
        nowDispStateBdg = nowDispStateBdg + "GRI、";
      }
      if (nowDispStateBdg.length > 0 && nowDispStateBdg.substring(nowDispStateBdg.length - 1) == "、") {
        nowDispStateBdg = nowDispStateBdg.substring(0, nowDispStateBdg.length - 1);
      }
    } else {
      nowDispStateBdg = allConditions.allBadge;
    }

    this.setState({
      dispStateBm: nowDispStateBm,
      dispStateCtg: nowDispStateCtg,
      dispStateAndOr: nowDispStateAndOr,
      dispStateBdg: nowDispStateBdg,
    });
    this.dashboardAccount = 0;
    this.dashboardKeySearchList = [];

    this.noBadgeAccount = 0;
    this.casAccount = 0;
    this.isoAccount = 0;
    this.griAccount = 0;
    this.catAccount = [];
    this.catList.map((item, index) => {
      this.catAccount[index] = 0;
    })
    this.ctAccount = 0;
    this.setPageInfoCond(mainPageInfoList);
    this.setPageInfoCond(monitoringInfoList);
    this.resetShowAllBtn(true);

    if (this.dashboardKeySearchList.length != 0) {
      await this.initDataNoDs();
      if (this.bmSet[this.dashboardKeySearchList[0]] != undefined) {
        this.bmHaveFlg = true;
      } else {
        this.bmHaveFlg = false;
      }
    }
    if (this.saveDSKey != null) {
      if (this.dashboardKeySearchList.length != 0) {
        if (!this.dashboardKeySearchList.includes(this.saveDSKey) && this.dashboard) {
          this.changeDashboard(this.dashboardKeySearchList[0], true);
          this.saveDSKey = null;
        } else {
          this.getQuickSightDashboardEmbedURL(this.saveDSKey, this.state.isYear);
        }
      }
    }
    this.tmpChangeDSKey = null;
    if (this.saveCtDSKey != null) {
      if (this.dashboardKeySearchList.length != 0 && this.dashboardKeySearchList.includes(this.saveCtDSKey)) {
        this.tmpChangeDSKey = this.saveCtDSKey;
      }
    }
    if (this.tmpChangeDSKey == null) {
      this.firstChangeDSKey = this.dashboardInfo?.key;
      if (this.dashboardKeySearchList.length != 0 && !this.dashboardKeySearchList.includes(this.dashboardInfo.key)) {
        this.firstChangeDSKey = this.dashboardKeySearchList[0];
      }
    }
  }

  saveDSKey = null;
  saveDashboard = async () => {
    this.closeSetModal();
    if (!this.dashboardInfo && this.dashboardKeyList.length) {
      const keyobj = this.keyinfo(this.dashboardKeyList[0]);
      this.dashboardList = this.getDashboardList(keyobj.key, keyobj.index1);
      this.dashboardInfo = this.dashboardList[keyobj.index1].dashboard[keyobj.index2];
    }
    this.saveDSKey = this.dashboardInfo.key;
    this.setState({
      pageInfoDashboardKey: this.saveDSKey,
    });
    this.doSaveDashboard();
  };

  isSaveCondition = false;
  saveSearchCondition = async () => {
    if (this.isSaveCondition) {
      return;
    }
    try {
      this.isSaveCondition = true;
      await this.getSc();
      if (this.scList == null || this.scList.length == 0) {
        await this.registSc();
      } else {
        this.scVersion = this.scList[0]["Version"];
        await this.updateSc();
      }
      this.setState({
        saveConBtnFlg: false,
      });
      this.showToast("保存しました。次回アクセス時にこの条件で表示します");
      const modalBg = document.querySelector(".bg-modal-window");
      modalBg.classList.remove("animate__fadeIn");
      modalBg.classList.add("animate__fadeOut");
      const fadeOutTimeoutSec = 300;
      setTimeout(function () {
        modalBg.classList.remove("is-active");
      }, fadeOutTimeoutSec);
    } finally {
      this.isSaveCondition = false;
    }
  };

  resetDashboard = () => {
    let radioBmAllFlgSes = this.radioBmAllFlg;
    let radioBmHaveFlgSes = this.radioBmHaveFlg;
    let radioBmNohaveFlgSes = this.radioBmNohaveFlg;
    let chBoxCtgAllFlgSes = this.chBoxCtgAllFlg;
    let chBoxCtgFlgSes = this.chBoxCtgFlg;
    let chBoxBdgAllFlgSes = this.chBoxBdgAllFlg;
    let chBoxBdgFlgSes = this.chBoxBdgFlg;
    // ブックマーク
    this.nowBmValue = [];
    if (radioBmAllFlgSes == null || radioBmAllFlgSes) {
      this.nowBmValue[0] = "all";
    }
    if (radioBmHaveFlgSes == null || radioBmHaveFlgSes) {
      this.nowBmValue[1] = "1";
    }
    if (radioBmNohaveFlgSes == null || radioBmNohaveFlgSes) {
      this.nowBmValue[2] = "2";
    }
    // 用途タグ
    let chBoxCtg = document.getElementsByClassName('chBoxCtg');
    this.nowCtgValue = [];
    let len = chBoxCtg.length;
    if (chBoxCtgFlgSes != null) {
      for (let i = 0; i < len; i++) {
        if (chBoxCtgFlgSes[i] != null && chBoxCtgFlgSes[i]) {
          if (i == 0) {
            this.nowCtgValue[i] = i;
          } else {
            this.nowCtgValue[i] = this.catList[i - 1]["CategoryKey"];
          }
        } else {
          this.nowCtgValue[i] = null;
        }
      }
    } else {
      this.nowCtgValue[0] = "all";
    }
    if (chBoxCtgAllFlgSes == null || chBoxCtgAllFlgSes) {
      this.nowCtgValue[0] = "all";
    } else {
      if (chBoxCtgFlgSes != null) {
        if (chBoxCtgFlgSes[0] != null && chBoxCtgFlgSes[0]) {
          this.nowCtgValue[0] = "null";
        }
      } else {
        this.nowCtgValue[0] = "all";
      }
    }
    // バッジ
    this.nowBdgValue = [];
    if (chBoxBdgAllFlgSes == null || chBoxBdgAllFlgSes) {
      this.nowBdgValue[0] = "all";
    } else {
      if (chBoxBdgFlgSes != null) {
        if (chBoxBdgFlgSes[0] != null && chBoxBdgFlgSes[0]) {
          this.nowBdgValue[0] = "null";
        }
        if (chBoxBdgFlgSes[1] != null && chBoxBdgFlgSes[1]) {
          this.nowBdgValue[1] = "CAS";
        }
        if (chBoxBdgFlgSes[2] != null && chBoxBdgFlgSes[2]) {
          this.nowBdgValue[2] = "ISO";
        }
        if (chBoxBdgFlgSes[3] != null && chBoxBdgFlgSes[3]) {
          this.nowBdgValue[3] = "GRI";
        }
      } else {
        this.nowBdgValue[0] = "all";
      }
    }
    this.dashboardAccount = 0;
    this.dashboardKeySearchList = [];

    this.noBadgeAccount = 0;
    this.casAccount = 0;
    this.isoAccount = 0;
    this.griAccount = 0;
    this.catAccount = [];
    this.catList.map((item, index) => {
      this.catAccount[index] = 0;
    })
    this.ctAccount = 0;
    this.setPageInfoCond(mainPageInfoList);
    this.setPageInfoCond(monitoringInfoList);
  };

  resetAll = async () => {
    this.nowBmValue = [];
    this.nowBmValue[0] = "all";
    this.nowCtgValue = [];
    this.nowCtgValue[0] = "all";
    this.nowBdgValue = [];
    this.nowBdgValue[0] = "all";
    this.nowSearchAndOr = "And";
    this.isAndOr = true;
    this.setState({
      dispStateBm: "",
      dispStateCtg: "",
      dispStateAndOr: "",
      dispStateBdg: "",
    });

    let tmpRadioBmAllFlg = true;
    let tmpRadioBmHaveFlg = false;
    let tmpRadioBmNohaveFlg = false;
    let tmpChBoxCtgAllFlg = true;
    let tmpChBoxCtgFlg = [];
    let tmpChBoxBdgAllFlg = true;
    let tmpChBoxBdgFlg = [];
    tmpRadioBmAllFlg = true;
    tmpRadioBmHaveFlg = false;
    tmpRadioBmNohaveFlg = false;
    tmpChBoxCtgAllFlg = true;
    tmpChBoxBdgAllFlg = true;
    await this.getCat();
    if (this.catList != undefined && this.catList != null && this.catList != []) {
      for (let i = 0; i <= this.catList.length; i++) {
        tmpChBoxCtgFlg[i] = true;
      }
    }
    tmpChBoxBdgFlg[0] = true;
    tmpChBoxBdgFlg[1] = true;
    tmpChBoxBdgFlg[2] = true;
    tmpChBoxBdgFlg[3] = true;
    this.radioBmAllFlg = tmpRadioBmAllFlg;
    this.radioBmHaveFlg = tmpRadioBmHaveFlg;
    this.radioBmNohaveFlg = tmpRadioBmNohaveFlg;
    this.chBoxCtgAllFlg = tmpChBoxCtgAllFlg;
    this.chBoxCtgFlg = tmpChBoxCtgFlg;
    this.chBoxBdgAllFlg = tmpChBoxBdgAllFlg;
    this.chBoxBdgFlg = tmpChBoxBdgFlg;
    document.getElementById("searchAnd").defaultChecked = true;
    document.getElementById("searchAnd").checked = "checked";
    document.getElementById("searchOr").defaultChecked = false;
    document.getElementById("searchOr").checked = "";
    this.scList = [];
    this.dashboardKeyList.sort((a, b) => this.dashboardKeySort(a, b));
    this.dashboardKeySearchList = this.dashboardKeyList;
    this.dashboardKeyDispList = this.dashboardKeySearchList;
    this.defaultShowCount = this.dashboardKeyList.length;
    if (!this.dashboardAccount) {
      this.dashboardAccount = this.dashboardKeySearchList.length
    }
    if (!this.dashboardInfo && this.dashboardKeyList.length) {
      const keyobj = this.keyinfo(this.dashboardKeyList[0]);
      this.dashboardList = this.getDashboardList(keyobj.key, keyobj.index1);
      this.dashboardInfo = this.dashboardList[keyobj.index1].dashboard[keyobj.index2];
      this.saveDSKey = this.dashboardInfo.key;
      this.setState({
        pageInfoDashboardKey: this.saveDSKey,
      });
    }
    if (this.dashboardInfo != undefined) {
      this.tmpChangeDSKey = this.dashboardInfo.key;
    }
    this.doTimeForReset = 1;
    await this.initData();
  };

  setIsOpenKpiYear = (isOpen) => {
    this.setState({
      isOpenKpiYear: isOpen,
    });
    const kpiYearButton = document.getElementById("kpiYearButton");
    if (isOpen) {
      kpiYearButton.classList.add('is-active');
    } else {
      kpiYearButton.classList.remove('is-active');
    }
  };

  changeKpiYear = async (value) => {
    try {
      this.setState({
        kpiTargetInfo: value,
      });
      this.setIsOpenKpiYear(false);
      const paramObj = await this.getParameters(this.state.year, this.state.month, value, this.state.benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYear", error);
    }
  };

  dashboardKeySort = (a, b) => {
    // 大見出しでソート
    const aLKey = a.split(".")[0];
    const bLKey = b.split(".")[0];
    if (a.startsWith("S") && b.startsWith("S")) {
      if (parseFloat(aLKey.replace("S", "")) > parseFloat(bLKey.replace("S", ""))) return 1;
      if (parseFloat(aLKey.replace("S", "")) < parseFloat(bLKey.replace("S", ""))) return -1;
    } else if (aLKey.startsWith("S")) {
      return -1;
    } else if (bLKey.startsWith("S")) {
      return 1;
    } else {
      if (parseFloat(aLKey) > parseFloat(bLKey)) return 1;
      if (parseFloat(aLKey) < parseFloat(bLKey)) return -1;
    }
    // 中見出しでソート
    const aMKey = parseFloat(a.split(".")[1]);
    const bMKey = parseFloat(b.split(".")[1]);
    if (aMKey != bMKey) {
      if (aMKey > bMKey) return 1;
      if (aMKey < bMKey) return -1;
    }
    // 小見出しでソート
    const aSKey = parseFloat(a.split(".")[2]);
    const bSKey = parseFloat(b.split(".")[2]);
    if (aSKey != bSKey) {
      if (aSKey > bSKey) return 1;
      if (aSKey < bSKey) return -1;
    }
    return 0;
  }

  getRelDashboard = async () => {
    try {
      this.relDashboardList = await getDynamoDB(
        table.RelatedDashboard,
        null,
        this.props.corporationNumber
      );
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getRelDashboard", error);
    }
  };

  setRelDashboardList = () => {
    try {
      let relDashboardList = [];
      if (this.relDashboardList.length > 0) {
        relDashboardList =
          this.relDashboardList
            .filter(v => v.DashboardKey == this.dashboardInfo.key)
            .filter((v) => {
              const largeKey = (v.RelDashboardKey.split(".")[0]); // 大見出しのキー
              const mIdx = (v.RelDashboardKey.split(".")[1]) - 1; // 中見出しの要素
              const sIdx = (v.RelDashboardKey.split(".")[2]) - 1; // 小見出しの要素
              const pageInfo = this.pageInfoList.find(page => { return page.key === largeKey });
              return pageInfo.dashboardList[mIdx].dashboard[sIdx].activeFlg;
            })
            .map((v) => { return v.RelDashboardKey })
            .sort((a, b) => this.dashboardKeySort(a, b));
      }
      this.setState({
        relDashboardList: relDashboardList,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "setRelDashboardList", error);
    }
  };

  getKpiTargetInfo = async () => {
    try {
      this.kpiTargetInfo = await getDynamoDB(
        table.KpiTargetInfo,
        null,
        this.props.corporationNumber,
        { Mode: analysisMode.standalone }
      );
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getKpiTargetInfo", error);
    }
  };

  setKpiTargetInfoList = () => {
    try {
      let kpiTargetInfoList = [];
      let kpiTargetInfo = {};
      if (this.kpiTargetInfo.length > 0) {
        let kpiKeyIdx = this.dashboardInfo.key.indexOf(".") + 1;
        let array = this.kpiTargetInfo[0].TargetValue.find(v => v.Key == this.dashboardInfo.key.substring(kpiKeyIdx));
        if (array != undefined) {
          array = array.Value;
          for (const [k, v] of Object.entries(array)) {
            if (v.Value != "") {
              kpiTargetInfoList.push({ "year": k, "value": v.Value });
            }
          }
          if (kpiTargetInfoList.length > 0) {
            kpiTargetInfoList.sort((a, b) => {
              return (a["year"] > b["year"]) ? 1 : -1;
            });
            kpiTargetInfo = kpiTargetInfoList[0];
          }
        }
      }
      this.setState({
        kpiTargetInfoList: kpiTargetInfoList,
        kpiTargetInfo: kpiTargetInfo,
      });
      return kpiTargetInfo;
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "setKpiTargetInfoList", error);
    }
  };

  getBenchmarkTarget = async () => {
    const initData = {
      Key: 0,
      Title: '条件指定なし',
      ConditionInfo: {
        CompanyList: [],
        ListingClassification: [],
        IndustrySector: [],
        CompanySize: []
      }
    };
    try {
      let benchmarkTarget = await getDynamoDB(
        table.BenchmarkTarget,
        null,
        this.props.corporationNumber,
        null,
        null
      );
      // ユーザマスタを取得
      let user = await getDynamoDB(
        table.User,
        null,
        this.props.corporationNumber,
        null,
        null
      );
      if (benchmarkTarget.length > 0) {
        const l1 = benchmarkTarget.filter(l => user.find(u => l.UserId === u.UserId)); // 退職者を除く
        const l2 = l1.filter(l => l.UserId === this.props.userId) // 自身が登録した条件
          .sort((a, b) => {
            if (a.Title > b.Title) return 1;
            if (b.Title > a.Title) return -1;
            return 0;
          });
        const l3 = l1.filter(l => l.UserId !== this.props.userId && l.SharedFlag) // 他者が共有した条件（名称に[共有]を付ける）
          .map(l => { return { ...l, Title: l.Title + '[共有]' } })
          .sort((a, b) => {
            if (a.Title > b.Title) return 1;
            if (b.Title > a.Title) return -1;
            return 0;
          });
        benchmarkTarget = l2.concat(l3).map(l => { return { Key: l.Key, Title: l.Title, ConditionInfo: l.ConditionInfo } });
      }
      benchmarkTarget.unshift(initData);
      this.setState({
        benchmarkTarget: initData,
        benchmarkTargetList: benchmarkTarget,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getBenchmarkTarget", error);
    }
  };

  getBenchmarkIndustrySector = async (dashboardKeyList, selectedDashboardKey) => {
    try {
      // 女活データの場合 type=2、財務データの場合 type=3
      const jokatsuIndustrySectorType = '2';
      const edinetIndustrySectorType = '3';

      // ベンチマーク情報（業種設定）取得
      // 10系ダッシュボード「法廷開示レポート」は女活の業種設定を取得
      if (dashboardKeyList.some(v => v.startsWith('10'))) {
        this.jokatsuIndustrySector = await getIndustrySector(this.props.corporationNumber, jokatsuIndustrySectorType);
        
      };
      // S2系ダッシュボード「人的資本KPI」はEDINETの業種設定を取得
      if (dashboardKeyList.some(v => v.startsWith('S'))) {
        this.edinetIndustrySector = await getIndustrySector(this.props.corporationNumber, edinetIndustrySectorType);
      };

      // フィルター条件 "業種" の初期値設定
      if (selectedDashboardKey.startsWith('10')) {
        this.industrySector = this.jokatsuIndustrySector
      } else if (selectedDashboardKey.startsWith('S')) {
        this.industrySector = this.edinetIndustrySector
      }
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getBenchmarkIndustrySector", error);
    }
  };

  changeBenchmarkTarget = async (value) => {
    const benchmarkTarget = this.state.benchmarkTargetList.find(l => l.Key === parseInt(value));
    try {
      this.setState({
        benchmarkTarget: benchmarkTarget,
      });
      const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeBenchmarkTarget", error);
    }
  }

  getRelDashboardInfo = (key) => {
    const largeKey = (key.split(".")[0]); // 大見出しのキー
    const mIdx = (key.split(".")[1]) - 1; // 中見出しの要素
    const sIdx = (key.split(".")[2]) - 1; // 小見出しの要素
    const pageInfo = this.pageInfoList.find(page => { return page.key === largeKey; });
    const url = pageInfo.url + (mIdx) + "/" + (sIdx);
    const pageText = this.state.pageText[largeKey];
    const name = pageText.dashboardList[mIdx].dashboard[sIdx].name + (pageInfo.dashboardList[mIdx].dashboard[sIdx].quarterFlg ? " [Y/Q]" : "");
    // 参照権限があるか判定
    let availability = false;
    if (this.dashboardAuthConf.Availability) {  // ダッシュボードへの参照権限がある
      let authConf = this.dashboardAuthConf.Auth_Conf.find(auth => auth.Key === largeKey);
      if (authConf?.Availability) {  // 大見出しレベルで参照権限がある
        const mDashboardId = largeKey + '.' + String(mIdx + 1);
        const dashboardList = authConf.DashboardList.find(d => d.Key === mDashboardId);
        if (dashboardList?.Availability) {  // 中見出しレベルで参照権限がある
          const sDashboardId = mDashboardId + '.' + (sIdx + 1);
          const authDashboard = dashboardList.Dashboard.find(d => d === sDashboardId);
          if (authDashboard) {  // 小見出しレベルで参照権限がある
            availability = true;
          }
        }
      }
    }
    return { "name": name, "url": url, "availability": availability };
  };

  dashboardKeyDispList = [];
  getPageInfoHtml = (dashboardAccount) => {
    if (this.dashboardKeyList.length == 0) {
      return (
        <div className="box-graph__header">
        </div>
      );
    } else if (dashboardAccount == 0) {
      return "";
    } else {
      return (
        <div className="box-graph__header pt-4 pb-5 px-2 rounded-b-lg white mb-8">
          <ul id="ulNav" className="nav-graph flex flex-wrap pb-0.5 pb-px">
            {this.dashboardKeyDispList.map((dskey, index) => {
              const item = this.getDashboardItemByKey(dskey);
              if (this.state.isDashboardLoaded) {
                return (
                  <div key={index}>
                    <li id={`liNav${index}`} name="liNav" className={`liNav nav-graph__item ${this.state.pageInfoDashboardKey === dskey ? ' is-active' : ' '}${(index >= this.defaultShowCount && !this.isShowAll) ? ' display-none ' : ''}`}>
                      <button type="button" className="nav-graph__btn text-h6 px-2 py-1.5 mx-4 my-1" disabled={!this.state.isDashboardLoaded} onClick={() => this.changeDashboard(dskey, true)}>
                        <span className="nav-graph__text">{this.getDashboardNameByKey(dskey)}{item.quarterFlg ? " [Y/Q]" : ""}</span>
                        <span className="nav-graph__preview"><img src={item.img} alt="" /></span>
                      </button>
                    </li>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <li id={`liNav${index}`} name="liNav" className={`liNav nav-graph__item-load ${this.state.pageInfoDashboardKey === dskey ? ' is-active' : ' '}${(index >= this.defaultShowCount && !this.isShowAll) ? ' display-none ' : ''}`}>
                      <button type="button" className="nav-graph__btn-load text-h6 px-2 py-1.5 mx-4 my-1 is-disabled" disabled={!this.state.isDashboardLoaded}>
                        <span className="nav-graph__text-load">{this.getDashboardNameByKey(dskey)}{item.quarterFlg ? " [Y/Q]" : ""}</span>
                      </button>
                    </li>
                  </div>
                );
              }
            })}
          </ul>
          <div>
            <button id="showAllBtn" type="button" className={`nav-graph__btn my-dashboard-toggle text-pulldown px-2 py-1.5 mx-4 my-1 show-part ${this.dashboardKeyDispList.length <= this.defaultShowCount ? ' display-none ' : ''}`} onClick={() => this.showAllLink()}>
              <span id="myDashboardToggleText" className="nav-graph__text my-dashboard-toggle-text">すべて表示（全{this.dashboardKeyDispList.length}件）</span>
            </button>
          </div>
        </div>
      );
    }
  }

  getDashboardHtml = (dashboardAccount, dashboardKeyDisp) => {
    if (this.dashboardKeyList.length == 0) {
      if (document.getElementById("embeddingContainer") != null) {
        if (!document.getElementById("embeddingContainer").classList.contains('display-none')) {
          document.getElementById("embeddingContainer").classList.remove('display-block');
          document.getElementById("embeddingContainer").classList.add('display-none');
        }
        return (
          <div className="rounded-2xl">
            {/* // ↑ 申し送り
            ■マイダッシュボード登録なしによるクラスの差し替え
            マイダッシュボード登録なし　rounded-2xl
            マイダッシュボード登録あり　box-graph rounded-2xl white */}

            {/* ===== カテゴリータイトルとコントロール ===== */}
            <div className="dashboard-title-area flex justify-between items-center pl-10 pr-8">
              {/* ===== カテゴリータイトル =====
              ===== /カテゴリータイトル =====
              ===== コントロール =====
  
              ===== /コントロール ===== */}
            </div>
            {/* ===== /カテゴリータイトルとコントロール =====
  
            ===== グラフ ===== */}
            <div className="">
              {/* // ↑ 申し送り
                ■マイダッシュボード登録なしによるクラスの差し替え
                マイダッシュボード登録なし　
                マイダッシュボード登録あり　box-graph__body */}
              <div id="embeddingContainer">
                <div className="text-center">
                  <p className="text-h4 py-20">
                    この画面には、ブックマークや用途タグを設定したダッシュボードが集約して表示されます。<br></br>
                    ブックマークをつけたダッシュボードだけを表示したい場合や、あらかじめ用途タグを設定した<br></br>特定のダッシュボードだけを確認したいときなどにご活用ください。
                  </p>
                  <figure className="w-fit m-auto">
                    <img src={img_my_dashboard_init} alt="" className="div-mid" />
                  </figure>
                </div>
              </div>
              <div className="text-center">
                <p className="text-h4 py-20">
                  この画面には、ブックマークや用途タグを設定したダッシュボードが集約して表示されます。<br></br>
                  ブックマークをつけたダッシュボードだけを表示したい場合や、あらかじめ用途タグを設定した<br></br>特定のダッシュボードだけを確認したいときなどにご活用ください。
                </p>
                <figure className="w-fit m-auto">
                  <img src={img_my_dashboard_init} alt="" className="div-mid" />
                </figure>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="rounded-2xl">
            {/* // ↑ 申し送り
            ■マイダッシュボード登録なしによるクラスの差し替え
            マイダッシュボード登録なし　rounded-2xl
            マイダッシュボード登録あり　box-graph rounded-2xl white */}

            {/* ===== カテゴリータイトルとコントロール ===== */}
            <div className="dashboard-title-area flex justify-between items-center pl-10 pr-8">
              {/* ===== カテゴリータイトル =====
              ===== /カテゴリータイトル =====
              ===== コントロール =====
  
              ===== /コントロール ===== */}
            </div>
            {/* ===== /カテゴリータイトルとコントロール =====
  
            ===== グラフ ===== */}
            <div className="">
              {/* // ↑ 申し送り
                ■マイダッシュボード登録なしによるクラスの差し替え
                マイダッシュボード登録なし　
                マイダッシュボード登録あり　box-graph__body */}
              <div id="embeddingContainer">
                <div className="text-center">
                  <p className="text-h4 py-20">
                    この画面には、ブックマークや用途タグを設定したダッシュボードが集約して表示されます。<br></br>
                    ブックマークをつけたダッシュボードだけを表示したい場合や、あらかじめ用途タグを設定した<br></br>特定のダッシュボードだけを確認したいときなどにご活用ください。
                  </p>
                  <figure className="w-fit m-auto">
                    <img src={img_my_dashboard_init} alt="" className="div-mid" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (dashboardAccount == 0) {
      if (document.getElementById("embeddingContainer") != null) {
        if (!document.getElementById("embeddingContainer").classList.contains('display-none')) {
          document.getElementById("embeddingContainer").classList.remove('display-block');
          document.getElementById("embeddingContainer").classList.add('display-none');
        }
      }
      return (
        <div className="text-center">
          <p className="text-h4 py-20">
            表示できるダッシュボードがありません。<br></br>
            表示条件を変更して再度お試し下さい。
          </p>
        </div>
      );
    } else {
      if (document.getElementById("embeddingContainer") != null) {
        if (!document.getElementById("embeddingContainer").classList.contains('display-block')) {
          document.getElementById("embeddingContainer").classList.remove('display-none');
          document.getElementById("embeddingContainer").classList.add('display-block');
        }
      }
      if (this.dashboardInfo == undefined) {
        this.pageInfoKey = dashboardKeyDisp[0].split(".")[0];
        this.dashboardList = this.getDashboardList(this.pageInfoKey);
        this.titleIdx = Number(dashboardKeyDisp[0].split(".")[1]) - 1;
        this.dashboardIdxSub = Number(dashboardKeyDisp[0].split(".")[2]) - 1;
      } else {
        this.pageInfoKey = this.dashboardInfo.key.split(".")[0];
        this.dashboardList = this.getDashboardList(this.pageInfoKey);
        this.titleIdx = Number(this.dashboardInfo.key.split(".")[1]) - 1;
        this.dashboardIdxSub = Number(this.dashboardInfo.key.split(".")[2]) - 1;
      }
      return (
        <div className="box-graph rounded-2xl white">
          {/* ===== カテゴリータイトルとコントロール ===== */}
          <div className="dashboard-title-area flex justify-between items-center pl-10 pr-8">
            {/* ===== カテゴリータイトル ===== */}
            <div className="dashboard-title py-8">
              <h2 className="dashboard-title__name text-h3">{(this.state.pageText[this.pageInfoKey].dashboardList[this.titleIdx].dashboard[this.dashboardIdxSub].name)}</h2>
            </div>
            {/* ===== /カテゴリータイトル ===== */}
            {/* ===== コントロール ===== */}
            <div className="action-area flex">
              {/* ブックマーク */}
              <div className="action-area__bookmark mr-4">
                {/* ブックマークボタンがON状態のとき
                  下記のbutton要素に is-active クラスを追加してください。
                  <button type="button" className="grid rounded-xl btn-bookmark">
                    ↓
                  <button type="button" className="grid rounded-xl btn-bookmark is-active">
                */}
                <button type="button" className={`grid rounded-xl btn-bookmark ${this.state.isDashboardLoaded && this.bmHaveFlg ? ' is-active' : ''}`} disabled={!this.state.isDashboardLoaded} onClick={() => this.openBmModal()}>
                  <span className="icon-bookmark-on self-center justify-self-center"></span>
                  <span className="icon-bookmark-off self-center justify-self-center"></span>
                </button>
              </div>
              {/* 用途タグ */}
              {/* 用途タグが設定されているとき、
                  action-area__tagに is-active クラスを追加してください。
                  <div className="action-area__tag mr-4 tag-container rounded-xl flex items-center">
                    ↓
                  <div className="action-area__tag mr-4 tag-container rounded-xl flex items-center is-active"></div> */}
              <div className={`action-area__tag mr-4 tag-container rounded-xl flex items-center ${this.state.isDashboardLoaded && this.ctgCheckCategoryNm.length != 0 ? ' is-active' : ''}`}>
                <div className="tag-container__icon grid mx-3"><span
                  className="icon icon-tag-outline justify-self-center"></span></div>
                <span className="tag-container__label text-h7 pl-3">用途タグ：</span>
                <div className="tag-container__text text-h7 pr-4"><span>{this.ctgCheckCategoryNm.length == 0 ? "未設定" : this.ctgCheckCategoryNm.join()}</span></div>
                <button type="button" className={`btn-tag text-h7 py-3 px-4 ${this.state.isDashboardLoaded ? ' text-link' : ''}`} disabled={!this.state.isDashboardLoaded} onClick={() => this.openCtModal(this.dashboardInfo.key)}>設定</button>
              </div>
              {/* 年度選択 */}
              <div className="action-area__year-select">
                <div className="year-container" tabIndex="0" ref={this.yearRef} onBlur={() => this.setIsOpenYear(false)}>
                  {/*
                    ■yearButtonについて
                    クリックしてドロップダウンリストを開いた状態は yearButton クラスに is-active クラスを追加してください。
                    <div className="yearButton is-active flex items-center justify-between rounded-xl pl-4 pr-2">

                    disabled状態は yearButton クラスに is-disabled クラスを追加してください。
                    <div className="yearButton is-disabled flex items-center justify-between rounded-xl pl-4 pr-2">
                  */}
                  <div id="yearButton" className={`yearButton flex items-center justify-between rounded-xl pl-4 pr-2 ${(this.dashboardList[this.titleIdx] != undefined) ? (!this.state.isDashboardLoaded || !this.dashboardList[this.titleIdx].dashboard[this.dashboardIdxSub].yearFilterFlg ? ' is-disabled' : '') : ' is-disabled'}`} onClick={() => this.setIsOpenYear(!this.state.isOpenYear)}>
                    <div className="yearButton__text text-h7 mr-2">{this.state.year + this.state.month}</div>
                    <div className="yearButton__icon flex items-center justify-center w-8 h-8"><span className="icon-arrow"></span></div>
                  </div>
                  {/*
                    通常は
                    <ul className="year" hidden>
                  */}
                  <ul className="year rounded-xl" hidden={!this.state.isOpenYear}>
                    {this.yearList.map((value, index) => {
                      return (
                        <li key={index} className="item text-h7" onClick={() => this.changeYear(value)}> {value} </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              {/* 年度/四半期選択 */}
              <div className="action-area__year-quarter ml-4">
                {/*
                  disabled状態は各input要素にdisabled属性を追加してください。
                  <input type="radio" name="yearQuarter" id="year" checked className="hidden" disabled/>
                */}
                <div className="radioArea rounded-xl flex gap-x-2" id="makeImg">
                  <input type="radio" name="yearQuarter" id="year" onClick={() => this.changeYearQuarter(true)} defaultChecked={this.state.isYear} className="hidden" disabled={!this.state.isDashboardLoaded || !this.dashboardList[this.titleIdx].dashboard[this.dashboardIdxSub].quarterFlg} />
                  <label htmlFor="year" className="text-h7 rounded">年度</label>
                  <input type="radio" name="yearQuarter" id="quarter" onClick={() => this.changeYearQuarter(false)} defaultChecked={!this.state.isYear} className="hidden" disabled={!this.state.isDashboardLoaded || !this.dashboardList[this.titleIdx].dashboard[this.dashboardIdxSub].quarterFlg} />
                  <label htmlFor="quarter" className="text-h7 rounded">四半期</label>
                </div>
              </div>
            </div>
            {/* ===== /コントロール ===== */}
          </div>
          {/* ===== /カテゴリータイトルとコントロール ===== */}

          {/* S2_人的資本KPI画面もしくは10_法廷開示レポート画面で表示する */}
          <div className="box-graph__middle w-full flex justify-center items-center">
            <ul className="nav-graph flex flex-wrap pt-6 pb-px">
              {/* 目標値の対象年度 */}
              {this.state.isKpiRelDashboard &&
                <li className="nav-graph__item px-4">
                  {/* 目標値の対象年度 */}
                  {/* 目標値の対象年度の表示・設定
                        目標未設定時は、box-graph__target-fiscal-yearクラスにhiddenクラスを追加してください
                    */}
                  <div className="box-graph__target-fiscal-year flex justify-center items-center">
                    <div className="text-h5">目標値の対象年度：</div>
                    {/* 年度選択 */}
                    {this.state.kpiTargetInfoList.length > 0 &&
                      <div className="action-area">
                        <div className="year-container" tabIndex="0" ref={this.yearRef} onBlur={() => this.setIsOpenKpiYear(false)}>
                          {/*
                              ■yearButtonについて
                              クリックしてドロップダウンリストを開いた状態は yearButton クラスに is-active クラスを追加してください。
                              <div className="yearButton is-active flex items-center justify-between rounded-xl pl-4 pr-2">
        
                              disabled状態は yearButton クラスに is-disabled クラスを追加してください。
                              <div className="yearButton is-disabled flex items-center justify-between rounded-xl pl-4 pr-2">
                            */}
                          <div id="kpiYearButton" className={`yearButton flex items-center justify-between rounded-xl pl-4 pr-2 ${!this.state.isDashboardLoaded ? ' is-disabled' : ''}`} style={{ paddingTop: "4px", paddingBottom: "4px" }} onClick={() => this.setIsOpenKpiYear(!this.state.isOpenKpiYear)}>
                            <div className="yearButton__text text-h7 mr-2">{this.state.kpiTargetInfo.year} {this.props.accPeriod}期</div>
                            <div className="yearButton__icon flex items-center justify-center w-8 h-8"><span className="icon-arrow"></span></div>
                          </div>
                          {/*
                              通常は
                              <ul className="year" hidden>
                            */}
                          <ul className="year rounded-xl" hidden={!this.state.isOpenKpiYear || !this.state.isDashboardLoaded}>
                            {this.state.kpiTargetInfoList.map((value, index) => {
                              return (
                                <li key={index} className="item text-h7" onClick={() => this.changeKpiYear(value)}> {value.year} {this.props.accPeriod}期 </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    }
                    {this.state.kpiTargetInfoList.length == 0 &&
                      <div className="text-h5 red--text py-2">目標値未設定</div>
                    }
                  </div>
                </li>
              }
              {/* /目標値の対象年度 */}
              {(() => {
                {/* ベンチマーク対象 */ }
                if (this.state.isBenchmarkRelDashboard) {
                  // 設定ファイルからベンチマーク対象プルダウンを表示するか判定
                  const isVisible = this.mode == analysisMode.standalone ? this.dashboardInfo?.benchmarkTarget.standalone : this.dashboardInfo?.benchmarkTarget.consolidate;
                  if (isVisible) {
                    return (
                      <li className={"nav-graph__item"}>
                        <div className="box-graph__target-fiscal-year flex justify-end items-center px-4">
                          <div className="form-control text-nowrap">
                            <div className="select-control flex justify-center items-center">
                              <div className="text-h5">ベンチマーク対象：</div>
                              <select className="form-select select-p-large rounded-lg text-h7 pr-10" style={{ width: "200px" }} value={this.state.benchmarkTarget.Key}
                                onChange={(e) => this.changeBenchmarkTarget(e.target.value)}
                                disabled={!this.state.isDashboardLoaded}>
                                {this.state.benchmarkTargetList.map((bt, idx) => {
                                  return <option key={idx} value={bt.Key}>{bt.Title}</option>
                                })}
                              </select>
                              <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                                <span className="icon-arrow rotate-90"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  };
                };
              })()}
            </ul>
          </div>
          {/* /ベンチマーク対象 */}

          {/* ===== グラフ ===== */}
          <div className="box-graph__body">
            <div id="embeddingContainer">
              <div className="py-8">
                <div className="dashboard-loading">
                  <img src={Loading} alt="Loading" />
                </div>
              </div>
            </div>
          </div>
          {/* ===== /グラフ ===== */}

          {/* 関連コンテンツ */}
          {this.state.isKpiRelDashboard &&
            <div className="box-graph rounded-2xl white -mt-px pt-12 pb-16">
              <h2 className="text-h3 text-center mb-6">関連ダッシュボード</h2>
              <ul className="nav-graph flex flex-wrap justify-center pb-0.5 pb-px mx-32">
                {this.state.relDashboardList.map((item, index) => {
                  let relDashboardInfo = this.getRelDashboardInfo(item);
                  return (
                    <li key={index} className={`nav-graph__item ${!relDashboardInfo.availability ? "is-disabled" : ""}`}>
                      <button type="button" className="nav-graph__btn text-h6 px-2 py-1.5 mx-4 my-1">
                        {(() => {
                          const label = <span className="nav-graph__text">{relDashboardInfo.name}</span>;
                          if (relDashboardInfo.availability) {
                            return (
                              <Link underline="none" href={relDashboardInfo.url} target="_blank">{label}</Link>
                            );
                          } else {
                            return label;
                          }
                        })()}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          }
          {/* /関連コンテンツ */}
        </div>
      );
    }
  }

  getBytesCount(str) {
    var cnt = 0;
    for (var i = 0; i < str.length; i++) {
      var c = str.charAt(i);
      if (/^[\u0000-\u00ff]$/.test(c)) {
        cnt++;
      } else {
        cnt += 2;
      }
    }
    return cnt;
  }

  getDispStateCtgBdgHtml = () => {
    let nowDispStateBm = this.state.dispStateBm;
    let nowDispStateCtg = this.state.dispStateCtg;
    let nowDispStateAndOr = this.state.dispStateAndOr;
    let nowDispStateBdg = this.state.dispStateBdg;
    let nowDispStateCtgSize = 0;
    let nowDispStateBdgSize = 0;
    let btyeCtgLength = this.getBytesCount(nowDispStateCtg);
    let btyeBdgLength = this.getBytesCount(nowDispStateBdg);
    nowDispStateCtgSize = getNowDispStateCtgSize(btyeCtgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg);
    nowDispStateBdgSize = getNowDispStateBdgSize(btyeBdgLength, nowDispStateBm, nowDispStateCtg, nowDispStateBdg);
    if (this.dashboardKeyList.length == 0) {
      return (
        <div className="dashboard-filter-box my-3 flex">
          <p className="text-pulldown white--text py-3 pr-2">マイダッシュボード登録なし</p>
          <p className=" text-pulldown white--text py-3 pr-2">（ 0 / 0 ）</p>
        </div>
      );
    } else if (nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark) {
      if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="red--text text-pulldown px-5 py-3">すべてを表示中</li>
              </ul>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm nobadge">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobm-nobadge px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobm px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
              <span className="text-link text-pulldown px-3 py-2 ">{(nowDispStateAndOr != undefined && nowDispStateAndOr != null && nowDispStateAndOr != '') ? nowDispStateAndOr : "かつ"}</span>
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      }
    } else {
      if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="red--text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
        && (nowDispStateBdg != null && nowDispStateBdg != "" && nowDispStateBdg != allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="red--text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm">
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else if ((nowDispStateCtg != null && nowDispStateCtg != "" && nowDispStateCtg != allConditions.allCategory)
        && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)) {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="red--text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex nobm nobadge">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage-nobadge px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      } else {
        return (
          <div className="dashboard-filter-box my-3 flex">
            <div className="dashboard-filter-box-inner">
              {/* // ↑ 申し送り
                ブックマーク指定がない（すべてを表示）場合はこの <div> 全体を消去 */}
              <ul className="dashboard-filter-box-inner-list bookmark">
                <li className="red--text text-pulldown px-5 py-3">{(nowDispStateBm != undefined && nowDispStateBm != null && nowDispStateBm != '') ? nowDispStateBm : allConditions.allBookmark}</li>
                {/* // ↑ 申し送り
                  初期表示の際はこの<li>のテキストを以下に変更
                  ダッシュボード一覧→ 「すべてのダッシュボード」 マイダッシュボード→ 「すべてのマイダッシュボード」  */}
              </ul>
            </div>
            <div className="dashboard-filter-box-inner tags px-2 py-1 flex">
              {/* // ↑ 申し送り
                初期表示の際、および用途タグもブックマークも指定がない場合はこの <div> 全体を消去
                用途タグの<li>を増やしていくとこのボックスが広がっていき、最大幅に達すると横スクロール表示となります */}
              <ul className="dashboard-filter-box-inner-list myds-usage px-4 pt-2 pb-1 flex" id="myCtgUi">
                {/* // ↑ 申し送り
                用途タグ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateCtg != undefined && nowDispStateCtg != null && nowDispStateCtg != '') ? nowDispStateCtg : allConditions.allCategory}</li>
              </ul>
              {/* 用途タグ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 -translate-x-1/2 `} style={{ left: (nowDispStateCtgSize) + 'px' }}>{nowDispStateCtg}</div>
              <span className="text-link text-pulldown px-3 py-2 ">{(nowDispStateAndOr != undefined && nowDispStateAndOr != null && nowDispStateAndOr != '') ? nowDispStateAndOr : "かつ"}</span>
              {/* // ↑ 申し送り
                指定が有効な（用途タグ指定、バッジ指定の両方がある）ときはこの <span> を表示 */}
              <ul className="dashboard-filter-box-inner-list badges px-4 pt-2 pb-1 flex">
                {/* // ↑ 申し送り
                バッジ指定がない（すべてを表示）場合はこの <ul> を消去 */}
                <li className="red--text text-pulldown pt-0 pb-1 myds-over">{(nowDispStateBdg != undefined && nowDispStateBdg != null && nowDispStateBdg != '') ? nowDispStateBdg : allConditions.allBadge}</li>
              </ul>
              {/* バッジ表示エリアにマウスオーバー時のフキダシ */}
              <div className={`dashboard-filter-selected-items z-40 translate-x-1/2 `} style={{ right: (nowDispStateBdgSize) + 'px' }}>{nowDispStateBdg}</div>
            </div>
            <p className=" text-pulldown white--text py-3 pr-2">（ {this.dashboardKeyDispList.length} / {this.dashboardKeyList.length} ）</p>
          </div>
        );
      }
    }
  }

  getDispStateButtonHtml = () => {
    let nowDispStateBm = this.state.dispStateBm;
    let nowDispStateCtg = this.state.dispStateCtg;
    let nowDispStateBdg = this.state.dispStateBdg;
    if ((nowDispStateBm == null || nowDispStateBm == "" || nowDispStateBm == allConditions.allBookmark)
      && (nowDispStateCtg == null || nowDispStateCtg == "" || nowDispStateCtg == allConditions.allCategory)
      && (nowDispStateBdg == null || nowDispStateBdg == "" || nowDispStateBdg == allConditions.allBadge)
      || this.isSaveCondition) {
      return (
        <div className="mydashboard-filter-box mybutton-box my-3">
          <div className="dashboard-filter-box-inner buttons mybuttons flex">
            <button type="button" className="filter-btn white--text text-h7 myds-disp-btn" onClick={() => { this.openSetModal() }}>表示条件を設定</button>
            <button type="button" className="text-h7 text-link py-3 pl-6 myds-disp-reset" onClick={() => { this.resetAll() }}>リセット</button>
            {/* 表示条件保存ボタン */}
            <div className="mydashboard-filter-box mysave-box my-3 -mr-2 pl-6 mybuttons">
              <button type="button" disabled className="btn-save-filter-conditions" title="初期表示の設定として保存"><span className="icon icon-save"></span></button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mydashboard-filter-box mybutton-box my-3">
          <div className="dashboard-filter-box-inner buttons mybuttons flex">
            <button type="button" className="filter-btn white--text text-h7 myds-disp-btn" onClick={() => { this.openSetModal() }}>表示条件を設定</button>
            <button type="button" className="text-h7 text-link py-3 pl-6 myds-disp-reset" onClick={() => { this.resetAll() }}>リセット</button>
            {/* 表示条件保存ボタン */}
            <div className="mydashboard-filter-box mysave-box my-3 -mr-2 pl-6 mybuttons">
              <button type="button" disabled={!this.state.saveConBtnFlg} className="btn-save-filter-conditions" title="初期表示の設定として保存" onClick={() => { this.saveSearchCondition() }}><span className="icon icon-save"></span></button>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    this.dashboardKeyDispList = this.dashboardKeySearchList;
    this.dashboardKeyDispList.sort((a, b) => this.dashboardKeySort(a, b));
    let dispStateCtgBdg = this.getDispStateCtgBdgHtml();
    let dispStateButton = this.getDispStateButtonHtml();
    let pageInfoHtml = this.getPageInfoHtml(this.dashboardAccount);
    let dashboardHtml = this.getDashboardHtml(this.dashboardAccount, this.dashboardKeyDispList);
    this.resetShowAllBtn(true);
    // タイトル設定
    document.title = titleName.myDashboard + titleName.common;
    return (
      <React.Fragment>
        <main className="page-container">
          {/* トースト表示枠 */}
          <div className="box-toast flex items-center pl-8 blue1 rounded-b-2xl fixed left-1/2 z-20">
            <div className="box-toast__text text-h6 white--text mr-1">{this.state.toastMessage}</div>
            <button type="button" className="box-toast__btn white--text p-5 leading-none"><span className="icon-close-s" onClick={() => this.removeToast()}></span></button>
          </div>
          <section className="page-content">
            <div className="centered-container">
              {/* コンテンツヘッダ */}
              <header className="category-page-header flex items-center my-6">
                <div className="category-page-header__img ml-4 mr-8"><img src={img_category_mydashboard} alt="" /></div>
                <div className="category-page-header__text pl-8 relative">
                  <div className="category-page-header__subtitle flex items-center red--text">
                    <span
                      className="category-page-header__subtitle-text text-h6-en category-page-header__subtitle-text--no-line">My
                      Dashboard</span>
                  </div>
                  <h1 className="category-page-header__title">
                    <span className="category-page-header__title-text text-h2">マイダッシュボード</span>
                  </h1>
                  <p className="category-page-header__description text-caption-2 grey--text text--lighten-70">
                    表示条件を設定することで、よく使うダッシュボードをひとまとめにセットできます。
                  </p>
                </div>
              </header>
              {/* コンテンツボディ */}
              <div className="page-body">
                {/* ===== 表示設定 ===== */}
                <div className={`dashboard-filter grey ${(this.dashboardKeyList.length == 0) ? ' rounded-lg ' : 'rounded-t-lg'} px-8 flex`}>
                  {/* // ↑ 申し送り
                  ダッシュボード一覧→ `rounded-lg` マイダッシュボード→ `rounded-t-lg` をclass 指定するとバーの形状が変わります */}
                  <h6 className="text-h6 white--text py-6 pr-2">表示対象：</h6>
                  {dispStateCtgBdg}
                  {dispStateButton}
                </div>
                {/* ===== 表示設定 ===== */}
                {/* ===== /カテゴリーナビゲーション ===== */}
                {/* ===== グラフナビゲーション ===== */}
                {/*
                  ■ナビゲーションのアクティブ表示について
                  選択しているカテゴリーのli要素に is-active クラスを入れてください
                */}
                {pageInfoHtml}
                {/* ===== /グラフナビゲーション ===== */}
                {dashboardHtml}
                {/* /box-graph */}
              </div>
            </div>
          </section>
        </main>
        {/* ========== モーダルウィンドウ ========== */}
        <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50" onClick={() => this.closeBmModal()}></div>
        {/* ===== ブックマーク入力 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-entry">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on grey--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマーク</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            {/* エラー時表示 */}
            {this.state.bmError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div className="form-control">
              <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeBmMemo(e)} value={this.state.bmMemo}></textarea>
            </div>
            <div className="modal-window__btns text-center mt-8">
              <button className="btn btn-modal-bookmark white--text grey rounded-lg px-16 text-h5 font-medium" onClick={() => this.registBm()}>ブックマークする</button>
            </div>
          </div>
        </div>
        {/* ===== /ブックマーク入力 ===== */}
        {/* ===== ブックマーク中 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-on">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマーク中</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            {/* エラー時表示 */}
            {this.state.bmError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div className="form-control">
              <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" className="modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeBmMemo(e)} value={this.state.bmMemo}></textarea>
            </div>
            <div className="modal-window__btns text-center mt-8">
              <div className="flex gap-4">
                <button className="btn btn-outline btn-modal-delete-bookmark rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteConfBm()}>ブックマークを削除</button>
                <button className="btn btn-modal-save white--text grey rounded-lg px-16 text-h5 font-medium" onClick={() => { this.updateBm() }}>保存</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /ブックマーク中 ===== */}
        {/* ===== ブックマークの削除確認 ===== */}
        <div className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--bookmark-delete-confirm">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center py-10">
              <span className="modal-window__title-icon icon-bookmark-on red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">ブックマークの削除確認</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeBmModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 pb-10">
            <div className="text-body text-center mb-14">
              ブックマーク及び入力したメモを<br />
              削除してもよろしいでしょうか？
            </div>
            <div className="modal-window__btns text-center mt-8">
              <div className="flex gap-4">
                <button className="btn btn-outline btn-modal-back rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteBackBm()}>戻る</button>
                <button className="btn btn-modal-delete white--text red rounded-lg px-16 text-h5 font-medium" onClick={() => this.deleteBm()}>削除</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /ブックマークの削除確認 ===== */}
        {/* ===== 用途タグ中 ===== */}
        <div className="modal-window modal-window__wide fixed bg sub z-50 rounded-2xl" id="modal-window--tag-list">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center pt-10">
              <span className="modal-window__title-icon icon-tag-fill red--text mr-2"></span>
              <span className="modal-window__title-text text-h3 font-medium">用途タグ一覧</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeCtModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 py-10">
            {/* エラー時表示 */}
            {this.state.ctError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            {/* /エラー時表示 */}
            <div id="form--tag-list">
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">※設定したタグや入力したメモは全員に表示されます。</div>
              <div className="form-control">
                <ul>
                  {/* チェックボックスがONのとき、そのリストアイテム（li）に対して is-activeクラスを追加してください。
                  <li className="px-6 py-3 is-active">
                  ※ そのリストアイテムの中の *__inactive クラスのついている要素が非表示になります
                  ※ そのリストアイテムの中の *__active クラスのついている要素が表示になります */}
                  {this.catList.map((item, index) => {
                    return (
                      <li className="px-6 py-3 is-active" key={index} id={`chLi${item["CategoryKey"]}`} name="chLi">
                        <dl className="flex items-center">
                          <dt className="mr-6">
                            <label className="ui-checkbox">
                              <input type="checkbox" id={`checkbox${item["CategoryKey"]}`} className="chBox ui-checkbox__input" value={item["CategoryKey"]} onClick={() => this.checkOne()}></input>
                              <span className="ui-checkbox__icon"></span>
                              <span className="ui-checkbox__ripple"></span>
                              <span className="ui-checkbox__label text-h7 font-Regular">{item["CategoryName"]}</span>
                            </label>
                          </dt>
                          <dd>
                            <span className="__inactive text-caption-2 grey--text text--lighten-35 px-4 py-2">未設定</span>
                            <textarea maxLength="200" placeholder="メモを入力（任意、最大120文字）" id={`chTextarea${item["CategoryKey"]}`} name="chTextarea" className="__active modal-window__content-textarea rounded-lg px-4 py-3 w-full" onChange={(e) => this.handleChangeCtgMemo(item["CategoryKey"], e)}></textarea>
                          </dd>
                        </dl>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="text-center mt-8 div-mid">
                <button className="btn btn-outline modal-window__btn-close rounded-lg px-16 mr-8 text-h5 font-medium btn-margin" onClick={() => this.closeCtModal()}>戻る</button>
                <button id="saveCtgBtn" className="btn btn-modal-tag-apply white--text grey rounded-lg px-16 text-h5 font-medium btn-margin" onClick={() => this.saveCategory()}>設定</button>
                <label id="saveCtgLabel" className="btn white--text grey-disabled rounded-lg px-16 text-h5 btn-margin display-none">設定</label>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /用途タグ中 ===== */}
        {/* ===== 表示条件詳細 ===== */}
        <div className="modal-window modal-window__wide modal-window--dashboard-filter fixed bg sub z-50 rounded-2xl animate__fadeIn" id="modal-window--dashboard-filter">
          <div className="modal-window__header">
            <div className="modal-window__title flex items-center justify-center pt-10">
              <span className="modal-window__title-text text-h3 font-medium">表示条件詳細</span>
            </div>
            <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => this.closeSetModal()}><span className="icon-close"></span></button>
          </div>
          <div className="modal-window__content px-8 py-10">
            {/* エラー時表示 */}
            {this.state.scError &&
              <div className="text-body text-caption-2 text-center red--text mb-10 -mt-6">
                エラーが発生しました。<br />
                時間をおいて再度お試しください。
              </div>
            }
            <div id="form--dashboard-filter">
              <div className="box-xy-bordered flex rounded-xl py-8">
                <dl className="flex px-8 items-center">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-bookmark-off red--text mr-4"></span>ブックマーク</div>
                  </dt>
                  <dd>
                    <ul className="flex gap-x-8">
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmAll" className="radioBmAll ui-radio__input" name="bookmark" value="0" onClick={() => this.radioOne("bmAll")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmHave" className="radioBm ui-radio__input" name="bookmark" value="1" onClick={() => this.radioOne("have")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">ブックマークあり（{this.bmList.length}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-radio">
                          <input type="radio" id="radioBmNohave" className="radioBm ui-radio__input" name="bookmark" value="2" onClick={() => this.radioOne("nohave")}></input>
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                          <span className="ui-radio__label text-h7 font-Regular">ブックマークなし（{(this.dashboardKeyList.length - this.bmList.length)}）</span>
                        </label>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="box-xy-bordered rounded-xl mt-4 py-8">
                <dl className="flex px-8">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-tag-outline red--text mr-4"></span>用途タグ</div>
                  </dt>
                  <dd>
                    {/* 一部が選択されている状態を表現するには
                      input[type="checkbox"] が indeterminate（未確定）状態になるか、 classに 'indeterminate' を追加します
                     */}
                    <ul className="flex flex-wrap">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxCtgAll" name="tag_all" value="0" className="chBoxCtgAll ui-checkbox__input" onClick={() => this.checkAllCtg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap ml-10">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxCtgNull" name="tag[]" value="99" className="chBoxCtg ui-checkbox__input" onClick={() => this.checkOneCtg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">用途タグなし（{(this.dashboardKeyList.length - this.ctAccount) < 0 ? 0 : (this.dashboardKeyList.length - this.ctAccount)}）</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap gap-x-8 gap-y-6 ml-10">
                      {this.catList.map((item, index) => {
                        return (
                          <li key={index}>
                            <label className="ui-checkbox">
                              <input type="checkbox" id={`chBoxCtg${item["CategoryKey"]}`} name="chBoxCtg" value={item["CategoryKey"]} className="chBoxCtg ui-checkbox__input" onClick={() => this.checkOneCtg()}></input>
                              <span className="ui-checkbox__icon"></span>
                              <span className="ui-checkbox__ripple"></span>
                              <span className="ui-checkbox__label text-h7 font-Regular">{item["CategoryName"]}（{this.catAccount[index] == null ? 0 : this.catAccount[index]}）</span>
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </dl>
                <div className="divider my-8">
                  <div className="btn-group-container flex justify-center">
                    <input type="radio" name="and_or" id="searchAnd" className="ui-radio__input" value="and" onClick={() => this.searchForAndOr(true)} defaultChecked={this.isAndOr}></input>
                    <label htmlFor="searchAnd" className="btn text-h7 px-9">
                      <span className="ui-radio__label">かつ（ AND ）</span>
                    </label>
                    <input type="radio" name="and_or" id="searchOr" className="ui-radio__input" value="or" onClick={() => this.searchForAndOr(false)} defaultChecked={!this.isAndOr}></input>
                    <label htmlFor="searchOr" className="btn text-h7 px-9">
                      <span className="ui-radio__label">または（ OR ）</span>
                    </label>
                  </div>
                </div>
                <dl className="flex px-8">
                  <dt className="shrink-0 w-48 text-h5">
                    <div className="flex items-center"><span className="icon-badge-outline red--text mr-4"></span>バッジ</div>
                  </dt>
                  <dd>
                    <ul className="flex flex-wrap">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxBdgAll" name="badge_all" className="chBoxBdgAll ui-checkbox__input" value="0" onClick={() => this.checkAllBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          <span className="ui-checkbox__label text-h7 font-Regular">すべてを表示</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap ml-10">
                      <li>
                        <label className="ui-checkbox mb-6">
                          <input type="checkbox" id="chBoxBdgNull" name="badge[]" className="chBoxBdg ui-checkbox__input" value="99" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          {/* バッジなし（78） */}
                          <span className="ui-checkbox__label text-h7 font-Regular">バッジなし（{this.noBadgeAccount}）</span>
                        </label>
                      </li>
                    </ul>
                    <ul className="flex flex-wrap gap-x-8 gap-y-6 ml-10">
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgCAS" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="CAS" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          {/* CAS（33） */}
                          <span className="ui-checkbox__label text-h7 font-Regular">CAS（{this.casAccount}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgISO" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="ISO" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          {/* ISO30414（33） */}
                          <span className="ui-checkbox__label text-h7 font-Regular">ISO30414（{this.isoAccount}）</span>
                        </label>
                      </li>
                      <li>
                        <label className="ui-checkbox">
                          <input type="checkbox" id="chBoxBdgGRI" name="chBoxBdg" className="chBoxBdg ui-checkbox__input" value="GRI" onClick={() => this.checkOneBdg()}></input>
                          <span className="ui-checkbox__icon"></span>
                          <span className="ui-checkbox__ripple"></span>
                          {/* GRI（33） */}
                          <span className="ui-checkbox__label text-h7 font-Regular">GRI（{this.griAccount}）</span>
                        </label>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="modal-window__btns text-center mt-8">
                <button id="saveDashboardBtn" className="btn btn-modal-dashboard-filter-apply white--text grey rounded-lg px-16 text-h5 font-medium btn-mid" onClick={() => { this.saveDashboard() }}>この条件で表示</button>
                <button id="saveDashboardLabel" className="btn white--text grey-disabled rounded-lg px-16 text-h5 btn-mid display-none">この条件で表示</button>
              </div>
            </div>
          </div>
        </div>
        {/* ===== /表示条件詳細 ===== */}
        {/* ========== /モーダルウィンドウ ========== */}
      </React.Fragment>
    );
  }
}

const MyDashboard = () => {
  const state = useSelector((state) => state);
  const [mode, setMode] = React.useState(null);

  // useEffect、useCallbackフックで初回ロード時のみ実行させる
  const getCallback = React.useCallback((mode) => {
    setMode(mode);
  }, []);

  React.useEffect(() => {
    getCallback(state.analysis.mode);
  }, []);

  // 連結オプションが有効の場合はuseEffectフックを実行させるためreturnする
  if (state.company.isParent && !mode) {
    return
  }
  if ((!state.company.isParent || mode == analysisMode.standalone) && state.authority.Dashboard_AuthInfo.Availability) {
    return (
      <MyDashboardContent
        pageId={viewId.myDashboard}
        userId={state.user.sub}
        corporationNumber={state.company.corporationNumber}
        accPeriod={state.company.accPeriod}
        isParent={state.company.isParent}
        mode={state.analysis.mode}
        condition={state.analysis.condition}
        targets={state.analysis.targets}
        bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
        authority={state.authority}
        dashboardAuthConf={state.authority.Dashboard_AuthInfo}
        categoryTagAuthConf={state.authority.CategoryTag_AuthInfo}
      />
    );
  } else {
    return (
      <Forbidden />
    );
  }
};

export default MyDashboard;