import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { Roll } from "../_constants/Code";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import AuthorityGroupList from "../pages/auth/AuthorityGroupList";
import AuthorityGroupInfo from "../pages/auth/AuthorityGroupInfo";
import Home from "../pages/Home";
import HCBasicInfo from "../pages/report/HCBasicInfo";
import HCConfiguration from "../pages/report/HCConfiguration";
import HRManagement from "../pages/report/HRManagement";
import HiringAndOnboarding from "../pages/report/HiringAndOnboarding";
import DevelopAndRetention from "../pages/report/DevelopAndRetention";
import MonthMonitoring from "../pages/report/MonthMonitoring";
import ISO30414 from "../pages/report/ISO30414";
import DEI from "../pages/report/DEI";
import HealthManage from "../pages/report/HealthManage";
import LegalDisclosure from "../pages/report/LegalDisclosure";
import KGI from "../pages/indicator/KGI";
import KPI from "../pages/indicator/KPI";
// import FileUpload from "../pages/data/FileUpload";
import TaskList from "../pages/data/TaskList";
import AnalysisData from "../pages/data/AnalysisData";
import AccountingData from "../pages/data/AccountingData";
import MyDashboard from "../pages/dashboard/MyDashboard";
import DashboardList from "../pages/dashboard/DashboardList";
import News from "../pages/info/News";
import Notice from "../pages/info/Notice";
import KPISetting from "../pages/indicator/KPISetting";
import KGIManagement from "../pages/indicator/KGIManagement";
import BusinessPlanNew from "../pages/indicator/BusinessPlanNew";
import BusinessPlanEdit from "../pages/indicator/BusinessPlanEdit";
import ChangePassword from "../pages/user/ChangePassword";
import UserList from "../pages/user/UserList";
import UserPermission from "../pages/user/UserPermission";
import BenchmarkTargetList from "../pages/benchmark/BenchmarkTargetList";
import BenchmarkTargetInfo from "../pages/benchmark/BenchmarkTargetInfo";
import MasterList from "../pages/master/MasterList";
import SegmentInfo from "../pages/master/segment/SegmentInfo";
import NewGraduateInfo from "../pages/master/newGraduate/NewGraduateInfo";
import RecruitmentPlan from "../pages/master/recruitmentPlan/RecruitmentPlanInfo";
import AbsenceRateInfo from "../pages/master/absenceRate/AbsenceRateInfo";
import HierarchyInfo from "../pages/master/org/HierarchyInfo";
import AssociationInfo from "../pages/master/org/AssociationInfo";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import Tos from "../pages/legal/TermsOfUse";
import StaticPage from "../pages/common/StaticPage";
import NotFound from "../_components/NotFound";
import { LoginContextProvider } from "../pages/auth/LoginContextProvider";
import { PrivateRoute, StaticRoute } from "./PrivateRoute";

const Router = () => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <LoginContextProvider>
          <Routes>
            <Route
              exact
              path="/"
              element={<PrivateRoute Component={Home} />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route
              exact
              path="/report/1"
              element={<PrivateRoute Component={HCBasicInfo} />}
            />
            <Route
              exact
              path="/report/1/:listNum"
              element={<PrivateRoute Component={HCBasicInfo} />}
            />
            <Route
              exact
              path="/report/1/:listNum/:listNumSub"
              element={<PrivateRoute Component={HCBasicInfo} />}
            />
            <Route
              exact
              path="/report/2"
              element={<PrivateRoute Component={HCConfiguration} />}
            />
            <Route
              exact
              path="/report/2/:listNum"
              element={<PrivateRoute Component={HCConfiguration} />}
            />
            <Route
              exact
              path="/report/2/:listNum/:listNumSub"
              element={<PrivateRoute Component={HCConfiguration} />}
            />
            <Route
              exact
              path="/report/3"
              element={<PrivateRoute Component={HRManagement} />}
            />
            <Route
              exact
              path="/report/3/:listNum"
              element={<PrivateRoute Component={HRManagement} />}
            />
            <Route
              exact
              path="/report/3/:listNum/:listNumSub"
              element={<PrivateRoute Component={HRManagement} />}
            />
            <Route
              exact
              path="/report/4"
              element={<PrivateRoute Component={HiringAndOnboarding} />}
            />
            <Route
              exact
              path="/report/4/:listNum"
              element={<PrivateRoute Component={HiringAndOnboarding} />}
            />
            <Route
              exact
              path="/report/4/:listNum/:listNumSub"
              element={<PrivateRoute Component={HiringAndOnboarding} />}
            />
            <Route
              exact
              path="/report/5"
              element={<PrivateRoute Component={DevelopAndRetention} />}
            />
            <Route
              exact
              path="/report/5/:listNum"
              element={<PrivateRoute Component={DevelopAndRetention} />}
            />
            <Route
              exact
              path="/report/5/:listNum/:listNumSub"
              element={<PrivateRoute Component={DevelopAndRetention} />}
            />
            <Route
              exact
              path="/report/6"
              element={<PrivateRoute Component={MonthMonitoring} />}
            />
            <Route
              exact
              path="/report/6/:listNum"
              element={<PrivateRoute Component={MonthMonitoring} />}
            />
            <Route
              exact
              path="/report/6/:listNum/:listNumSub"
              element={<PrivateRoute Component={MonthMonitoring} />}
            />
            <Route
              exact
              path="/report/7"
              element={<PrivateRoute Component={ISO30414} />}
            />
            <Route
              exact
              path="/report/7/:listNum"
              element={<PrivateRoute Component={ISO30414} />}
            />
            <Route
              exact
              path="/report/7/:listNum/:listNumSub"
              element={<PrivateRoute Component={ISO30414} />}
            />
            <Route
              exact
              path="/report/8"
              element={<PrivateRoute Component={DEI} />}
            />
            <Route
              exact
              path="/report/8/:listNum"
              element={<PrivateRoute Component={DEI} />}
            />
            <Route
              exact
              path="/report/8/:listNum/:listNumSub"
              element={<PrivateRoute Component={DEI} />}
            />
            <Route
              exact
              path="/report/9"
              element={<PrivateRoute Component={HealthManage} />}
            />
            <Route
              exact
              path="/report/9/:listNum"
              element={<PrivateRoute Component={HealthManage} />}
            />
            <Route
              exact
              path="/report/9/:listNum/:listNumSub"
              element={<PrivateRoute Component={HealthManage} />}
            />
            <Route
              exact
              path="/report/10"
              element={<PrivateRoute Component={LegalDisclosure} />}
            />
            <Route
              exact
              path="/report/10/:listNum"
              element={<PrivateRoute Component={LegalDisclosure} />}
            />
            <Route
              exact
              path="/report/10/:listNum/:listNumSub"
              element={<PrivateRoute Component={LegalDisclosure} />}
            />
            <Route
              exact
              path="/indicator/S1"
              element={<PrivateRoute Component={KGI} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/indicator/S2"
              element={<PrivateRoute Component={KPI} />}
            />
            <Route
              exact
              path="/indicator/S2/:listNum"
              element={<PrivateRoute Component={KPI} />}
            />
            <Route
              exact
              path="/indicator/S2/:listNum/:listNumSub"
              element={<PrivateRoute Component={KPI} />}
            />
            <Route
              exact
              path="/data/taskList"
              element={<PrivateRoute Component={TaskList} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/data/analysisData/:dataCreateTaskKey"
              element={<PrivateRoute Component={AnalysisData} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/data/analysisData/:dataCreateTaskKey/accountingData/:key"
              element={<PrivateRoute Component={AccountingData} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/dashboard/myDashboard"
              element={<PrivateRoute Component={MyDashboard} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/dashboard/dashboardList"
              element={<PrivateRoute Component={DashboardList} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/info/news"
              element={<PrivateRoute Component={News} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/info/notice"
              element={<PrivateRoute Component={Notice} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/indicator/kpiSetting"
              element={<PrivateRoute Component={KPISetting} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/indicator/kgi/businessPlan"
              element={<PrivateRoute Component={KGIManagement} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/indicator/kgi/businessPlan/new"
              element={<PrivateRoute Component={BusinessPlanNew} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/indicator/kgi/businessPlan/edit/:key"
              element={<PrivateRoute Component={BusinessPlanEdit} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/auth/authorityGroupList"
              element={<PrivateRoute Component={AuthorityGroupList} roles={[Roll.administrator]} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/auth/authorityGroupInfo/:mode"
              element={<PrivateRoute Component={AuthorityGroupInfo} roles={[Roll.administrator]} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/auth/authorityGroupInfo/:mode/:key"
              element={<PrivateRoute Component={AuthorityGroupInfo} roles={[Roll.administrator]} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/user/changePassword"
              element={<PrivateRoute Component={ChangePassword} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/user/userList"
              element={<PrivateRoute Component={UserList} roles={[Roll.administrator]} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/user/userPermission/:sub"
              element={<PrivateRoute Component={UserPermission} roles={[Roll.administrator]} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/benchmark/benchmarkTargetList"
              element={<PrivateRoute Component={BenchmarkTargetList} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/benchmark/benchmarkTargetInfo/:mode"
              element={<PrivateRoute Component={BenchmarkTargetInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/benchmark/benchmarkTargetInfo/:mode/:key"
              element={<PrivateRoute Component={BenchmarkTargetInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/masterList"
              element={<PrivateRoute Component={MasterList} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/group/segmentInfo"
              element={<PrivateRoute Component={SegmentInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/dashboard/NewGraduateInfo"
              element={<PrivateRoute Component={NewGraduateInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/dashboard/RecruitmentPlanInfo"
              element={<PrivateRoute Component={RecruitmentPlan} hideSubHeader={true} />}
              />
            <Route
              exact
              path="/master/dashboard/AbsenceRateInfo"
              element={<PrivateRoute Component={AbsenceRateInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/org/hierarchyInfo"
              element={<PrivateRoute Component={HierarchyInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/master/org/associationInfo"
              element={<PrivateRoute Component={AssociationInfo} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/legal/privacyPolicy"
              element={<PrivateRoute Component={PrivacyPolicy} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/legal/tos"
              element={<PrivateRoute Component={Tos} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/staticPage/:pageId"
              element={<StaticRoute Component={StaticPage} hideSubHeader={true} />}
            />
            <Route
              exact
              path="/*"
              element={<PrivateRoute Component={NotFound} />}
            />
          </Routes>
        </LoginContextProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
};
export default Router;
