"use strict";
import { getDynamoDB, getGroupCompany } from "../../common/utils/DynamoDbUtils";
import { errorLog } from "../../common/logger/Logger";
import { table } from "../../_constants/Code";

const _getAuthInfos = async (corporationNumber, user) => {
  // 機能利用権限を取得
  const authInfos = [];
  if (!user) {
    return authInfos;
  }
  for (let i = 0; i < user.AuthGroupList.length; i++) {
    const authGroupKey = user.AuthGroupList[i];
    const authorityGroup = await getDynamoDB(
      table.AuthorityGroup,
      null,
      corporationNumber,
      { Key: authGroupKey }
    );
    if (!authorityGroup.length) {
      continue;
    }
    authInfos.push(authorityGroup[0].AuthInfo);
  }
  return authInfos;
}

const _getUser = async (corporationNumber, userId) => {
  // ユーザーマスタを取得
  const user = await getDynamoDB(
    table.User,
    null,
    corporationNumber,
    { UserId: userId }
  );
  return user[0]
}

const _haveReleaseKgi = async (mode, corporationNumber) => {
  // 経営計画
  const dynamoDBData = await getDynamoDB(
    table.BusinessPlan, null, corporationNumber, null,
    { Mode: mode, Status: 'release', DeleteFlg: 0 }
  )
  return dynamoDBData.length > 0
}

/**
 * 権限のある会社とセグメントの情報を取得する
 */
export const getRefAuthInfo = async (user, groupCompanyMaster) => {
  // 権限情報取得
  let authorityData = {}
  if (user["RefAuthGroupKey"]) {
    const data = await getDynamoDB(table.AuthorityGroup, null, user.CorporationNumber,
      { Key: parseInt(user["RefAuthGroupKey"]) }
    );
    authorityData = data[0];
  }
  const info = {
    authority: "all",
    authCompany: [],
    authSegment: [],
  }
  if (authorityData["RefAuthInfo"]) {
    const authInfo = authorityData["RefAuthInfo"].Company_AuthInfo;
    info.authority = authInfo.RefAuthKind;
    // 権限：すべて
    if (info.authority === "all") {
      groupCompanyMaster.forEach(company => {
        info.authCompany.push(company.CorporationNumber);
        company.Segment.forEach(segment => {
          info.authSegment.push(segment.Code)
        });
      });
    }
    // 権限：会社
    else if (info.authority === "company") {
      groupCompanyMaster.forEach(company => {
        if (authInfo.Company_List.some(e => e === company.CorporationNumber)) {
          info.authCompany.push(company.CorporationNumber);
          company.Segment.forEach(segment => {
            info.authSegment.push(segment.Code)
          });
        }
      });
    }
    // 権限：セグメント
    else if (info.authority === "segment") {
      groupCompanyMaster.forEach(company => {
        // セグメントを検索して、権限があれば設定
        const segment = company.Segment.filter(e => {
          return authInfo.Segment_List.includes(e.Code)
        });
        segment.forEach(seg => {
          info.authCompany.push(company.CorporationNumber);
          info.authSegment.push(seg.Code);
        });
      });
    }
    // 重複削除
    info.authCompany = Array.from(new Set(info.authCompany));
    info.authSegment = Array.from(new Set(info.authSegment));
  }
  return info
}

/**
* ユーザーが保持している権限情報を取得する
* @param {String} mode 分析モード
* @param {String} corporationNumber 法人番号
* @param {String} userId ユーザーID
* @returns 権限情報
*/
export const getAuthority = async (mode, corporationNumber, userId) => {
  // ユーザーを取得
  const user = await _getUser(corporationNumber, userId);
  // 機能利用権限
  const authInfos = await _getAuthInfos(corporationNumber, user);
  // 整形する
  try {
    // 機能利用権限を設定
    const authority = authInfos.reduce((accumulator, currentValue) => {
      // ダッシュボード参照権限
      const dashboard = currentValue.Dashboard_AuthInfo;
      if (dashboard?.Availability) {
        accumulator.Dashboard_AuthInfo.Availability = true;
        dashboard.Auth_Conf.forEach((conf) => {
          if (!conf.Availability) {
            return;
          }
          const a_conf = accumulator.Dashboard_AuthInfo.Auth_Conf.find(node => node.Key === conf.Key);
          if (a_conf) {
            // 中分類
            conf.DashboardList.forEach((list) => {
              if (!list.Availability) {
                return;
              }
              const a_list = a_conf.DashboardList.find(node => node.Key === list.Key);
              if (a_list) {
                a_list.Dashboard = Array.from(
                  new Set([...a_list?.Dashboard, ...list?.Dashboard])
                );
              } else {
                a_conf.DashboardList.push(list);
              }
            });
          } else {
            const dashboardList = [];
            // 中分類
            conf.DashboardList.forEach((list) => {
              if (!list.Availability) {
                return;
              }
              dashboardList.push({
                Key: list.Key,
                Availability: true,
                Dashboard: list.Dashboard
              });
            });
            accumulator.Dashboard_AuthInfo.Auth_Conf.push({
              Key: conf.Key,
              Availability: true,
              DashboardList: dashboardList
            });
          }
        });
      }
      // KGI機能利用権限
      const kgi = currentValue.Kgi_AuthInfo;
      if (kgi?.Availability) {
        accumulator.Kgi_AuthInfo.Availability = true;
        if (kgi.Auth_Conf.View) {
          accumulator.Kgi_AuthInfo.Auth_Conf.View = true;
        }
        if (kgi.Auth_Conf.Regist) {
          accumulator.Kgi_AuthInfo.Auth_Conf.Regist = true;
        }
      }
      // KPI機能利用権限
      const Kpi = currentValue.Kpi_AuthInfo;
      if (Kpi?.Availability) {
        accumulator.Kpi_AuthInfo.Availability = true;
        if (Kpi.Auth_Conf.View) {
          accumulator.Kpi_AuthInfo.Auth_Conf.View = true;
        }
        if (Kpi.Auth_Conf.Regist) {
          accumulator.Kpi_AuthInfo.Auth_Conf.Regist = true;
        }
      }
      // データアップロード機能利用権限
      const upload = currentValue.Upload_AuthInfo;
      if (upload?.Availability) {
        accumulator.Upload_AuthInfo.Availability = true;
        if (upload.Auth_Conf.View) {
          accumulator.Upload_AuthInfo.Auth_Conf.View = true;
        }
        if (upload.Auth_Conf.Regist) {
          accumulator.Upload_AuthInfo.Auth_Conf.Regist = true;
        }
        if (upload.Auth_Conf.Data?.Availability) {
          accumulator.Upload_AuthInfo.Auth_Conf.Data.Availability = true;
          if (upload.Auth_Conf.Data.Create) {
            accumulator.Upload_AuthInfo.Auth_Conf.Data.Create = true;
          }
          if (upload.Auth_Conf.Data.Lock) {
            accumulator.Upload_AuthInfo.Auth_Conf.Data.Lock = true;
          }
          if (upload.Auth_Conf.Data.Unlock) {
            accumulator.Upload_AuthInfo.Auth_Conf.Data.Unlock = true;
          }
        }
        // アップロード可能ファイルリスト
        if (upload.Auth_Conf.Uploadable_Monthly?.Availability) {
          const a_monthly = accumulator.Upload_AuthInfo.Auth_Conf.Uploadable_Monthly;
          a_monthly.Availability = true;
          a_monthly.Files = Array.from(
            new Set([...a_monthly.Files, ...upload.Auth_Conf.Uploadable_Monthly.Files])
          );
        }
        if (upload.Auth_Conf.Uploadable_Quarter?.Availability) {
          const a_quarter = accumulator.Upload_AuthInfo.Auth_Conf.Uploadable_Quarter;
          a_quarter.Availability = true;
          a_quarter.Files = Array.from(
            new Set([...a_quarter.Files, ...upload.Auth_Conf.Uploadable_Quarter.Files])
          );
        }
        if (upload.Auth_Conf.Uploadable_Year?.Availability) {
          const a_year = accumulator.Upload_AuthInfo.Auth_Conf.Uploadable_Year;
          a_year.Availability = true;
          a_year.Files = Array.from(
            new Set([...a_year.Files, ...upload.Auth_Conf.Uploadable_Year.Files])
          );
        }
        if (upload.Auth_Conf.Uploadable_Accounting?.Availability) {
          const a_accounting = accumulator.Upload_AuthInfo.Auth_Conf.Uploadable_Accounting;
          a_accounting.Availability = true;
          if (upload.Auth_Conf.Uploadable_Accounting.Standalone) {
            a_accounting.Standalone = true;
          }
          if (upload.Auth_Conf.Uploadable_Accounting.Consolidated) {
            a_accounting.Consolidated = true;
          }
        }
      }
      // 用途タグ登録権限
      const categoryTag = currentValue.CategoryTag_AuthInfo;
      if (categoryTag?.Availability) {
        accumulator.CategoryTag_AuthInfo.Availability = true;
        if (categoryTag.Auth_Conf.CategoryTag_Set) {
          accumulator.CategoryTag_AuthInfo.Auth_Conf.CategoryTag_Set = true;
        }
      }
      // マスタ管理権限
      const master = currentValue.Master_AuthInfo;
      if (master?.Availability) {
        accumulator.Master_AuthInfo.Availability = true;
        if (master.Auth_Conf?.Group?.SegmentInfo_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Group.SegmentInfo_Set = true;
        }
        if (master.Auth_Conf?.Department?.DepartmentHierarchy_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Department.DepartmentHierarchy_Set = true;
        }
        if (master.Auth_Conf?.Department?.AnalysisHQAssociations_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Department.AnalysisHQAssociations_Set = true;
        }
        if (master.Auth_Conf?.Dashboard?.NewGraduate_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Dashboard.NewGraduate_Set = true;
        }
        if (master.Auth_Conf?.Dashboard?.RecruitmentPlan_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Dashboard.RecruitmentPlan_Set = true;
        }
        if (master.Auth_Conf?.Dashboard?.AbsenceRate_Set) {
          accumulator.Master_AuthInfo.Auth_Conf.Dashboard.AbsenceRate_Set = true;
        }
      }
      return accumulator;
    }, {
      Company_AuthInfo: {
        Availability: false
      },
      Dashboard_AuthInfo: {
        Availability: false,
        Auth_Conf: []
      },
      Kgi_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false
        }
      },
      Kpi_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false
        }
      },
      Upload_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          View: false,
          Regist: false,
          Data: {
            Availability: false,
            Create: false,
            Lock: false,
            Unlock: false,
          },
          Uploadable_Monthly: {
            Availability: false,
            Files: [],
          },
          Uploadable_Quarter: {
            Availability: false,
            Files: [],
          },
          Uploadable_Year: {
            Availability: false,
            Files: [],
          },
          Uploadable_Accounting: {
            Availability: false,
            Standalone: false,
            Consolidated: false,
          },
        },
      },
      Download_AuthInfo: {},
      CategoryTag_AuthInfo: {
        Availability: false,
        Auth_Conf: {}
      },
      Master_AuthInfo: {
        Availability: false,
        Auth_Conf: {
          Group: {
            SegmentInfo_Set: false
          },
          Department: {
            DepartmentHierarchy_Set: false,
            AnalysisHQAssociations_Set: false
          },
          Dashboard: {
            NewGraduate_Set: false,
            RecruitmentPlan_Set: false,
            AbsenceRate_Set: false
          },
        },
      },
    });
    // グループ会社を取得
    const groupCompanyMaster = await getGroupCompany(corporationNumber);
    // データ参照権限を追加
    authority.referenceAuth = await getRefAuthInfo(user, groupCompanyMaster);
    if(authority.referenceAuth.authCompany.length || authority.referenceAuth.authSegment.length) {
      authority.Company_AuthInfo.Availability = true;
    }
    // KGI判定を追加
    authority.haveReleaseKgi = await _haveReleaseKgi(mode, corporationNumber);
    return authority;
  } catch (error) {
    console.log(error, authInfos);
    errorLog("E000001", userId, "", "getAuthority", error);
    throw error;
  }
}