"use strict";
import * as React from "react";
import { Checkbox, FormControlLabel, Radio, RadioGroup, CircularProgress } from "@mui/material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import { viewId, listingClassification, companySize as companySizeCode } from "../../../_constants/Code";
import IconButton from "@mui/material/IconButton";
import { getBenchmarkCompany } from "../../../common/utils/FileUtils";
import { hankanaToZenkana, convertToUpperCase, haneisuToZeneinu } from "../../../common/utils/StringUtils";
import { getIndustrySector } from "../../../common/utils/FileUtils";
import { errorLog } from "../../../common/logger/Logger";
import { Delete, Search } from "@mui/icons-material";
import { PathInfoList } from "../../../_constants/PathInfoList";

const maxCompanySize = 10;
const maxBenchmarkCompanySize = 100;

const searchColumn = {
  'init': 'CorporationNumber',
  'search': 'SearchWord',
}

/**
 * 共通パーツ「詳細情報」
 */
const detailInfo = {
  ConditionKind: '',
  ConditionInfo: {
    ListingClassification:[],
    IndustrySector:[],
    CompanySize:[],
    CompanyList:[]
  },
};

/**
 * 詳細情報エラー項目
 */
const detailInfoError = {
  AttributeDesignation: null,
  CompanyDesignation: null
};

/**
 * 業種
 */
let industrySector = [];

/**
 * 企業規模
 */
const companySize =  Object.keys(companySizeCode).reduce((l, key) => {
  l[key] = companySizeCode[key].name;
  return l;
}, {});

const ConditionInfoContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  // 画面入力情報
  const [conditionInfo, setConditionInfo] = React.useState();
  const [searchCondition, setSearchCondition] = React.useState("");
  const [isSearchConditionEmpty, setIsSearchConditionEmpty] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const [benchmarkCompanyInfo, setBenchmarkCompanyInfo] = React.useState([]);
  const [inputError, setInputError] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  /**
   * ベンチマーク会社情報取得
   */
  const _getBenchmarkCompanyInfo = async (column, searchWord) => {
    try {
      let s3Data = await getBenchmarkCompany(
        props.corporationNumber,
        PathInfoList.Folder.benchmarkCompanyFolder + PathInfoList.Objectkeyname.benchmarkCompany,
        column,
        searchWord);
      s3Data = s3Data.sort((a, b) => {
        if (a.CorporationName > b.CorporationName) return 1;
        if (b.CorporationName > a.CorporationName) return -1;
        return 0;
      });
      return s3Data;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "_getBenchmarkCompanyInfo", error);
    }
  }

  /**
   * 業種情報取得
   */
  const _getIndustrySectorInfo = async () => {
    try {
      let s3Data = await getIndustrySector(props.corporationNumber, '1');
      s3Data = s3Data.sort((a, b) => {
        if (a.CorporationName > b.CorporationName) return 1;
        if (b.CorporationName > a.CorporationName) return -1;
        return 0;
      });
      // 画面表示用に整形する
      const newList = [];
      s3Data.forEach(item => {
          const newItem = {
              key: item.Key,
              name: item.Name
          };
          // SuperKeyが存在する場合は親カテゴリに追加する
          if (item.SuperKey !== null) {
              const parentItem = newList.find(parent => parent.key === item.SuperKey);
              if (parentItem) {
                  // 親カテゴリにlistプロパティが存在しない場合は作成する
                  if (!parentItem.hasOwnProperty('list')) {
                      parentItem.list = [];
                  }
                  parentItem.list.push(newItem);
              }
          } else {
            newList.push(newItem);
          }
      });
      return newList;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "_getIndustrySectorInfo", error);
    }
  }

  /**
   * 初期表示
   */
  const init = async () => {
    industrySector = await _getIndustrySectorInfo();
    if (props.viewMode === 'new') {
      detailInfo.ConditionKind = '1';
      detailInfo.ConditionInfo.ListingClassification = [];
      detailInfo.ConditionInfo.IndustrySector = [];
      detailInfo.ConditionInfo.CompanySize = [];
      detailInfo.ConditionInfo.CompanyList = [];
    } else {
      if (detailInfo.ConditionKind === '1') {
        detailInfo.ConditionInfo.CompanyList = [];
      } else if (detailInfo.ConditionKind === '2') {
        const companyInfo = await _getBenchmarkCompanyInfo(searchColumn.init, detailInfo.ConditionInfo.CompanyList);
        detailInfo.ConditionInfo.ListingClassification = [];
        detailInfo.ConditionInfo.IndustrySector = [];
        detailInfo.ConditionInfo.CompanySize = [];
        // 表示用に加工
        detailInfo.ConditionInfo.CompanyList = companyInfo.filter(c => detailInfo.ConditionInfo.CompanyList.includes(c.CorporationNumber));
      } else {
        detailInfo.ConditionInfo.CompanyList = [];
        detailInfo.ConditionInfo.ListingClassification = [];
        detailInfo.ConditionInfo.IndustrySector = [];
        detailInfo.ConditionInfo.CompanySize = [];
      }
    }
    setConditionInfo(detailInfo);
    setInputError(detailInfoError);
    setIsLoaded(true);
  }

  /**
   * 条件分類の値を変更する
   * @param {イベント} e 
   */
  const changeConditionKind = (e) => {
    detailInfo.ConditionKind = e.target.value;
    setConditionInfo({ ...conditionInfo, ConditionKind: e.target.value });
  }

  /**
   * 上場区分の値を変更する
   * @param {イベント} e 
   */
  const changeListingClassification = (e) => {
    let list = detailInfo.ConditionInfo.ListingClassification;
    if (list.includes(e.target.value)) {
      list = list.filter(node => node !== e.target.value);
    } else {
      list.push(e.target.value);
    }
    detailInfo.ConditionInfo.ListingClassification = list;
    setConditionInfo({ ...detailInfo });
  }

  /**
   * 業種（大分類）の値を変更する（小分類なし）
   * @param {イベント} e 
   */
  const changeIndustrySector1 = (e) => {
    let list = detailInfo.ConditionInfo.IndustrySector;
    if (list.includes(e.target.value)) {
      list = list.filter(node => node !== e.target.value);
    } else {
      list.push(e.target.value);
    }
    detailInfo.ConditionInfo.IndustrySector = list;
    setConditionInfo({ ...detailInfo });
  }

  /**
   * 業種（大分類）の値を変更する（小分類あり）
   * @param {イベント} e 
   * @param {リスト} l 
   */
  const changeIndustrySector2 = (e, l) => {
    let list = detailInfo.ConditionInfo.IndustrySector;
    let subList = l.concat();
    subList = subList.map(node => node.key);
    const isChecked = list.includes(e.target.value);
    if (isChecked) {  // ☑ → □
      subList.unshift(e.target.value);
      list = list.filter(node => !subList.includes(node));
    } else {  // □ → ☑
      subList.unshift(e.target.value);
      list = [...list, ...subList];
    }
    detailInfo.ConditionInfo.IndustrySector = list;
    setConditionInfo({ ...detailInfo });
  }

  /**
   * 業種（小分類）の値を変更する
   * @param {文字列} parentKey 
   * @param {イベント} e 
   * @param {リスト} l 
   */
  const changeIndustrySector2_1 = (parentKey, e, l) => {
    let list = detailInfo.ConditionInfo.IndustrySector;
    let subList = l.concat();
    subList = subList.map(node => node.key);
    let isChecked = list.includes(e.target.value);
    if (isChecked) {  // ☑ → □
      list = list.filter(node => node !== e.target.value);
      isChecked = subList.some(node => list.includes(node)); // 他の小分類がチェックされているかチェック
      if (!isChecked) {
        list = list.filter(node => node !== parentKey);
      }
    } else {  // □ → ☑
      isChecked = subList.some(node => list.includes(node)); // 他の小分類がチェックされているかチェック
      if (isChecked) {
        list = [...list, ...[e.target.value]];
      } else {
        list = [...list, ...[parentKey], ...[e.target.value]];
      }
    }
    detailInfo.ConditionInfo.IndustrySector = list;
    setConditionInfo({ ...detailInfo });
  }

  /**
   * 企業規模の値を変更する
   * @param {イベント} e 
   */
  const changeCompanySize = (e) => {
    let list = detailInfo.ConditionInfo.CompanySize;
    if (list.includes(e.target.value)) {
      list = list.filter(node => node !== e.target.value);
    } else {
      list.push(e.target.value);
    }
    detailInfo.ConditionInfo.CompanySize = list;
    setConditionInfo({ ...detailInfo });
  }

  /**
   * 検索条件の値を変更する
   * @param {イベント} e 
   */
  const changeSearchCondition = (e) => {
    setSearchCondition(e.target.value);
  }

  /**
   * 検索を行う（エンターキー押下）
   */
  const searchBenchmarkCompanyEnter = async() => {
    if (event.key === 'Enter') {
      await searchBenchmarkCompany();
    }
  }

  /**
   * 検索を行う
   */
  const searchBenchmarkCompany = async() => {
    try {
      // 前回検索結果0件かつ検索条件未入力の場合は実行しない
      if (!benchmarkCompanyInfo.length && !searchCondition) {
        return;
      }
      if (!searchCondition) {
        setIsSearchConditionEmpty(true);
        setBenchmarkCompanyInfo([]);
        return;
      }
      setIsSearchConditionEmpty(false);
      setIsSearching(true);
      // 検索用に変換
      let searchWord = hankanaToZenkana(searchCondition);  // 半角ｶﾅ → 全角カナ
      searchWord = convertToUpperCase(searchWord);  // 小文字 → 大文字
      searchWord = haneisuToZeneinu(searchWord);  // 半角英字 → 全角英字
      const companyInfo = await _getBenchmarkCompanyInfo(searchColumn.search, searchWord);
      setBenchmarkCompanyInfo(companyInfo);
      setIsSearching(false);
    } catch (error) {
      errorLog("E000001", props.userId, viewId.benchmarkTargetInfo, "searchBenchmarkCompany", error);
    }
  }

  /**
   * 企業リストに値を追加する
   * @param {企業情報} company 
   */
  const addCompanyList = (company) => {
    let list = detailInfo.ConditionInfo.CompanyList;
    if (list.length < maxCompanySize) {
      if (!list.includes(company)) {
        list.push(company);
        detailInfo.ConditionInfo.CompanyList = list;
        setConditionInfo({ ...detailInfo });
      }
    } else {
      const title = '';
      const body = <React.Fragment>
        <div className="text-center">絞り込みできる企業は{maxCompanySize}社までです。</div>
      </React.Fragment>;
      props.showModal({ title: title, body: body, isWide: false });
    }
  }

  /**
   * 企業リストから値を削除する
   * @param {企業情報} company 
   */
  const deleteCompanyList = (company) => {
    let list = detailInfo.ConditionInfo.CompanyList;
    if (list.includes(company)) {
      list = list.filter(node => node !== company);
      detailInfo.ConditionInfo.CompanyList = list;
      setConditionInfo({ ...detailInfo });
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return (
      <div className="flex justify-center">
          <span className="pr-3 mt-0.5">
              <CircularProgress size={18} style={{ color: 'black' }} />
          </span>
          <span>データ取得中 ...</span>
      </div>
    )
  }

  const isReference = props.viewMode === 'reference';
  return (
    <React.Fragment>
      {/* ===== 指定条件フォーム ===== */}
      <div className="text-body-2-medium font-medium mt-10 mb-10">
        対象企業の選択方法を指定してください。
      </div>
      <div className="flex ml-2 gap-x-8 mb-10">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={conditionInfo.ConditionKind}
          onChange={(e) => changeConditionKind(e)}
        >
          <FormControlLabel value="1" disabled={props.viewMode != 'new'} control={<Radio size="small" />} label={<span className={`ui-radio__label text-h7 font-Regular ${isReference && conditionInfo.ConditionKind == "1" ? "radio_checked radio_disabled radio_label" : ""}`}>上場区分／業種／規模を指定して選択（属性指定）</span>} />
          <FormControlLabel value="2" disabled={props.viewMode != 'new'} control={<Radio size="small" />} label={<span className={`ui-radio__label text-h7 font-Regular ${isReference && conditionInfo.ConditionKind == "2" ? "radio_checked radio_disabled radio_label" : ""}`}>企業名を検索して個別に選択（個別指定）</span>} />
        </RadioGroup>
      </div>
      <div className="box-xy-bordered border-lightgray rounded-xl py-8 blue1 lighten-3">
        {(() => {
          if (conditionInfo.ConditionKind === '1') {
            return (
              <div className="px-9">
                <div className="mb-3.5 pb-2.5 text-h7 font-bold border-b border-solid border-blue2-lighten-60">上場区分</div>
                <ul className="gap-x-8 gap-y-3 flex flex-wrap ml-2">
                  {Object.keys(listingClassification).map((lf, idx) => {
                    const isCheck = detailInfo.ConditionInfo.ListingClassification.includes(listingClassification[lf].value);
                    return (
                      <React.Fragment key={idx}>
                        <li className="w-30">
                          <label className="ui-checkbox ml-10">
                            <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                              value={listingClassification[lf].value}
                              checked={isCheck}
                              disabled={isReference}
                              onChange={(e) => changeListingClassification(e)} />
                            <span className={`ui-checkbox__label text-h7 font-Regular ${isReference && !isCheck ? "label_disabled" : ""}`}>{listingClassification[lf].name}</span>
                          </label>
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
                <div className="mt-6 mb-3.5 pb-2.5 text-h7 font-bold border-b border-solid border-blue2-lighten-60">業種</div>
                {industrySector.map((is, idx) => {
                  let someCheck, isCheckAll;
                  if (is?.list) {
                    someCheck = is.list.some((node) => {
                      return detailInfo.ConditionInfo.IndustrySector.includes(node.key);
                    });
                    isCheckAll = is.list.every((node) => {
                      return detailInfo.ConditionInfo.IndustrySector.includes(node.key);
                    });
                  } else {
                    isCheckAll = detailInfo.ConditionInfo.IndustrySector.includes(is.key);
                  }
                  return (
                    <React.Fragment key={idx}>
                      <ul className="gap-x-8 gap-y-3 flex flex-wrap ml-2">
                        <li>
                          <label className="ui-checkbox ml-10 mb-4">
                            <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                              value={is.key}
                              indeterminate={someCheck && !isCheckAll}
                              checked={isCheckAll}
                              disabled={isReference}
                              onChange={(e) => !(is?.list) ? changeIndustrySector1(e) : changeIndustrySector2(e, is.list)} />
                            <span className={`ui-checkbox__label text-h7 font-Regular ${isReference && !(someCheck || isCheckAll) ? "label_disabled" : ""}`}>{is.key}&nbsp;{is.name}</span>
                          </label>
                        </li>
                      </ul>
                      {is?.list && 
                        <ul className="gap-x-14 flex flex-wrap ml-10">
                          {is.list.map((is2, idx2) => {
                            const isCheck = detailInfo.ConditionInfo.IndustrySector.includes(is2.key);
                            return (
                              <li key={idx2}>
                                <label className="ui-checkbox ml-10 mb-4">
                                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                    value={is2.key}
                                    checked={isCheck}
                                    disabled={isReference}
                                    onChange={(e) => changeIndustrySector2_1(is.key, e, is.list)} />
                                  <span className={`ui-checkbox__label text-h7 font-Regular ${isReference && !isCheck ? "label_disabled" : ""}`}>{is2.key}&nbsp;{is2.name}</span>
                                </label>
                              </li>
                            )
                          })}
                        </ul>
                      }
                    </React.Fragment>
                  )
                })}
                <div className="mt-2.5 mb-3.5 pb-2.5 text-h7 font-bold border-b border-solid border-blue2-lighten-60">企業規模</div>
                <ul className="gap-x-8 gap-y-3 flex flex-wrap ml-2">
                  {Object.keys(companySize).map((is, idx) => {
                    const isCheck = detailInfo.ConditionInfo.CompanySize.includes(is);
                    return (
                      <React.Fragment key={idx}>
                        <li className="w-30">
                          <label className="ui-checkbox ml-10">
                            <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                              value={is}
                              checked={isCheck}
                              disabled={isReference}
                              onChange={(e) => changeCompanySize(e)} />
                            <span className={`ui-checkbox__label text-h7 font-Regular ${isReference && !isCheck ? "label_disabled" : ""}`}>{companySize[is]}</span>
                          </label>
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
              </div>
            );
          {/* 企業名から検索して設定 */}
          } else if (conditionInfo.ConditionKind === '2') {
            return (
              <div className="px-9">
                {/* 検索ボックス */}
                {!isReference &&
                  <React.Fragment>
                    <div className="w-1/3 relative">
                      <input type="text" value={searchCondition}
                        className="text-caption-2 block rounded-lg pl-4 pr-12 pt-2 pb-2.5 w-full !border-0"
                        placeholder=" 法人番号または企業名を入力してください"
                        onChange={(e) => changeSearchCondition(e)}
                        onKeyDown={() => searchBenchmarkCompanyEnter()}
                      />
                      <button type="button" className="absolute icon-32px right-2 inset-y-0" onClick={() => searchBenchmarkCompany()}>
                        <span className="icon-search"></span>
                      </button>
                    </div>
                    <div className="mt-6 mb-3 text-caption-2">検索結果 : {benchmarkCompanyInfo.length}件</div>
                    {/* 検索結果テーブル */}
                    <div className="setting-table is-benchmark is-search-result">
                      <table className="setting-table__table w-full">
                        <thead className="sticky top-0">
                          <tr>
                            <th className="setting-table__col-benchmark-setting-btn"></th>
                            <th className="setting-table__col-benchmark-setting-company-name">企業名</th>
                            <th className="setting-table__col-benchmark-setting-company-number">法人番号</th>
                            <th className="setting-table__col-benchmark-setting-classification">上場区分</th>
                            <th className="setting-table__col-benchmark-setting-address">所在地</th>
                            <th className="setting-table__col-benchmark-setting-scale">規模</th>
                            <th className="setting-table__col-benchmark-setting-women-empowerment">女活</th>
                            <th className="setting-table__col-benchmark-setting-finance">財務</th>
                          </tr>
                        </thead>
                        <tbody className="text-caption-1">
                          {(() => {
                            if(isSearchConditionEmpty) {
                              return (
                                <tr>
                                  <td colSpan="8">
                                    <span className="grey--text text--lighten-70 pl-4">検索結果が表示されます</span>
                                  </td>
                                </tr>
                              );
                            } else {
                              if (isSearching) {
                                return (
                                  <tr>
                                    <td colSpan="8">
                                      <span className="grey--text text--lighten-70 pl-4">検索中 ...</span>
                                    </td>
                                  </tr>
                                );
                              } else {
                                if (!benchmarkCompanyInfo.length) {
                                  return (
                                    <tr>
                                      <td colSpan="8">
                                        <span className="grey--text text--lighten-70 pl-4">該当する企業は見つかりませんでした</span>
                                      </td>
                                    </tr>
                                  );
                                } else if (benchmarkCompanyInfo.length > maxBenchmarkCompanySize) {
                                  return (
                                    <tr>
                                      <td colSpan="8">
                                        <span className="grey--text text--lighten-70 pl-4">検索結果が{maxBenchmarkCompanySize}件を超えています。検索値を変更して再度検索してください。</span>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <React.Fragment>
                                      {benchmarkCompanyInfo.map((data, idx) => {
                                        const selected = conditionInfo.ConditionInfo.CompanyList.some(d => d.CorporationNumber == data.CorporationNumber);
                                        return (
                                          // ■選択済みの企業の場合
                                          // ・選択済みの企業が入っている tr 要素には is-selected クラスを付与してください
                                          // ・選択済みの企業が入っている tr 要素内のinput要素は checked 属性と disalbed属性を付与してください
                                          <tr key={idx} className={`${selected ? "is-selected" : ""}`}>
                                            <td className="!p-0">
                                              <div className="flex justify-center pl-2">
                                                <label className="ui-checkbox">
                                                  <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                                    checked={selected}
                                                    disabled={selected}
                                                    onClick={() => addCompanyList(data)} />
                                                </label>
                                              </div>
                                            </td>
                                            <td>{data.CorporationName}</td>
                                            <td>{data.CorporationNumber}</td>
                                            <td>{data.ListingClassification}</td>
                                            <td>{data.Address}</td>
                                            <td>{data.CompanySize}</td>
                                            <td className="!text-center">{data.Jyokatsu === '1' ? "○" : "×"}</td>
                                            <td className="!text-center">{data.Zaimu === '1' ? "○" : "×"}</td>
                                          </tr>
                                        )
                                      })}
                                    </React.Fragment>
                                  );
                                }
                              }
                            }
                          })()}
                        </tbody>
                      </table>
                    </div>
                    {/* 注釈＆矢印  */}
                    {/* 
                      ■検索結果がない場合
                      下記のdiv要素に opacity-50 クラスを付与してください
                      ■検索結果がある場合
                      下記のdiv要素から opacity-50 クラスを削除してください
                    */}
                    <div className={`text-center mt-8 ${
                      !isSearchConditionEmpty && (benchmarkCompanyInfo.length && benchmarkCompanyInfo.length <= maxBenchmarkCompanySize)
                      ? "" : "opacity-50"}`}
                    >
                      <span className="text-center text-caption-2 block mb-2 grey--text text--lighten-70">チェックして対象企業一覧に追加 （最大10社）</span>
                      <span className="icon-direction icon-32px rotate-90 inline-block link--text"></span>
                    </div>
                  </React.Fragment>
                }

                {/* 対象企業数 */}
                <div className="mb-3 text-caption-2">対象企業  : {conditionInfo.ConditionInfo.CompanyList.length}件</div>

                {/* 選択企業テーブル */}
                {/* 
                  選択企業テーブル
                  下記のdiv要素に is-benchmarkt クラスのみ付与してください
                */}
                <div className="setting-table is-benchmark">
                  <table className="setting-table__table w-full">
                    <thead>
                      <tr>
                        <th className="setting-table__col-benchmark-setting-btn"></th>
                        <th className="setting-table__col-benchmark-setting-company-name">企業名</th>
                        <th className="setting-table__col-benchmark-setting-company-number">法人番号</th>
                        <th className="setting-table__col-benchmark-setting-classification">上場区分</th>
                        <th className="setting-table__col-benchmark-setting-address">所在地</th>
                        <th className="setting-table__col-benchmark-setting-scale">規模</th>
                        <th className="setting-table__col-benchmark-setting-women-empowerment">女活</th>
                        <th className="setting-table__col-benchmark-setting-finance">財務</th>
                      </tr>
                    </thead>
                    <tbody className="text-caption-1">
                      {(() => {
                        {/*} 
                          ■初期状態・対象企業の設定がない場合
                          tdに colspan="8" を設定してメッセージエリアとして使います
                        */}
                        if (!conditionInfo.ConditionInfo.CompanyList.length) {
                          return (
                            <tr>
                              <td colSpan="8">
                                <span className="grey--text text--lighten-70 pl-4">対象企業は設定されていません</span>
                              </td>
                            </tr>
                          )
                        } else {
                          return (
                            <React.Fragment>
                              {conditionInfo.ConditionInfo.CompanyList.map((data, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="!p-0 !text-center">
                                      <button type="button" className="btn-icon-delete p-1.5" disabled={isReference} onClick={() => deleteCompanyList(data)}>
                                        <span className="icon-delete text-xl leading-none"></span>
                                      </button>
                                    </td>
                                    <td>{data.CorporationName}</td>
                                    <td>{data.CorporationNumber}</td>
                                    <td>{data.ListingClassification}</td>
                                    <td>{data.Address}</td>
                                    <td>{data.CompanySize}</td>
                                    <td className="!text-center">{data.Jyokatsu === '1' ? "○" : "×"}</td>
                                    <td className="!text-center">{data.Zaimu === '1' ? "○" : "×"}</td>
                                  </tr>
                                )
                              })}
                            </React.Fragment>
                          )
                        }
                      })()}
                    </tbody>
                  </table>
                </div>
                {/* 注釈 */}
                {/* 
                  ■対象企業の設定がない場合
                  下記のdiv要素に opacity-50 クラスを付与してください
                  ■対象企業の設定がある場合
                  下記のdiv要素から opacity-50 クラスを削除してください
                */}
                {!isReference &&
                  <div className={`text-center mt-4 ${conditionInfo.ConditionInfo.CompanyList.length ? "" : "opacity-50"}`}>
                    <span className="text-center text-caption-2 block mb-2 grey--text text--lighten-70">ゴミ箱アイコンで対象企業から削除</span>
                  </div>
                }
              </div>
            );
          }
        })()}
      </div>
      {conditionInfo.ConditionKind === '1' && <span className="red--text">{inputError.AttributeDesignation}</span>}
      {conditionInfo.ConditionKind === '2' && <span className="red--text">{inputError.CompanyDesignation}</span>}
    </React.Fragment>
  )
}

/**
 * 詳細情報を取得
 * @returns 詳細情報
 */
export const getDetailInfo = () => {
  return { ...JSON.parse(JSON.stringify(detailInfo)) };
}

/**
 * 詳細情報を設定
 * @param {詳細情報} info 
 */
export const setDetailInfo = (info) => {
  Object.keys(detailInfo).forEach(key => detailInfo[key] = info[key] ? info[key] : '');
}

export const clearCheck = () => {
  // 詳細情報部のエラー情報をクリア
  Object.keys(detailInfoError).forEach(key => detailInfoError[key] = null);
}

/**
 * 詳細情報入力チェック
 * @returns エラー情報
 */
export const checkDetailInfo = () => {
  // 詳細情報部のエラー情報をクリア
  clearCheck();
  // 属性指定の場合
  if (detailInfo.ConditionKind === '1') {
    // -- 1つずつ以上選択されていること --
    if ( !detailInfo.ConditionInfo.ListingClassification.length
      || !detailInfo.ConditionInfo.IndustrySector.length
      || !detailInfo.ConditionInfo.CompanySize.length ) {
      detailInfoError.AttributeDesignation = "上場区分、業種、企業規模をそれぞれ1つ以上選択してください。";
    }
  // 企業指定の場合
  } else {
    // -- 1社以上選択されていること --
    if (!detailInfo.ConditionInfo.CompanyList.length) {
      detailInfoError.CompanyDesignation = "対象企業を1つ以上設定してください。";
    }
  }
  return Object.keys(detailInfoError).some(key => detailInfoError[key] !== null);
}

export default (props) => <ConditionInfoContent {...props} />;
