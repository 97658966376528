"use strict";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { SpinnerButton } from "../../common/CustomButton";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from "date-fns/locale/ja";
import 'react-datepicker/dist/react-datepicker.css';
import { errorLog } from "../../../common/logger/Logger";
import { getDynamoDB, updateDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { viewId, titleName, table, masterStatus, masterClassification } from "../../../_constants/Code";
import { formatDate } from "../../../common/utils/DateUtils";
import Forbidden from "../../../_components/Forbidden";

// 日本語ロケールを登録
registerLocale('ja', ja);

// ドラッグ可能な行のコンポーネント
const SortableItem = ({ id, seg, changeSegmentValidStartMonth, changeSegmentValidEndMonth, inputError }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes}>
      <td className="cursor-pointer order-icon" {...listeners}>
        <MenuIcon />
      </td>
      <td className="cursor-default"><span className="cursor-text">{seg.Code}</span></td>
      <td className="cursor-default"><span className="cursor-text">{seg.Name}</span></td>
      <td className="cursor-default">
        <DatePicker
          id="segmentValidStartMonth"
          selected={seg.SegmentValidStartMonth}
          onChange={(date) => changeSegmentValidStartMonth(seg.Code, date)}
          showMonthYearPicker
          dateFormat="yyyy/MM"
          className="segment-valid-date block rounded-lg px-3 py-1"
          placeholderText=""
          autoComplete="off"
          // isClearable
          locale="ja"
        />
      </td>
      <td className="cursor-default">
        <DatePicker
          id="segmentValidEndMonth"
          selected={seg.SegmentValidEndMonth}
          onChange={(date) => changeSegmentValidEndMonth(seg.Code, date)}
          showMonthYearPicker
          dateFormat="yyyy/MM"
          className={`segment-valid-date block rounded-lg px-3 py-1 ${inputError[seg.Code]?.datePeriod ? "is-error" : ""}`}
          placeholderText=""
          autoComplete="off"
          // isClearable
          locale="ja"
        />
      </td>
    </tr>
  );
};

const SegmentInfoContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [master, setMaster] = React.useState([]);
  const [targetSegment, setTargetSegment] = React.useState([]);
  const [displayOrderList, setDisplayOrderList] = React.useState([]);
  const [inputError, setInputError] = React.useState({});

  React.useEffect(() => {
    document.title = props.pageTitle;
    init();
  }, []);

  const getMaster = async () => {
    try {
      let master = await getDynamoDB(
        table.Master,
        null,
        props.corporationNumber,
        {"CorporationNumber": props.corporationNumber, "MasterId": viewId.segmentInfo},
        null
      );
      return master[0];
    } catch (error) {
      errorLog("E000001", props.userId, viewId.segmentInfo, "getMaster", error);
    }
  }

  const getAnalysisTargetInfo = async () => {
    try {
      let analysisTargetInfo = await getDynamoDB(
        table.AnalysisTargetInfo,
        null,
        props.corporationNumber,
        null,
        null
      );
      return analysisTargetInfo[0];
    } catch (error) {
      errorLog("E000001", props.userId, viewId.segmentInfo, "getAnalysisTargetInfo", error);
    }
  }

  const init = async () => {
    // マスター情報取得
    let master = await getMaster();
    setMaster(master);
    // セグメント情報取得
    let analysisTargetInfo = await getAnalysisTargetInfo();

    // ソートと表示用の加工
    if (analysisTargetInfo.TargetSegment?.length) {
      analysisTargetInfo.TargetSegment
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
        .forEach(seg => {
          seg.SegmentValidStartMonth = seg.SegmentValidStartMonth == "" ? null : seg.SegmentValidStartMonth;
          seg.SegmentValidEndMonth = seg.SegmentValidEndMonth == "" ? null : seg.SegmentValidEndMonth;
        });

      setTargetSegment(analysisTargetInfo.TargetSegment);
      setDisplayOrderList(analysisTargetInfo.TargetSegment.map(seg => seg.Code));
    }

    setIsLoaded(true);
  };

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError.errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 有効開始月の値を変更する
   * @param {イベント} e 
   */
  const changeSegmentValidStartMonth = (code, date) => {
    // 削除した時はnullを設定、値を選択したときはYYYY/MM形式で設定
    let segment = targetSegment.map(seg => seg.Code === code ? { ...seg, SegmentValidStartMonth: date == null ? date : date.getFullYear() + "/" + ('0' + (date.getMonth() + 1)).slice(-2) } : seg);
    setTargetSegment(segment);
  };

  /**
   * 有効終了月の値を変更する
   * @param {イベント} e 
   */
  const changeSegmentValidEndMonth = (code, date) => {
    // 削除した時はnullを設定、値を選択したときはYYYY/MM形式で設定
    let segment = targetSegment.map(seg => seg.Code === code ? { ...seg, SegmentValidEndMonth: date == null ? date : date.getFullYear() + "/" + ('0' + (date.getMonth() + 1)).slice(-2) } : seg);
    setTargetSegment(segment);
  };

  /**
   * レコードの順序を変更する
   * @param {イベント} e 
   */
  const handleDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      const oldIndex = displayOrderList.indexOf(active.id);
      const newIndex = displayOrderList.indexOf(over.id);

      const newDisplayOrderList = arrayMove(displayOrderList, oldIndex, newIndex);

      // targetSegmentの順番は変えずにDisplayOrderを更新
      const updatedItems = targetSegment.map(item => ({
        ...item,
        DisplayOrder: newDisplayOrderList.indexOf(item.Code) + 1
      }));

      setDisplayOrderList(newDisplayOrderList);
      setTargetSegment(updatedItems);
    }
  };

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    let inputError = {};
    try {
      const segment = JSON.parse(JSON.stringify(targetSegment));
      segment.forEach(seg => {
        const error = {}
        // -- 日付範囲 --
        if (seg.SegmentValidStartMonth && seg.SegmentValidEndMonth && seg.SegmentValidStartMonth > seg.SegmentValidEndMonth) {
          error.datePeriod = "セグメントコード「" + seg.Code + "」の「有効終了月」には「有効開始月」以降の日付を入力してください。"
        }
        // エラー判定
        if (Object.keys(error).length === 0) {
          return false;
        } else {
          inputError[seg.Code] = error;
          inputError.errorMessage = '入力内容に誤りがあります。'
          return true;
        }
      });
      return Object.keys(inputError).length > 0;
    } finally {
      // 入力エラーを設定
      setInputError(inputError);
    }
  }

  /**
   * 更新処理
   */
  const updateSegment = async () => {

    if (_checkInput()) {
      return;
    }

    try {
      // セグメント更新
      let analysisTargetInfo = await getAnalysisTargetInfo();
      const nowDate = formatDate(new Date());
      const segment = JSON.parse(JSON.stringify(targetSegment));
      
      // 有効期限がnullの場合は空文字に置換
      segment.forEach(seg => {
        seg.SegmentValidStartMonth = seg.SegmentValidStartMonth == null ? "" : seg.SegmentValidStartMonth;
        seg.SegmentValidEndMonth = seg.SegmentValidEndMonth == null ? "" : seg.SegmentValidEndMonth;
      });

      // キー項目削除
      delete analysisTargetInfo.CorporationNumber;
      // 更新内容設定
      analysisTargetInfo.TargetSegment = segment;
      analysisTargetInfo.UpdateDateTime = nowDate;
      analysisTargetInfo.UpdateUser = props.userName;

      // 更新
      await updateDynamoDB(
        table.AnalysisTargetInfo,
        'CorporationNumber',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber
        },
        analysisTargetInfo,
        props.userId
      );

      // マスター一覧更新
      let master = await getMaster();
      
      // キー項目削除
      delete master.CorporationNumber;
      delete master.MasterId;

      // 更新内容設定
      master.Status = masterStatus.reflected.value;
      master.UpdateDateTime = nowDate;
      master.UpdateUserName = props.userName;

      // 更新
      await updateDynamoDB(
        table.Master,
        'CorporationNumber',
        props.corporationNumber,
        {
          CorporationNumber: props.corporationNumber,
          MasterId: viewId.segmentInfo
        },
        master,
        props.userId
      );
      props.navigate('/master/masterList', { state: { message: 'セグメント設定を保存しました。' } });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.segmentInfo, "updateSegment", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            <div className="page-body">
              <div>
                <span style={{ color: "red" }}>{inputError.errorMessage}</span>
              </div>
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">セグメント情報の設定</h2>
                {targetSegment.length ? (
                  <React.Fragment>
                    <div className="flex items-center justify-between my-6">
                    セグメントの表示順と有効期間を設定します。<br />
                      表示順を変更するには「≡」を上下にドラッグ＆ドロップしてください。<br />
                      設定した順序で「分析対象の絞り込み」画面および「権限グループ設定」画面が表示されます。<br />
                      有効開始/終了月は、レポートデータ作成時のチェックや上記画面の表示に使用されます。
                    </div>
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                      <div className="setting-table is-segment">
                        <table className="setting-table__table table-fixed w-full form-control">
                          <thead>
                            <tr>
                              <th className="setting-table__col-segment-order">↑↓</th>
                              <th className="setting-table__col-segment-code">セグメントコード</th>
                              <th className="setting-table__col-segment-name">セグメント名</th>
                              <th className="setting-table__col-segment-valid-start-date">有効開始月</th>
                              <th className="setting-table__col-segment-valid-end-date">有効終了月</th>
                            </tr>
                          </thead>
                          <tbody className="text-caption-1">
                            <SortableContext items={displayOrderList} strategy={verticalListSortingStrategy}>
                              {displayOrderList.map((code) => {
                                const seg = targetSegment.find(seg => seg.Code === code);
                                return (
                                  <SortableItem
                                    key={seg.Code}
                                    id={seg.Code}
                                    seg={seg}
                                    changeSegmentValidStartMonth={changeSegmentValidStartMonth}
                                    changeSegmentValidEndMonth={changeSegmentValidEndMonth}
                                    inputError={inputError}
                                  />
                                );
                              })}
                            </SortableContext>
                          </tbody>
                        </table>
                        {/* エラーメッセージ表示 */}
                        {Object.keys(inputError).filter(key => key !== "errorMessage").map((key, idx) => (
                          <span key={key} className="red--text">{idx != 0 && <br />}{inputError[key].datePeriod}</span>
                        ))}
                      </div>
                    </DndContext>
                  </React.Fragment>
                ) : (
                  <div className="text-center my-10 pt-10">
                    <span className="text-h5 red--text">セグメントが未登録です。</span>
                  </div>
                )}
                <div className="content-footer mt-16 mb-8">
                  <div className="border-t border-blue1" />
                  {/*  ===== フッターボタン ===== */}
                  <div className="footer_button_area mt-8">
                    <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                      <button type="button" className="footer-btn button_back">キャンセル</button>
                    </Link>
                    {targetSegment.length !== 0 &&
                      <SpinnerButton name="登録" className="footer-btn button_base"
                      onClick={() => updateSegment()} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

const SegmentInfo = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  // 親会社でない、もしくは権限を持っていない場合は画面表示しない
  if (!state.company.isParent || !state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Group.SegmentInfo_Set) {
    return <Forbidden />
  }

  return <SegmentInfoContent
    pageTitle={titleName.segmentInfo + titleName.common}
    corporationNumber={state.company.corporationNumber}
    userId={state.user.sub}
    userName={state.user.userName}
    navigate={navigate}
  />;
};

export default SegmentInfo;
