"use strict";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { PathInfoList } from "../../../_constants/PathInfoList";
import { SpinnerButton } from "../../common/CustomButton"
import { getDynamoDB, updateDepartmentHierarchy } from "../../../common/utils/DynamoDbUtils";
import { viewId, titleName, table, masterClassification } from "../../../_constants/Code";
import { errorLog } from "../../../common/logger/Logger";
import { getObject, readCsvFile } from "../../../common/utils/FileUtils";
import { convertDateToJapaneseFormat } from "../../../common/utils/DateUtils";
import Forbidden from "../../../_components/Forbidden";
import { useModal } from "../../../_components/Modal";
import RelatedDashboard from "../parts/RelatedDashboard";

// ヘッダー項目
const headers = [
  "所属組織1（テーブル用）",
  "所属組織2（テーブル用）",
  "所属組織3（テーブル用）",
  "所属組織4（テーブル用）",
  "所属組織5（テーブル用）",
  "所属組織6（テーブル用）",
]

const HierarchyInfoContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [master, setMaster] = React.useState([]);
  const [periodList, setPeriodList] = React.useState([]);
  const [isOpenPeriod, setIsOpenPeriod] = React.useState(false);
  const [targetStartDate, setTargetStartDate] = React.useState();
  const [orgHierarchyInfoList, setOrgHierarchyInfoList] = React.useState([]);
  // ヘッダに対応するインデックス
  const [indexList, setIndexList] = React.useState([]);
  // テーブルデータ
  const [masterList, setMasterList] = React.useState([]);
  const [processedList, setProcessedList] = React.useState([]);
  // 変更判定
  const [isChanged, setIsChanged] = React.useState(false);

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {
    // 情報を取得
    const masterData = await getDynamoDB(table.Master, null, props.corporationNumber, {"CorporationNumber": props.corporationNumber, "MasterId": viewId.hierarchyInfo});
    setMaster(masterData[0]);
    const dynamoDBData = await getDynamoDB(table.DepartmentHierarchy, null, props.corporationNumber);
    setOrgHierarchyInfoList(dynamoDBData);
    if (dynamoDBData.length) {
      // 対象期間のリスト
      const pList = dynamoDBData.sort((a, b) => a.StartDate > b.StartDate ? -1 : 1).map(data => {
        return {
          "value": data.StartDate,
          "name": convertDateToJapaneseFormat(data.StartDate) + " ～ " + (data.EndDate != "" ? convertDateToJapaneseFormat(data.EndDate) : "")
        }
      });
      setPeriodList(pList);
      setTargetStartDate(pList[0].value);
      // 組織階層テーブル
      const targetStartDate = pList[0].value;
      await resetList(dynamoDBData.find(d => d.StartDate === targetStartDate));
    }

    setIsLoaded(true);
  }

  /**
   * 階層リストを再設定
   */
  const resetList = async (info) => {
    // CSVファイル取得
    const _getCsvData = async (filePath, fileName) => {
      const fileData = await getObject(props.bucketName, filePath + fileName);
      const file = new File([fileData], fileName, { type: "text/plain" });
      let list = await readCsvFile(file);
      // 最終行が空行の場合は削除する
      list = list.filter((row, index) => index !== list.length - 1 || !row.every(cell => cell === "" || cell === null || cell === undefined));
      return list;
    }
    // 組織階層マスタ
    // 組織階層マスタには"所属組織N（グラフ用）"列がないため、"所属組織N（テーブル用）"列をコピーして列を追加する
    let master = await _getCsvData(PathInfoList.Folder.departmentCsv, info.FileName);
    master = master.map((row, rowIndex) => {
      let newRow = [];
      row.forEach((value, index) => {
          newRow.push(value);
          // "所属組織N（テーブル用）" の列を見つけた場合
          if (index > 0 && index < row.length - 2 && index % 2 === 1) {
            if (rowIndex === 0) {
              newRow.push(value.replace("テーブル用", "グラフ用")); // ヘッダー行の場合
            } else {
              newRow.push(value); // データ行の場合
            }
          }
      });
      return newRow;
    });
    // 組織階層マスタ階層マスタ（加工）
    const processed = await _getCsvData(PathInfoList.Folder.departmentCsvDrilldown, info.FileName);
    // ヘッダーのインデックスを取得
    if (master.length > 0) {
      setIndexList(headers.map(head => {
        return master[0].findIndex(ele => ele === head);
      }));
    }
    // リストに設定（加工済みを先に反映）
    setProcessedList(processed);
    setMasterList(master);
    setIsChanged(false);
  }

  const _changeProcessedList = (fromIdx, toIdx) => {
    // 全ての行に対して引数fromIdxに該当する値をtoIdxの列に設定する
    const newList = processedList.map((row, rowIdx) => {
      // ヘッダスキップ
      if (rowIdx === 0) { return row; }
      // マスタのtoIdxに値があればスキップ
      if (masterList[rowIdx][toIdx]) { return row; }
      // 加工ファイルのテーブル用に値を設定
      row[toIdx] = row[fromIdx];
      // 加工ファイルのグラフ用に値を設定
      if (masterList[rowIdx][fromIdx]) {
        // マスタのfromIdxに値がある場合はそのまま更新
        row[toIdx + 1] = "(" + (Math.floor(fromIdx / 3) + 1) + ")" + row[fromIdx];
      } else {
        // マスタのfromIdxに値がない場合は列をコピー
        row[toIdx + 1] = row[fromIdx + 1];
      }
      return row;
    });
    setProcessedList(newList);
  }

  /**
   * 下位階層設定
   */
  const setLowerHierarchy = (colIdx) => {
    const fromIdx = indexList[colIdx];
    const toIdx = indexList[colIdx + 1];
    _changeProcessedList(fromIdx, toIdx);
    setIsChanged(true);
  }

  /**
   * 上位階層設定
   */
  const setUpperHierarchy = (colIdx) => {
    const fromIdx = indexList[colIdx];
    const toIdx = indexList[colIdx - 1];
    _changeProcessedList(fromIdx, toIdx);
    setIsChanged(true);
  }

  /**
   * 対象期間ドロップダウンの開閉を切り替え
   * @param {boolean} isOpen - ドロップダウン開閉判定
   */
  const togglePeriodDropdown = (isOpen) => {
    setIsOpenPeriod(isOpen);
    document.getElementById("periodButton").classList.toggle('is-active', isOpen);
  };

  /**
   * 登録ボタン押下
   */
  const updateHierarchy = async () => {

    try {
      const title = 'マスター設定値登録の確認';
      const description = <React.Fragment>
        <div className="text-center">設定値を登録してダッシュボードに反映します。よろしいですか？</div>
        <div className="text-center pt-7">※ダッシュボードへの反映には5分～10分程度かかります</div>
        <div className="text-center">マスター管理画面のステータスから反映状況を確認してください</div>
      </React.Fragment>;
      const button = [{
        name: '実行',
        className: 'footer-btn button_base',
        click: async () => {
          // 対象を抽出
          const info = orgHierarchyInfoList.find(d => d.StartDate === targetStartDate);
          // マスター更新
          await updateDepartmentHierarchy(props.corporationNumber, props.userName, viewId.hierarchyInfo, info.FileName, processedList);
          props.navigate('/master/masterList', { state: { message: '組織階層設定を保存しました。' } });
        }
      }];
      props.showModal({ title: title, description: description, button: button, isWide: true });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.hierarchyInfo, "updateHierarchy", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment >
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="form-control">
                <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                  {/*  ===== 基本情報の設定 ===== */}
                  <div className="content-area">
                    <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">階層設定</h2>
                    {orgHierarchyInfoList.length ? (
                      <React.Fragment>
                        <div className="flex mt-5">
                          <ul className="text-body-2-medium grow">
                            <li>各階層で集計対象とする組織を設定してください。</li>
                          </ul>
                        </div>
                        <div className="box-graph__target-fiscal-year flex items-center mt-2">
                          <div className="text-h5">対象期間：</div>
                          <div className="action-area" style={{ position: "relative" }}>
                            <div className="year-container" tabIndex="0" onBlur={() => togglePeriodDropdown(false)}>
                              <div
                                id="periodButton"
                                className="yearButton flex items-center justify-between rounded-xl pl-4 pr-2 periodButton"
                                style={{ paddingTop: "4px", paddingBottom: "4px" }}
                                onClick={() => togglePeriodDropdown(!isOpenPeriod)}
                              >
                                <div className="yearButton__text text-h7 mr-2">
                                  {(() => {const list = periodList.find(period => period.value === targetStartDate); return list.name})()}
                                </div>
                                <div className="yearButton__icon flex items-center justify-center w-8 h-8">
                                  <span className="icon-arrow"></span>
                                </div>
                              </div>
                              <ul className="year rounded-xl" hidden={!isOpenPeriod} style={{ zIndex: 30 }}>
                                {periodList.map((period, index) => (
                                  <li key={index} className="item text-h7" onClick={async () => {
                                    togglePeriodDropdown(!isOpenPeriod);
                                    const value  = period.value;
                                    if (value !== targetStartDate) {
                                      if (isChanged) {
                                        const title = '対象期間変更確認';
                                        const description = <React.Fragment>
                                          <div className="text-center">現在の変更は破棄されます。よろしいですか？</div>
                                        </React.Fragment>;
                                        const button = [{
                                          name: '実行',
                                          className: 'footer-btn button_base',
                                          click: async () => {
                                            setTargetStartDate(value);
                                            await resetList(orgHierarchyInfoList.find(d => d.StartDate === value));
                                          }
                                        }];
                                        props.showModal({ title: title, description: description, button: button, isWide: false });
                                      } else {
                                        setTargetStartDate(value);
                                        await resetList(orgHierarchyInfoList.find(d => d.StartDate === value));
                                      }
                                    }
                                  }}>
                                    {period.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <table className="base-table hierarchy-table w-full mt-1">
                            <thead className="text-h7">
                              <tr>
                                {(() => {
                                  const cols = [1, 2, 3, 4, 5, 6];
                                  return cols.map((col, idx) => {
                                    const headerIdx = masterList[0].indexOf(headers[col - 1]); // ヘッダー列のインデックスを取得
                                    const uniqueDepartments = new Set(
                                      masterList.slice(1) // ヘッダー行を除く
                                        .map(row => row[headerIdx])
                                        .filter(value => value !== "")
                                    );
                                    return <React.Fragment key={idx}>
                                      <th>
                                        <div className="flex">
                                          {idx !== 0 && idx !== 1 && <>
                                            <button className="arrow-left" onClick={() => setUpperHierarchy(idx)}>
                                              <ArrowCircleLeftOutlinedIcon fontSize="small" />
                                            </button>
                                          </>}
                                          <span className="head-title">
                                            階層{col}の組織
                                            <span className="pl-2">[{uniqueDepartments.size}]</span>
                                          </span>
                                          {idx !== cols.length - 1 && <>
                                            <button className="arrow-right" onClick={() => setLowerHierarchy(idx)}>
                                              <ArrowCircleRightOutlinedIcon fontSize="small" />
                                            </button>
                                          </>}
                                        </div>
                                      </th>
                                    </React.Fragment>
                                  });
                                })()}
                              </tr>
                            </thead>
                            <tbody className="text-caption-2 text-nowrap">
                              {masterList.map((row, idx) => {
                                // ヘッダ除く
                                if (idx == 0) {
                                  return;
                                }
                                return <tr key={idx}>
                                  {/* 対象インデックスの値を表示 */}
                                  {indexList.map((index, idx2) => {
                                    const mCol = row[index];
                                    const pCol = processedList.length !== 0 ? processedList[idx][index] : "";
                                    if (mCol) {
                                      // マスタ列
                                      return <td key={idx2} className="title">
                                        <span title={mCol}>{mCol}</span>
                                      </td>
                                    } else {
                                      // 加工列
                                      // 所属組織N（グラフ用）列の組織名は「(x)組織名」となる
                                      // x < n + 1の場合、親組織が設定されていることとなるため、isUpperHierarchyをtrueと判定する
                                      const isUpperHierarchy = parseInt(processedList[idx][index + 1].match(/\((\d+)\)/)[1], 10) < idx2 + 1;
                                      return <td key={idx2}>
                                        <div className={`flex department-name ${isUpperHierarchy ? "blue3--text" : "green--text"}`}>
                                          <div className="text-center">{isUpperHierarchy ? ">" : ""}</div>
                                          <div title={pCol}>{pCol}</div>
                                          <div className="text-center">{!isUpperHierarchy ? "<" : ""}</div>
                                        </div>
                                      </td>
                                    }
                                  })}
                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">組織情報が未登録です。</span>
                      </div>
                    )
                  }
                  </div>
                  <div className="content-footer mt-16 mb-8">
                    <div className="border-t border-blue1" />
                    {/*  ===== フッターボタン ===== */}
                    <div className="footer_button_area mt-8">
                      <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                        <button type="button" className="btn footer-btn button_back">キャンセル</button>
                      </Link>
                      {orgHierarchyInfoList.length !== 0 &&
                        <SpinnerButton name="登録" className="btn footer-btn button_base" onClick={() => updateHierarchy()} />
                      }
                    </div>
                    {/*  ===== /フッターボタン ===== */}
                  </div>
                </div>
              </div>
              {/*  ===== 関連ダッシュボード ===== */}
              <RelatedDashboard dashboards={master.RelatedDashboard} authority={props.authority} mode={props.mode} />
            </div>
          </div>
        </section>
      </main>
    </React.Fragment >
  )
}

const HierarchyInfo = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Department.DepartmentHierarchy_Set) {
    return <Forbidden />
  }

  return <HierarchyInfoContent
    userId={state.user.sub}
    userName={state.user.userName}
    authority={state.authority}
    mode={state.analysis.mode}
    pageTitle={titleName.hierarchyInfo + titleName.common}
    corporationNumber={state.company.corporationNumber}
    bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
    navigate={navigate}
    showModal={showModal}
  />;
};

export default HierarchyInfo;
