import { PathInfoList } from "../../_constants/PathInfoList";
import { restApiKey } from "../../_constants/Code";
import { httpGet, httpPost, httpPut } from '../../services/api/AmplifyApi'


export const getDynamoDB = async (table, indexName, corporationNumber, key, filter) => {
  let result = "";
  try {
    let params = {
      table: table,
    };
    if (indexName != undefined && indexName != null) {
      params.indexName = indexName;
    };
    // JSON形式のデータはhttpGetで処理を行うとエラーになるため、JSON文字列に変換する。
    if (key != undefined && key != null) {
      params.key = JSON.stringify(key);
    };
    if (filter != undefined && filter != null) {
      params.filter = JSON.stringify(filter);
    };

    const init = {
      headers: {},
      queryStringParameters: params,
    };
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getDynamodb(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

// KPIマスタ情報取得専用
export const getKpi = async (corporationNumber, key, filter) => {
  let result = "";
  try {
    let params = {};
    // JSON形式のデータはhttpGetで処理を行うとエラーになるため、JSON文字列に変換する。
    if (key != undefined && key != null) {
      params.key = JSON.stringify(key);
    };
    if (filter != undefined && filter != null) {
      params.filter = JSON.stringify(filter);
    };

    let init = {
      headers: {},
      queryStringParameters: params,
    };
    if (Object.keys(params).length) {
      init.queryStringParameters = params;
    }
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getKpi(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const getDataCreateTask = async (corporationNumber, searchKey) => {
  let result = "";
  try {
    let params = {
      searchKey: searchKey
    };

    let init = {
      headers: {},
      queryStringParameters: params,
    };
    if (Object.keys(params).length) {
      init.queryStringParameters = params;
    }
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getDataCreateTask(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    throw new Error(err);
  }
}

// ファイルアップロードタスク管理専用
export const getFileUploadTask = async (indexName, corporationNumber, key, filter) => {
  let result = "";
  try {
    let params = {};
    if (indexName != undefined && indexName != null) {
      params.indexName = indexName;
    };
    // JSON形式のデータはhttpGetで処理を行うとエラーになるため、JSON文字列に変換する。
    if (key != undefined && key != null) {
      params.key = JSON.stringify(key);
    };
    if (filter != undefined && filter != null) {
      params.filter = JSON.stringify(filter);
    };

    const init = {
      headers: {},
      queryStringParameters: params,
    };
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.fileUploadTask(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    console.log(err)
    throw new Error(err);
  }
};

// 財務アップロードタスク管理取得
export const getAccountingUploadTask = async (corporationNumber, superKey, key) => {
  let result = "";
  try {

    let init = {
      headers: {},
      queryStringParameters: {
        superKey: superKey,
      },
    };
    if (key) {
      init.queryStringParameters["key"] = key
    }
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.accountingUploadTask(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    throw new Error(err);
  }
}

// グループ会社取得
export const getGroupCompany = async (corporationNumber) => {
  let result = "";
  try {

    let init = {
      headers: {},
    };
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getGroupCompany(corporationNumber), init)
      .then((res) => {
        result = res;
      });
    return result;
  } catch (err) {
    throw new Error(err);
  }
}

// 組織階層マスタ―更新
export const updateDepartmentHierarchy = async (corporationNumber, userName, masterId, fileName, fileData) => {
  let result = "";
  try {
    const init = {
      body: {
        corporation_number: corporationNumber,
        master_id: masterId,
        user_name: userName,
        file_name: fileName,
        file_data: fileData,
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateDepartmentHierarchy(corporationNumber), init)
      .then((res) => {
        result = res;
      });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

// 組織第一階層の経年紐づけ設定マスタ―更新
export const updateDepartmentAssociation = async (corporationNumber, userName, masterId, fileData) => {
  let result = "";
  try {
    const init = {
      body: {
        corporation_number: corporationNumber,
        master_id: masterId,
        user_name: userName,
        file_data: fileData,
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateDepartmentAssociation(corporationNumber), init)
      .then((res) => {
        result = res;
      });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const registDynamoDB = async (table, pk, corporationNumber, records, userSub) => {
  let result = "";
  try {
    const init = {
      body: {
        request: {
          table: table,
          pk: pk,
          records: records,
          user_sub: userSub,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.registDynamodb(corporationNumber), init)
      .then((res) => {
        result = res;
      });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateDynamoDB = async (table, pk, corporationNumber, key, item, userSub) => {
  let result = "";
  try {
    const init = {
      body: {
        request: {
          table: table,
          pk: pk,
          key: key,
          item: item,
          user_sub: userSub,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateDynamodb(corporationNumber), init)
      .then((res) => {
        result = res;
      });
  } catch (err) {
    throw new Error(err);
  }
  return result;
};

export const deleteDynamoDB = async (table, key, corporationNumber, userSub) => {
  let result = "";
  try {
    const init = {
      body: {
        request: {
          table: table,
          key: key,
          user_sub: userSub,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.deleteDynamodb(corporationNumber), init)
      .then((res) => {
        result = res;
      });
  } catch (err) {
    throw new Error(err);
  }
  return result;
};

export const nextSequence = async (table, corporationNumber, userSub) => {
  let result = "";
  try {
    const init = {
      body: {
        request: {
          corporationNumber: corporationNumber,
          table: table,
          user_sub: userSub,
        },
      },
      headers: {},
    };
    await httpPut(restApiKey.CHROFY_REST_API, PathInfoList.Resource.nextSequene(corporationNumber), init)
      .then((res) => {
        result = res;
      });
  } catch (err) {
    throw new Error(err);
  }
  return result;
}
