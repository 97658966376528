'use strict'
import * as React from 'react'
import { Link } from '@mui/material'
import { mainPageInfoList, monitoringInfoList } from '../../../_constants/PageInfoList'
import { analysisMode } from '../../../_constants/Code'
import { getPageText } from '../../../common/utils/FileUtils'

import { useLocation } from 'react-router-dom'

const RelatedDashboardContent = (props) => {
  const [pageText, setPageText] = React.useState({})
  const [dashboards, setDashboards] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  const init = async () => {
    // ダッシュボード情報取得
    const pageText = await getPageText()
    setPageText(pageText)

    // ダッシュボードIDをソート
    setDashboards(props.dashboards.sort((a, b) => dashboardSort(a, b)))

    setIsLoaded(true)
  }

  React.useEffect(() => {
    init()
  }, [])

  const dashboardSort = (a, b) => {
    // ダッシュボードIDを分割して配列にする
    const aParts = a.split('.').map((part) => (part.startsWith('S') ? `0.${part.substring(1)}` : `1.${part}`))
    const bParts = b.split('.').map((part) => (part.startsWith('S') ? `0.${part.substring(1)}` : `1.${part}`))

    // 各部分を比較してソートする
    for (let i = 0; i < aParts.length; i++) {
      const aNum = parseFloat(aParts[i])
      const bNum = parseFloat(bParts[i])
      if (aNum > bNum) return 1
      if (aNum < bNum) return -1
    }
    return 0
  }

  const pageInfoList = mainPageInfoList.concat(monitoringInfoList)

  const getRelDashboardInfo = (key) => {
    const largeKey = key.split('.')[0] // 大見出しのキー
    const mIdx = key.split('.')[1] - 1 // 中見出しの要素
    const sIdx = key.split('.')[2] - 1 // 小見出しの要素
    const pageInfo = pageInfoList.find((page) => {
      return page.key === largeKey
    })
    const url = pageInfo.url + mIdx + '/' + sIdx
    const dashboardInfo = pageInfo.dashboardList[mIdx].dashboard[sIdx]
    const pt = pageText[largeKey]
    const name = pt.dashboardList[mIdx].dashboard[sIdx].name + (dashboardInfo.quarterFlg ? ' [Y/Q]' : '')
    let availability = false
    // リリース済みか判定
    if (props.mode === analysisMode.standalone ? dashboardInfo.activeFlg : dashboardInfo.groupFlg) {
      // 参照権限があるか判定
      if (props.authority.Dashboard_AuthInfo.Availability) {
        // ダッシュボードへの参照権限がある
        let authConf = props.authority.Dashboard_AuthInfo.Auth_Conf.find((auth) => auth.Key === largeKey)
        if (authConf?.Availability) {
          // 大見出しレベルで参照権限がある
          const mDashboardId = largeKey + '.' + String(mIdx + 1)
          const dashboardList = authConf.DashboardList.find((d) => d.Key === mDashboardId)
          if (dashboardList?.Availability) {
            // 中見出しレベルで参照権限がある
            const sDashboardId = mDashboardId + '.' + (sIdx + 1)
            const authDashboard = dashboardList.Dashboard.find((d) => d === sDashboardId)
            if (authDashboard) {
              // 小見出しレベルで参照権限がある
              availability = true
            }
          }
        }
      }
    }
    return { name: name, url: url, img: dashboardInfo.img, availability: availability }
  }

  const location = useLocation()
  /**
   *  @param {string} text
   *  @returns {boolean}
   */
  const checkIfMasterSetting = (text) => {
    const masterString = '/master'
    return text.includes(masterString)
  }

  const relatedDashboardTitle = checkIfMasterSetting(location.pathname) ? '適用対象' : '関連ダッシュボード'

  if (isLoaded) {
    return (
      <React.Fragment>
        <div
          className="box-xy-bordered rounded-t-2xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1"
          style={{ borderTop: 'none' }}
        >
          <h2 className="text-h3 text-center mb-6">{relatedDashboardTitle}</h2>
          <ul className="nav-graph flex flex-wrap justify-center pb-0.5 pb-px mx-32">
            {dashboards.map((item, index) => {
              let relDashboardInfo = getRelDashboardInfo(item)
              return (
                <li key={index} className={`nav-graph__item ${!relDashboardInfo.availability ? 'is-disabled' : ''}`}>
                  <button type="button" className="nav-graph__btn text-h6 px-2 py-1.5 mx-4 my-1">
                    {(() => {
                      const label = <span className="nav-graph__text">{relDashboardInfo.name}</span>
                      if (relDashboardInfo.availability) {
                        return (
                          <React.Fragment>
                            <Link underline="none" href={relDashboardInfo.url} target="_blank">
                              {label}
                            </Link>
                            <span className="nav-graph__preview">
                              <img src={relDashboardInfo.img} alt="" />
                            </span>
                          </React.Fragment>
                        )
                      } else {
                        return label
                      }
                    })()}
                  </button>
                </li>
              )
            })}
          </ul>
          {/*  ===== プレビュー画像 ===== */}
          {props.image && (
            <figure className="flex flex-wrap justify-center">
              <img src={props.image} alt="" className="w-[700px] h-auto my-6" />
            </figure>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default (props) => <RelatedDashboardContent {...props} />
