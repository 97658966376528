// パスパラメータ用のテンプレートリテラルを返却する
const getTemplate = raw =>{
  const rawObj ={raw:raw};
  return (...prm)=>String.raw( rawObj , ...prm);
}

export const PathInfoList = {
  StaticPage : [
    // 利用規約
    {
      id : 1,
      name : "TOS",
      pageTile : "利用規約",
    },
    // プライバシーポリシー
    {
      id : 2,
      name : "PrivacyPolicy",
      pageTile : "プライバシーポリシー",
    },
  ],
  Objectkeyname : {
    // ご利用ガイド
    guide: "ご利用ガイド.pdf",
    // news
    news: "news.csv",
    // notice
    notice: "notice.csv",
    // uploadGuide
    uploadGuide: "アップロードガイド.pdf",
    // configFile
    configFile: "config.json",
    // dashboardFile
    dashboardFile:  "dashboard.json",
    // textSettingFile
    textSettingFile:  "setting.json",
    // uploadTaskConfigFile
    uploadTaskConfigFile: "upload_task_config.yaml",
    // dashboardGuide
    dashboardGuide: "【参照権限設定参考資料】ダッシュボード一覧.xlsx",
    // dataFileUploadGuide
    dataFileUploadGuide: "アップロードガイド.pdf",
    // benchmarkCompany
    benchmarkCompany: "company.csv",
    // recruitmentPlan
    recruitmentPlan: "recruitment_plan.csv",
    // absenceRate
    absenceRate: "absence_rate.csv",
    // departmentNameMapping
    departmentNameMapping: "department_name_mapping.csv",
  },
  Folder : {
    // uploadFolder
    uploadFolder: "web/uploadFile/",
    // uploadGuideFolder
    uploadGuideFolder: "web/uploadFileGuide/",
    // dashboardFolder
    dashboardFolder: "web/config/",
    // guideFolder
    guideFolder: "guide/",
    // newsFolder
    newsFolder: "news/",
    // noticeFolder
    noticeFolder: "notice/",
    // textFolder
    textFolder: "text/",
    // configFolder
    configFolder: "config/",
    // dataCreationFolder
    dataCreationFolder: "config/data-creation-task/",
    // benchmarkCompanyFolder
    benchmarkCompanyFolder: "company/",
    // recruitmentPlanFolder
    recruitmentPlanFolder: "etl-dataset/recruitment-plan/",
    // absenceRateFolder
    absenceRateFolder: "qs-dataset/input/absence-rate/",
    // departmentCsv
    departmentCsv: "standard-format-creation/table/master/department/csv/",
    // departmentCsvDrilldown
    departmentCsvDrilldown: "standard-format-creation/table/master/department/csv_drilldown/",
    // departmentNameMapping
    departmentNameMapping: "qs-dataset/input/department-name-mapping/",
  },
  Resource : {
    // マルチテナント環境参照
    // year_list
    // yearList: "/year_list",
    // kpi
    getKpi: getTemplate`/${null}/kpi`,
    // file_list
    fileList: getTemplate`/${null}/file_list`,
    // file_name_list
    fileNameList: getTemplate`/${null}/file_name_list`,
    // quick_sight_embed_url
    quickSightEmbedUrl: getTemplate`/${null}/quick_sight_embed_url`,
    // download_object
    downloadObject: getTemplate`/${null}/s3_object`,
    // json_object
    jsonObject: getTemplate`/${null}/s3_json`,
    // list
    // s3Select: getTemplate`/${null}/s3_select`,
    // moveToCorporationBucket
    moveToCorporationBucket: getTemplate`/${null}/s3_move_to_corporation_bucket`,
    // getDynamodb
    getDynamodb: getTemplate`/${null}/dynamodb_get`,
    // registDynamodb
    registDynamodb: getTemplate`/${null}/dynamodb_regist`,
    // updateDynamodb
    updateDynamodb: getTemplate`/${null}/dynamodb_update`,
    // deleteDynamodb
    deleteDynamodb: getTemplate`/${null}/dynamodb_delete`,
    // nextSequene
    nextSequene: getTemplate`/${null}/sequence_next`,
    // getLatestDataSet
    latestDataSet: getTemplate`/${null}/latest_data_set`,
    // uploadFile
    uploadFile: getTemplate`/${null}/upload_file`,
    // getFileUploadTask
    fileUploadTask: getTemplate`/${null}/file_upload_task`,
    // uploadAccountingile
    uploadAccountingFile: getTemplate`/${null}/upload_accounting_file`,
    // getFileUploadTask
    accountingUploadTask: getTemplate`/${null}/accounting_upload_task`,
    // dataCreate
    dataCreate: getTemplate`/${null}/create_data`,
    // dataFix
    dataFix: getTemplate`/${null}/fix_data`,
    // dataFixCancel
    dataFixCancel: getTemplate`/${null}/fix_cancel_data`,
    // getUserList
    getUserList: getTemplate`/${null}/get_user_list`,
    // getEmail
    getEmail: getTemplate`/${null}/get_email`,
    // getDataCreateTask
    getDataCreateTask: getTemplate`/${null}/datacreatetask_get`,
    // getGroupCompany
    getGroupCompany: getTemplate`/${null}/get_group_company`,
    // getBenchmarkCompany
    getBenchmarkCompany: getTemplate`/${null}/get_benchmark_company`,
    // getIndustrySector
    getIndustrySector: getTemplate`/${null}/get_industry_sector`,
    // getNewGraduate
    getNewGraduate: getTemplate`/${null}/get_new_graduate`,
    // updateNewGraduate
    updateNewGraduate: getTemplate`/${null}/update_new_graduate`,
    // updateRecruitmentPlan
    updateRecruitmentPlan: getTemplate`/${null}/update_recruitment_plan`,
    // updateAbsenceRate
    updateAbsenceRate: getTemplate`/${null}/update_absence_rate`,
    // updateDepartmentHierarchy
    updateDepartmentHierarchy: getTemplate`/${null}/update_department_hierarchy`,
    // updateDepartmentAssociation
    updateDepartmentAssociation: getTemplate`/${null}/update_department_association`,
    // CHROFY環境参照
    // get_object
    getObject: `/s3_csv`,
    // download_object
    downloadCommonObject: `/s3_object`,
  },
}
