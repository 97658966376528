"use strict";
import * as React from "react";
import { SpinnerButton } from "../pages/common/CustomButton"

export const ModalContext = React.createContext(() => { });
ModalContext.displayName = 'ModalContext';

export const useModal = () => {
  return React.useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [body, setBody] = React.useState();
  const [button, setButton] = React.useState([]);
  const [cancelName, setCancelName] = React.useState();
  const [isCancel, setIsCancel] = React.useState();
  const [wide, setWide] = React.useState();

  const showModal = ({ title, description, body, button, cancelName = "キャンセル", isCancel = true, isWide = true }) => {
    setTitle(title);
    setDescription(description);
    setBody(body);
    if (button) {
      setButton(button);
    } else {
      setButton([]);
    }
    setCancelName(cancelName);
    setIsCancel(isCancel);
    setWide(isWide);
    // モーダル表示
    _openUpModal(isWide);
  }

  const _openUpModal = (isWide) => {

    const modalBg = document.querySelector(".bg-modal-window");
    modalBg?.classList.add("is-active");
    const modalUploading = document.querySelector("#modal-context-modal-window--upload-confirm");
    if (!modalUploading?.classList.contains("animate__fadeIn")) {
      modalUploading?.classList.remove("animate__fadeOut");
      modalUploading?.classList.add("animate__fadeIn");
    }
    modalUploading?.classList.remove("animate__fadeOut");
    modalUploading?.classList.add("animate__fadeIn");
    if (isWide) {
      modalUploading?.classList.add("modal-window__wide");
    }
    modalUploading?.classList.add("is-active");
  }

  const _closeUpModal = () => {
    const modalUploading = document.querySelector("#modal-context-modal-window--upload-confirm");
    if (!modalUploading?.classList.contains("animate__fadeOut")) {
      modalUploading?.classList.remove("animate__fadeIn");
      modalUploading?.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document.querySelector(".bg-modal-window.is-active")?.classList.remove("is-active");
      document.querySelector(".modal-window.is-active")?.classList.remove("is-active");
      modalUploading?.classList.remove("modal-window__wide");
    }, fadeOutTimeoutSec);
  };

  return (
    <ModalContext.Provider value={showModal}>
      {children}
      <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50"></div>
      <div id="modal-context-modal-window--upload-confirm" className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-text text-h3 font-medium">
              {title}
            </span>
          </div>
          <div className="text-body text-caption-2 text-center grey--text text--lighten-70 pt-4">
            {description}
          </div>
          <button
            type="button"
            className="modal-window__btn-close absolute top-5 right-5"
            onClick={() => _closeUpModal()}>
            <span className="icon-close"></span>
          </button>
        </div>

        <div className="modal-window__content px-8 py-5">
          {body && (
            wide ? (
              <div className="modal-window__upload-result box-xy-bordered rounded-xl px-10 pt-4 pb-14 mx-auto overflow-y-auto">
                {body}
              </div>
            ) : (
              <div>
                {body}
              </div>
            )
          )}
          <div className="modal-content-footer mt-8">
            <div className="footer_button_area gap-x-16">
              {isCancel && <button className="footer-btn button_back"
                onClick={() => _closeUpModal()}>{cancelName} </button>}
              {button.map((item, idx) => {
                return (
                  <SpinnerButton key={idx} name={item.name} className={`${item.className}`}
                    onClick={async () => {
                      try {
                        await item.click();
                      } finally {
                        _closeUpModal();
                      }
                    }} />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  )
}