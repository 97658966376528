"use strict";
import * as React from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import { errorLog } from "../../../common/logger/Logger";
import { httpPost } from '../../../services/api/AmplifyApi'
import { getDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { getObject, getCsvByJson, getFileNameList } from "../../../common/utils/FileUtils";
import { formatCommaDigit, parseCommaDigit } from "../../../common/utils/NumberUtils";
import { viewId, titleName, orderBy, table, restApiKey, masterClassification } from "../../../_constants/Code";
import { PathInfoList } from "../../../_constants/PathInfoList";
import { useModal } from "../../../_components/Modal"
import Forbidden from "../../../_components/Forbidden";
import RelatedDashboard from "../parts/RelatedDashboard";

const AbsenceRateInfoContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [scrollInitialized, setScrollInitialized] = React.useState(false); // スクロール初期化用のフラグ
  const [master, setMaster] = React.useState([]);
  const [absenceRate, setAbsenceRate] = React.useState([]);
  const [inputError, setInputError] = React.useState({});
  const [serverError, setServerError] = React.useState({});

  React.useEffect(() => {
    document.title = props.pageTitle;
    init();
  }, []);

  const getMaster = async () => {
    try {
      let master = await getDynamoDB(
        table.Master,
        null,
        props.corporationNumber,
        {"CorporationNumber": props.corporationNumber, "MasterId": viewId.absenceRateInfo},
        null
      );
      return master[0];
    } catch (error) {
      errorLog("E000001", props.userId, viewId.absenceRateInfo, "getMaster", error);
    }
  }

  const getStdFmt = async () => {
    try {
      const baseKey = process.env.REACT_APP_FOLDER_DATA_SET_BASE;
      let fileKey = process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL;
      let fileList = await getFileNameList(props.bucketName, baseKey + fileKey, orderBy.asc);
      if (fileList.length) {
        // 最初のアンダースコアより前が数値のものを取り出す
        fileList = fileList
          .map((item) => {
            return item.split("_")[0];
          })
          .filter((item) => {
            return !isNaN(item);
          });
        // もっとも古いものを返却する
        return fileList[0];
      } else {
        return null;
      }
    } catch (error) {
      errorLog("E000001", props.userId, props.pageId, "getStdFmt", error);
    }
  };

  const getPeriodYm = (period, year) => {
    return (period !== "12月" ? (parseInt(year.slice(0, 4)) + 1) + year.slice(4, 5) : year.slice(0, -1)) + period + "期"
  }

  const init = async () => {
    let list = [];

    // マスター情報取得
    let master = await getMaster();
    setMaster(master);

    // ダッシュボード情報取得
    // 子コンポーネントで実行する

    // 高欠勤率情報取得
    let fileData = await getObject(props.bucketName, PathInfoList.Folder.absenceRateFolder + PathInfoList.Objectkeyname.absenceRate);
    fileData = new File([fileData], PathInfoList.Objectkeyname.absenceRate, { type: "text/csv" });
    const jsonData = await getCsvByJson(fileData); // blobからjsonに変換

    // 標準フォーマット（年次）のファイル名取得
    const stdFmt = await getStdFmt();

    // 決算期開始年度判定
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    // 決算期から現在進行年度を求める
    const year = (props.accPeriod !== "12月" && m <= props.accPeriod.slice(0, -1)) ? y - 1 : y;
    let s, e = year + 6; // s:開始年度、 e:終了年度
    if (stdFmt !== null) {
      // 標準フォーマットがあれば最古の年度 ～ 現在進行年度+5年度分
      s = stdFmt;
    } else {
      // 欠勤率があれば、最古の欠勤率の年度 ～ 現在進行年度+5年度分
      if (jsonData.length) {
        s = jsonData.map(item => parseInt(item.年度.replace('年度', '')))
                    .reduce((oldest, current) => Math.min(oldest, current));
      } else {
        // 標準フォーマットがない、かつ欠勤率もなければ現在進行年度 ～ +5年度分
        s = year;
      }
    }
    // 高欠勤率の値を設定する
    for (let i = s; i < e; i++) {
      const year = (i) + "年度";
      const data = jsonData.find(j => j["年度"] === year);
      const absenceRate = data?.["高欠勤率"] ? data["高欠勤率"] : "";
      list.push({"年度": year, "高欠勤率": absenceRate});
    }
    setAbsenceRate(list);

    setIsLoaded(true);
  };

  /**
   * スクロールバー設定
   */
  const setScrollBar = () => {

      // ＞ボタンにdisabledをつける
      const btnScrollRights = document.querySelectorAll(".setting-table__btn-scroll-right");
      btnScrollRights.forEach((v) => {
        v.setAttribute('disabled', '');
      });
      // スクロールイベント登録
      document.querySelectorAll('.setting-table__scroll').forEach(function (element) {
        if (element.classList.contains("is-scroll")) {
          return;
        }
        element.addEventListener('scroll', (event) => {
          // 要素の取得
          const $parent = event.target.closest('.setting-table');
          const $tableWrapper = $parent.querySelector('.setting-table__scroll');
          const $btnScrollLeft = $parent.querySelector('.setting-table__btn-scroll-left');
          const $btnScrollRight = $parent.querySelector('.setting-table__btn-scroll-right');
          const isScrollEnd = $tableWrapper.scrollWidth - ($tableWrapper.scrollLeft + $tableWrapper.clientWidth) < 1;

          // ＜＞ボタンのdisabledの付け替え
          if ($tableWrapper.scrollLeft === 0) {
            // スクロール位置の左端
            if (!$btnScrollLeft.hasAttribute('disabled')) {
              $btnScrollLeft.setAttribute('disabled', '');
            }
          } else if ($tableWrapper.scrollLeft > 0 && !isScrollEnd) {
            // スクロール位置の中間
            if ($btnScrollLeft.hasAttribute('disabled')) {
              $btnScrollLeft.removeAttribute('disabled');
            }
            if ($btnScrollRight.hasAttribute('disabled')) {
              $btnScrollRight.removeAttribute('disabled');
            }
          } else if (isScrollEnd) {
            // スクロール位置の右端
            if (!$btnScrollRight.hasAttribute('disabled')) {
              $btnScrollRight.setAttribute('disabled', '');
            }
          }
        });
        element.classList.add("is-scroll");

        // 初期状態のチェック
        const $parent = element.closest('.setting-table');
        const $tableWrapper = $parent.querySelector('.setting-table__scroll');
        const $btnScrollLeft = $parent.querySelector('.setting-table__btn-scroll-left');
        const $btnScrollRight = $parent.querySelector('.setting-table__btn-scroll-right');
        if ($tableWrapper.scrollWidth <= $tableWrapper.clientWidth) {
          $btnScrollLeft.setAttribute('disabled', '');
          $btnScrollRight.setAttribute('disabled', '');
        }
      });
  }

  // 初期表示時、＞ボタンにdisabledをつける
  React.useEffect(() => {
    if (isLoaded && !scrollInitialized) {
      setScrollBar();
      setScrollInitialized(true); // スクロール位置の初期化が完了したことを示すフラグを設定
    }
  }, [isLoaded]);

  // スクロール位置設定
  React.useEffect(() => {
    if (isLoaded && scrollInitialized) {
      // スクロール位置を再右に設定
      const scrollTables = document.querySelectorAll('.setting-table__scroll');
      scrollTables.forEach((element) => {
        element.scrollLeft = element.scrollWidth;
      });
    }
  }, [isLoaded, scrollInitialized, props.location.pathname]);

  /**
   * 欠勤率表スクロール 左
   * @param {イベント} e 
   */
  const scrollLeft = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.setting-table__scroll');
    $tableWrapper.scrollBy({
      left: -130,
      behavior: "smooth",
    });
  }

  /**
   * 欠勤率表スクロール →
   * @param {イベント} e 
   */
  const scrollRight = (e) => {
    const $parent = e.target.closest('.setting-table');
    const $tableWrapper = $parent.querySelector('.setting-table__scroll');
    $tableWrapper.scrollBy({
      left: 130,
      behavior: "smooth",
    });
  }

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError.errorMessage || serverError.errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [inputError, serverError]);

  /**
   * 欠勤率の値を変更する
   * @param {イベント} e
   * @param {年度} year
   */
  const changeAbsenceRate = (e, year) => {
    let list = absenceRate.map((ar) => {
      if (ar["年度"] == year) {
        ar["高欠勤率"] = e.target.value;
      }
      return ar;
    });
    setAbsenceRate(list);
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    let inputError = {};
    try {
      const list = JSON.parse(JSON.stringify(absenceRate));
      list.forEach(ar => {
        const error = {}
        const rate = parseCommaDigit(ar["高欠勤率"]);
        // -- 数値範囲 --
        if (parseFloat(rate) < 0 || 100 < parseFloat(rate)) {
          error.numRange = getPeriodYm(props.accPeriod, ar["年度"]) + "の欠勤率は0～100の範囲で入力してください"
        }
        // エラー判定
        if (Object.keys(error).length === 0) {
          return false;
        } else {
          inputError[ar["年度"]] = error;
          inputError.errorMessage = '入力内容に誤りがあります。'
          return true;
        }
      });
      return Object.keys(inputError).length > 0;
    } finally {
      // 入力エラーを設定
      setInputError(inputError);
    }
  }

  /**
   * 更新処理
   */
  const updateAbsenceRate = async () => {

    if (_checkInput()) {
      return;
    }

    try {
      const title = 'マスター設定値登録の確認';
      const description = <React.Fragment>
        <div className="text-center">
          設定値を登録してダッシュボードに反映します。よろしいですか？
        </div>
        <div className="pt-5 text-center">
          ※ダッシュボードへの反映には5分～10分程度かかります<br />
          マスター管理画面のステータスから反映状況を確認してください
        </div>
      </React.Fragment>;
      const button = [{
        name: '実行',
        className: 'footer-btn button_base',
        click: async () => {
          try {
            setServerError({});
            // カンマを除去
            const list = JSON.parse(JSON.stringify(absenceRate));
            list.forEach(ar => {
              ar["高欠勤率"] = parseCommaDigit(ar["高欠勤率"]);
            });

            const init = {
              body: {
                request: {
                  corporation_number: props.corporationNumber,
                  corporation_name: props.corporationName,
                  user_name: props.userName,
                  file_data: list,
                  user_sub: props.userId,
                },
              },
              headers: {},
            };
            // IF02042_欠勤率更新処理
            await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateAbsenceRate(props.corporationNumber), init)
              .then((res) => {
                if (res.message.indexOf('Success') !== -1) {
                  props.navigate('/master/masterList', { state: { message: 'データ反映を開始しました。' } });
                } else {
                  setServerError({errorMessage: '更新処理でエラーが発生しました。'});
                }
              });
          } catch (error) {
            errorLog("E000001", props.userId, viewId.absenceRateInfo, "updateAbsenceRate", error);
          }
        }
      }];
      props.showModal({ title: title, description: description, button: button, isWide: true });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.absenceRateInfo, "updateAbsenceRate", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            <div className="page-body">
              <div>
                <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                <span style={{ color: "red" }}>{serverError.errorMessage}</span>
              </div>
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">欠勤率の設定</h2>
                <div className="flex items-center justify-between mt-6">
                  <div className="text-body-2-medium grow">
                    <div>■年度ごとの高欠勤率閾値設定</div>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-5">
                  <div>
                    高欠勤と判定するための閾値を設定してください。
                  </div>
                </div>
                <div className="setting-table relative is-absence-rate">
                  {/*  左にスクロールするボタン
                    非活性時はdisabled属性を追加
                  */}
                  <button type="button" className="setting-table__btn-scroll-left button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollLeft(e)}>
                    <span className="icon-arrow white--text rotate-180"></span>
                  </button>
                  {/*  右にスクロールするボタン
                    非活性時はdisabled属性を追加
                  */}
                  <button type="button" className="setting-table__btn-scroll-right button w-8 h-8 flex items-center justify-center absolute" onClick={(e) => scrollRight(e)}>
                    <span className="icon-arrow white--text"></span>
                  </button>
                  {/*  グラデーション1 */}
                  <div className="setting-table__gradation--left"></div>
                  {/*  グラデーション2 */}
                  <div className="setting-table__gradation--right"></div>
                  {/*  表組み */}
                  <div className="setting-table__scroll overflow-x-auto">
                    <table className="setting-table__table w-full form-control">
                      <thead>
                        <tr>
                          <th className="sticky top-0 left-0 z-10"></th>
                          {absenceRate.map((ar, idx) => {
                            // 決算期が12月　→　年度の末尾1文字削除 + "月期"（2021年度 → 2021年12月期）
                            // 決算期が12月以外　→　年度の一年後 + "年" + "月期"（2021年度 → 2022年3月期）
                            return <th key={idx}>{getPeriodYm(props.accPeriod, ar["年度"])}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="sticky left-0 z-10 setting-table__col-name">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__name">
                                高欠勤率（%）
                              </div>
                            </div>
                          </th>
                          {absenceRate.map((ar, idx) => {
                            return (
                              <td key={idx}>
                                <input
                                  className={`text-input format-digit ${inputError[ar["年度"]]?.numRange ? "is-error" : ""}`}
                                  type="text"
                                  name={"text_" + idx}
                                  placeholder="数値を入力"
                                  value={ar["高欠勤率"]}
                                  onChange={(e) => changeAbsenceRate(e, ar["年度"])}
                                  onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                  onBlur={(e) => {
                                    e.target.value = formatCommaDigit(e.target.value, 1);
                                    changeAbsenceRate(e, ar["年度"]);
                                  }}
                                />
                              </td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* エラーメッセージ表示 */}
                {Object.keys(inputError).filter(key => key !== "errorMessage").map((key, idx) => (
                  <span key={key} className="red--text">{idx != 0 && <br />}{inputError[key].numRange}</span>
                ))}
                <div className="content-footer mt-16 mb-8">
                  <div className="border-t border-blue1" />
                  {/*  ===== フッターボタン ===== */}
                  <div className="footer_button_area mt-8">
                    <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                      <button type="button" className="footer-btn button_back">キャンセル</button>
                    </Link>
                    <button className="btn footer-btn button_base" onClick={() => updateAbsenceRate()}>
                      <div>登録</div>
                    </button>
                  </div>
                </div>
              </div>
              {/*  ===== 関連ダッシュボード ===== */}
              <RelatedDashboard dashboards={master.RelatedDashboard} authority={props.authority} mode={props.mode} />
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

const AbsenceRateInfo = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();
  const location = useLocation();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Dashboard.AbsenceRate_Set) {
    return <Forbidden />
  }

  return <AbsenceRateInfoContent
    pageTitle={titleName.absenceRateInfo + titleName.common}
    corporationNumber={state.company.corporationNumber}
    corporationName={state.company.name}
    bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
    accPeriod={state.company.accPeriod}
    userId={state.user.sub}
    userName={state.user.userName}
    authority={state.authority}
    mode={state.analysis.mode}
    navigate={navigate}
    showModal={showModal}
    location={location}
  />;
};

export default AbsenceRateInfo;
