"use strict";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { Link, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material";
import { DndContext, DragOverlay, useDroppable, useDraggable, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import LeaderLine from "leader-line-new";
import { PathInfoList } from "../../../_constants/PathInfoList";
import { SpinnerButton } from "../../common/CustomButton"
import { getDynamoDB, updateDepartmentAssociation, updateDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { viewId, titleName, table, assosiationMarks, masterClassification } from "../../../_constants/Code";
import { errorLog } from "../../../common/logger/Logger";
import { getObject, readCsvFile } from "../../../common/utils/FileUtils";
import { formatDate, convertDateToJapaneseFormat } from "../../../common/utils/DateUtils";
import Forbidden from "../../../_components/Forbidden";
import { useModal } from "../../../_components/Modal";
import RelatedDashboard from "../parts/RelatedDashboard";

// ヘッダー項目
const header = "所属組織1（テーブル用）";

const AssociationInfoContent = (props) => {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [scrollInitialized, setScrollInitialized] = React.useState(false); // スクロール初期化用のフラグ
  const [master, setMaster] = React.useState([]);
  const [associationList, setAssociationList] = React.useState([]);
  // 線
  const [lineList, setLineList] = React.useState([]);
  const [hasLineList, setHasLineList] = React.useState({ "fromIdx": [], "toIdx": [] });
  // モーダルのマーク選択情報
  const [markInfo, setMarkInfo] = React.useState({});

  const [dragContent, setDragContent] = React.useState();

  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    init();
  }, []);

  /**
   * 初期表示
   */
  const init = async () => {

    const masterData = await getDynamoDB(table.Master, null, props.corporationNumber, { "CorporationNumber": props.corporationNumber, "MasterId": viewId.associationInfo });
    setMaster(masterData[0]);
    const dynamoDBData = await getDynamoDB(table.DepartmentHierarchy, null, props.corporationNumber);
    dynamoDBData.sort((a, b) => a.StartDate > b.StartDate ? 1 : -1);
    const list = [];
    const lineList = [];
    for (const [idx, data] of dynamoDBData.entries()) {
      // 組織階層マスタ
      const fileData = await getObject(props.bucketName, PathInfoList.Folder.departmentCsv + data.FileName);
      const file = new File([fileData], data.FileName, { type: "text/plain" });
      const master = await readCsvFile(file);
      // ヘッダーのインデックスを取得
      let headIdx;
      if (master.length > 0) {
        headIdx = master[0].findIndex(ele => ele === header);
      }
      // 組織階層マスタの重複する組織情報を削除する
      const uniqueMaster = [master[0]]; // ヘッダー行を追加
      const seen = new Set();
      for (const row of master.slice(1)) {
        if (!seen.has(row[0])) {
          uniqueMaster.push(row);
          seen.add(row[0]);
        }
      }
      // 画面項目にセット
      const tableData = {
        DepartmentHierarchy: data,
        title: convertDateToJapaneseFormat(data.StartDate) + " ～ " + (data.EndDate != "" ? convertDateToJapaneseFormat(data.EndDate) : ""),
        record: uniqueMaster.filter((m, index) => index !== 0 && m.length > headIdx).map((m, idx) => m[headIdx]),
      }
      list.push(tableData);
      // 紐づき情報をセット（描画はしない → isloaded=trueになったら描画する）
      data.LineInfo.forEach(d => {
        lineList.push({
          fromIdx: idx + "_" + d.fromIdx,
          toIdx: (idx + 1) + "_" + d.toIdx,
          leaderLine: null,
          handler: null,
        });
      })
    }

    setAssociationList(list);
    setLineList(lineList);
    setIsLoaded(true);
  }

  /**
   * 初期表示時に紐づけを描画する
   * Domを取得するため、一度画面描画が終わった後に実行する
   */
  React.useEffect(() => {
    const fetchData = async () => {
      const tmpLineList = [];
      const tmpHasLinelist = hasLineList;
      if (associationList.length > 1) {
        lineList.forEach(l => {
          // Dom取得
          const start = document.getElementById("association_" + l.fromIdx);
          const end = document.getElementById("association_" + l.toIdx);
          // ライン追加
          const line = _createLine(start, end);
          // スクロールイベントで再描画
          const handler = _setScrollEvent(line, start, end);

          tmpLineList.push({
            fromIdx: l.fromIdx,
            toIdx: l.toIdx,
            leaderLine: line,
            handler: handler,
          });

          tmpHasLinelist.fromIdx = [...new Set([...tmpHasLinelist.fromIdx, l.fromIdx])];
          tmpHasLinelist.toIdx = [...new Set([...tmpHasLinelist.toIdx, l.toIdx])];
        });
      }
      setLineList(tmpLineList);
      setHasLineList(tmpHasLinelist);
    };

    if (isLoaded && !scrollInitialized) {
      setScrollInitialized(true); // スクロール位置の初期化が完了したことを示すフラグを設定
    }
    setTimeout(fetchData, 200);  // Domを取得するため、時間差をつける
  }, [isLoaded]);

  /**
   * スクロール位置設定
   */
  React.useEffect(() => {
    if (isLoaded && scrollInitialized) {
      // スクロール位置を再右に設定
      const scrollTables = document.querySelectorAll('.association-table__scroll');
      scrollTables.forEach((element) => {
        element.scrollLeft = element.scrollWidth;
      });
    }
  }, [isLoaded, scrollInitialized, props.location.pathname]);


  /**
   * Draggableコンポーネント
   */
  const Draggable = (props) => {
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({ id: props.id, data: props.val });
    const style = transform ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
    } : undefined;
    return !isDragging && props.children !== null && props.children !== "" ? (
      <span ref={setNodeRef} className="draggable" style={style} {...listeners} {...attributes}>{props.children}</span>
    ) : (
      <span className="draggable"></span>
    )
  }

  /**
   * Droppableコンポーネント
   */
  const Droppable = (props) => {
    const { isOver, setNodeRef } = useDroppable({ id: props.id });
    return (
      <div className={`droppable ${isOver ? "isOver" : ""}`} ref={setNodeRef}> {props.children}</div>
    )
  }

  /**
   * ドラッグ開始処理
   */
  const handleDragStart = (e) => {
    setDragContent(e.active.data.current);
    const fromIdx = Number(e.active.id.split("_")[0]);
    const targetIdx = fromIdx + 1;
    // 同じドラッグ元から引いている線を抽出
    const writedLines = lineList.filter(line => line.fromIdx === e.active.id);
    // [統合]のマッピング元を特定する
    // `toIdx` でグループ化する
    let groupedByToIdx = lineList.reduce((acc, obj) => {
      // `toIdx` の値をキーとして使用
      if (!acc[obj.toIdx]) {
        acc[obj.toIdx] = [];
      }
      acc[obj.toIdx].push(obj.fromIdx);
      return acc;
    }, {});
    // 同一の `toIdx` を持つ `fromIdx` の値を一つの配列にまとめる
    let fromIdxList = [];
    for (let key in groupedByToIdx) {
      if (groupedByToIdx[key].length > 1) {
        fromIdxList = fromIdxList.concat(groupedByToIdx[key]);
      }
    }
    if (writedLines.length) {
    }
    // ドロップ可能エリアをハイライト
    document.querySelectorAll(".association-table tr td.association_" + targetIdx).forEach((ele) => {
      // 設定済みは除く（fromとtoが同じ
      if (!writedLines.some(line => "association_" + line.toIdx === ele.id)) {
        // [新設]マークが設定されている場合は除く
        if (!associationList[targetIdx].DepartmentHierarchy.MarkInfo.some(mark => mark.Index === Number(ele.id.replace("association_", "").split("_")[1]) && mark.Mark === assosiationMarks.new.value)) {
          // n:mとなる場合は除く
          if (!(hasLineList.fromIdx.includes(e.active.id) && hasLineList.toIdx.some(line => "association_" + line === ele.id))) {
            // [統合]のマッピングをした場合は除く
            if (!fromIdxList.includes(e.active.id)) {
              ele.classList.add('highlight');
            }
          }
        }
      }
    });
  }

  /**
   * ドラッグ終了処理
   */
  const handleDragEnd = (e) => {
    const { active, over } = e;
    const fromIdx = Number(active.id.split("_")[0]);
    const targetIdx = fromIdx + 1;
    document.querySelectorAll(".association-table tr td.association_" + targetIdx).forEach((ele) => {
      ele.classList.remove('highlight');
    });
    if (!over) {
      return;
    }
    // 一つ先以外にはドラッグ不可
    if (targetIdx !== Number(over.id.split("_")[0])) {
      return;
    }
    // 設定済みは除く
    if (lineList.some(line => line.fromIdx === active.id && line.toIdx === over.id)) {
      return;
    }
    // [新設]マークが設定されている場合は除く
    if (associationList[targetIdx].DepartmentHierarchy.MarkInfo.some(mark => mark.Index === Number(over.id.split("_")[1]) && mark.Mark === assosiationMarks.new.value)) {
      return;
    }
    // n:mの場合は除く
    if (hasLineList.fromIdx.includes(active.id) && hasLineList.toIdx.includes(over.id)) {
      return;
    }
    // [統合]のマッピング元を特定する
    // `toIdx` でグループ化する
    let groupedByToIdx = lineList.reduce((acc, obj) => {
      // `toIdx` の値をキーとして使用
      if (!acc[obj.toIdx]) {
        acc[obj.toIdx] = [];
      }
      acc[obj.toIdx].push(obj.fromIdx);
      return acc;
    }, {});
    // 同一の `toIdx` を持つ `fromIdx` の値を一つの配列にまとめる
    let fromIdxList = [];
    for (let key in groupedByToIdx) {
      if (groupedByToIdx[key].length > 1) {
        fromIdxList = fromIdxList.concat(groupedByToIdx[key]);
      }
    }
    // [統合]のマッピングをした場合は除く
    if (fromIdxList.includes(active.id)) {
      return;
    }

    const start = document.getElementById("association_" + active.id);
    const end = document.getElementById("association_" + over.id);
    // ライン追加
    const line = _createLine(start, end);
    // スクロールイベントで再描画
    const handler = _setScrollEvent(line, start, end);

    lineList.push({
      fromIdx: active.id,
      toIdx: over.id,
      leaderLine: line,
      handler: handler,
    });
    setLineList([...lineList]);

    // n:m対策で、1:m・n:1になっているライン情報を保持する
    const list = structuredClone(hasLineList);
    lineList.forEach(line => {
      // fromIdxの処理
      if (line.fromIdx === active.id && !list.fromIdx.includes(active.id)) {
        list.fromIdx.push(active.id);
      }
      // toIdxの処理
      if (line.toIdx === over.id && !list.toIdx.includes(over.id)) {
        list.toIdx.push(over.id);
      }
    });
    setHasLineList(list);
  }

  /**
   * 線の表示・非表示を更新する
   */
  const _updateLineVisibility = (line, start, end) => {
    const scrollElement = document.getElementById("association-table__scroll");
    const scrollRect = scrollElement.getBoundingClientRect();
    const startRect = start.getBoundingClientRect();
    const endRect = end.getBoundingClientRect();

    if (endRect.left < scrollRect.left || startRect.right > scrollRect.right) {
      line.hide('none'); // LeaderLine を非表示にする
    } else {
      line.show('none'); // LeaderLine を表示する
      line.position(); // LeaderLine を再描画
    }
  };

  /**
   * 線描画
   */
  const _createLine = (start, end) => {
    const line = new LeaderLine(start, end, {
      path: 'straight', // 必要
      startSocket: 'right', // 必要
      endSocket: 'left', // 必要
      color: '#e1e1eb',
      size: 4,
      startPlug: 'behind', // 必要
      endPlug: 'arrow1', // 必要
    });

    // 初期表示時に表示・非表示を設定する
    _updateLineVisibility(line, start, end);

    return line;
  };

  /**
   * スクロールイベント
   */
  const _setScrollEvent = (line, start, end) => {
    // スクロールイベントで再描画
    const scloll = document.getElementById("association-table__scroll");
    // 再描画関数
    const reRender = () => {
      try {
        _updateLineVisibility(line, start, end);
      } catch (e) { }
    };
    // スクロールイベント
    scloll.addEventListener("scroll", reRender, false);
    window.addEventListener("resize", reRender, false);
    return reRender;
  }

  /**
   * 描画削除
   */
  const deleteLine = (idx, idx2, keyType, oppositeKeyType) => {
    const key = idx + "_" + idx2;
    // 削除対象の線を絞り込み
    const lines = lineList.filter(line => line[keyType] === key);
    // 削除対象以外の線を絞り込み
    const newLines = lineList.filter(line => line[keyType] !== key);
    const scroll = document.getElementById("association-table__scroll");

    const newLinesOppositeKeySet = new Set(newLines.map(line => line[oppositeKeyType]));
    const list = structuredClone(hasLineList);
    lines.forEach(e => {
      try {
        // 線とスクロールイベントを削除
        e.leaderLine.remove();
        scroll.removeEventListener("scroll", e.handler);
        scroll.removeEventListener("resize", e.handler);
        // n:mチェック用の値格納（ 1:m,n:1になっているものを抽出
        const index = list[oppositeKeyType].indexOf(e[oppositeKeyType]);
        if (!newLinesOppositeKeySet.has(e[oppositeKeyType]) && index !== -1) {
          list[oppositeKeyType].splice(index, 1);
        }
      } catch (e) { }
    });
    if (newLines.some(line => line[keyType])) {
      list[keyType] = list[keyType].filter(idx => idx !== key);
    }
    setHasLineList(list);
    setLineList(newLines);
  }

  /**
   * 次期間への描画削除
   */
  const deleteNextLine = (idx, idx2) => {
    deleteLine(idx, idx2, "fromIdx", "toIdx");
  }

  /**
   * 前期間への描画削除
   */
  const deleteBeforeLine = (idx, idx2) => {
    deleteLine(idx, idx2, "toIdx", "fromIdx");
  }

  /**
   * モーダル表示
   */
  const _openModal = () => {
    const modalBg = document.querySelector(".bg-modal-window");
    modalBg.classList.add("is-active");
    const modalUploading = document.querySelector("#association-mark-window");
    modalUploading.classList.remove("animate__fadeOut");
    modalUploading.classList.add("animate__fadeIn");
    modalUploading.classList.add("is-active");
  }

  /**
   * モーダル非表示
   */
  const _closeModal = () => {
    const modal = document.querySelector("#association-mark-window");
    if (!modal.classList.contains("animate__fadeOut")) {
      modal.classList.remove("animate__fadeIn");
      modal.classList.add("animate__fadeOut");
    }
    const fadeOutTimeoutSec = 300;
    setTimeout(function () {
      document.querySelector(".bg-modal-window.is-active").classList.remove("is-active");
      document.querySelector(".modal-window.is-active").classList.remove("is-active");
    }, fadeOutTimeoutSec);
  }

  /**
   * 登録ボタン押下
   */
  const updateAssociation = async () => {

    try {
      const title = 'マスター設定値登録の確認';
      const description = <React.Fragment>
        <div className="text-center">設定値を登録してダッシュボードに反映します。よろしいですか？</div>
        <div className="text-center pt-7">※ダッシュボードへの反映には5分～10分程度かかります</div>
        <div className="text-center">マスター管理画面のステータスから反映状況を確認してください</div>
      </React.Fragment>;
      const button = [{
        name: '実行',
        className: 'footer-btn button_base',
        click: async () => {
          // 組織階層マスタのマーク、ライン情報を更新
          for (const [idx, updateData] of associationList.entries()) {
            const pk = {
              CorporationNumber: props.corporationNumber,
              StartDate: updateData.DepartmentHierarchy.StartDate
            }
            delete updateData.DepartmentHierarchy.CorporationNumber;
            delete updateData.DepartmentHierarchy.StartDate;
            const nowDate = formatDate(new Date());
            updateData.DepartmentHierarchy.UpdateUserName = props.userName;
            updateData.DepartmentHierarchy.UpdateDateTime = nowDate;
            // LineInfoを設定
            const deepCopyLineList = JSON.parse(JSON.stringify(lineList));
            updateData.DepartmentHierarchy.LineInfo = deepCopyLineList
              .filter(item => item.fromIdx.startsWith(idx + "_"))
              .map(item => ({
                fromIdx: item.fromIdx.split('_')[1],
                toIdx: item.toIdx.split('_')[1]
              }))
              .sort((a, b) => {  // fromIdx, toIdxの順でソート
                if (a.fromIdx !== b.fromIdx) {
                  return a.fromIdx - b.fromIdx;
                } else {
                  return a.toIdx - b.toIdx;
                }
              });
            // 更新
            await updateDynamoDB(table.DepartmentHierarchy, 'CorporationNumber', props.corporationNumber,
              pk, updateData.DepartmentHierarchy, props.userId
            );
          }
          // 保持しているインデックスから名称を取得
          const getName = (idxs) => {
            return associationList[Number(idxs[0])].record[Number(idxs[1])];
          }
          // マッピング表を作成
          let mappingData = {};
          lineList.forEach(line => {
            const fromName = getName(line.fromIdx.split("_"));
            let toName = getName(line.toIdx.split("_"));
            if (lineList.filter(l => l.fromIdx === line.fromIdx).length > 1) {
              toName = fromName + "（旧）";
            }
            mappingData[fromName] = toName;
          });
          // 整形する（key-valueでvalueの値がkeyの場合はvalueを更新
          Object.keys(mappingData).forEach(key => {
            const newVal = mappingData[mappingData[key]];
            if (newVal) {
              mappingData[key] = newVal;
            }
          });
          // データ反映
          await updateDepartmentAssociation(props.corporationNumber, props.userName, viewId.associationInfo, Object.entries(mappingData));
          props.navigate('/master/masterList', { state: { message: '組織第一階層の経年紐づけ設定を保存しました。' } });
        }
      }];
      props.showModal({ title: title, description: description, button: button, isWide: true });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.associationInfo, "updateAssociation", error);
    }
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment >
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div className="form-control">
                <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                  {/*  ===== 基本情報の設定 ===== */}
                  <div className="content-area">
                    <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">第1階層の経年紐づけ設定</h2>
                    {associationList.length ? (
                      <React.Fragment>
                        <div className="flex mt-5">
                          <ul className="text-body-2-medium grow">
                            <li>組織の第1階層での集計を経年で確認するために、組織間の紐づけを行ってください。</li>
                          </ul>
                        </div>
                        <div className="association-table__scroll-wrapper">
                          <div className="border-t border-blue1" />
                          <div className="association-table__scroll-left"></div>
                          <div id="association-table__scroll" className="association-table__scroll">
                            <DndContext autoScroll={false} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                              <div className="flex">
                                {associationList.map((association, idx) => {
                                  return (
                                    <div key={idx} className="association-list">
                                      <table className="base-table association-table w-full mt-1">
                                        <thead className="text-h7">
                                          <tr>
                                            <th>{association.title}</th>
                                          </tr>
                                        </thead>
                                        <tbody className="text-caption-2 text-nowrap">
                                          {association.record?.map((rec, idx2) => {
                                            const id = idx + "_" + idx2;
                                            const mark = association.DepartmentHierarchy.MarkInfo.find(mark => mark.Index === idx2);
                                            let isComplete = false;
                                            // 最新年度の組織の場合
                                            if (idx === associationList.length - 1) {
                                              // [新設]が設定されている、もしくは前年度の組織から紐づけされている場合、設定完了とする
                                              if (assosiationMarks.new.value === mark?.Mark || lineList.some(line => line.toIdx === id)) {
                                                isComplete = true;
                                              }
                                              // 最新年度以外の組織の場合
                                            } else {
                                              // [廃止]が設定されている、もしくは次年度の組織に紐づけされている場合、設定完了とする
                                              if (assosiationMarks.abolish.value === mark?.Mark || lineList.some(line => line.fromIdx === id)) {
                                                isComplete = true;
                                              }
                                            }
                                            const fromList = lineList.filter(line => line.fromIdx === id);
                                            const toList = lineList.filter(line => line.toIdx === id);
                                            // 統合の判定
                                            // toIdxに2つ以上設定されているものは、組織名の前に[統合]を表示する
                                            const integrateForFront = toList.length > 1;
                                            // fromIdxが設定されている要素のtoIdxの値が、fromIdxに2つ以上設定されているものは、組織名の後ろに[統合]を表示する
                                            let integrateForBack = false;
                                            for (const list of fromList) {
                                              const l = lineList.filter(line => line.toIdx === list.toIdx);
                                              if (l.length > 1) {
                                                integrateForBack = true;
                                                break;
                                              }
                                            }
                                            // 分割の判定
                                            // fromIdxに2つ以上設定されているものは、組織名の前に[分割]を表示する
                                            const divideForBack = fromList.length > 1;
                                            // toIdxが設定されている要素のfromIdxの値が、toIdxに2つ以上設定されているものは、組織名の後ろに[分割]を表示する
                                            let divideForFront = false;
                                            for (const list of toList) {
                                              const l = lineList.filter(line => line.fromIdx === list.fromIdx);
                                              if (l.length > 1) {
                                                divideForFront = true;
                                                break;
                                              }
                                            }
                                            return (
                                              <tr key={idx2}>
                                                <td id={"association_" + id} className={`${isComplete ? "complete" : ""} association_${idx}`}>
                                                  <div className="association-drag-drop flex">
                                                    <div className="frontMark">
                                                      {integrateForFront ? (
                                                        <span className="mr-2 grey--text">
                                                          {"[統合]"}
                                                        </span>
                                                      ) : (
                                                        <>
                                                          {divideForFront ? (
                                                            <span className="mr-2 orange--text">
                                                              {"[分割]"}
                                                            </span>
                                                          ) : (
                                                            <>
                                                              {assosiationMarks.maintain.value === mark?.Mark && (
                                                                <span className="mr-2 grey2--text">
                                                                  {"[" + assosiationMarks[mark.Mark].label + "]"}
                                                                </span>
                                                              )}
                                                              {assosiationMarks.new.value === mark?.Mark && (
                                                                <span className="mr-2 blue3--text">
                                                                  {"[" + assosiationMarks[mark.Mark].label + "]"}
                                                                </span>
                                                              )}
                                                              {assosiationMarks.rename.value === mark?.Mark && (
                                                                <span className="mr-2 green--text">
                                                                  {"[" + assosiationMarks[mark.Mark].label_2 + "]"}
                                                                </span>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                    <div className="orgName">
                                                      <Link color="inherit" className="text-link" title={rec}
                                                        onClick={async (e) => {
                                                          setMarkInfo({
                                                            tableIndex: idx,
                                                            index: idx2,
                                                            title: association.title,
                                                            name: rec,
                                                            markCheck: mark ? mark.Mark : assosiationMarks.maintain.value,
                                                          });
                                                          _openModal();
                                                        }}>
                                                        {rec}
                                                      </Link>
                                                    </div>
                                                    <div className="backMark">
                                                      {divideForBack ? (
                                                        <span className="ml-3 orange--text">
                                                          {"[分割]"}
                                                        </span>
                                                      ) : (
                                                        <>
                                                          {integrateForBack ? (
                                                            <span className="ml-3 grey--text">
                                                              {"[統合]"}
                                                            </span>
                                                          ) : (
                                                            <>
                                                              {assosiationMarks.abolish.value === mark?.Mark && (
                                                                <span className="ml-3 red--text">{"[" + assosiationMarks[mark.Mark].label + "]"}</span>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                    <Droppable id={id}></Droppable>
                                                    {(() => {
                                                      const isLined = lineList.some(e => e.fromIdx === id);
                                                      return isLined ? (
                                                        <div className="display-hover delete-icon">
                                                          <IconButton size="small" onClick={() => deleteNextLine(idx, idx2)} sx={{ padding: "0 2" }}>
                                                            <Delete fontSize="small" />
                                                          </IconButton>
                                                        </div>
                                                      ) : ""
                                                    })()}
                                                    {assosiationMarks.abolish.value !== mark?.Mark &&
                                                      <Draggable id={id} val={rec}>→</Draggable>
                                                    }
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  )
                                })}
                              </div>
                              <DragOverlay dropAnimation={{
                                sideEffects: defaultDropAnimationSideEffects({
                                  styles: {
                                    active: {},
                                    dragOverlay: {
                                      opacity: "0"
                                    }
                                  }
                                })
                              }}>
                                <div className="drag-content">{dragContent}</div>
                              </DragOverlay>
                            </DndContext>
                          </div>
                          <div className="association-table__scroll-right"></div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="text-center my-10 pt-10">
                        <span className="text-h5 red--text">組織情報が未登録です。</span>
                      </div>
                    )
                    }
                  </div>
                  <div className="content-footer mt-16 mb-8">
                    <div className="border-t border-blue1" />
                    {/*  ===== フッターボタン ===== */}
                    <div className="footer_button_area mt-8">
                      <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                        <button type="button" className="btn footer-btn button_back">キャンセル</button>
                      </Link>
                      {associationList.length !== 0 &&
                        <SpinnerButton name="登録" className="btn footer-btn button_base" onClick={() => updateAssociation()} />
                      }
                    </div>
                    {/*  ===== /フッターボタン ===== */}
                  </div>
                </div>
              </div>
              {/*  ===== 関連ダッシュボード ===== */}
              <RelatedDashboard dashboards={master.RelatedDashboard} authority={props.authority} mode={props.mode} />
            </div>
          </div>
        </section>
      </main>
      <div className="bg-modal-window w-screen h-screen fixed top-0 left-0 grey lighten-70 z-50"></div>
      {/* マーク選択モーダル */}
      <div id="association-mark-window"
        className="modal-window fixed bg sub z-50 rounded-2xl animate__fadeIn">
        <div className="modal-window__header">
          <div className="modal-window__title flex items-center justify-center pt-10">
            <span className="modal-window__title-text text-h3 font-medium">マーク設定</span>
          </div>
          <button type="button" className="modal-window__btn-close absolute top-5 right-5" onClick={() => _closeModal()}>
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="modal-window__content px-8 py-10 !overflow-y-hidden">
          {/* <div className="flex text-center mb-2">説明</div> */}
          <div>
            <table className="base-table w-full">
              <tbody className="text-h7">
                <tr>
                  <th width="15%">期間</th>
                  <td width="35%">{markInfo.title}</td>
                </tr>
                <tr>
                  <th width="15%">組織名</th>
                  <td width="35%">{markInfo.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="my-5">
            <table className="base-table w-full">
              <thead className="text-h7">
                <tr>
                  <th width="10%">選択</th>
                  <th width="90%">マーク</th>
                </tr>
              </thead>
              <tbody className="text-caption-2">
                {Object.entries(assosiationMarks).map(([key, val], markIdx) => {
                  return (
                    <tr key={markIdx}>
                      <th className="text-center">
                        <label className="ml-2 ui-radio">
                          <input type="radio" className="ui-radio__input" name="marks"
                            checked={markInfo.markCheck === key} value={key}
                            onChange={(e) => {
                              setMarkInfo({ ...markInfo, markCheck: e.target.value });
                            }} />
                          <span className="ui-radio__icon"></span>
                          <span className="ui-radio__ripple"></span>
                        </label>
                      </th>
                      <td>
                        <span className="ui-checkbox__label text-h6 font-Regular">{val.label}</span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="modal-content-footer">
            <div className="footer_button_area gap-x-16">
              <button className="btn footer-btn button_back" onClick={() => _closeModal()}>キャンセル</button>
              <SpinnerButton name="設定" className="btn footer-btn button_base"
                onClick={() => {
                  // 対象を抽出
                  const association = associationList[markInfo.tableIndex];
                  const beforeMark = association.DepartmentHierarchy.MarkInfo.find(e => e.Index === markInfo.index);
                  if (beforeMark) {
                    beforeMark.Mark = markInfo.markCheck;
                  } else {
                    association.DepartmentHierarchy.MarkInfo.push({
                      Index: markInfo.index,
                      Mark: markInfo.markCheck
                    });
                  }
                  setAssociationList([...associationList]);
                  // 新規を選択した場合、前期間の紐づく線を削除する
                  if (markInfo.markCheck === assosiationMarks.new.value) {
                    deleteBeforeLine(markInfo.tableIndex, markInfo.index);
                  }
                  // 廃止を選択した場合、次期間に紐づく線を削除する
                  else if (markInfo.markCheck === assosiationMarks.abolish.value) {
                    deleteNextLine(markInfo.tableIndex, markInfo.index);
                  }
                  _closeModal();
                }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

const AssociationInfo = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();
  const location = useLocation();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Department.DepartmentHierarchy_Set) {
    return <Forbidden />
  }

  return <AssociationInfoContent
    userId={state.user.sub}
    userName={state.user.userName}
    authority={state.authority}
    mode={state.analysis.mode}
    pageTitle={titleName.associationInfo + titleName.common}
    corporationNumber={state.company.corporationNumber}
    bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
    navigate={navigate}
    showModal={showModal}
    location={location}
  />;
};

export default AssociationInfo;
