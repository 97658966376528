"use strict";
import { Auth } from "aws-amplify";
import ExcelJS from "exceljs";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { DndContext, DragOverlay, useDroppable, useDraggable, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import LeaderLine from "leader-line-new";
import { Link, Checkbox, IconButton } from "@mui/material"
import { AccountTree, DeleteOutlineOutlined } from "@mui/icons-material";
import CheckBoxRountedIcon from "@mui/icons-material/CheckBoxRounded";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SpinnerButton, RefleshButton } from "../common/CustomButton"
import { errorLog } from "../../common/logger/Logger";
import { putFileNoName, uploadAccountingFile, readCsvFile, readExcelFile, getObject } from "../../common/utils/FileUtils";
import { formatCommaDigit, parseCommaDigit } from "../../common/utils/NumberUtils";
import { viewId, titleName, accountingUploadTaskStatus, periodKind, analysisMode } from "../../_constants/Code";
import { useToast } from "../../_components/Toast";
import { useModal } from "../../_components/Modal";
import { formatDate } from "../../common/utils/DateUtils";
import { getMessage } from "../../common/utils/MessageUtils";
import { getAccountingUploadTask } from "../../common/utils/DynamoDbUtils";
import config from "../../_constants/Config";

const AccountingDataContent = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  // 財務データアップロード情報
  const [accountingUploadInfo, setAccountingUploadInfo] = React.useState();
  // 財務ファイルテーブル
  const [accountingTable, setAccountingTable] = React.useState();
  // ファイル情報
  const [inputFileInfo, setInputFileInfo] = React.useState();
  const inputAccountingFile = React.createRef();
  // 線
  const [lineList, setLineList] = React.useState([]);
  // ドラッグ要素
  const [dragContent, setDragContent] = React.useState();
  const [dragContentWidth, setDragContentWidth] = React.useState(0);
  const [selectAmountColumn, setSelectAmountColumn] = React.useState(false);

  const [inputError, setInputError] = React.useState({});

  // 有効行のインデックス
  const [activeRowIdx, setActiveRowIdx] = React.useState(-1);

  // 初期表示の処理
  React.useEffect(() => {
    // タイトル設定
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  React.useEffect(() => {
    if (inputError.errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [inputError]);

  /**
   * 初期表示
   */
  const init = async () => {
    // 財務アップロードタスクを取得
    const accountingUploadTask = await _getAccountingUploadTask();
    const fileInfo = {
      fileType: "CSV",
      headerRow: 1,
      inputFile: undefined,
      inputError: undefined,
      sheetList: [],
    }
    if (accountingUploadTask) {
      delete accountingUploadTask.SuperKey;
      delete accountingUploadTask.Key;
      // 処理中、登録済みの場合、ファイル情報を設定
      if (accountingUploadTask.Status === accountingUploadTaskStatus.registered.value && accountingUploadTask.FileName && accountingUploadTask.FileType) {
        const fileData = await getObject(props.bucketName, accountingUploadTask.FilePath);
        fileInfo.fileType = accountingUploadTask.FileType;
        fileInfo.headerRow = accountingUploadTask.HeaderRow;
        fileInfo.inputFile = {
          name: accountingUploadTask.FileName,
          extension: accountingUploadTask.FileName.slice(accountingUploadTask.FileName.lastIndexOf(".") + 1),
          fileData: new File([fileData], accountingUploadTask.FileName, { type: accountingUploadTask.ContentType }),
        }
        // 読み取り処理
        const sheetList = await _readAccountingData(fileInfo);

        // 拡張子がxlsxの場合
        if (fileInfo.fileType === "EXCEL") {
          // 財務アップロードタスク管理情報からファイル情報を取得できた場合、ファイル情報から財務データテーブルを再読み込み
          const sheet = sheetList.find(ele => ele.id === accountingUploadTask.SheetNo);
          setAccountingTable(_readWorksheet(sheet.worksheet, fileInfo.headerRow));
        }
      }
      else {
        // 財務アップロードタスク管理情報が取得できた場合でもファイル情報が空の場合は、デフォルトのファイル情報を設定
        setInputFileInfo(fileInfo);
      }
      // 対象外フラグ設定
      // accountingUploadTask.AccountInfo.forEach(account => {
      //   if (!account.NoTargetFlag && !account.CurrentInfo.length) {
      //     account.NoTargetFlag = account._PreviousNoTargetFlag;
      //   }
      // });
    }
    else {
      // 財務アップロードタスク管理情報が取得できない場合、デフォルトのファイル情報を設定
      setInputFileInfo(fileInfo);
    }
    setAccountingUploadInfo(accountingUploadTask);
    setLineList([]);
    setIsLoaded(true);
  }

  /**
   * ドラッグコンテンツの横幅設定
   */
  React.useEffect(() => {
    // ResizeObserverのインスタンスを作成
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.classList.contains('is-selected-amount')) {
          const computedStyle = window.getComputedStyle(entry.target);
          const paddingLeft = parseFloat(computedStyle.paddingLeft);
          const paddingRight = parseFloat(computedStyle.paddingRight);
          const totalWidth = entry.contentRect.width + paddingLeft + paddingRight;
          setDragContentWidth(totalWidth);
        }
      }
    });
    // 監視対象の要素を探して監視を開始
    const selectedAmountTh = document.querySelector('th.is-selected-amount');
    if (selectedAmountTh) {
      resizeObserver.observe(selectedAmountTh);
    }
    // クリーンアップ関数: 監視を停止
    return () => {
      if (selectedAmountTh) {
        resizeObserver.unobserve(selectedAmountTh);
      }
    };
  }, [isLoaded, selectAmountColumn]);

  /**
   * 財務アップロードタスク取得
   */
  const _getAccountingUploadTask = async () => {

    const dynamoDBData = await getAccountingUploadTask(
      props.corporationNumber, props.dataCreateTaskKey, props.AccountingKey
    );
    return dynamoDBData;
  }

  /**
   * ファイル選択
   */
  const setInputAccountingFile = (e) => {
    if (e.target.value.length) {
      const fileData = e.target.files[0];
      setInputFileInfo({
        ...inputFileInfo,
        inputFile: {
          name: fileData.name,
          extension: fileData.name.slice(fileData.name.lastIndexOf(".") + 1),
          fileData: fileData
        }
      });
    };
  }

  /**
   * ファイルチェック
   */
  const _checkInputFile = () => {
    let errorMessage = undefined;
    try {
      const extension = inputFileInfo.inputFile.extension.toLowerCase();
      // タイプ：CSV
      if (inputFileInfo.fileType === "CSV" && extension !== "csv") {
        errorMessage = getMessage("E010009", "ファイルの拡張子");
        return true;
      }
      // タイプ：Excel
      if (inputFileInfo.fileType === "EXCEL" && !["xls", "xlsx"].includes(extension)) {
        errorMessage = getMessage("E010009", "ファイルの拡張子");
        return true;
      }
      // ヘッダー行
      if (!Number.isFinite(inputFileInfo.headerRow) || inputFileInfo.headerRow < 1) {
        errorMessage = getMessage("E010010", "ヘッダー行", "1");
        return true;
      }
      // ファイルサイズ
      const size = inputFileInfo.inputFile.fileData.size;
      if (size <= 0) {
        errorMessage = getMessage("E010011", "空ファイル", "読み込み");
        return true;
      }
      if (size > config["maxSizeInBytesAccounting"]) {
        errorMessage = getMessage("E010012", "ファイルサイズ");
        return true;
      }
    } finally {
      setInputFileInfo({
        ...inputFileInfo,
        inputError: errorMessage,
      });
    }
    return false;
  }

  /**
   * ファイル読み取り確認（上書き）
   */
  const confirmAccounting = () => {

    if (_checkInputFile()) {
      return;
    }
    const title = '読み込み確認';
    const body = <React.Fragment>
      <div className="text-center">ファイルを読み込むと、現在のマッピング設定がすべて解除されます。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        readAccountingData();
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * ファイル変更確認（上書き）
   */
  const confirmFileChange = () => {
    const title = 'ファイル変更確認';
    const body = <React.Fragment>
      <div className="text-center">ファイルを変更すると、現在のマッピング設定がすべて解除されます。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        changeFile();
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 選択解除確認
   */
  const confirmClearSettings = (idx, accountIdx, amountIdx) => {
    const title = '選択解除確認';
    const body = <React.Fragment>
      <div className="text-center">科目列、金額列の選択を解除すると、現在のマッピング設定がすべて解除されます。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        handleClearSettings(idx, accountIdx, amountIdx);
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * シート名変更確認（選択解除）
   */
  const confirmSheetChange = (e, changeSheetNo) => {
    const title = '選択解除確認';
    const body = <React.Fragment>
      <div className="text-center">シート名を変更すると、現在のマッピング設定がすべて解除されます。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        e.target.value = changeSheetNo // 切り替えを行う場合は、現在のシートNoを切り替え先のSheetNoで上書きする。
        handleSheetChange(e);
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 財務ファイル読み取り
   */
  const readAccountingData = async () => {
    if (_checkInputFile()) {
      return;
    }
    // 選択状態等を初期化
    _clearSettings();
    // 読み取り処理
    const sheetList = await _readAccountingData(inputFileInfo);
    // 財務アップロードタスク管理に設定
    setAccountingUploadInfo(prevState => {
      return {
        ...prevState,
        // ファイル情報
        FileName: inputFileInfo.inputFile.name,
        ContentType: inputFileInfo.inputFile.fileData.type,
        FileType: inputFileInfo.fileType,
        HeaderRow: inputFileInfo.headerRow,
        FileUpdateDateTime: formatDate(new Date()),
        FileUpdateUserName: props.userName,
        SheetNo: inputFileInfo.fileType === "EXCEL" ? sheetList[0].id : null,
        // ヘッダー選択初期化
        AccountHeaderColIndex: null,
        AmountHeaderColIndex: null,
      };
    });
  }

  /**
   * 財務ファイル読み取り
   */
  const changeFile = () => {
    // 選択状態等を初期化
    _clearSettings();
    // 選択ファイルを初期化
    _clearFile();
  }

  /**
   * 財務ファイル読み取り
   */
  const _readAccountingData = async (fileInfo) => {
    const sheetList = [];
    // 拡張子がCSVの場合
    if (fileInfo.fileType === "CSV") {
      let csvData = await readCsvFile(fileInfo.inputFile.fileData);
      csvData = csvData.filter((row, index) => index !== csvData.length - 1 || !row.every(cell => cell === "" || cell === null || cell === undefined));
      setAccountingTable({
        headers: csvData[fileInfo.headerRow - 1],
        records: csvData.slice(fileInfo.headerRow),
      });
    }
    // 拡張子がxlsxの場合
    else if (fileInfo.fileType === "EXCEL") {
      const workbook = await readExcelFile(fileInfo.inputFile.fileData);
      workbook.eachSheet((worksheet, sheetId) => {
        sheetList.push({
          id: sheetId,
          name: worksheet.name,
          worksheet: workbook.getWorksheet(sheetId),
        });
      });
      setAccountingTable(_readWorksheet(sheetList[0].worksheet, fileInfo.headerRow));
    }
    // それ以外は処理なし
    else {
      return;
    }
    setInputFileInfo({
      ...fileInfo,
      inputError: undefined,
      sheetList: sheetList,
    });
    return sheetList;
  }

  /**
   * エクセルワークシート読み取り
   */
  const _readWorksheet = (sheet, rowNum) => {

    const table = {
      headers: [],
      records: [],
    };
    // ヘッダー行
    const headerRow = sheet.getRow(rowNum);
    for (let i = 1; true; i++) {
      const headerCell = headerRow.getCell(i);
      let val;
      if (headerCell.type === ExcelJS.ValueType.Formula) {
        val = headerCell.result; // 数式の計算結果を取得
      } else {
        val = headerCell.value;  // セル値を取得
      }
      if (!val) {
        break;
      }
      table.headers.push(val);
    }
    // レコード行
    for (let i = rowNum + 1; true; i++) {
      const row = sheet.getRow(i);
      if (!row.getCell(1).value) {
        break;
      }
      const rec = [];
      for (let j = 1; j <= table.headers.length; j++) {
        const cell = row.getCell(j);
        if (cell.type === ExcelJS.ValueType.Formula) {
          rec.push(cell.result); // 数式の計算結果を取得
        } else {
          rec.push(cell.value); // セル値を取得
        }
      }
      table.records.push(rec);
    }
    return table;
  }

  /**
   * Droppableコンポーネント
   */
  const Droppable = (props) => {
    const { isOver, setNodeRef } = useDroppable({ id: props.id });
    // CHROFY利用科目
    const account = accountingUploadInfo.AccountInfo.find(ele => {
      return ele.AccountCode === Number(props.id.split("_")[0])
    });
    return account.NoTargetFlag ? (
      <table className="inline-table">
        {props.children}
      </table>
    ) : (
      <table className={`inline-table ${isOver ? "dragging" : ""}`} ref={setNodeRef}>
        {props.children}
      </table>
    )
  }

  /**
   * Draggableコンポーネント
   */
  const Draggable = (props) => {
    const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({ id: props.id, data: props.val });
    const style = transform ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
    } : undefined;
    return !isDragging && props.children !== null && props.children !== "" ? (
      <td ref={setNodeRef} style={style} className={props.className} {...listeners} {...attributes}>
        {props.children}
      </td>
    ) : (
      <td className={props.className} >
        {props.children}
      </td>
    )
  }

  /**
   * ドラッグ開始処理
   */
  const handleDragStart = (e) => {
    setDragContent(e.active.data.current);
    // ドラッグ行 
    const dragRowIdx = Number(e.active.id.split("_")[0]);
    // すでに設定済みのCHROFY科目情報を抜き出す
    const rowSelected = accountingUploadInfo.AccountInfo.filter(account => {
      return account.CurrentInfo.some(c => c.RowIndex === dragRowIdx);
    });
    document.querySelectorAll(".accounting-account-table tr td.dragable").forEach((ele) => {
      // 設定されていない部分にハイライト
      if (!rowSelected.some(s => ele.id === "chrofy_account_" + s.AccountCode)) {
        ele.classList.add('highlight');
      }
    });
  }

  /**
   * ドラッグ終了処理
   */
  const handleDragEnd = (e) => {
    const { active, over } = e;
    document.querySelectorAll(".accounting-account-table tr td.dragable").forEach((ele) => {
      ele.classList.remove('highlight');
    });
    if (!over) {
      return
    }
    // 行のインデックス
    const rowIndex = Number(active.id.split("_")[0]);
    // CHROFY科目情報
    const accountIndex = accountingUploadInfo.AccountInfo.findIndex(ele => {
      return ele.AccountCode === Number(over.id.split("_")[0]);
    });
    const account = accountingUploadInfo.AccountInfo[accountIndex];
    // すでに設定済みであれば無視
    if (account.CurrentInfo.some(c => c.RowIndex === rowIndex)) {
      return;
    }
    const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
    const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
    // 科目
    const currentAccount = accountingTable.records[rowIndex][accountIdx];
    // 金額
    const currentAmount = accountingTable.records[rowIndex][amountIdx];
    const amount = Number(parseCommaDigit(String(currentAmount)));
    // 数値以外は処理対象外
    if (!Number.isFinite(amount)) {
      return;
    }
    // CHROFY利用科目に設定
    account.CurrentInfo.push({
      AccountTitle: currentAccount,
      Amount: amount,
      RowIndex: rowIndex,
      Operation: "+",
    });
    // 財務データ表の順に入替
    account.CurrentInfo.sort((a, b) => a.RowIndex - b.RowIndex);
    setAccountingUploadInfo({ ...accountingUploadInfo });
    _reWriteLineRow(account, accountIndex, true);
  }
  // 角丸を作る関数（必要）
  // addArc(SVGのパスデータ, 角丸の半径)
  // 参考：leader-lineのissueで作者のansekiさんが提案されていたもののまま
  // https://github.com/anseki/leader-line/issues/28#issuecomment-425339319
  const addArc = (pathData, radius) => {
    var reL = /^L ?([\d.\-+]+) ([\d.\-+]+) ?/,
      newPathData, curXY, curDir, newXY, newDir,
      sweepFlag, arcXY, arcStartXY;

    function getDir(xy1, xy2) {
      if (xy1.x === xy2.x) {
        return xy1.y < xy2.y ? 'd' : 'u';
      } else if (xy1.y === xy2.y) {
        return xy1.x < xy2.x ? 'r' : 'l';
      }
      throw new Error('Invalid data');
    }

    function captureXY(s, x, y) {
      newXY = { x: +x, y: +y };
      return '';
    }

    function offsetXY(xy, dir, offsetLen, toBack) {
      return {
        x: xy.x + (dir === 'l' ? -offsetLen : dir === 'r' ? offsetLen : 0) * (toBack ? -1 : 1),
        y: xy.y + (dir === 'u' ? -offsetLen : dir === 'd' ? offsetLen : 0) * (toBack ? -1 : 1)
      };
    }

    pathData = pathData.trim().replace(/,/g, ' ').replace(/\s+/g, ' ')
      .replace(/^M ?([\d.\-+]+) ([\d.\-+]+) ?/, function (s, x, y) {
        curXY = { x: +x, y: +y };
        return '';
      });
    if (!curXY) { throw new Error('Invalid data'); }
    newPathData = 'M' + curXY.x + ' ' + curXY.y;

    while (pathData) {
      newXY = null;
      pathData = pathData.replace(reL, captureXY);
      if (!newXY) { throw new Error('Invalid data'); }

      newDir = getDir(curXY, newXY);
      if (curDir) {
        arcStartXY = offsetXY(curXY, curDir, radius, true);
        arcXY = offsetXY(curXY, newDir, radius);
        sweepFlag =
          curDir === 'l' && newDir === 'u' ? '1' :
            curDir === 'l' && newDir === 'd' ? '0' :
              curDir === 'r' && newDir === 'u' ? '0' :
                curDir === 'r' && newDir === 'd' ? '1' :
                  curDir === 'u' && newDir === 'l' ? '0' :
                    curDir === 'u' && newDir === 'r' ? '1' :
                      curDir === 'd' && newDir === 'l' ? '1' :
                        curDir === 'd' && newDir === 'r' ? '0' :
                          null;
        if (!sweepFlag) { throw new Error('Invalid data'); }
        newPathData += 'L' + arcStartXY.x + ' ' + arcStartXY.y +
          'A ' + radius + ' ' + radius + ' 0 0 ' + sweepFlag + ' ' + arcXY.x + ' ' + arcXY.y;
      }

      curXY = newXY;
      curDir = newDir;
    }
    newPathData += 'L' + curXY.x + ' ' + curXY.y;
    return newPathData;
  }

  /**
   * CHROFY科目表の行単位で線を再描画
   */
  const _reWriteLineRow = (account, idx, isForce) => {
    if (activeRowIdx === idx && !isForce) {
      return;
    }
    setActiveRowIdx(idx);
    // 線とスクロールイベントを削除
    const accountingTableScroll = document.getElementById("accounting-table__scroll");
    const accountingTableScrollLeft = document.getElementById("accounting-table__scroll--left");
    lineList.forEach(e => {
      try {
        e.leaderLine.remove();
        accountingTableScroll.removeEventListener("scroll", e.handler);
        accountingTableScroll.removeEventListener("resize", e.handler);
        accountingTableScrollLeft.removeEventListener("scroll", e.handler);
        accountingTableScrollLeft.removeEventListener("resize", e.handler);
      } catch (e) { }
    })
    // 対象外チェックが入っている場合は描画しない
    if (account.NoTargetFlag) {
      setLineList([]);
      return;
    }
    // 線描画
    const timer = setTimeout(() => {
      const newLineList = [];
      account.CurrentInfo.forEach((info, idx2) => {
        const start = document.getElementById("chrofy_account_" + account.AccountCode + "_" + idx + "_" + idx2);
        const end = document.getElementById("accounting_" + info.RowIndex);
        if (start && end) {
          // ライン追加
          const line = _createLine(start, end);
          // スクロールイベントで再描画
          const handler = _setScrollEvent(line, start, end);
          newLineList.push({
            id: account.AccountCode,
            idx: info.RowIndex,
            leaderLine: line,
            handler: handler,
          });
        }
      });
      setLineList(newLineList);
    });
    return () => clearTimeout(timer);
  }

  /**
   * 線描画
   */
  const _createLine = (start, end) => {
    const line_y = end.getBoundingClientRect().y;
    const accountingTableTop = document.getElementById("accounting-table-top");
    const accountingTableBottom = document.getElementById("accounting-table-bottom");
    const table_t_y = accountingTableTop.getBoundingClientRect().y;
    const table_b_y = accountingTableBottom.getBoundingClientRect().y;
    if (line_y <= table_t_y) {
      end = accountingTableTop;
    } else if (line_y >= table_b_y) {
      end = accountingTableBottom;
    }
    return new LeaderLine(start, end, {
      path: 'grid',         // 必要
      startSocket: 'right', // 必要
      endSocket: 'left',    // 必要
      color: '#e1e1eb',
      size: 4,
      startPlug: 'behind',  // 必要
      endPlug: 'behind',    // 必要
    })
  }

  /**
   * 財務テーブルのスクロールイベント
   */
  const _setScrollEvent = (line, start, end) => {
    // スクロールイベントで再描画
    // 左側のテーブルの関連要素
    const accountingTableScrollLeft = document.getElementById("accounting-table__scroll--left");
    const accountingTableTopLeft = document.getElementById("accounting-table-top--left");
    const accountingTableBottomLeft = document.getElementById("accounting-table-bottom--left");
    // 右側のテーブルの関連要素
    const accountingTableScroll = document.getElementById("accounting-table__scroll");
    const accountingTableTop = document.getElementById("accounting-table-top");
    const accountingTableBottom = document.getElementById("accounting-table-bottom");
    // 角丸処理
    const lineArc = (id) => {
      // 線を表示するsvg内のpath要素
      // id属性値として「leader-line-<線のID値>-line-path」が
      // 指定されているのを利用して、path要素を取得
      const elmsPath = document.getElementById(`leader-line-${id}-line-path`);
      // 線に角丸をつける
      elmsPath.setAttribute('d', addArc(elmsPath.getAttribute('d'), 10));
    }
    // 再描画関数
    const reRender = () => {
      try {
        // 線の始点・終点のy座標
        const line_y_left = start.getBoundingClientRect().y;
        const line_y = end.getBoundingClientRect().y;
        // 左側のテーブルの上部・下部のy座標
        const table_t_y_left = accountingTableTopLeft.getBoundingClientRect().y;
        const table_b_y_left = accountingTableBottomLeft.getBoundingClientRect().y;
        // 右側のテーブルの上部・下部のy座標
        const table_t_y = accountingTableTop.getBoundingClientRect().y;
        const table_b_y = accountingTableBottom.getBoundingClientRect().y;
        // 線の終点がテーブルの上部・下部を超えないように調整
        // path: gridだと表組みの上下の境界が15pxほど上にずれるので調整
        if (line_y <= table_t_y - 15) {
          line.end = accountingTableTop;
        } else if (line_y >= table_b_y - 15) {
          line.end = accountingTableBottom;
        } else {
          line.end = end;
          line.position();
        }
        // 線の始点がテーブルの上部・下部を超えないように調整
        // path: gridだと表組みの上下の境界が15pxほど上にずれるので調整
        if (line_y_left <= table_t_y_left - 15) {
          line.start = accountingTableTopLeft;
        } else if (line_y_left >= table_b_y_left - 15) {
          line.start = accountingTableBottomLeft;
        } else {
          line.start = start;
          line.position();
        }
        lineArc(line._id);
      } catch (e) { }
    }
    // スクロールイベント
    accountingTableScrollLeft.addEventListener("scroll", reRender, false);
    accountingTableScroll.addEventListener("scroll", reRender, false);
    window.addEventListener("resize", reRender, false);
    lineArc(line._id);
    return reRender;
  }

  /**
   * 描画削除
   */
  const deleteLine = (idx, idx2) => {
    // CHROFY利用科目を削除
    const account = accountingUploadInfo.AccountInfo[idx];
    account.CurrentInfo = account.CurrentInfo.filter((_, i) => i !== idx2);
    setAccountingUploadInfo({ ...accountingUploadInfo });
    // 線描画
    _reWriteLineRow(account, idx, true);
  }

  /**
   * 選択ファイルクリア
   */
  const _clearFile = () => {
    // ファイル情報を初期化
    const fileInfo = {
      fileType: "CSV",
      headerRow: 1,
      inputFile: undefined,
      inputError: undefined,
      sheetList: [],
    }
    setInputFileInfo(fileInfo);
    // 財務アップロードタスク情報を初期化
    setAccountingUploadInfo(prevState => {
      return {
        ...prevState,
        FileName: "",
        ContentType: "",
        FileType: "",
        HeaderRow: null,
        FileUpdateDateTime: "",
        FileUpdateUserName: "",
        SheetNo: null,
        AccountHeaderColIndex: null,
        AmountHeaderColIndex: null,
      };
    });
    // 表を初期化
    setAccountingTable();
  }

  /**
   * 設定クリア
   */
  const _clearSettings = (isInitHeader = true) => {
    // CHROFY利用科目を初期化
    setAccountingUploadInfo(prevState => {
      const newAccountInfo = prevState.AccountInfo.map(account => {
        return {
          ...account,
          CurrentInfo: []
        };
      });
      return {
        ...prevState,
        AccountInfo: newAccountInfo,
      };
    });
    // 線とスクロールイベントを削除
    const scloll = document.getElementById("accounting-table__scroll");
    lineList.forEach(e => {
      try {
        e.leaderLine.remove();
        scloll.removeEventListener("scroll", e.handler);
        scloll.removeEventListener("resize", e.handler);
      } catch (e) { }
    })
    setLineList([]);
    if (isInitHeader) {
      setAccountingUploadInfo(prevState => {
        return {
          ...prevState,
          // ヘッダー選択を初期化
          AccountHeaderColIndex: null,
          AmountHeaderColIndex: null,
        };
      });
    }
  }

  /**
   * 入力チェック
   */
  const _checkInput = () => {

    let inputError = {}
    try {
      // マッピングチェック
      accountingUploadInfo.AccountInfo.forEach(account => {
        if (!account.NoTargetFlag && account.CurrentInfo.length === 0) {
          inputError.errorMessage = getMessage("E011009", "CHROFY利用科目");
        }
      });
      // エラー判定
      if (Object.keys(inputError).length === 0) {
        return false;
      } else {
        return true;
      }
    } finally {
      // 入力エラーを設定
      setInputError({ ...inputError });
    }
  }

  /**
   * アップロード
   */
  const uploadAccountingData = () => {

    if (_checkInput()) {
      return;
    }
    const title = 'データ登録の確認';
    const body = <React.Fragment>
      <div className="text-center">財務データを登録します。よろしいですか？</div>
    </React.Fragment>;
    const button = [{
      name: '実行',
      className: 'footer-btn button_base',
      click: async () => {
        try {
          const uploadInfo = structuredClone(accountingUploadInfo);
          // 日時取得
          const nowDate = formatDate(new Date());
          uploadInfo.Status = accountingUploadTaskStatus.processing.value;
          uploadInfo.UpdateUserName = props.userName;
          uploadInfo.UpdateDateTime = nowDate;
          // 余分な要素を削除
          uploadInfo.AccountInfo.forEach(account => {
            delete account._AccountTitle;
            delete account._SortKey;
            delete account._PreviousCurrentInfo;
            delete account._PreviousNoTargetFlag;
            // 対象外の場合は子情報を初期化
            if (account.NoTargetFlag) {
              account.CurrentInfo = [];
            }
          });
          delete uploadInfo._PeriodKind;
          delete uploadInfo._PeriodValue;
          // ファイル登録
          // 金額をマッピングしている場合、アップロードする
          if (inputFileInfo.inputFile !== undefined && !uploadInfo.AccountInfo.every(account => account.NoTargetFlag)) {
            await putFileNoName(uploadInfo.FilePath, inputFileInfo.inputFile.fileData);
          } else {
            uploadInfo.FileUpdateDateTime = nowDate;
            uploadInfo.FileUpdateUserName = props.userName;
          }
          const identityId = await Auth.currentUserCredentials().then((userCredential) => {
            return userCredential.identityId;
          });
          // DB更新
          const message = await uploadAccountingFile(props.corporationNumber,
            identityId,
            {
              SuperKey: parseInt(props.dataCreateTaskKey),
              Key: parseInt(props.AccountingKey)
            },
            uploadInfo,
          );
          if (message.length === 0) {
            props.navigate('/data/analysisData/' + props.dataCreateTaskKey, {
              state: {
                message: '財務データ登録を開始しました。処理状況はステータスで確認できます。'
              }
            });
          } else {
            // エラー
            setInputError({ ...inputError, errorMessage: message });
          }
        } catch (error) {
          console.error(error)
          errorLog("E000001", props.userId, viewId.accountingData, "uploadAccountingData", error);
        }
      }
    }];
    props.showModal({ title: title, body: body, button: button, isWide: false });
  }

  /**
   * 操作可否判定（ステータスと権限確認）
   */
  const hasOperation = () => {
    // 処理中は操作不可
    if (accountingUploadInfo.Status === accountingUploadTaskStatus.processing.value) {
      return false;
    }
    if (!props.auth.Availability) {
      return false;
    }
    if (accountingUploadInfo.Mode === analysisMode.standalone) {
      return props.auth.Standalone;
    }
    if (accountingUploadInfo.Mode === analysisMode.consolidated) {
      return props.auth.Consolidated;
    }
  }

  /**
   * マウスオーバー時の処理（ごみ箱アイコンを表示）
   */
  const handleMouseOver = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.add('active');
    }
  };

  /**
   * マウスアウト時の処理（ごみ箱アイコンを非表示）
   */
  const handleMouseOut = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.remove('active');
    }
  };

  /**
   * 選択解除
   */
  const handleClearSettings = (idx, accountIdx, amountIdx) => {
    // 選択済み科目列
    if (accountIdx === idx) {
      setAccountingUploadInfo({ ...accountingUploadInfo, AccountHeaderColIndex: null });
    }
    // 選択済み金額列
    else if (amountIdx === idx) {
      setAccountingUploadInfo({ ...accountingUploadInfo, AmountHeaderColIndex: null });
      setSelectAmountColumn(false);
    }
    else {
      return;
    }
    // 選択状態等を初期化
    _clearSettings(false);
  }

  /**
   * シート名変更
   */
  const handleSheetChange = (e) => {
    const sheet = inputFileInfo.sheetList.find(ele => ele.id === Number(e.target.value));
    setAccountingTable(_readWorksheet(sheet.worksheet, inputFileInfo.headerRow));
    setAccountingUploadInfo({ ...accountingUploadInfo, SheetNo: Number(e.target.value) });
    // 選択状態等を初期化
    _clearSettings();
  }

  /**
   * 財務情報紐づきチェック
   */
  const isAccountInfoLinked = (accountingUploadInfo) => {
    return accountingUploadInfo.AccountInfo.some(account => account.CurrentInfo.length > 0);
  }

  /**
   * 描画処理
   */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <div id="wrapper">
        {/* ========== コンテンツ ========== */}
        <main className="page-container">
          <section className="page-content">
            <div className="centered-container">
              {/* 上階層に戻る */}
              <div className="bg main">
                <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                  <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                    <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                      <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                      <span className="text-h5 font-bold">{titleName.analysisData}に戻る</span>
                    </span>
                  </Link>
                </div>
              </div>
              {/* コンテンツヘッダ */}
              <header className="page-header flex justify-center py-1">
                <h1 className="page-header__name text-h1 grey--text py-8">{titleName.accountingData}</h1>
              </header>
              {/* コンテンツボディ */}
              <div className="page-body">
                <div className="centered-container-1600">
                  {accountingUploadInfo && <>
                    <div className="border-t border-lightgray blue1 lighten-3"></div>
                    <div className="sub-title-area flex justify-center py-10">
                      <h3 className="text-h4 mt-1 font-bold">更新対象データ：</h3>
                      {/* 表組み */}
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">種類</td>
                            <td width="100px" className="border-blue1">{periodKind[accountingUploadInfo._PeriodKind].name}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">期間</td>
                            <td width="200px" className="border-blue1">{accountingUploadInfo._PeriodValue}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="base-table ml-5">
                        <tbody className="text-h5 text-center">
                          <tr>
                            <td width="100px" className="blue1 white--text">対象</td>
                            <td width="100px" className="border-blue1">{accountingUploadInfo.Mode === analysisMode.standalone ? "単体" : "連結"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>}
                  <div>
                    <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                  </div>
                  {accountingUploadInfo ? (
                    <>
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                        <div className="content-area">
                          <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">財務データのアップロードとデータマッピング</h2>
                          <div className="accounting-header flex my-5">
                            {(() => {
                              const isDisabled = !hasOperation()
                              return (
                                <div className="accounting-btn-group flex text-caption-2">
                                  <span className="file-type font-bold">ファイル形式：</span>
                                  <select className="input-file-type" value={inputFileInfo.fileType}
                                    disabled={isDisabled}
                                    onChange={(e) => setInputFileInfo({ ...inputFileInfo, fileType: e.target.value })}>
                                    <option value="CSV">CSV</option>
                                    <option value="EXCEL">Excel</option>
                                  </select>
                                  <span className="excel-header font-bold">ヘッダー行：</span>
                                  <input type="number" className="input-excel-header"
                                    disabled={isDisabled} min={1}
                                    value={inputFileInfo.headerRow}
                                    onChange={(e) => setInputFileInfo({ ...inputFileInfo, headerRow: Number(e.target.value) })}></input>
                                  <span className="px-2 text-caption-2 input-file" title={inputFileInfo.inputFile?.name}>{inputFileInfo.inputFile ? inputFileInfo.inputFile.name : "未選択"}</span>
                                  <input type="file" ref={inputAccountingFile} style={{ display: "none" }} onChange={(e) => setInputAccountingFile(e)}></input>
                                  <button className="btn read-btn white--text text-nowrap py-1.5 ml-1 pr-10 inline-flex items-center"
                                    disabled={isDisabled || !accountingTable}
                                    onClick={() => confirmAccounting()}>再読み込み</button>
                                  <button className="btn read-btn white--text text-nowrap py-1.5 ml-1 pr-10 inline-flex items-center"
                                    disabled={isDisabled || !accountingTable}
                                    onClick={() => confirmFileChange()}>ファイル変更</button>
                                </div>
                              )
                            })()}
                            <div className="red--text text-caption-2 ml-2">{inputFileInfo.inputError}</div>
                          </div>
                          <div className="border-t border-blue1" />
                          {accountingUploadInfo.Status !== accountingUploadTaskStatus.processing.value ? (
                            <>
                              <DndContext autoScroll={false} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                <div className="flex">
                                  <div className="mt-2">
                                    <div className="accounting-file flex middle text-caption-2 mb-3">
                                      <span className="text-caption-2 font-bold">CHROFY利用科目</span>
                                      {
                                        (() => {
                                          let description = "";
                                          if (accountingTable && accountingUploadInfo.AccountHeaderColIndex !== null && accountingUploadInfo.AmountHeaderColIndex !== null) {
                                            description = "右表から対象のセルをドラッグ&ドロップしてください。"
                                          } else {
                                            return "";
                                          }
                                          return <div className="red--text text-caption-2 ml-4 accounting-description px-2">{description}</div>
                                        })()
                                      }
                                    </div>
                                    <div id="accounting-table-top--left" className="-translate-y-0.5"></div>
                                    <div id="accounting-table--left" className="setting-table relative is-accounting">
                                      <div id="accounting-table__scroll--left" className="accounting-table__scroll">
                                        <table className="base-table accounting-account-table w-full">
                                          <thead className="text-h7">
                                            <tr>
                                              <th>科目</th>
                                              <th>対象外</th>
                                              <th>金額(前{periodKind[accountingUploadInfo._PeriodKind].unit})</th>
                                              <th colSpan={3}>金額(今{periodKind[accountingUploadInfo._PeriodKind].unit})</th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-caption-2 text-nowrap">
                                            {(() => {
                                              // 対象外チェック
                                              const changeNoTarget = (idx) => {
                                                // CHROFY利用科目を削除
                                                const account = accountingUploadInfo.AccountInfo[idx];
                                                account.NoTargetFlag = !account.NoTargetFlag;
                                                setAccountingUploadInfo({ ...accountingUploadInfo });
                                                // 線を再描画
                                                _reWriteLineRow(account, idx, true);
                                              }
                                              // 加算処理
                                              const plusAction = (info) => {
                                                info.Operation = "+";
                                                setAccountingUploadInfo({ ...accountingUploadInfo });
                                              };
                                              // 減算処理
                                              const minusAction = (info) => {
                                                info.Operation = "-";
                                                setAccountingUploadInfo({ ...accountingUploadInfo });
                                              };
                                              // 金額計算エリア
                                              const calcAmountArea = (info) => {
                                                const plusDisabled = info.Operation !== "+"
                                                const minusDisabled = info.Operation !== "-"
                                                return (<>
                                                  <span className="calc-area">
                                                    <button className="button_plus" onClick={() => plusAction(info)} disabled={!plusDisabled}>
                                                      <AddIcon fontSize="small" color={plusDisabled ? "disabled" : ""} />
                                                    </button>
                                                    <button className="button_minus" onClick={() => minusAction(info)} disabled={!minusDisabled}>
                                                      <RemoveIcon fontSize="small" color={minusDisabled ? "disabled" : ""} />
                                                    </button>
                                                  </span>
                                                </>)
                                              }
                                              return accountingUploadInfo.AccountInfo.sort((a, b) => a._SortKey < b._SortKey ? -1 : 1).map((account, idx) => {
                                                return (
                                                  <tr key={idx}>
                                                    <td className={`chrofy-account-title ${activeRowIdx === idx ? "is-active" : ""}`} title={account._AccountTitle} onClick={() => _reWriteLineRow(account, idx)}>
                                                      <div className="ellipsis">{account._AccountTitle}</div>
                                                      {/* 内訳 */}
                                                      {(account.CurrentInfo.length > 1 || account._PreviousCurrentInfo.length > 1) && (
                                                        <div className="mt-1">（内訳）</div>
                                                      )}
                                                    </td>
                                                    <td className="no-target l-large text-center top">
                                                      <Checkbox size='small' checkedIcon=<CheckBoxRountedIcon />
                                                        disabled={!hasOperation()} checked={account.NoTargetFlag} onChange={(e) => changeNoTarget(idx)} />
                                                    </td>
                                                    <td className="previous-info" onClick={() => _reWriteLineRow(account, idx)}>
                                                      <div className="table-container">
                                                        {account._PreviousCurrentInfo.length === 0 ? (
                                                          <table className="inline-table">
                                                            <tbody>
                                                              <tr>
                                                                <td className="top w-full">
                                                                  <div className="grey2--text mt-1 ml-2">{account._PreviousNoTargetFlag ? "対象外" : "未設定"}</div>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        ) : (
                                                          <table className="inline-table">
                                                            <tbody>
                                                              {/* 合計行 */}
                                                              {account._PreviousCurrentInfo.length > 1 ? (
                                                                // 合計行
                                                                <tr className="sum-area">
                                                                  <td className="right amount-no-calc amount-area">
                                                                    {formatCommaDigit(account._PreviousCurrentInfo.reduce((sum, info) => {
                                                                      return info.Operation === "+" ? sum + info.Amount : sum - info.Amount;
                                                                    }, 0))}
                                                                  </td>
                                                                  <td></td>
                                                                </tr>
                                                              ) : ""}
                                                              {/* 個別行 */}
                                                              {account._PreviousCurrentInfo.map((info, idx2) => {
                                                                return (
                                                                  <tr key={idx2} className={account._PreviousCurrentInfo.length === 1 && account.CurrentInfo.length > 1 ? "sum-area" : ""}>
                                                                    <td className="right amount-no-calc"><span className="pr-1">{info.Operation}</span><span>{formatCommaDigit(info.Amount)}</span></td>
                                                                    <td className="account-title-no-calc" title={info.AccountTitle}>[{info.AccountTitle}]</td>
                                                                  </tr>
                                                                )
                                                              })}
                                                            </tbody>
                                                          </table>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td id={"chrofy_account_" + account.AccountCode} className={`title current-info ${account.NoTargetFlag ? "" : "dragable"}`} onClick={() => _reWriteLineRow(account, idx)}>
                                                      <div className="table-container">
                                                        {account.CurrentInfo.length === 0 || account.NoTargetFlag ? (<>
                                                          <Droppable id={account.AccountCode + "_" + idx}>
                                                            <tbody>
                                                              <tr>
                                                                <td className="top w-full">
                                                                  <div className="grey2--text mt-1 ml-2">{account.NoTargetFlag ? "対象外" : "未設定"}</div>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </Droppable>
                                                          <table className="hidden-table">
                                                            <tbody>
                                                              <tr id={"chrofy_account_" + account.AccountCode + "_" + idx + "_0"}><td></td></tr>
                                                            </tbody>
                                                          </table>
                                                        </>) : (<>
                                                          <Droppable id={account.AccountCode + "_" + idx}>
                                                            <tbody>
                                                              {/* 合計行 */}
                                                              {account.CurrentInfo.length > 1 ? (
                                                                <tr className="sum-area">
                                                                  <td colSpan="2" className="right amount">
                                                                    {formatCommaDigit(account.CurrentInfo.reduce((sum, info) => {
                                                                      return info.Operation === "+" ? sum + info.Amount : sum - info.Amount;
                                                                    }, 0))}
                                                                  </td>
                                                                  <td></td>
                                                                  <td></td>
                                                                </tr>
                                                              ) : ""}
                                                              {/* 個別行 */}
                                                              {account.CurrentInfo.map((info, idx2) => {
                                                                const displayHoverId = `display-hover-${idx}_${idx2}`;
                                                                return (
                                                                  <tr key={idx2} className={account.CurrentInfo.length === 1 && account._PreviousCurrentInfo.length > 1 ? "sum-area" : ""}>
                                                                    <td className="amount flex">{calcAmountArea(info, displayHoverId)}</td>
                                                                    <td className="amount-area">
                                                                      <span onMouseOver={() => handleMouseOver(displayHoverId)} onMouseOut={() => handleMouseOut(displayHoverId)}>{formatCommaDigit(info.Amount)}</span>
                                                                    </td>
                                                                    <td className="account-title" onMouseOver={() => handleMouseOver(displayHoverId)} onMouseOut={() => handleMouseOut(displayHoverId)} title={info.AccountTitle}>[{info.AccountTitle}]</td>
                                                                    <td className="remove">
                                                                      {hasOperation() && activeRowIdx === idx ? (
                                                                        <div id={displayHoverId} className="display-hover">
                                                                          <IconButton size="small" onClick={() => deleteLine(idx, idx2)} sx={{ padding: "0 2" }}>
                                                                            <DeleteOutlineOutlined fontSize="small" />
                                                                          </IconButton>
                                                                        </div>
                                                                      ) : (<div className="pr-5"></div>)
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              })}
                                                            </tbody>
                                                          </Droppable>
                                                          <table className="hidden-table">
                                                            <tbody>
                                                              {account.CurrentInfo.length > 1 && <tr><td></td></tr>}
                                                              {account.CurrentInfo.map((_, idx2) => {
                                                                return (
                                                                  <tr key={idx2} id={"chrofy_account_" + account.AccountCode + "_" + idx + "_" + idx2}
                                                                    className={activeRowIdx === idx ? "is-selected" : ""}><td></td></tr>
                                                                )
                                                              })}
                                                            </tbody>
                                                          </table>
                                                        </>)}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              })
                                            })()}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div id="accounting-table-bottom--left" className="h-1"></div>
                                  </div>
                                  <div className={`w-[60px] shrink-0 relative ${lineList.length > 0 ? "is-mapping" : ""}`}>
                                    <div className="relative z-10 bg-[#ffffff] pt-12 text-center">
                                      {/* 矢印 */}
                                      {accountingTable &&
                                        <span className={`align-top link--text icon-direction icon-32px rotate-180 inline-block is-active
                                          ${accountingUploadInfo.AccountHeaderColIndex === null || accountingUploadInfo.AmountHeaderColIndex === null ? "opacity-50" : ""}`}></span>
                                      }
                                      {/* 角丸線を省略表示する要素（上） */}
                                      <div className="leader-line-omission"><span></span><span></span><span></span><span></span><span></span></div>
                                    </div>
                                    {/* 角丸線を省略表示する要素（下） */}
                                    <div className="z-10 w-[60px] absolute -bottom-0.5" id="leader-line-omission-bottom">
                                      <div className="leader-line-omission !relative"><span></span><span></span><span></span><span></span><span></span></div>
                                      <div className="mt-px h-3 bg-white"></div>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div className="accounting-file-header flex middle text-caption-2 mb-3">
                                      <span className={`file-name font-bold ${accountingUploadInfo.FileType === "CSV" ? "type-csv" : "type-excel"}`} title={accountingUploadInfo.FileName}>{accountingUploadInfo.FileName ? accountingUploadInfo.FileName : "未選択"}</span>
                                      {inputFileInfo.sheetList.length > 0 && <>
                                        <span className="excel-sheet font-bold">シート名：</span>
                                        <select className="excel-sheet-list"
                                          value={accountingUploadInfo.SheetNo}
                                          onChange={(e) => {
                                            // 科目情報が紐づいていない場合、確認ダイアログを表示しない
                                            if (isAccountInfoLinked(accountingUploadInfo)) {
                                              // 切り替え先のシート番号をここで引き渡す。モーダルウィンドウ表示のタイミングでは変更前の物が取得されるため。
                                              confirmSheetChange(e, Number(e.target.value));
                                            } else {
                                              handleSheetChange(e);
                                            }
                                          }
                                          }>
                                          {inputFileInfo.sheetList.map((sheet, idx) => {
                                            return <option key={idx} value={sheet.id}>{sheet.name}</option>
                                          })}
                                        </select>
                                      </>}
                                      {
                                        (() => {
                                          let description = "";
                                          if (accountingTable) {
                                            if (accountingUploadInfo.AccountHeaderColIndex === null) {
                                              description = "科目列を選択してください。（再クリックで選択解除）"
                                            }
                                            else if (accountingUploadInfo.AmountHeaderColIndex === null) {
                                              description = "金額列を選択してください。（再クリックで選択解除）"
                                            } else {
                                              description = "金額を選択してドラッグ&ドロップしてください。"
                                            }
                                            return <div className="red--text text-caption-2 ml-4 accounting-description px-2">{description}</div>
                                          } else {
                                            return "";
                                          }
                                        })()
                                      }
                                    </div>
                                    <div id="accounting-table-top" className="translate-y-0.5"></div>
                                    <div id="accounting-table" className="setting-table relative is-accounting">
                                      {/*  表組み */}
                                      <div id="accounting-table__scroll" className={accountingTable ? "accounting-table__scroll" : ""}>
                                        {accountingTable ? (
                                          <table className="base-table accounting-file-table w-full">
                                            <thead className="text-h7">
                                              <tr>
                                                <th width="1px"></th>
                                                {
                                                  (() => {
                                                    const scroll = document.getElementById("accounting-table__scroll");
                                                    const width = (scroll?.getBoundingClientRect().width / accountingTable.headers.length) + "px";
                                                    return accountingTable.headers.map((header, idx) => {
                                                      const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
                                                      const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
                                                      const className = (() => {
                                                        if (accountIdx === idx) return "is-selected-account";
                                                        if (amountIdx === idx) return "is-selected-amount";
                                                        if (accountIdx === null) return "select-account";
                                                        if (amountIdx === null) return "select-amount";
                                                      })();

                                                      const handleClick = () => {
                                                        // 選択済み科目列または金額列の選択解除
                                                        if (accountIdx === idx || amountIdx === idx) {
                                                          // 科目情報が紐づいていない場合、確認ダイアログを表示しない
                                                          if (isAccountInfoLinked(accountingUploadInfo)) {
                                                            confirmClearSettings(idx, accountIdx, amountIdx);
                                                          } else {
                                                            handleClearSettings(idx, accountIdx, amountIdx);
                                                          }
                                                        }
                                                        // 科目列未選択
                                                        else if (accountIdx === null) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AccountHeaderColIndex: idx });
                                                        }
                                                        // 金額列未選択
                                                        else if (amountIdx === null) {
                                                          setAccountingUploadInfo({ ...accountingUploadInfo, AmountHeaderColIndex: idx });
                                                          setSelectAmountColumn(true);
                                                        } else {
                                                          return;
                                                        }
                                                      }
                                                      return <th key={idx} style={{ width: width }} className={hasOperation() ? className : className + " disabled"}>
                                                        <div onClick={hasOperation() ? handleClick : () => { }}>{header ? header : "　"}</div>
                                                      </th>
                                                    })
                                                  })()
                                                }
                                              </tr>
                                            </thead>
                                            <tbody className="text-caption-2 text-nowrap">
                                              {accountingTable.records.map((record, idx) => {
                                                const isSelectRow = accountingUploadInfo.AccountInfo.some((ele, accountIdx) => {
                                                  return activeRowIdx === accountIdx && ele.CurrentInfo.find(e => e.RowIndex === idx);
                                                });
                                                const rowClass = isSelectRow ? "select-row" : undefined;
                                                return (
                                                  <tr key={idx} className={rowClass}>
                                                    <th id={"accounting_" + idx}></th>
                                                    {record.map((col, idx2) => {
                                                      const accountIdx = accountingUploadInfo.AccountHeaderColIndex;
                                                      const amountIdx = accountingUploadInfo.AmountHeaderColIndex;
                                                      const colClass = (() => {
                                                        if (amountIdx === idx2) {
                                                          if (rowClass) {
                                                            if (hasOperation()) {
                                                              return "draggable-col select-col"
                                                            }
                                                            return "select-col"
                                                          } else {
                                                            return "draggable-col"
                                                          }
                                                        }
                                                      })();
                                                      if (hasOperation() && amountIdx === idx2 && accountIdx !== null) {
                                                        return <Draggable key={idx2} id={idx + '_' + idx2} val={col} className={colClass}>{col}</Draggable>
                                                      } else {
                                                        return <td key={idx2} className={colClass}>{col}</td>
                                                      }
                                                    })}
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <Dropzone multiple={false} disabled={inputFileInfo.inputFile !== undefined} onDrop={async (acceptedFiles) => {
                                            const fileData = acceptedFiles[0];
                                            setInputFileInfo({
                                              ...inputFileInfo,
                                              inputFile: {
                                                name: fileData.name,
                                                extension: fileData.name.slice(fileData.name.lastIndexOf(".") + 1),
                                                fileData: fileData
                                              }
                                            });
                                          }}>
                                            {({
                                              getRootProps,
                                              getInputProps,
                                              isDragAccept,
                                            }) => (
                                              <div role="presentation" tabIndex={0}
                                                {...getRootProps({
                                                  className: `accounting-file-upload accounting-upload-base-style
                                                    ${isDragAccept && "acceptStyle"}
                                                    ${inputFileInfo.inputFile !== undefined && "is-disabled"}`,
                                                })}>
                                                {inputFileInfo.inputFile ? (<>
                                                  {/* ファイル選択済み */}
                                                  <p className="text-center">
                                                    <span className="block text-h5 mt-20 mb-12">
                                                      {inputFileInfo.inputFile.name}<br />
                                                    </span>
                                                    <span className="block text-h4 mb-2">
                                                      ファイル形式とヘッダー行を確認して
                                                    </span>
                                                    <span className="block text-h4 mb-3">
                                                      ファイルを読み込んでください
                                                    </span>
                                                    <RefleshButton name="読み込み" className="btn btn-select-file inline-block mt-7"
                                                      onClick={() => readAccountingData()} />
                                                  </p>
                                                </>) : (<>
                                                  {/* ファイル未選択 */}
                                                  <input
                                                    multiple=""
                                                    type="file"
                                                    tabIndex={-1}
                                                    style={{ display: "none" }}
                                                    {...getInputProps()}
                                                  />
                                                  <p className="text-center">
                                                    <span className="block text-h4 mt-40 mb-4">
                                                      設定元データファイルをここにドラッグ&ドロップするか<br />
                                                    </span>
                                                    <span className="block text-h4">
                                                      ボタンを押して選択してください
                                                    </span>
                                                    <span className="btn btn-select-file inline-block mt-7">
                                                      ファイルを選択
                                                    </span>
                                                  </p>
                                                </>)}
                                              </div>
                                            )}
                                          </Dropzone>
                                        )}
                                      </div>
                                    </div>
                                    <div id="accounting-table-bottom"></div>
                                  </div>
                                </div>
                                <DragOverlay dropAnimation={{
                                  sideEffects: defaultDropAnimationSideEffects({
                                    styles: {
                                      active: {},
                                      dragOverlay: {
                                        opacity: "0"
                                      }
                                    }
                                  })
                                }}>
                                  <div className="drag-content" style={{ minWidth: dragContentWidth + "px" }}>{dragContent}</div>
                                </DragOverlay>
                              </DndContext>
                            </>
                          ) : (
                            <>
                              <div className="text-center my-10 pt-10">
                                <span className="text-h5 red--text">ステータスが「{accountingUploadTaskStatus.processing.name}」のため操作できません。</span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="content-footer mt-16 mb-8">
                          <div className="border-t border-blue1" />
                          <p className="text-caption-2 red--text text-center mt-4 mb-10">
                            ※レポートデータ更新を行うことで、ダッシュボードに反映されます。
                          </p>
                          {/*  ===== フッターボタン ===== */}
                          <div className="footer_button_area px-24 mt-4">
                            <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                              <button type="button" className="btn footer-btn button_back">キャンセル</button>
                            </Link>
                            <SpinnerButton name="アップロード" className="btn footer-btn button_base"
                              disabled={!hasOperation()} onClick={() => uploadAccountingData()} />
                          </div>
                          {/*  ===== /フッターボタン ===== */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                        <div className="content-area">
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">選択した財務データアップロードはすでに削除されているか存在しません。</h2>
                          <h2 className="text-h4-em text-center text--lighten-70 mb-2">{titleName.analysisData}に戻って操作をやり直してください。</h2>
                        </div>
                        <div className="content-footer mt-16">
                          <div className="border-t border-blue1" />
                          {/*  ===== フッターボタン ===== */}
                          <div className="footer_button_area mt-8">
                            <Link color="inherit" variant="button" underline="none" component={RouterLink} to={"/data/analysisData/" + props.dataCreateTaskKey}>
                              <button type="button" className="footer-btn button_back">戻る</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment >
  )
}

const AccountingData = (props) => {
  const state = useSelector((state) => state);
  const dataCreateTaskKey = props.params.dataCreateTaskKey;
  const key = props.params.key;
  const showToast = useToast();
  const showModal = useModal();
  const navigate = useNavigate();
  if (state.authority.Upload_AuthInfo.Availability && state.authority.Upload_AuthInfo.Auth_Conf.View) {
    return (
      <AccountingDataContent
        dataCreateTaskKey={dataCreateTaskKey}
        AccountingKey={key}
        userId={state.user.sub}
        userName={state.user.userName}
        pageTitle={titleName.accountingData + titleName.common}
        corporationNumber={state.company.corporationNumber}
        bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
        showToast={showToast}
        showModal={showModal}
        navigate={navigate}
        auth={state.authority.Upload_AuthInfo.Auth_Conf.Uploadable_Accounting}
      />
    )
  } else {
    return <Forbidden />
  }
};

export default (props) => <AccountingData {...props} params={useParams()} />;
