import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

import Forbidden from "../../../_components/Forbidden";
import RelatedDashboard from "../parts/RelatedDashboard";
import { useModal } from "../../../_components/Modal";
import { getMessage } from "../../../common/utils/MessageUtils";
import { errorLog } from "../../../common/logger/Logger";
import { httpGet, httpPost } from "../../../services/api/AmplifyApi";
import { formatCommaDigit, parseCommaDigit } from "../../../common/utils/NumberUtils";
import { getDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { viewId, titleName, table, restApiKey, masterClassification, dataCreateStatus, masterStatus } from "../../../_constants/Code";
import { PathInfoList } from "../../../_constants/PathInfoList";
import img_dashboard_reference from "../../../assets/img/master/img_dashboard_reference.png"

/*==================================
  定数定義
==================================*/
/**
 * 新卒年齢階層に紐づく設定値
 * @constant
 * @type {Array<string>}
 */
const AGE_GROUPS = [
  {
    ageGroup: "25歳未満",
    label: "-24歳",
    level: 1,
    range: { start: 18, end: 24 }
  },
  {
    ageGroup: "25歳以上30歳未満",
    label: "25歳-29歳",
    level: 2,
    range: { start: 25, end: 29 }
  },
];

/**
 * 性別（男性、女性、nonbinary）
 * @constant
 * @type {Object}
 */
const GENDERS = {
  male: "男性",
  female: "女性",
  nonbinary: "nonbinary"
};

/**
 * 新卒採用情報のデータキー
 * @constant
 * @type {Object}
 */
const DATA_KEYS = {
  new_graduate_transfer_years: "new_graduate_transfer_years",
  new_graduate: "new_graduate"
};

/**
 * NewGraduateInfoContentコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.pageTitle - ページタイトル
 * @param {string} props.corporationNumber - 法人番号
 * @param {string} props.corporationName - 会社名
 * @param {string} props.bucketName - 企業バケット名
 * @param {string} props.accPeriod - 決算月
 * @param {string} props.userId - ユーザsub
 * @param {string} props.userName - ユーザ名
 * @param {function} props.navigate - ナビゲーション関数
 * @param {function} props.showModal - モーダル表示関数
 */
const NewGraduateInfoContent = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [master, setMaster] = useState([]);
  const [newGradData, setNewGradData] = useState([]);
  const [newGradTransferYearsData, setNewGradTransferYearsData] = useState([]);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState({});
  const [serverError, setServerError] = useState({});
  const [inputError, setInputError] = useState({});
  const [averageAges, setAverageAges] = useState({});
  const [validAgeGroups, setValidAgeGroups] = useState([]);

  // 初期処理
  useEffect(() => {
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
  }, []);

  // 入力エラー時にスクロールをトップへ
  useEffect(() => {
    if (inputError.errorMessage || serverError.errorMessage) {
      window.scrollTo(0, 0);
    };
  }, [inputError, serverError]);

  // 設定可能な年齢階層判定
  useEffect(() => {
    const validGroups = AGE_GROUPS.filter(group => group.level <= selectedAgeGroup.level).map(group => group.ageGroup);
    setValidAgeGroups(validGroups);
  }, [selectedAgeGroup]);

  const isGroupDisabled = (ageGroup) => {
    return !validAgeGroups.includes(ageGroup);
  };

  /*==================================
    イベントハンドラ
  ==================================*/
  /*  ===== 新卒年齢階層の設定 ===== */
  /**
   * 新卒年齢階層（ラジオボタン）が変更された際の処理
   * @param {string} ageGroup - 新卒年齢階層
   */
  const changeAgeGroup = (ageGroup) => {
    const selectedGroup = AGE_GROUPS.find(group => group.ageGroup === ageGroup);
    setSelectedAgeGroup(selectedGroup);

    const AverageAges = calcAverageAges(newGradTransferYearsData);
    setAverageAges(AverageAges);
  };

  /*  ===== 新卒中心年齢の設定 ===== */
  /**
   * 年齢加算年数を計算
   * @param {number} averageAge - 平均年齢
   * @param {number} rangeEnd - 年齢範囲の終了値
   * @returns {number} 年齢加算年数
   */
  const calculateAgeAddition = (averageAge, rangeEnd) => {
    return rangeEnd + 1 - averageAge;
  };

  /**
   * 新卒中心年齢が変更された際の処理
   * @param {string} ageGroup - 新卒年齢階層
   * @param {string} value - 新卒中心年齢
   */
  const handleInputChange = (ageGroup, value) => {
    const newData = [...newGradTransferYearsData];
    const averageAge = parseInt(value, 10);
    const targetIndex = newData.findIndex(item => item.age_group_new_grad === ageGroup);

    if (targetIndex !== -1) {
      const ageGroupData = AGE_GROUPS.find(group => group.ageGroup === ageGroup);
      const rangeEnd = ageGroupData.range.end;
      const ageAddition = calculateAgeAddition(averageAge, rangeEnd);

      newData[targetIndex].years_setting_new_grad = ageAddition;

      setAverageAges(prevState => ({
        ...prevState,
        [`${ageGroup}-centerAge`]: averageAge
      }));

      setNewGradTransferYearsData(newData);
    }
  };

  /*  ===== 新卒入社予定人数の設定 ===== */
  /**
   * 入力欄「入社予定人数」からフォーカスが外れた際の処理
   * @param {Object} e - イベントオブジェクト
   * @param {number} dataIndex - データのインデックス
   */
  const handleBlur = (e, dataIndex) => {
    let value = e.target.value;

    // 負数が入力されている場合、値をクリア
    if (parseFloat(value) < 0) {
      value = "";
    } else {
      value = formatCommaDigit(value);
    };

    // 更新される行の人数を変更
    const updatedData = [...newGradData];
    updatedData[dataIndex].number_of_people = value;

    setNewGradData(updatedData);
  };

  /**
   * 入力欄「入社予定人数」の設定値が変更された際の処理
   * @param {Object} e - イベントオブジェクト
   * @param {number} dataIndex - データのインデックス
   */
  const handleChange = (e, dataIndex) => {
    let value = e.target.value;

    // 更新された行の人数を変更
    const updatedData = [...newGradData];
    updatedData[dataIndex].number_of_people = value;

    setNewGradData(updatedData);
  };

  /*==================================
    ユーティリティ関数（ヘルパー関数）
  ==================================*/
  /**
   * 初期処理
   * @returns {Promise<void>}
   */
  const init = async () => {
    // マスター情報取得
    const masterData = await fetchMasterData();
    setMaster(masterData);

    // 新卒採用情報取得
    const { graduateData, transferYearsData, initAgeGroups } = await fetchNewGraduateData();
    setNewGradData(graduateData);
    setNewGradTransferYearsData(transferYearsData);

    // 新卒年齢階層設定
    const initialAgeGroup = getInitialAgeGroup(initAgeGroups);
    const selectedGroup = AGE_GROUPS.find(group => group.ageGroup === initialAgeGroup);
    setSelectedAgeGroup(selectedGroup);

    // 新卒中心年齢算出
    const initialAverageAges = calcAverageAges(transferYearsData);
    setAverageAges(initialAverageAges);

    // 初期処理完了判定設定
    setIsLoaded(true);
  };

  /**
   * マスター一覧情報取得関数
   * @returns {Promise<Object>} マスター一覧情報
   */
  const fetchMasterData = async () => {
    try {
      const masterData = await getDynamoDB(
        table.Master,
        null,
        props.corporationNumber,
        { "CorporationNumber": props.corporationNumber, "MasterId": viewId.newGraduateInfo },
        null
      );
      return masterData[0];
    } catch (error) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "fetchMasterData", error);
    };
  };

  /**
   * データ作成タスク管理情報取得関数
   * @returns {Promise<Object>} データ作成タスク管理情報
   */
  const fetchDataCreateTask = async () => {
    try {
      const dataCreateTask = await getDynamoDB(
        table.DataCreateTask,
        null,
        props.corporationNumber,
        { "CorporationNumber": props.corporationNumber, }, { "DeleteFlag": 0, }
      );
      return dataCreateTask;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "fetchDataCreateTask", error);
    };
  };

  /**
   * 新卒採用情報取得関数
   * @returns {Promise<Object>} 新卒採用情報
   * @returns {Object[]} return.graduateData - 新卒人数情報
   * @returns {Object[]} return.transferYearsData - 新卒移動年数情報
   * @returns {string[]} return.initAgeGroups - 初期設定された新卒年齢階層
   */
  const fetchNewGraduateData = async () => {
    try {
      // IF02039_新卒採用取得処理
      // リクエスト情報
      let init = {
        headers: {},
        queryStringParameters: {
          corporation_number: props.corporationNumber
        }
      };
      const jsonData = await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getNewGraduate(props.corporationNumber), init);
      if (jsonData) {
        let initAgeGroups = [];
        let transferYearsData = [];

        // データテンプレート作成（新卒人数情報）
        const graduateData = AGE_GROUPS.flatMap(group => {
          return Object.values(GENDERS).map(gender => {
            const dataItem = jsonData.new_graduate.find(item => item.age_group === group.ageGroup && item.gender === gender);
            return {
              "age_group": group.ageGroup,
              "gender": gender,
              "number_of_people": dataItem?.number_of_people !== undefined ? dataItem.number_of_people.toString() : ""
            };
          });
        });

        // データテンプレート作成（新卒移動年数情報）
        // ※下位階層の移動年数情報を優先して追加するため、上位階層からテンプレートデータを作成する
        AGE_GROUPS.slice().reverse().forEach(group => {
          const dataItem = jsonData?.new_graduate_transfer_years.find(item => item.age_group_new_grad === group.ageGroup);

          if (dataItem) {
            // 初期値となる新卒年齢階層を保持
            initAgeGroups.push(group.ageGroup);

            // 下位階層の新卒年齢階層情報を取得
            const lowerLevelGroup = getLowerLevelGroup(group.level);

            // 直下階層の移動年数情報を追加
            if (lowerLevelGroup && !transferYearsData.some(item => item.age_group_new_grad === lowerLevelGroup.ageGroup)) {
              transferYearsData.push({
                "age_group_new_grad": lowerLevelGroup.ageGroup,
                "average_age_new_grad": "",
                "years_setting_new_grad": dataItem?.years_setting_other_new_grad || ""
              });
            }
          }

          // 新卒年齢階層のデータを追加
          if (!transferYearsData.some(item => item.age_group_new_grad === group.ageGroup)) {
            transferYearsData.push({
              "age_group_new_grad": group.ageGroup,
              "average_age_new_grad": dataItem?.average_age_new_grad || "",
              "years_setting_new_grad": dataItem?.years_setting_new_grad || ""
            });
          }
        });

        return { graduateData, transferYearsData, initAgeGroups };
      } else {
        setServerError({ errorMessage: "新卒採用情報取得処理でエラーが発生しました。" });
      }
    } catch (error) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "fetchNewGraduateData", error);
    }
  };

  /**
   * 下位階層取得関数（指定された新卒年齢階層の下位階層（直下）を取得）
   * @param {number} level - 指定された新卒年齢階層のレベル
   * @returns {Object|null} 下位階層（直下）のグループ情報、存在しない場合は null
   */
  const getLowerLevelGroup = (level) => {
    return AGE_GROUPS.find(group => group.level === level - 1) || null;
  };

  /**
   * 新卒中心年齢算出関数
   * @param {Object[]} transferYearsData - 新卒移動年数情報
   * @returns {object} 新卒中心年齢
   */
  const calcAverageAges = (transferYearsData) => {
    const averageAges = {};
    AGE_GROUPS.forEach(group => {
      const data = transferYearsData.find(item => item.age_group_new_grad === group.ageGroup);
      if (data) {
        const rangeEnd = group.range.end;
        averageAges[`${group.ageGroup}-centerAge`] = data.years_setting_new_grad !== "" ? rangeEnd + 1 - data.years_setting_new_grad : "";
      } else {
        averageAges[`${group.ageGroup}-centerAge`] = "";
      }
    });
    return averageAges;
  };

  /**
   * 新卒年齢階層取得（初回表示）
   * @param {Array<string>} initAgeGroups - 新卒年齢階層
   * @returns {string} 新卒年齢階層（初回表示）
   */
  const getInitialAgeGroup = (initAgeGroups) => {
    return initAgeGroups.reduce((prev, current) => {
      const prevGroup = AGE_GROUPS.find(group => group.ageGroup === prev);
      const currentGroup = AGE_GROUPS.find(group => group.ageGroup === current);
      return (prevGroup?.level || 0) > (currentGroup?.level || 0) ? prev : current;
    }, AGE_GROUPS[0].ageGroup);
  };

  /**
  * 新卒移動年数情報生成関数（新卒移動年数情報をマージし、選択された年齢階層に基づいてデータを整形）
  * @param {Array<Object>} transferYearsData - 新卒移動年数情報の配列
  * @param {Object} selectedGroup - 選択された年齢階層の情報
  * @returns {Array<Object>} マージされた新卒移動年数情報
  */
  const generateTransferYearsData = (transferYearsData, selectedGroup) => {
    const mergedData = transferYearsData.map(groupData => {
      if (groupData.age_group_new_grad === selectedGroup.ageGroup) {
        const lowerLevelGroup = getLowerLevelGroup(selectedGroup.level);
        const lowerLevelData = lowerLevelGroup
          ? transferYearsData.find(item => item.age_group_new_grad === lowerLevelGroup.ageGroup)
          : null;

        return {
          ...groupData,
          years_setting_other_new_grad: lowerLevelData ? lowerLevelData.years_setting_new_grad : ""
        };
      }
      return groupData;
    }).filter(data => data.age_group_new_grad === selectedGroup.ageGroup);

    return mergedData;
  };

  /*==================================
    コンポーネント
  ==================================*/
  /*  ===== 新卒採用情報更新 ===== */
  /**
   * 更新対象データを抽出
   * @returns {Object} 更新対象データ
   */
  const extractUpdateData = () => {
    const extractGraduateData = newGradData.filter(data => validAgeGroups.includes(data.age_group));
    const extractTransferYearsData = generateTransferYearsData(newGradTransferYearsData, selectedAgeGroup);

    return {
      new_graduate: extractGraduateData,
      new_graduate_transfer_years: extractTransferYearsData
    };
  };

  /**
    * 入力チェック
    * @param {Object[]} new_graduate - チェック対象の新卒データ
    * @param {Object[]} new_graduate_transfer_years - チェック対象の新卒移動年数データ
    * @returns {boolean} エラー判定（true：エラーあり、false：エラーなし）
    */
  const _checkInput = (new_graduate, new_graduate_transfer_years) => {
    let inputError = {};
    let hasError = false;

    try {
      // 新卒中心年齢の必須チェック
      new_graduate_transfer_years.forEach((item) => {
        const error = {};
        const errorMessage = getMessage("E010001", "新卒中心年齢");
        if (!item.years_setting_new_grad) {
          error.required = errorMessage;
          inputError[`${DATA_KEYS.new_graduate_transfer_years}-${selectedAgeGroup.ageGroup}`] = error;
          hasError = true;
        }

        // 新卒年齢階層が「25歳未満」でない場合、項目「年数設定（その他新卒）」の必須チェックを行う
        if (selectedAgeGroup.level !== 1) {
          const lowerAgeGroup = getLowerLevelGroup(selectedAgeGroup.level);
          if (lowerAgeGroup && !item.years_setting_other_new_grad) {
            error.required = errorMessage;
            inputError[`${DATA_KEYS.new_graduate_transfer_years}-${lowerAgeGroup.ageGroup}`] = error;
            hasError = true;
          }
        }
      });

      // 新卒入社予定人数の設定チェック
      new_graduate.forEach((item) => {
        const error = {};
        if (!item.number_of_people) {
          error.required = getMessage("E010001", `入社予定人数（${item.gender}）`);
          inputError[`${DATA_KEYS.new_graduate}-${item.age_group}-${item.gender}`] = error;
          hasError = true;
        }
      });

      if (hasError) {
        inputError.errorMessage = "入力内容に誤りがあります。";
      }

      return hasError;
    } catch (e) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "_checkInput", e);
      return true;
    } finally {
      setInputError(inputError);
    }
  };

  /**
  * 競合チェック
  * @param {Object} masterData - 最新のマスター一覧情報
  * @param {Object} dataCreateTask - 最新のデータ作成タスク管理情報
  * @returns {{ conflictError: boolean, errorMessages: object }} エラー判定（true：エラーあり、false：エラーなし）、エラー内容
  */
  const _checkConflict = (masterData, dataCreateTask) => {
    const masterErrorMessage = "「新卒採用設定」は他のユーザーがデータ反映を実行中です。データ反映完了後に再度設定値を変更して「登録」ボタンを押下してください。"
    const taskErrorMessage = "レポートデータを更新中です。データ更新完了を待ってから再度設定値を変更して「登録」ボタンを押下してください。";
    let errorMessages = {};
    let conflictError = false;

    try {
      // マスター一覧情報のステータスチェック
      if (masterData.Status === masterStatus.reflecting.value) {
        errorMessages[masterData.Classification] = errorMessages[masterData.Classification] || [];
        errorMessages[masterData.Classification].push({ errorMessage: masterErrorMessage });
        conflictError = true;
      };

      // データ作成タスク管理情報のステータスチェック
      const creatingTaskExists = dataCreateTask.some((t) => t.DataCreateStatus === dataCreateStatus.creating.value);
      if (creatingTaskExists) {
        errorMessages[masterData.Classification] = errorMessages[masterData.Classification] || [];
        errorMessages[masterData.Classification].push({ errorMessage: taskErrorMessage });
        conflictError = true;
      };
    } catch (e) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "_checkConflict", e);
      conflictError = true;
    } finally {
      return { conflictError, errorMessages };
    }
  };

  /**
   * 新卒採用情報更新処理（子画面）
   * @returns {JSX.Element} 新卒採用情報更新処理（子画面）
   */
  const updateNewGraduate = async () => {
    // データ抽出
    const { new_graduate, new_graduate_transfer_years } = extractUpdateData();

    if (_checkInput(new_graduate, new_graduate_transfer_years)) {
      return;
    }
    try {
      const title = "マスター設定値登録の確認";
      const description = (
        <React.Fragment>
          <div className="text-center">
            設定値を登録してダッシュボードに反映します。よろしいですか？
          </div>
          <div className="pt-5 text-center">
            ※ダッシュボードへの反映には5分～10分程度かかります<br />
            マスター管理画面のステータスから反映状況を確認してください
          </div>
        </React.Fragment>
      );
      const buttons = [{
        name: "実行",
        className: "footer-btn button_base",
        click: async () => {
          // マスター一覧情報取得（最新状態）
          let masterData = await fetchMasterData();
          // データ作成タスク管理情報取得（最新状態）
          let dataCreateTask = await fetchDataCreateTask();
          // 競合チェック
          const { conflictError, errorMessages } = await _checkConflict(masterData, dataCreateTask);
          if (!conflictError) {
            // 新卒採用情報更新処理
            try {
              setServerError({});
              const dataList = {
                new_graduate,
                new_graduate_transfer_years
              };
              const init = {
                body: {
                  request: {
                    corporation_number: props.corporationNumber,
                    corporation_name: props.corporationName,
                    user_name: props.userName,
                    file_data: dataList,
                    user_sub: props.userId,
                  },
                },
                headers: {},
              };
              await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateNewGraduate(props.corporationNumber), init)
                .then((res) => {
                  if (res.message.indexOf("Success") !== -1) {
                    props.navigate("/master/masterList", { state: { message: "データ反映を開始しました。" } });
                  } else {
                    setServerError({ errorMessage: "新卒採用情報更新処理でエラーが発生しました。" });
                  }
                });
            } catch (error) {
              errorLog("E000001", props.userId, viewId.newGraduateInfo, "updateNewGraduate", error);
            }
          } else {
            props.navigate("/master/masterList", { state: { errorMessages: errorMessages } });
          };
        }
      }];
      props.showModal({ title: title, description: description, button: buttons, isWide: true });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.newGraduateInfo, "updateNewGraduate", error);
    };
  };

  /*  ===== 新卒中心年齢の設定 ===== */
  /**
   * 新卒中心年齢リスト生成
   * @param {Object} range - 新卒年齢階層別、年齢範囲
   * @returns {Array} 年齢範囲リスト（リストボックス）
   */
  const renderDropdownOptions = (range) => {
    const options = [];
    for (let i = range.start; i <= range.end; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  /**
   * 新卒年齢階層（下位階層）判定
   * @param {Object} group - 判定対象の年齢階層
   * @param {Object} selectedGroup - 選択された年齢階層
   * @returns {boolean} 年齢階層の下位階層を判定
   */
  const isPreviousLevel = (group, selectedGroup) => {
    return group.level <= selectedGroup.level;
  };

  /**
   * 新卒中心年齢の設定（テーブル）
   * @returns {JSX.Element} 新卒中心年齢の設定（テーブル）
   */
  const settingMedianAgeNewGrad = () => {
    return AGE_GROUPS.map((group) => {
      const dataItem = newGradTransferYearsData.find(item => item.age_group_new_grad === group.ageGroup);

      // 新卒移動年数情報を取得できない場合はテーブルレコードを表示しない
      if (!dataItem) {
        return null;
      }

      let yearsSetting, centerAge;
      // 設定可能な年齢階層判定
      let isDisabled = isGroupDisabled(group.ageGroup);
      let errorClass = inputError[`${DATA_KEYS.new_graduate_transfer_years}-${group.ageGroup}`]?.required ? "is-error" : "";

      // 選択した新卒年齢階層に応じてマッピング（選択した新卒年齢階層・直下階層のみ活性状態）
      if (group.level === selectedAgeGroup.level || isPreviousLevel(group, selectedAgeGroup)) {
        yearsSetting = dataItem ? dataItem.years_setting_new_grad : "";
        centerAge = averageAges[`${group.ageGroup}-centerAge`] || "";
      } else {
        yearsSetting = null;
        centerAge = "";
        isDisabled = true;
      }

      // 新卒中心年齢の設定（テーブル）
      return (
        <tr key={group.level}>
          <th className="sticky left-0 z-10 setting-table__col-name">
            <div className="flex items-center setting-heading">
              <div className="setting-heading__name flex items-center">
                <span>{group.label}</span>
                {!isDisabled && <span className="text-h5 red--text">*</span>}
              </div>
            </div>
          </th>
          <td>
            <div className="flex items-center justify-center setting-table__data">
              <div className="select-control">
                <select
                  id={`select-center-age_${group.level}`}
                  className={`form-select rounded-lg ${errorClass}`}
                  value={isDisabled ? "" : centerAge}
                  onChange={(e) => handleInputChange(group.ageGroup, e.target.value)}
                  disabled={isDisabled}
                >
                  {centerAge === "" && <option value="" disabled hidden></option>}
                  {renderDropdownOptions(group.range)}
                </select>
                <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                  <span className="icon-arrow rotate-90"></span>
                </div>
              </div>
              <div className="flex items-center text-left setting-table__data-unit col-age">
                歳
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center justify-center setting-table__data">
              <div className="setting-heading__text pl-4">
                {yearsSetting !== null && yearsSetting !== undefined && yearsSetting !== "" ? `${yearsSetting}` : "-"}
              </div>
              <div className="text-left setting-table__data-unit col-year">
                年
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  /*  ===== 新卒入社予定人数の設定 ===== */
  /**
   * 小計算出（新卒入社予定人数）
   * @param {Array} data - 新卒人数情報
   * @returns {Object} 小計
   */
  const calculateSubtotals = (data) => {
    const subtotals = {};
    AGE_GROUPS.forEach(group => {
      const groupData = data.filter(item => item.age_group === group.ageGroup);
      Object.keys(GENDERS).forEach(genderKey => {
        const gender = GENDERS[genderKey];
        const count = groupData.filter(item => item.gender === gender).reduce((sum, item) => sum + parseInt(parseCommaDigit(item.number_of_people) || 0, 10), 0);
        if (!subtotals[group.ageGroup]) {
          subtotals[group.ageGroup] = {};
        }
        subtotals[group.ageGroup][gender] = count;
      });
    });
    return subtotals;
  };

  /**
   * 合計算出（新卒入社予定人数）
   * @param {Array} subtotals - 小計（新卒入社予定人数）
   * @returns {Object} 合計
   */
  const calculateTotals = (subtotals) => {
    const totals = {};
    Object.keys(GENDERS).forEach(genderKey => {
      const gender = GENDERS[genderKey];
      let count = 0;
      AGE_GROUPS.forEach(group => {
        count += subtotals[group.ageGroup][gender] || 0;
      });
      totals[gender] = count;
    });
    totals["all"] = Object.values(totals).reduce((sum, count) => sum + count, 0);
    return totals;
  };

  /**
   * 新卒入社予定人数の設定（テーブル）
   * @returns {JSX.Element} 新卒入社予定人数の設定（テーブル）
   */
  const settingProspectiveNumberOfNewGrad = () => {
    const filteredNewGradData = newGradData.filter(data => validAgeGroups.includes(data.age_group));
    const subtotals = calculateSubtotals(filteredNewGradData);
    const totals = calculateTotals(subtotals);

    return (
      <React.Fragment>
        {AGE_GROUPS.map((group) => {
          // 設定可能な年齢階層判定
          let isDisabled = isGroupDisabled(group.ageGroup);

          return (
            <React.Fragment key={group.ageGroup}>
              <tr>
                <th
                  rowSpan={Object.keys(GENDERS).length + 1}
                  className="sticky left-0 z-10 setting-table__col-name"
                >
                  <div className="flex items-center setting-heading">
                    <div className="setting-heading__name flex items-center">
                      <span className="pt-[6px]">{group.label}</span>
                      {!isDisabled && <span className="text-h5 red--text">*</span>}
                    </div>
                  </div>
                </th>
              </tr>
              {Object.keys(GENDERS).map((genderKey, index) => {
                const gender = GENDERS[genderKey];
                const row = newGradData.find(item => item.age_group === group.ageGroup && item.gender === gender);
                const count = row?.number_of_people !== undefined ? row.number_of_people : "";
                const dataIndex = newGradData.findIndex(item => item.age_group === group.ageGroup && item.gender === gender);
                const errorClass = inputError[`${DATA_KEYS.new_graduate}-${group.ageGroup}-${gender}`]?.required ? "is-error" : "";
                return (
                  <tr key={genderKey} className="setting-table__data">
                    <td className="col-gender">
                      <div className="flex items-center gender-container">
                        {gender}
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center justify-end">
                        <input
                          className={`text-input ${errorClass}`}
                          type="text"
                          name={"text_" + index}
                          placeholder={isDisabled ? "" : "数値を入力"}
                          value={isDisabled ? "" : count}
                          onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                          onChange={(e) => handleChange(e, dataIndex)}
                          onBlur={(e) => handleBlur(e, dataIndex)}
                          disabled={isDisabled}
                        />
                      </div>
                    </td>
                    <td className="col-unit">
                      <div className="text-left setting-table__data-unit col-unit">人/年</div>
                    </td>
                    <td>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
        <tr>
          <th rowSpan={Object.keys(GENDERS).length + 1}
            className="sticky left-0 z-10 setting-table__col-name"
          >
            <div className="flex items-center setting-heading">
              <div className="setting-heading__name">小計</div>
            </div>
          </th>
        </tr>
        {Object.keys(GENDERS).map((genderKey) => {
          const gender = GENDERS[genderKey];
          const subtotal = Object.values(subtotals).reduce((sum, group) => {
            const value = group[gender] !== undefined && group[gender] !== null ? group[gender].toString() : "0";
            return sum + parseInt(parseCommaDigit(value), 10);
          }, 0);
          const percentage = totals["all"] > 0 ? Math.round((subtotal / totals["all"]) * 100) : 0;
          return (
            <tr key={genderKey} className="setting-table__data">
              <td className="col-gender">
                <div className="flex items-center">
                  {gender}
                </div>
              </td>
              <td>
                <div className="flex items-center justify-end text-right setting-table__data-number">
                  {formatCommaDigit(subtotal)}
                </div>
              </td>
              <td className="col-unit">
                <div className="flex items-center text-left setting-table__data-unit col-unit">
                  人/年
                </div>
              </td>
              <td>
                <div className="flex items-center justify-end text-right setting-table__data-percentage">
                  ({percentage}%)
                </div>
              </td>
            </tr>
          );
        })}
        <tr className="setting-table__data">
          <th className="sticky left-0 z-10 setting-table__col-name">
            <div className="flex items-center setting-heading">
              <div className="setting-heading__name">合計</div>
            </div>
          </th>
          <td></td>
          <td>
            <div className="flex items-center justify-end text-right setting-table__data-number">
              {formatCommaDigit(totals["all"])}
            </div>
          </td>
          <td className="col-unit">
            <div className="flex items-center text-left setting-table__data-unit col-unit">
              人/年
            </div>
          </td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  };

  /**
  * 描画処理
  */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div>
                <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                <span style={{ color: "red" }}>{serverError.errorMessage}</span>
              </div>
              {/*  ===== 新卒採用の設定 ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">新卒採用予定の設定</h2>
                {/* 新卒年齢階層の設定 */}
                <div className="flex items-center justify-between mt-6">
                  将来の男女構成シミュレーションに必要となる、新卒の入社予定の設定を行ってください。
                </div>
                <div className="text-body-2-medium grow mt-6">■新卒年齢層の設定</div>
                <div className="flex items-center justify-between mb-6">
                  新卒社員の平均年齢層を選択してください。（選択した年齢層以下の年齢層について新卒の入社予定等の設定が可能となります）
                </div>
                <div className="flex rounded-xl">
                  <dl className="flex px-8 items-center">
                    <dd>
                      <ul className="flex gap-x-8">
                        {AGE_GROUPS.map((group, index) => (
                          <li key={group.level}>
                            <label className="ui-radio">
                              <input
                                type="radio"
                                id={`radioAgeGroup${group.level}`}
                                className="ui-radio__input"
                                name="ageGroup"
                                value={index}
                                defaultChecked={selectedAgeGroup.ageGroup === group.ageGroup}
                                onClick={() => changeAgeGroup(group.ageGroup)}
                              />
                              <span className="ui-radio__icon"></span>
                              <span className="ui-radio__ripple"></span>
                              <span className="ui-radio__label text-h7 font-Regular">{group.label}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                </div>
                {/* 新卒中心年齢の設定 */}
                <div className="text-body-2-medium grow mt-6">■新卒中心年齢の設定</div>
                <div className="flex items-center justify-between mb-6">
                  各年齢層において新卒入社の中心的な年齢を設定してください。（年長の年齢層に移行するのに要する平均期間を示す「同一年齢層 滞留年数」を想定します）
                </div>
                <div className="setting-table relative is-new-graduate setting-median-age-new-grad my-6">
                  {/*  グラデーション1 */}
                  <div className="setting-table__gradation--left"></div>
                  {/*  グラデーション2 */}
                  <div className="setting-table__gradation--right"></div>
                  {/*  表組み */}
                  <div className="setting-table__scroll overflow-x-auto">
                    <table className="setting-table__table is-new-graduate w-full form-control">
                      <colgroup>
                        <col style={{ width: "26%" }} />
                        <col style={{ width: "27%" }} />
                        <col style={{ width: "27%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="sticky left-0 z-20">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__col-name">年齢層</div>
                            </div>
                          </th>
                          <th key="1" className="sticky top-0">入社時年齢（中心層）</th>
                          <th key="2" className="sticky top-0">同一年齢層 滞留年数</th>
                        </tr>
                      </thead>
                      <tbody className="text-caption-2">
                        {settingMedianAgeNewGrad()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* エラーメッセージ表示（新卒中心年齢の設定） */}
                <div className="error-message">
                  {Array.from(new Set(Object.keys(inputError).filter(key => key.startsWith(`${DATA_KEYS.new_graduate_transfer_years}-`)).map(key => inputError[key]?.required))).map((message, idx) => (
                    <span key={idx} className="red--text">{idx !== 0 && <br />}{message}</span>
                  ))}
                </div>
                {/* 新卒入社予定人数の設定 */}
                <div className="text-body-2-medium grow mt-6">■新卒入社予定人数の設定</div>
                <div className="flex items-center justify-between mb-6">
                  年齢層ごとに、1年間の新卒入社予定人数を設定してください。
                </div>
                <div className="setting-table relative is-new-graduate setting-prospective-number-of-new-grad my-6">
                  {/*  グラデーション1 */}
                  <div className="setting-table__gradation--left"></div>
                  {/*  グラデーション2 */}
                  <div className="setting-table__gradation--right"></div>
                  {/*  表組み */}
                  <div className="setting-table__scroll overflow-x-auto table-container-test">
                    <table className="setting-table__table is-new-graduate w-full form-control">
                      <colgroup>
                        <col style={{ width: "26%" }} />
                        <col style={{ width: "27%" }} />
                        <col style={{ width: "27%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="sticky left-0 z-20">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__col-name">年齢層</div>
                            </div>
                          </th>
                          <th key="1" className="sticky top-0 setting-heading__col-gender">
                            性別
                          </th>
                          <th key="2" className="sticky top-0 setting-heading__col-count">
                            新卒入社予定人数
                          </th>
                          <th key="3" className="sticky top-0 setting-heading__col-unit"></th>
                          <th key="4" className="sticky top-0 setting-heading__col-percentage"></th>
                        </tr>
                      </thead>
                      <tbody className="text-caption-2">
                        {settingProspectiveNumberOfNewGrad()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* エラーメッセージ表示（新卒入社予定人数の設定） */}
                <div className="error-message">
                  {Array.from(new Set(Object.keys(inputError).filter(key => key.startsWith(`${DATA_KEYS.new_graduate}-`)).map(key => inputError[key]?.required))).map((message, idx) => (
                    <span key={idx} className="red--text">{idx !== 0 && <br />}{message}</span>
                  ))}
                </div>
                <div className="content-footer mt-16 mb-8">
                  <div className="border-t border-blue1" />
                  {/*  ===== フッターボタン ===== */}
                  <div className="footer_button_area mt-8">
                    <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                      <button type="button" className="footer-btn button_back">キャンセル</button>
                    </Link>
                    <button className="btn footer-btn button_base" onClick={() => updateNewGraduate()}>
                      <div>登録</div>
                    </button>
                  </div>
                </div>
              </div>
              {/*  ===== 関連ダッシュボード ===== */}
              <RelatedDashboard dashboards={master.RelatedDashboard} authority={props.authority} mode={props.mode} image={img_dashboard_reference} />
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

/**
 * NewGraduateコンポーネント
 * 
 * ユーザーの権限に基づいて 新卒採用設定ページを表示。
 * ユーザーが適切な権限を持っていない場合には、Forbiddenコンポーネントを表示。
 * 
 * @returns {JSX.Element} 新卒採用設定ページまたは、Forbiddenコンポーネント
 */
const NewGraduate = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Dashboard.NewGraduate_Set) {
    return <Forbidden />;
  }

  return (
    <NewGraduateInfoContent
      pageTitle={titleName.NewGraduateInfo + titleName.common}
      corporationNumber={state.company.corporationNumber}
      corporationName={state.company.name}
      bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
      accPeriod={state.company.accPeriod}
      userId={state.user.sub}
      userName={state.user.userName}
      authority={state.authority}
      mode={state.analysis.mode}
      navigate={navigate}
      showModal={showModal}
    />
  );
};

export default NewGraduate;
