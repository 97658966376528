import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { errorLog } from "../../../common/logger/Logger";
import { httpPost } from '../../../services/api/AmplifyApi'
import { formatCommaDigit, parseCommaDigit } from "../../../common/utils/NumberUtils";
import { getObject, getCsvByJson, getFileNameList } from "../../../common/utils/FileUtils";
import { getDynamoDB } from "../../../common/utils/DynamoDbUtils";
import { viewId, titleName, orderBy, table, restApiKey, masterClassification } from "../../../_constants/Code";
import { PathInfoList } from "../../../_constants/PathInfoList";
import { useModal } from "../../../_components/Modal";
import Forbidden from "../../../_components/Forbidden";
import RelatedDashboard from "../parts/RelatedDashboard";

// 定数定義
/**
 * 採用予定人数情報のカラム名
 * @constant
 * @type {Object}
 */
const COLUMNS = {
  YEAR: "年度",
  FISCAL_YEAR_MONTH: "決算年月",
  CATEGORY: "区分",
  DETAILED_CATEGORY: "詳細区分",
  PLANNED_HIRES: "人数"
};

/**
 * 詳細区分の分類（全体、障がい者以外、障がい者）
 * @constant
 * @type {Object}
 */
const SECTIONS = {
  ALL: "全体",
  NON_DISABLED: "障がい者以外",
  DISABLED: "障がい者"
};

/**
 * CSVデータ（カラム名）⇔ jsonデータ（属性名）のマッピング設定
 * @constant
 * @type {Object}
 */
const ATTRIBUTE_MAPPING = {
  "年度": "year",
  "決算年月": "fiscal_year_month",
  "区分": "category",
  "詳細区分": "detailed_category",
  "人数": "planned_hires"
};

/**
 * データをAPI送信形式に変換する関数
 * @param {DataItem[]} dataList - データリスト
 * @param {Object} mapping - 属性名のマッピングオブジェクト
 * @returns {Object[]} 変換後のデータリスト
 */
const convertToFileData = (dataList, mapping) => {
  return dataList.map(item => {
      const convertedItem = {};
      Object.keys(item).forEach(key => {
          const newKey = mapping[key];
            // 人数を数値型に変換
            convertedItem[newKey] = key === "人数" ? parseInt(item[key], 10) : item[key];
      });
      return convertedItem;
  });
};

/**
 * 年月の末日を計算する関数
 * @param {number} year - 年
 * @param {number} month - 月 (0から11の値)
 * @returns {number} - 月末日
 */
const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0).getDate();

/**
 * 年度、決算月から画面表示する決算期を生成する関数
 * @param {string} period - 決算月
 * @param {string} year - 年度
 * @returns {string} - 表示決算期
 */
const getPeriodYm = (period, year) => {
  return (period !== "12月" ? (parseInt(year.slice(0, 4)) + 1) + year.slice(4, 5) : year.slice(0, -1)) + period + "期";
};

/**
 * 日付文字列を「YYYY年M月」フォーマットに変換する関数
 * @param {string} dateString - 日付文字列（YYYY-MM-DD）
 * @returns {string} - フォーマット変換した日付文字列（YYYY年M月）
 */
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}年${month}月`;
};

/**
 * 年度リストを生成する関数
 * @param {number} startYear - 開始年度
 * @param {number} endYear - 終了年度
 * @returns {Array<string>} - 年度のリスト（配列）
 */
const generateYearRange = (startYear, endYear) => {
  let years = [];
  for (let i = startYear; i <= endYear; i++) years.push(`${i}年度`);
  return years;
};

/**
 * 各年月のデータフォーマット（採用予定人数）を生成する関数
 * @param {string} year - 年度
 * @param {string} accPeriod - 決算年月
 * @returns {Array<Object>} - 各年月のデータフォーマット（採用予定人数）
 */
const generateMonthlyData = (year, accPeriod) => {
  const sections = [SECTIONS.ALL, SECTIONS.NON_DISABLED, SECTIONS.DISABLED];
  let data = [];
  const yearNumber = parseInt(year.split(COLUMNS.YEAR)[0], 10);
  const startMonth = accPeriod === "12月" ? 0 : parseInt(accPeriod.slice(0, -1), 10);

  for (let i = 0; i < 12; i++) {
    const month = (startMonth + i) % 12;
    const displayYear = month < startMonth ? yearNumber + 1 : yearNumber;
    const lastDay = getLastDayOfMonth(displayYear, month);
    sections.forEach((section) => {
      data.push({
        [COLUMNS.YEAR]: year,
        [COLUMNS.FISCAL_YEAR_MONTH]: `${displayYear}-${String(month + 1).padStart(2, '0')}-${lastDay}`,
        [COLUMNS.CATEGORY]: "採用予定",
        [COLUMNS.DETAILED_CATEGORY]: section,
        [COLUMNS.PLANNED_HIRES]: ""
      });
    });
  };
  return data;
};

/**
 * 全年度のデータフォーマット（採用予定人数）を生成する関数
 * @param {number} startYear - 開始年度
 * @param {number} endYear - 終了年度
 * @param {string} accPeriod - 決算年月
 * @returns {Array<Object>} - 全年度のデータフォーマット（採用予定人数）
 */
const generateFormattedData = (startYear, endYear, accPeriod) => {
  const years = generateYearRange(startYear, endYear);
  let formattedData = [];
  years.forEach((year) => {
    formattedData = formattedData.concat(generateMonthlyData(year, accPeriod));
  });
  return formattedData;
};

/**
 * API取得データとフォーマットをマージする関数
 * @param {Array<Object>} formattedData - フォーマットデータ
 * @param {Array<Object>} apiData - API取得データ
 * @returns {Array<Object>} - マージデータ
 */
const mergeData = (formattedData, apiData) => {
  const mergedData = [...formattedData];
  apiData.forEach((apiRow) => {
    const index = mergedData.findIndex(
      (row) =>
        row[COLUMNS.YEAR] === apiRow[COLUMNS.YEAR] &&
        row[COLUMNS.FISCAL_YEAR_MONTH] === apiRow[COLUMNS.FISCAL_YEAR_MONTH] &&
        row[COLUMNS.CATEGORY] === apiRow[COLUMNS.CATEGORY] &&
        row[COLUMNS.DETAILED_CATEGORY] === apiRow[COLUMNS.DETAILED_CATEGORY]
    );
    if (index !== -1) mergedData[index][COLUMNS.PLANNED_HIRES] = apiRow[COLUMNS.PLANNED_HIRES];
  });
  return mergedData;
};

/**
 * 年度合計を計算する関数
 * @param {Array<Object>} data - データ
 * @param {string} section - 区分（全体、障がい者）
 * @returns {number} - 合計人数
 */
const calculateAnnualTotal = (data, section) =>
  data
    .filter(row => row[COLUMNS.DETAILED_CATEGORY] === section)
    .reduce((sum, row) => sum + (parseInt(parseCommaDigit(row[COLUMNS.PLANNED_HIRES]), 10) || 0), 0);

/**
 * 更新データの補完と不要データの削除を行う関数
 * @param {Array<Object>} data - データ
 * @returns {Array<Object>} - 加工データ
 */
const getDataList = (data) => {
  let dataList = JSON.parse(JSON.stringify(data));

  // 全体的にカンマを除去
  dataList.forEach(item => {
    item[COLUMNS.PLANNED_HIRES] = parseCommaDigit(item[COLUMNS.PLANNED_HIRES]);
  });

  // 補完処理
  dataList.forEach(item => {
    if (item[COLUMNS.CATEGORY] === "採用予定" && item[COLUMNS.DETAILED_CATEGORY] === SECTIONS.ALL && item[COLUMNS.PLANNED_HIRES] !== "") {
      const monthData = dataList.filter(data =>
        data[COLUMNS.YEAR] === item[COLUMNS.YEAR] &&
        data[COLUMNS.FISCAL_YEAR_MONTH] === item[COLUMNS.FISCAL_YEAR_MONTH] &&
        data[COLUMNS.CATEGORY] === "採用予定"
      );

      const disabledData = monthData.find(data => data[COLUMNS.DETAILED_CATEGORY] === SECTIONS.DISABLED);
      const nonDisabledData = monthData.find(data => data[COLUMNS.DETAILED_CATEGORY] === SECTIONS.NON_DISABLED);

      if (!disabledData || disabledData[COLUMNS.PLANNED_HIRES] === "") {
        // 障がい者の人数が空の場合、0で補完
        if (disabledData) {
          disabledData[COLUMNS.PLANNED_HIRES] = "0";
        } else {
          dataList.push({
            [COLUMNS.YEAR]: item[COLUMNS.YEAR],
            [COLUMNS.FISCAL_YEAR_MONTH]: item[COLUMNS.FISCAL_YEAR_MONTH],
            [COLUMNS.CATEGORY]: "採用予定",
            [COLUMNS.DETAILED_CATEGORY]: SECTIONS.DISABLED,
            [COLUMNS.PLANNED_HIRES]: "0"
          });
        }

        // 障がい者以外の人数を全体 - 障がい者で補完
        const total = parseInt(item[COLUMNS.PLANNED_HIRES], 10);
        const disabled = parseInt(disabledData ? disabledData[COLUMNS.PLANNED_HIRES] : "0", 10);
        const nonDisabled = (total - disabled).toString();

        if (nonDisabledData) {
          nonDisabledData[COLUMNS.PLANNED_HIRES] = nonDisabled;
        } else {
          dataList.push({
            [COLUMNS.YEAR]: item[COLUMNS.YEAR],
            [COLUMNS.FISCAL_YEAR_MONTH]: item[COLUMNS.FISCAL_YEAR_MONTH],
            [COLUMNS.CATEGORY]: "採用予定",
            [COLUMNS.DETAILED_CATEGORY]: SECTIONS.NON_DISABLED,
            [COLUMNS.PLANNED_HIRES]: nonDisabled
          });
        };
      };
    };
  });
  
  // 不要データの削除
  dataList = dataList.filter(item => {
    // 採用予定人数が空のデータを除外
    const isValidData = dataList.some(data =>
      data[COLUMNS.YEAR] === item[COLUMNS.YEAR] &&
      data[COLUMNS.FISCAL_YEAR_MONTH] === item[COLUMNS.FISCAL_YEAR_MONTH] &&
      data[COLUMNS.CATEGORY] === "採用予定" &&
      data[COLUMNS.DETAILED_CATEGORY] === item[COLUMNS.DETAILED_CATEGORY] &&
      data[COLUMNS.PLANNED_HIRES] !== ""
    );

    return isValidData;
  });

  return dataList;
};

/**
 * RecruitmentPlanContentコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.pageTitle - ページタイトル
 * @param {string} props.corporationNumber - 法人番号
 * @param {string} props.corporationName - 会社名
 * @param {string} props.bucketName - 企業バケット名
 * @param {string} props.accPeriod - 決算月
 * @param {string} props.userId - ユーザsub
 * @param {string} props.userName - ユーザ名
 * @param {function} props.navigate - ナビゲーション関数
 * @param {function} props.showModal - モーダル表示関数
 */
const RecruitmentPlanContent = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [master, setMaster] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [serverError, setServerError] = useState({});
  const [inputError, setInputError] = useState({});
  const [isOpenFiscalYear, setIsOpenFiscalYear] = useState(false);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState('');
  const [recruitmentPlan, setRecruitmentPlan] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [monthlyHeaders, setMonthlyHeaders] = useState([]);

  // 初期処理
  useEffect(() => {
    document.title = props.pageTitle;
    window.scrollTo(0, 0);
    init();
  }, []);

  // 初期処理完了後、スクロールバー設定
  useEffect(() => {
    if (isLoaded) setScrollBar();
  }, [isLoaded]);

  // 入力エラー時にスクロールをトップへ
  useEffect(() => {
    if (inputError.errorMessage || serverError.errorMessage) {
      window.scrollTo(0, 0);
    };
  }, [inputError, serverError]);

  /**
   * 初期処理
   * @returns {Promise<void>}
   */
  const init = async () => {
    // マスター情報取得
    const masterData = await fetchMasterData();
    setMaster(masterData);

    // 採用予定人数情報取得
    let fileData = await getObject(props.bucketName, PathInfoList.Folder.recruitmentPlanFolder + PathInfoList.Objectkeyname.recruitmentPlan);
    fileData = new File([fileData], PathInfoList.Objectkeyname.recruitmentPlan, { type: "text/csv" });
    const jsonData = await getCsvByJson(fileData); // blobからjsonに変換

    // 年度リスト設定
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const currentYear = `${props.accPeriod !== "12月" && month <= props.accPeriod.slice(0, -1) ? year - 1 : year}年度`;
    const yearList = await fetchFiscalYearList(currentYear, jsonData);

    // 採用予定人数情報設定
    if (yearList && yearList.length > 0) {
      setSelectedFiscalYear(currentYear);
      const startYear = parseInt(yearList[yearList.length - 1].split(COLUMNS.YEAR)[0], 10);
      const endYear = parseInt(yearList[0].split(COLUMNS.YEAR)[0], 10);
      const formattedData = generateFormattedData(startYear, endYear, props.accPeriod);

      const mergedData = mergeData(formattedData, jsonData);
      setRecruitmentPlan(mergedData);

      const filteredData = mergedData.filter(row => row[COLUMNS.YEAR] === currentYear);
      setFilteredTableData(filteredData);

      const headers = Array.from(new Set(filteredData.map(row => formatDate(row[COLUMNS.FISCAL_YEAR_MONTH]))));
      setMonthlyHeaders(headers);
    }

    // 初期処理完了判定設定
    setIsLoaded(true);
  };

  /**
   * マスター情報取得関数
   * @returns {Promise<Object>} 取得したマスター情報
   */
  const fetchMasterData = async () => {
    try {
      const masterData = await getDynamoDB(
        table.Master,
        null,
        props.corporationNumber,
        { "CorporationNumber": props.corporationNumber, "MasterId": viewId.recruitmentPlanInfo },
        null
      );
      return masterData[0];
    } catch (error) {
      errorLog("E000001", props.userId, viewId.recruitmentPlanInfo, "fetchMasterData", error);
    };
  };

  /**
   * 年度リスト取得関数
   * @param {string} currentYear - 現在年度
   * @param {Array<Object>} recruitmentPlan - 採用予定人数
   * @returns {Promise<string[]>} 年度リスト
   */
  const fetchFiscalYearList = async (currentYear, recruitmentPlan) => {
    try {
      const baseKey = process.env.REACT_APP_FOLDER_DATA_SET_BASE;
      const fileKey = process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL;
      // 標準フォーマット（年次）のファイル名取得
      const fileList = await getFileNameList(props.bucketName, baseKey + fileKey, orderBy.asc);
      // 決算期開始年度判定
      let startYear = fileList.length
        ? fileList.map(item => item.split("_")[0]).filter(item => !isNaN(item)).sort((a, b) => parseInt(a, 10) + parseInt(b, 10))[0]
        : recruitmentPlan.length
          ? recruitmentPlan.map(item => item.年度).sort((a, b) => b.localeCompare(a))[0]
          : currentYear.split(COLUMNS.YEAR)[0];

      const yearList = []
      for (let year = parseInt(startYear, 10); year <= parseInt(currentYear.split(COLUMNS.YEAR)[0], 10) + 5; year++) yearList.unshift(`${year}年度`);

      // 年度リスト設定
      setFiscalYearList(yearList);
      return yearList;
    } catch (error) {
      errorLog("E000001", props.userId, viewId.recruitmentPlanInfo, "fetchFiscalYearList", error);
    };
  };

  /**
   * 選択年度更新処理
   * @param {string} value - 選択年度
   */
  const updateSelectedFiscalYear = (value) => {
    setSelectedFiscalYear(value);
    toggleFiscalYearDropdown(false);
    const filteredData = recruitmentPlan.filter(row => row[COLUMNS.YEAR] === value);
    setFilteredTableData(filteredData);
    const headers = Array.from(new Set(filteredData.map(row => formatDate(row[COLUMNS.FISCAL_YEAR_MONTH]))));
    setMonthlyHeaders(headers);
  };

  /**
   * 年度ドロップダウンの開閉を切り替え
   * @param {boolean} isOpen - ドロップダウン開閉判定
   */
  const toggleFiscalYearDropdown = (isOpen) => {
    setIsOpenFiscalYear(isOpen);
    document.getElementById("fiscalYearButton").classList.toggle('is-active', isOpen);
  };

  /**
   * 採用予定人数設定テーブルを左にスクロール
   * @param {Object} e - イベントオブジェクト
   */
  const scrollLeft = (e) => {
    e.target.closest('.setting-table').querySelector('.setting-table__scroll').scrollBy({ left: -130, behavior: "smooth" });
  };

  /**
   * 採用予定人数設定テーブルを右にスクロール
   * @param {Object} e - イベントオブジェクト
   */
  const scrollRight = (e) => {
    e.target.closest('.setting-table').querySelector('.setting-table__scroll').scrollBy({ left: 130, behavior: "smooth" });
  };

  /**
   * スクロールバー設定
   */
  const setScrollBar = () => {
    // ＜ボタンにdisabledをつける
    document.querySelectorAll(".setting-table__btn-scroll-left").forEach(v => v.setAttribute('disabled', ''));
    // スクロールイベント登録
    document.querySelectorAll('.setting-table__scroll').forEach(element => {
      if (element.classList.contains("is-scroll")) return;
      element.addEventListener('scroll', (event) => {
        // 要素の取得
        const $parent = event.target.closest('.setting-table');
        const $tableWrapper = $parent.querySelector('.setting-table__scroll');
        const $btnScrollLeft = $parent.querySelector('.setting-table__btn-scroll-left');
        const $btnScrollRight = $parent.querySelector('.setting-table__btn-scroll-right');
        const isScrollEnd = $tableWrapper.scrollWidth - ($tableWrapper.scrollLeft + $tableWrapper.clientWidth) < 1;

        $btnScrollLeft.toggleAttribute('disabled', $tableWrapper.scrollLeft === 0);
        $btnScrollRight.toggleAttribute('disabled', isScrollEnd);
      });
      element.classList.add("is-scroll");
    });
  };

  /**
   * 入力欄「採用予定人数」からフォーカスが外れた際の処理
   * @param {Object} e - イベントオブジェクト
   * @param {Object} row - 行データ
   * @param {number} dataIndex - データのインデックス
   */
  const handleBlur = (e, row, dataIndex) => {
    let value = e.target.value;

    // 負数が入力されている場合、値をクリア
    if (parseFloat(value) < 0) {
      value = "";
    } else {
      value = formatCommaDigit(value);
    };

    // 更新される行の人数を変更
    const updatedData = [...recruitmentPlan];
    updatedData[dataIndex][COLUMNS.PLANNED_HIRES] = value;

    // 「障がい者以外」の人数を再計算
    if (row[COLUMNS.DETAILED_CATEGORY] === SECTIONS.ALL || row[COLUMNS.DETAILED_CATEGORY] === SECTIONS.DISABLED) {
      const totalRow = updatedData.find(r => r[COLUMNS.YEAR] === row[COLUMNS.YEAR] && r[COLUMNS.FISCAL_YEAR_MONTH] === row[COLUMNS.FISCAL_YEAR_MONTH] && r[COLUMNS.DETAILED_CATEGORY] === SECTIONS.ALL);
      const disabledRow = updatedData.find(r => r[COLUMNS.YEAR] === row[COLUMNS.YEAR] && r[COLUMNS.FISCAL_YEAR_MONTH] === row[COLUMNS.FISCAL_YEAR_MONTH] && r[COLUMNS.DETAILED_CATEGORY] === SECTIONS.DISABLED);
      const nonDisabledRow = updatedData.find(r => r[COLUMNS.YEAR] === row[COLUMNS.YEAR] && r[COLUMNS.FISCAL_YEAR_MONTH] === row[COLUMNS.FISCAL_YEAR_MONTH] && r[COLUMNS.DETAILED_CATEGORY] === SECTIONS.NON_DISABLED);

      if (totalRow && nonDisabledRow) {
        let total = totalRow[COLUMNS.PLANNED_HIRES] !== "" ? parseInt(parseCommaDigit(totalRow[COLUMNS.PLANNED_HIRES]), 10) : null;
        let disabled = disabledRow && disabledRow[COLUMNS.PLANNED_HIRES] !== "" ? parseInt(parseCommaDigit(disabledRow[COLUMNS.PLANNED_HIRES]), 10) : null;

        if (total === null) {
          nonDisabledRow[COLUMNS.PLANNED_HIRES] = "";
        } else {
          if (disabled === null) disabled = 0;
          nonDisabledRow[COLUMNS.PLANNED_HIRES] = (total - disabled).toString();
        };
      };
    };

    setRecruitmentPlan(updatedData);
    setFilteredTableData(updatedData.filter(row => row[COLUMNS.YEAR] === selectedFiscalYear));
  };

  /**
   * 入力欄「採用予定人数」の設定値が変更された際の処理
   * @param {Object} e - イベントオブジェクト
   * @param {Object} row - 行データ
   * @param {number} dataIndex - データのインデックス
   */
  const handleChange = (e, row, dataIndex) => {
    let value = e.target.value;

    // 更新された行の人数を変更
    const updatedData = [...recruitmentPlan];
    updatedData[dataIndex][COLUMNS.PLANNED_HIRES] = value;

    setRecruitmentPlan(updatedData);
    setFilteredTableData(updatedData.filter(row => row[COLUMNS.YEAR] === selectedFiscalYear));
  };

  /**
   * 入力チェック
   * @returns {boolean} エラー判定
   */
  const _checkInput = () => {
    let inputError = {};
    try {
      const list = JSON.parse(JSON.stringify(recruitmentPlan));

      list.forEach(ar => {
        const error = {};
        const total = parseInt(parseCommaDigit(ar[COLUMNS.PLANNED_HIRES]), 10);
        const disabledRow = list.find(r => r[COLUMNS.YEAR] === ar[COLUMNS.YEAR] && r[COLUMNS.FISCAL_YEAR_MONTH] === ar[COLUMNS.FISCAL_YEAR_MONTH] && r[COLUMNS.DETAILED_CATEGORY] === SECTIONS.DISABLED);
        const disabled = disabledRow ? parseInt(parseCommaDigit(disabledRow[COLUMNS.PLANNED_HIRES]), 10) || 0 : 0;

        if (ar[COLUMNS.DETAILED_CATEGORY] === SECTIONS.ALL) {
          const period = formatDate(ar[COLUMNS.FISCAL_YEAR_MONTH]);

          // -- 整合性チェック --
          if ((isNaN(total) && disabled > 0) || (!isNaN(total) && total < disabled)) {
            error.numMismatch = `${period}の「採用人数」には「うち、障害者」人数以上の数値を入力してください。`;
          };
        };
        // エラー判定
        if (Object.keys(error).length === 0) {
          return false;
        } else {
          inputError[ar[COLUMNS.FISCAL_YEAR_MONTH]] = error;
          inputError.errorMessage = '入力内容に誤りがあります。'
          return true;
        }
      });
      return Object.keys(inputError).length > 0;
    } finally {
      // 入力エラーを設定
      setInputError(inputError);
    };
  };

  /**
   * 採用予定人数更新処理
   * @returns {Promise<void>}
   */
  const updateRecruitmentPlan = async () => {
    if (_checkInput()) {
      return;
    }

    try {
      const title = 'マスター設定値登録の確認';
      const description = (
        <React.Fragment>
          <div className="text-center">
            設定値を登録してダッシュボードに反映します。よろしいですか？
          </div>
          <div className="pt-5 text-center">
            ※ダッシュボードへの反映には5分～10分程度かかります<br />
            マスター管理画面のステータスから反映状況を確認してください
          </div>
        </React.Fragment>
      );
      const buttons = [{
        name: '実行',
        className: 'footer-btn button_base',
        click: async () => {
          try {
            setServerError({});
            // 更新データ生成処理
            const dataList = getDataList(recruitmentPlan);
            // 更新データをAPI送信形式に変換（人数を数値型に変換・json属性名変換）
            const convertDataList = convertToFileData(dataList, ATTRIBUTE_MAPPING);
            const init = {
              body: {
                request: {
                  corporation_number: props.corporationNumber,
                  corporation_name: props.corporationName,
                  user_name: props.userName,
                  file_data: convertDataList,
                  user_sub: props.userId,
                },
              },
              headers: {},
            };
            // IF02041_採用予定人数更新処理
            await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.updateRecruitmentPlan(props.corporationNumber), init)
              .then((res) => {
                if (res.message.indexOf('Success') !== -1) {
                  props.navigate('/master/masterList', { state: { message: 'データ反映を開始しました。' } });
                } else {
                  setServerError({ errorMessage: '更新処理でエラーが発生しました。' });
                }
              });
          } catch (error) {
            errorLog("E000001", props.userId, viewId.recruitmentPlanInfo, "updateRecruitmentPlan", error);
          }
        }
      }];
      props.showModal({ title: title, description: description, button: buttons, isWide: true });
    } catch (error) {
      errorLog("E000001", props.userId, viewId.recruitmentPlanInfo, "updateRecruitmentPlan", error);
    };
  };

  /**
  * 描画処理
  */
  if (!isLoaded) {
    return null;
  }
  return (
    <React.Fragment>
      <main className="page-container">
        <section className="page-content">
          <div className="centered-container">
            {/* 上階層に戻る */}
            <div className="bg main">
              <div className="back-upper-level py-8 pl-8 border-b border-blue2-lighten-20">
                <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                  <span className="back-upper-level__link link--text hover:lighten-70 flex items-center">
                    <span className="icon-arrow-circle icon-32px mr-4 rotate-180 relative after:absolute after:bg-white after:w-full after:h-full after:rounded-full after:left-0 after:-z-10"></span>
                    <span className="text-h5 font-bold">{titleName.masterList}画面に戻る</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* コンテンツヘッダ */}
            <header className="page-header flex justify-center py-1">
              <h1 className="page-header__name text-h1 grey--text py-8">{masterClassification[master.Classification].name}</h1>
            </header>
            {/*  コンテンツボディ */}
            <div className="page-body">
              <div>
                <span style={{ color: "red" }}>{inputError.errorMessage}</span>
                <span style={{ color: "red" }}>{serverError.errorMessage}</span>
              </div>
              {/*  ===== 採用予定人数の設定 ===== */}
              <div className="box-xy-bordered rounded-t-3xl rounded-b-2xl pt-10 pb-8 px-16 border-blue1">
                <h2 className="box-bordered-heading blue1 white--text text-h3 font-medium pt-2.5 pb-3 px-14 -mt-10 -mx-16 rounded-t-2xl">採用予定人数の設定</h2>
                <div className="text-body-2-medium grow mt-6">■採用予定人数</div>
                <div className="flex items-center justify-between mb-6">
                  年度ごとの採用予定人数（新卒・中途合計）を設定してください。
                </div>
                <div className="box-graph__target-fiscal-year flex items-center">
                  <div className="text-h5">対象：</div>
                  <div className="action-area" style={{ position: "relative" }}>
                    <div className="year-container" tabIndex="0" onBlur={() => toggleFiscalYearDropdown(false)}>
                      <div
                        id="fiscalYearButton"
                        className={`yearButton flex items-center justify-between rounded-xl pl-4 pr-2 ${!isLoaded ? ' is-disabled' : ''}`}
                        style={{ paddingTop: "4px", paddingBottom: "4px" }}
                        onClick={() => toggleFiscalYearDropdown(!isOpenFiscalYear)}
                      >
                        <div className="yearButton__text text-h7 mr-2">{getPeriodYm(props.accPeriod, selectedFiscalYear)}</div>
                        <div className="yearButton__icon flex items-center justify-center w-8 h-8">
                          <span className="icon-arrow"></span>
                        </div>
                      </div>
                      <ul className="year rounded-xl" hidden={!isOpenFiscalYear || !isLoaded} style={{ zIndex: 30 }}>
                        {fiscalYearList.map((value, index) => (
                          <li key={index} className="item text-h7" onClick={() => updateSelectedFiscalYear(value)}>
                            {getPeriodYm(props.accPeriod, value)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="setting-table relative is-recruitment-plan my-6">
                  {/*  左にスクロールするボタン
                    非活性時はdisabled属性を追加
                  */}
                  <button type="button" className="setting-table__btn-scroll-left button w-8 h-8 flex items-center justify-center absolute z-20" onClick={scrollLeft}>
                    <span className="icon-arrow white--text rotate-180"></span>
                  </button>
                  {/*  右にスクロールするボタン
                    非活性時はdisabled属性を追加
                  */}
                  <button type="button" className="setting-table__btn-scroll-right button w-8 h-8 flex items-center justify-center absolute z-20" onClick={scrollRight}>
                    <span className="icon-arrow white--text"></span>
                  </button>
                  {/*  グラデーション1 */}
                  <div className="setting-table__gradation--left"></div>
                  {/*  グラデーション2 */}
                  <div className="setting-table__gradation--right"></div>
                  {/*  表組み */}
                  <div className="setting-table__scroll overflow-x-auto">
                    <table className="setting-table__table w-full form-control">
                      <thead>
                        <tr>
                          <th className="sticky left-0 z-20">
                            <div className="flex items-center setting-heading">
                              <div className="setting-heading__col-name-section">区分</div>
                              <div className="setting-heading__col-name-total">年度合計</div>
                            </div>
                          </th>
                          {monthlyHeaders.map((header, idx) => (
                            // 決算期が12月　→　年度の末尾1文字削除 + "月期"（2021年度 → 2021年12月期）
                            // 決算期が12月以外　→　年度の一年後 + "年" + "月期"（2021年度 → 2022年3月期）
                            <th key={idx} className="sticky top-0 th-monthly">{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="text-caption-2">
                        {[SECTIONS.ALL, SECTIONS.DISABLED].map((section, sectionIdx) => (
                          <tr key={sectionIdx}>
                            <th className="sticky left-0 z-10">
                              <div className="flex items-center setting-heading">
                                <div className="setting-heading__text-section">
                                  {section === SECTIONS.ALL ? "採用人数" : "うち、障害者"}
                                </div>
                                <div className="setting-heading__text-total blue2--text">
                                  {formatCommaDigit(calculateAnnualTotal(filteredTableData, section))}人
                                </div>
                              </div>
                            </th>
                            {monthlyHeaders.map((header, idx) => {
                              const row = filteredTableData.find(row => {
                                const monthYear = formatDate(row[COLUMNS.FISCAL_YEAR_MONTH]);
                                return monthYear === header && row[COLUMNS.DETAILED_CATEGORY] === section;
                              });
                              const dataIndex = recruitmentPlan.findIndex(data => data[COLUMNS.YEAR] === row[COLUMNS.YEAR] && data[COLUMNS.FISCAL_YEAR_MONTH] === row[COLUMNS.FISCAL_YEAR_MONTH] && data[COLUMNS.DETAILED_CATEGORY] === row[COLUMNS.DETAILED_CATEGORY]);

                              if (dataIndex === -1) return null;
                              return (
                                <td key={idx}>
                                  <input
                                    className={`text-input ${row[COLUMNS.DETAILED_CATEGORY] === SECTIONS.ALL && inputError[row[COLUMNS.FISCAL_YEAR_MONTH]]?.numMismatch ? "is-error" : ""}`}
                                    type="text"
                                    name={"text_" + idx}
                                    placeholder="数値を入力"
                                    value={row ? row[COLUMNS.PLANNED_HIRES] : ""}
                                    onFocus={(e) => { e.target.value = parseCommaDigit(e.target.value); }}
                                    onBlur={(e) => { handleBlur(e, row, dataIndex); }}
                                    onChange={(e) => { handleChange(e, row, dataIndex); }}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* エラーメッセージ表示 */}
                {Object.keys(inputError).filter(key => key !== "errorMessage").map((key, idx) => (
                  <span key={key} className="red--text">{idx != 0 && <br />}{inputError[key].numMismatch}</span>
                ))}
                <div className="content-footer mt-16 mb-8">
                  <div className="border-t border-blue1" />
                  {/*  ===== フッターボタン ===== */}
                  <div className="footer_button_area mt-8">
                    <Link color="inherit" variant="button" underline="none" component={RouterLink} to="/master/masterList">
                      <button type="button" className="footer-btn button_back">キャンセル</button>
                    </Link>
                    <button className="btn footer-btn button_base" onClick={() => updateRecruitmentPlan()}>
                      <div>登録</div>
                    </button>
                  </div>
                </div>
              </div>
              {/*  ===== 関連ダッシュボード ===== */}
              <RelatedDashboard dashboards={master.RelatedDashboard} authority={props.authority} mode={props.mode} />
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

/**
 * RecruitmentPlanコンポーネント
 * 
 * ユーザーの権限に基づいて 採用予定人数設定ページを表示。
 * ユーザーが適切な権限を持っていない場合には、Forbiddenコンポーネントを表示。
 * 
 * @returns {JSX.Element} 採用予定人数設定ページまたは、Forbiddenコンポーネント
 */
const RecruitmentPlan = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const showModal = useModal();

  // 権限を持っていない場合は画面表示しない
  if (!state.authority.Master_AuthInfo.Availability || !state.authority.Master_AuthInfo.Auth_Conf.Dashboard.RecruitmentPlan_Set) {
    return <Forbidden />;
  }

  return (
    <RecruitmentPlanContent
      pageTitle={titleName.RecruitmentPlanInfo + titleName.common}
      corporationNumber={state.company.corporationNumber}
      corporationName={state.company.name}
      bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + state.company.corporationNumber}
      accPeriod={state.company.accPeriod}
      userId={state.user.sub}
      userName={state.user.userName}
      authority={state.authority}
      mode={state.analysis.mode}
      navigate={navigate}
      showModal={showModal}
    />
  );
};

export default RecruitmentPlan;
