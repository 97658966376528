import { Storage } from "aws-amplify";
import { Buffer } from 'buffer';
import { load } from 'js-yaml';
import Encoding from "encoding-japanese";
import { parse } from "papaparse"
import ExcelJS from "exceljs";

import { PathInfoList } from "../../_constants/PathInfoList";
import { restApiKey } from "../../_constants/Code";
import { getDatetimeByYmdhms } from "../../common/utils/DateUtils";
import { httpGet, httpPost } from '../../services/api/AmplifyApi';

const downloadBase64File = (contentBase64, fileName) => {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
};

export const download = async (bucketName, objectKeyName) => {
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.downloadObject(bucketName), init)
    .then((res) => {
      downloadBase64File(res, objectKeyName.split("/").slice(-1)[0]);
    })
    .catch((err) => {
      throw new Error(err)
    });
};


export const getFile = async (folder) => {
  const result = await Storage.list(folder);
  return result;
};

export const putFile = async (folder, file) => {
  const result = await Storage.put(folder + file.name, file, {
    level: "private"
  });
  return result;
};

export const putFileNoName = async (folder, file) => {
  const result = await Storage.put(folder, file, {
    level: "private"
  });
  return result;
};

// export const moveToCorporationBucket = async (corporationNumber, userSub, fileName) => {
//   let result = null;
//   try {
//     const init = {
//       body: {
//         request: {
//           user_sub: userSub,
//           file_name: fileName
//         },
//       },
//       headers: {},
//     };
//     await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.moveToCorporationBucket(corporationNumber), init)
//       .then((res) => {
//         result = res;
//       })
//       .catch((err) => {
//         throw new Error(err)
//       });
//   } catch (err) {
//     throw new Error(err)
//   }
//   return result;
// }

const downloadBlob = async (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

const downloadFile = async (folder, file) => {
  const result = await Storage.get(folder + file, {
    download: true,
    cacheControl: "no-cache",
  });
  return result;
};

export const downloadGuide = async (backetName, objectKeyName) => {
  try {
    const init = {
      queryStringParameters: {
        bucket_name: backetName,
        object_key_name: objectKeyName,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY_CHROFY_COMMON
      }
    };
    await httpGet(restApiKey.CHROFY_REST_API_COMMON, PathInfoList.Resource.downloadCommonObject, init)
      .then((res) => {
        downloadBase64File(res, objectKeyName.split("/").slice(-1)[0]);
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  };
};

const getJsonObject = async (backetName, objectKeyName) => {
  let jsonData = null;
  try {
    const init = {
      queryStringParameters: {
        object_key_name: objectKeyName,
      },
      headers: {},
    };
    await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.jsonObject(backetName), init)
      .then((res) => {
        jsonData = res.data;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  };
  return jsonData;
}

let dashboardInfo = null;
export const readDashboardId = async (backetName) => {
  // Dashboardファイル読み込む（初回のみ）
  if (dashboardInfo === null) {
    const objectKeyName = PathInfoList.Folder.dashboardFolder + PathInfoList.Objectkeyname.dashboardFile;
    dashboardInfo = await getJsonObject(backetName, objectKeyName);
  }
  return dashboardInfo;
};

const csvToJson = (csvStr) => {
  var csvArray = csvStr.split("\n"); // 1行ごとに分割する

  var jsonArray = [];

  var items = csvArray[0].split(","); // ヘッダ行

  // データ行加工
  for (var i = 1; i < csvArray.length - 1; i++) {
    var a_line = new Object();
    var csvArrayD = csvArray[i].split(",");
    for (var j = 0; j < items.length; j++) {
      a_line[items[j]] = csvArrayD[j];
    }
    jsonArray.push(a_line);
  }
  return jsonArray;
};

const strToJson = (str) => {
  const jsonObj = JSON.parse(str);
  return jsonObj;
};

const blobToStr = async (blob) => {
  const blobText = await blobToText(blob);
  return blobText;
};

/**
 * 非同期にBlobをテキストとして読み込みます。
 */
const blobToText = async blob => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject();
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(blob);
  });
};

/**
 * ダッシュボードページテキストを取得
 */
let pageTextInfo = null;
export const getPageText = async () => {
  // 説明文を取得（初回のみ）
  if (pageTextInfo === null) {
    const result = await downloadFile(
      PathInfoList.Folder.textFolder,
      PathInfoList.Objectkeyname.textSettingFile
    );
    const str = await blobToStr(result.Body);
    pageTextInfo = strToJson(str);
  }
  return pageTextInfo;
};

export const getFileList = async (bucketName, objectKeyName, orderBy) => {
  let fileList = [];
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
      order_by: orderBy,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.fileList(bucketName), init)
    .then((res) => {
      fileList = res.fileList;
      if (fileList.length != 0) {
        fileList = fileList.map((file) => {
          return {
            key: file.key,
            lastModified: getDatetimeByYmdhms(file.lastModified),
            size: file.size
          }
        })
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
  return fileList;
};

export const getFileNameList = async (bucketName, objectKeyName, orderBy) => {
  let fileNameList = [];
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
      order_by: orderBy,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.fileNameList(bucketName), init)
    .then((res) => {
      fileNameList = res.fileNameList;
    })
    .catch((err) => {
      throw new Error(err);
    });
  return fileNameList;
};

export const getS3Select = async (bucketName, objectKeyName, clomunKeyName, Num, orderBy, removeDuplicates) => {
  let list = [];
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
      clomun_key_name: clomunKeyName,
      num: Num,
      order_by: orderBy,
      remove_duplicates: removeDuplicates,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.s3Select(bucketName), init)
    .then((res) => {
      list = res.list;
    })
    .catch((err) => {
      throw new Error(err);
    });
  return list;
};

export const getBenchmarkCompany = async (corporationNumber, objectKeyName, column, searchWord) => {
  let list = [];
  if (column === 'CorporationNumber') {
    searchWord = encodeURIComponent(searchWord.join(','));
  }
  const init = {
    queryStringParameters: {
      objectKeyName: objectKeyName,
      column: column,
      searchWord: searchWord
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getBenchmarkCompany(corporationNumber), init)
    .then((res) => {
      list = res.list;
    })
    .catch((err) => {
      throw new Error(err);
    });
  return list;
};

export const getIndustrySector = async (corporationNumber, type) => {
  let list = [];
  const init = {
    queryStringParameters: {
      type: type,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.getIndustrySector(corporationNumber), init)
    .then((res) => {
      list = res;
    })
    .catch((err) => {
      throw new Error(err)
    });
  return list;
};

// CHROFY環境のリソースを参照する時のみ使用すること
export const getObjectList = async (bucketName, objectKeyName) => {
  let list = [];
  const init = {
    queryStringParameters: {
      bucket_name: bucketName,
      object_key_name: objectKeyName,
    },
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY_CHROFY_COMMON
    },
  };
  await httpGet(restApiKey.CHROFY_REST_API_COMMON, PathInfoList.Resource.getObject, init)
    .then((res) => {
      list = res;
    })
    .catch((err) => {
      throw new Error(err)
    });
  return list;
}

// CHROFY環境のリソースを参照する時のみ使用すること
export const getObjectFromWebCommon = async (objectKeyName) => {
  let result;
  const init = {
    queryStringParameters: {
      bucket_name: process.env.REACT_APP_S3_WEB_CHROFY_COMMON,
      object_key_name: objectKeyName,
    },
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY_CHROFY_COMMON
    },
  };
  await httpGet(restApiKey.CHROFY_REST_API_COMMON, PathInfoList.Resource.downloadCommonObject, init)
    .then((res) => {
      const text = Buffer.from(res, 'base64');
      result = text;
    })
    .catch((err) => {
      throw new Error(err)
    });
  return result;
}

// IF2023:ファイルデータ取得
export const getObject = async (bucketName, objectKeyName) => {
  let result;
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.downloadObject(bucketName), init)
    .then((res) => {
      return result = Buffer.from(res, "base64");
    })
    .catch((err) => {
      throw new Error(err)
    });
  return result;
}

export const getCsvByJson = async (blob) => {
  try {
    // const result = await downloadFile(folder, file);
    const csvStr = await blobToStr(blob);
    const jsonObj = csvToJson(csvStr);
    return jsonObj;
  } catch (err) {
    throw new Error(err);
  }
};

export const getLatestDataSet = async (bucketName) => {
  let latestDataSet = null;
  const init = {
    queryStringParameters: {
      base_key: process.env.REACT_APP_FOLDER_DATA_SET_BASE,
      annual_key: process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL,
      quarter_key: process.env.REACT_APP_FOLDER_STANDARD_FORMAT_QUARTER,
      month_key: process.env.REACT_APP_FOLDER_STANDARD_FORMAT_MONTH,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.latestDataSet(bucketName), init)
    .then((res) => {
      latestDataSet = res
    })
    .catch((err) => {
      throw new Error(err);
    });
  return latestDataSet;
};

/**
 * S3（企業バケット）からYAMLファイルを取得
 * @param {バケット名} bucketName 
 * @param {オブジェクト名} objectKeyName 
 * @returns YAMLデータ（Object）
 */
export const getYamlObject = async (bucketName, objectKeyName) => {
  let jsonData = {};
  const init = {
    queryStringParameters: {
      object_key_name: objectKeyName,
    },
    headers: {},
  };
  await httpGet(restApiKey.CHROFY_REST_API, PathInfoList.Resource.downloadObject(bucketName), init)
    .then((res) => {
      const text = Buffer.from(res, 'base64').toString();
      jsonData = load(text);
    })
    .catch((err) => {
      throw new Error(err)
    });
  return jsonData;
}

// IF02030:データファイルアップロードチェック
export const uploadFile = async (corporationNumber, init) => {
  let result = null;
  try {
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.uploadFile(corporationNumber), init)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  }
  return result.message;
}

// IF02031:レポートデータ更新処理
export const dataCreate = async (userSub, corporationNumber, periodKind, tableKey) => {
  let result = null;
  try {
    const init = {
      body: {
        request: {
          user_sub: userSub,
          corporation_number: corporationNumber,
          period_kind: periodKind,
          table_key: tableKey,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.dataCreate(corporationNumber), init)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  }
  return result.message;
}

// IF02032:レポートデータ確定処理
export const dataFix = async (corporationNumber, userSub, userName, key) => {
  let result = null;
  try {
    const init = {
      body: {
        request: {
          corporation_number: corporationNumber,
          user_sub: userSub,
          user_name: userName,
          key: key,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.dataFix(corporationNumber), init)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  }
  return result.message;
}

// IF02033:レポートデータ確定解除処理
export const dataFixCancel = async (corporationNumber, operationUserSub, operationUserName, permitUserName, permitDate, key) => {
  let result = null;
  try {
    const init = {
      body: {
        request: {
          corporation_number: corporationNumber,
          operation_user_sub: operationUserSub,
          operation_user_name: operationUserName,
          permit_user_name: permitUserName,
          permit_date: permitDate,
          key: key,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.dataFixCancel(corporationNumber), init)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  }
  return result.message;
}

// IF02036:財務ファイルアップロード処理
export const uploadAccountingFile = async (corporationNumber, identityId, pk, item) => {
  let result = null;
  try {
    const init = {
      body: {
        request: {
          identityId: identityId,
          pk: pk,
          item: item,
        },
      },
      headers: {},
    };
    await httpPost(restApiKey.CHROFY_REST_API, PathInfoList.Resource.uploadAccountingFile(corporationNumber), init)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        throw new Error(err)
      });
  } catch (err) {
    throw new Error(err)
  }
  return result.message;
}

/**
 * CSVファイル読み取り
 * @param {File} fileData ファイル
 */
export const readCsvFile = async (fileData) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const codes = new Uint8Array(e?.target?.result);
      const encoding = Encoding.detect(codes);
      parse(fileData, {
        encoding: encoding,
        complete: (results) => {
          resolve(results.data);
        },
        error: () => {
          reject(new Error("csv parse err"));
        }
      });
    };
    reader.readAsArrayBuffer(fileData);
  });
}

/**
 * エクセルファイル読み取り
 * @param {File} fileData ファイル
 */
export const readExcelFile = async (fileData) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const codes = new Uint8Array(e?.target?.result);
      const workbook = new ExcelJS.Workbook();
      try {
        await workbook.xlsx.load(codes);
        resolve(workbook);
      } catch (e) {
        reject(new Error("excel load err"));
      }
    };
    reader.readAsArrayBuffer(fileData);
  });
}